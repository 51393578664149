import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clientService from '../service/clientUri';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import gql from 'graphql-tag';
import LinearProgress from '@material-ui/core/LinearProgress';
import preferenceService from '../service/preference';
import Snackbars from '../../core/error/Snackbars';
import { Mutation } from 'react-apollo';
import { resetFilterService } from '../login/services/reset-filter';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import '../../qBot/components/change-password/change-password.css';


const client = clientService.getClienUri();

const CHANGE_PASSWORD = gql`
mutation changePassword($userId: Long!, $currentPassword: String!, $newPassword: String!) {
    changePassword(
        userId: $userId,
        currentPassword: $currentPassword,
        newPassword: $newPassword,
    )
}
`;

class AppLogOut extends Component {

    constructor(pros) {
        super(pros);
        this.logOut = this.logOut.bind(this);
        this.state = {
            open: false,
            userId: preferenceService.getValue('users', 'local') ? preferenceService.getValue('users', 'local').id : null,
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleSnackbars = this.toggleSnackbars.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClickOpen.bind(this);
    }

    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.newPassword) {
                return false;
            }
            return true;
        });
    }

    toggleSnackbars() {
        this.setState({
            showSnackbar: false
        })
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    logOut() {
        resetFilterService.resetFilters();
        preferenceService.removeValue('user', 'local');
        client.resetStore();
    }

    handleClickOpen() {
        this.setState({ open: !this.state.open });
    }

    handleClose() {
        this.setState({ open: this.state.open });
    }

    render() {
        const { open, errorPassword } = this.state;
        return (
            <div>
                <Button
                    className="text-center"
                    aria-label="account of current user"
                    size="small"
                    variant="outlined"
                    aria-controls="menu-appbar"
                    onClick={this.handleClickOpen}
                    // 
                    color="primary">
                    Change Password
                </Button>
                <Mutation mutation={CHANGE_PASSWORD} onCompleted={() => { this.setState({ currentPassword: '', newPassword: '', confirmPassword: '' }); this.handleClose() }}>
                    {(ChangePassword, { loading, error }) => (
                        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
                            {loading ? <LinearProgress color="primary" /> : <Divider />}
                            <DialogContent>
                                <ValidatorForm id="changePassword" name="form" onSubmit={e => {
                                    e.preventDefault();
                                    if (this.state.newPassword !== this.state.confirmPassword) {
                                        this.setState({
                                            errorPassword: true,
                                            showSnackbar: true,
                                            loading: false,
                                        });
                                    } else {
                                        ChangePassword({ variables: { ...this.state } });
                                    }
                                }}>
                                    {error &&
                                        <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnacker={true} />
                                    }
                                    {errorPassword && <Snackbars status="error" message="Passwords don't match" showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />}
                                    <div className="row">
                                        <div className="col-md-10 mb-4 offset-md-1">
                                            <TextValidator type="password"
                                                name="currentPassword"
                                                inputProps={
                                                    { maxLength: 20, minLength: 6 }
                                                }
                                                value={this.state.currentPassword}
                                                onChange={this.handleChange}
                                                label="Current Password"
                                                validators={['required']}
                                                errorMessages={['This field is Required']} />
                                        </div>
                                        <div className="col-md-10 mb-4 offset-md-1">
                                            <TextValidator type="password"
                                                name="newPassword"
                                                inputProps={
                                                    { maxLength: 20, minLength: 6 }
                                                }
                                                value={this.state.newPassword}
                                                onChange={this.handleChange}
                                                label="New Password"
                                                validators={['required']}
                                                errorMessages={['This field is Required']} />
                                        </div>
                                        <div className="col-md-10 mb-5 offset-md-1">
                                            <TextValidator type="password"
                                                name="confirmPassword"
                                                inputProps={
                                                    { maxLength: 20, minLength: 6 }
                                                }
                                                value={this.state.confirmPassword}
                                                onChange={this.handleChange}
                                                label="Confirm Password"
                                                validators={['isPasswordMatch', 'required']}
                                                errorMessages={['Password Mismatch', 'This field is Required']} />
                                        </div>
                                    </div>
                                    <DialogActions>
                                        <Button onClick={this.handleClose} color="secondary">
                                            Close
                                        </Button>
                                        <Button color="primary" type="submit" variant="contained" disabled={loading}>
                                            {loading ? <CircularProgress disableShrink size={24} /> : 'Submit'}
                                        </Button>
                                    </DialogActions>
                                </ValidatorForm>
                            </DialogContent>
                        </Dialog>
                    )}
                </Mutation>
                <span> </span>
                <Link to="/">
                    <Button
                        className="text-center"
                        aria-label="account of current user"
                        size="small"
                        variant="outlined"
                        aria-controls="menu-appbar"
                        onClick={() => { this.logOut(); }}
                        color="secondary">
                        Sign Out
                    </Button>
                </Link>
            </div>
        )

    }
}

export default AppLogOut;