import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import EditRuleConfiguration from '../modals/ruleEdit/Index';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';

import CurrentRuleConfigvalues from './component/CurrentRuleConfigValue';
import DefaultValues from './component/DefaultValue';
const convertLinks = (input) => {
    var text = input || '';
    return { __html: text };
}

class RuleDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRuleModal: false,
            currentRule: {},
        }
        this.handleEditRule = this.handleEditRule.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.getLanguage = this.getLanguage.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    getLanguage(data) {
        var addLanguage = {
            html: 'html',
            jsp: 'html',
            javascript: 'javascript',
            typescript: 'typescript'
        }
        return addLanguage[data] || 'java';
    }

    handleEditRule = value => () => {
        if (value) {
            this.setState({
                showRuleModal: true,
                currentRule: value
            });
        }
    }

    handleDialogClose() {
        this.props.refresh && this.props.refresh(true);
        this.setState({ showRuleModal: false });
    }

    render() {
        return (
            <div className="w-100">
                {this.props.data.ruleName
                    ?
                    <div className="row rules-list">
                        <div className="col-md-12">
                            {this.props.ruleFlag && this.props.isRuleEditVisible ? <button onClick={this.handleEditRule(this.props.data)} className="btn btn-link p-0 text-right">
                                <Tooltip title="Rule Edit">
                                    <i className="material-icons align-middle pr-4">edit</i>
                                </Tooltip>
                            </button> : null}
                            <h4>Rule Name</h4>
                            <p>{this.props.data.ruleName}</p>
                        </div>
                        <div className="col-md-12">
                            <h4>Message</h4>
                            <p>{this.props.data && this.props.data.message ? this.props.data.message : 'No Message Found'}</p>
                        </div>
                        <div className="col-md-12">
                            <h4>Description</h4>
                            {this.props.data && this.props.data.description ? <p dangerouslySetInnerHTML={convertLinks(this.props.data.description)}></p> : 'No Description Found'}
                        </div>
                        <div className="col-md-12">
                            <DefaultValues data={this.props.data && this.props.data.configurations} />
                        </div>
                        <div className="col-md-12">
                            <CurrentRuleConfigvalues data={this.props.data} />
                        </div>
                        <div className="col-md-12">
                            <h4>Examples</h4>
                            <SyntaxHighlighter language={this.getLanguage(this.state.currentRule.languageName)} style={vs}>
                                {this.props.data.example && this.props.data.example.length ? this.props.data.example[0] : null}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                    : null
                }
                {this.props.isRuleEditVisible && <EditRuleConfiguration projectId={this.props.projectId} languageName={this.props.languageName} ruleList={this.state.currentRule} showModal={this.state.showRuleModal} onUpdate={this.handleEditRule} onClose={this.handleDialogClose} history={this.props.history}></EditRuleConfiguration>}
            </div>
        );
    }
}

export default RuleDescription;