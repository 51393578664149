import React from 'react';
import CodeQualityChart from './../common/CodeQualityChart';

const Quality = (props) => {
    if (!props.data) {
        return null;
    }

    return (
        <div className="row m-0 mt-5">
            <div className="col-6 px-0 text-center">
                <h2>Code Quality</h2>
                <p>(based on LOC and review comments)</p>
                <div className="col-12 py-0 m-auto " style={{ width: '300px' }}>
                    <CodeQualityChart codeQuality={props.data.qualityPercentage} />
                </div>
                <div className="col-12">
                    <div className="row m-0">
                        <div className="col-6 text-center">
                            <h2>{props.data.numOfUsers}</h2>
                            <p>Contributors</p>
                        </div>
                        <div className="col-6 text-center">
                            <h2>{props.data.numOfReviews}</h2>
                            <p>Reviews</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 border-left px-5">
                <div className="col-12">
                    <h1 className="text-success m-0">{props.data.numOfLinesAdded}</h1>
                    <p>Lines added</p>
                </div>
                <div className="col-12 mt-5">
                    <h4>Top {props.type}</h4>
                    <p className="p-0 m-0">(Based on LOC)</p>

                    <div className="row">
                        {props.users && props.users.map((data, i) => {
                            if (i > 3) {
                                return null;
                            }
                            return (
                                <div className="col-6 py-4" key={i}>
                                    <p className="p-0 m-0 word-break">{data.developer.name} ({data.developer.emailId})</p>
                                    <h4>{data.numOfLinesAdded} </h4>

                                </div>
                            )
                        })}
                        {props.data.users && props.data.users.data.map((data, i) => {
                            if (i > 3) {
                                return null;
                            }
                            return (
                                <div className="col-6 py-4" key={i}>
                                    <p className="p-0 m-0 word-break">{data.developer.name} ({data.developer.emailId})</p>
                                    <h4>{data.numOfLinesAdded} </h4>

                                </div>
                            )
                        })}
                        {props.data.project && props.data.project.map((data, i) => {
                            if (i > 3) {
                                return null;
                            }
                            return (
                                <div className="col-6 py-4" key={i}>
                                    <p className="p-0 m-0">{data.id}</p>
                                    <h4>{data.numOfLinesAdded} </h4>

                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quality;