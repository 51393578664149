import preferenceService from '../../service/preference';

export function authHeader() {
    // return authorization header with basic auth credentials
    let user = preferenceService.getValue('user', 'local');
    if (user && user.tokenType) {
        return { 'Authorization': 'Bearer ' + user.accessToken };
    } else {
        return {};
    }
}

export function refreshHeader() {
    // return authorization header with basic auth credentials
    var refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
    if (refreshToken && refreshToken.accessToken && refreshToken.accessToken !== 'Invalid Token') {
        return { 'Authorization': 'Bearer ' + refreshToken.accessToken };
    } else {
        return {};
    }
}