import React from 'react';
import Button from '@material-ui/core/Button';

class SuccessReviewStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-5">
                    <img src="/images/analysis.png" alt="success" width="100%" />
                </div>
                <div className="col-md-7 p-3">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="analysis-data text-center p-3">
                                <i className="material-icons align-middle">assessment</i> {this.props.data.numOfAnalyzedFiles}
                                <p className="p-2">Files Analyzed</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="analysis-data text-center p-3">
                                <i className="material-icons align-middle">code</i> {this.props.data.numOfLinesAdded}
                                <p className="p-2">Lines Added</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="analysis-data text-center p-3">
                                <i className="material-icons align-middle">comment</i> {this.props.data.numOfComments}
                                <p className="p-2">Total Comments</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                {!this.props.data.hasComments
                                    ?
                                    <div>
                                        <img src="images/happy.png" alt="happy" width="70px" />
                                        <p className="pt-2">Code looks good.</p>
                                    </div>
                                    :
                                    <div>
                                        <img src="images/sad.png" alt="sad" width="70px" />
                                        <p className="pt-2">Looks like you've got some review comments to fix.</p>
                                    </div>
                                }
                                <a href={"/review-details/" + this.props.data.reviewRequestId}><Button color="primary" >Show More information</Button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SuccessReviewStatus;