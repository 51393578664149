const preferenceService = {
    setValue: (key, value, storage) => {
        if (storage === 'session' || typeof storage === 'undefined'){
            sessionStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.setItem(key, JSON.stringify(value));
        }
    },
    getValue: (key, storage) => {
        if (storage === 'session' || typeof storage === 'undefined'){
            return JSON.parse(sessionStorage.getItem(key)) || {}
        } else {
            return JSON.parse(localStorage.getItem(key)) || {}
        }
    },
    removeValue: (key, storage) => {
        if (storage === 'session' || typeof storage === 'undefined'){
            sessionStorage.removeItem(key);
        } else {
            localStorage.removeItem(key);
        }
    }
};

export default preferenceService;