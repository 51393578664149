import React, { Component } from 'react';
import { DialogActions, DialogContent, DialogTitle, Dialog, Button, Select, MenuItem, Checkbox, ListItemText, Input, Toolbar } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';


export default function ConfirmDialog(props) {
    return (
        <div>
            <Dialog open={props.showModal} fullWidth={true} maxWidth={"lg"} onClose={props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="alert-dialog-title">{"File Uploaded Successfully"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please find the summary of User Organization mapping Bulk Upload report.
                </DialogContentText>
                    <CreateTable tableContent={props.tableValue}></CreateTable>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

function CreateTable(props) {
    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><b>S.NO</b></TableCell>
                        <TableCell align="left"><b>User Mail IDs</b></TableCell>
                        <TableCell align="left"><b>Org Mail IDs</b></TableCell>
                        <TableCell align="left"><b>Status</b></TableCell>
                        <TableCell align="left"><b>Reason</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.tableContent.map((row, i) =>
                        (
                            <TableRow>
                                <TableCell align="left">{i + 1}</TableCell>
                                <TableCell align="left">{row.userMailId}</TableCell>
                                <TableCell align="left">{row.orgMailId}</TableCell>
                                <TableCell align="left">{row.status ? <CheckCircleOutlineIcon className="success" /> : <Tooltip title={row.reason} placement="top"><InfoIcon color="secondary" /></Tooltip>}</TableCell>
                                <TableCell align="left">{row.reason}</TableCell>
                            </TableRow>))}
                </TableBody>
            </Table>
        </div>
    )

}