import React from 'react';
import { DialogActions, DialogContent, DialogTitle, Dialog, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Input } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Auth from '../../../../core/service/auth';
import preferenceService from '../../../../core/service/preference';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

const GET_USER_LIST = gql`
query RegisteredUser($isSuperAdmin: Boolean) {
    usersByOrganization {
            id
            name
            username
            email
            roles {
                id
                name
            }
            organizations {
                organizationId
                OrganizationName
                roles {
                    id
                    name
                }
            }
      }
      roles(isSuperAdmin: $isSuperAdmin) {
        id
        name
      }
}
`;

const ADD_USER_ORG = gql`
mutation assgineOrganization($userIds: [Long], $roles: [Long], $organizationId: Long) {
    assgineOrganization(
        userIds: $userIds,
        roles: $roles,
        organizationId: $organizationId
    )
}
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function AssignUser(props) {
    const [value, setValue] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [userRoles, setRoles] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState([]);
    const user = preferenceService.getValue('users', 'local');

    let allRoles;
    const handleChange = event => {
        var RoleName = event.target.value;
        setRoles(RoleName);
    }

    return (
        <div>
            <Dialog open={props.showModal} fullWidth={true} maxWidth={"sm"} onClose={props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Assign Users</DialogTitle>
                <DialogContent dividers>
                    <div className="container">
                        <div className="m-auto">
                            <Query query={GET_USER_LIST} variables={{ isSuperAdmin: Auth.hasRole(user, ['ROLE_SUPER_ADMIN']) }}>
                                
                                {({ loading, error, data, refetch }) => {
                                    if(!data){
                                        return null;
                                    }
                                    let { usersByOrganization, roles } = data;
                                    allRoles = roles;
                                    return (
                                        <div>
                                            <Autocomplete
                                                multiple
                                                onChange={(event, selectedItems) => {
                                                    setSelectedUser(selectedItems);
                                                }}
                                                id="multiple-limit-tags"
                                                inputValue={inputValue}
                                                onInputChange={(e, n) => {
                                                    setInputValue(n);
                                                    setValue(usersByOrganization.filter(e => e.email === n));
                                                }}
                                                options={value}
                                                getOptionLabel={(option) => {
                                                    return `${option.name} (${option.email})`
                                                }}

                                                filterSelectedOptions
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Users"
                                                        placeholder="User's Email id only some@domain.com"
                                                    />
                                                )}
                                            />
                                            <FormControl className="mt-5">
                                                <InputLabel htmlFor="select-multiple-checkbox">Roles</InputLabel>
                                                <Select
                                                    
                                                    multiple
                                                    value={userRoles}
                                                    onChange={handleChange}
                                                    input={<Input id="select-multiple-checkbox" />}
                                                    renderValue={selected => selected.map(data => data).join(', ')}
                                                    MenuProps={MenuProps}
                                                    required
                                                >
                                                    {roles && roles.filter(e => {
                                                        return e.id !== 6
                                                    }).map(name => (
                                                        <MenuItem key={name.id} value={name.name}>
                                                            <Checkbox checked={userRoles.indexOf(name.name) > -1} />
                                                            <ListItemText primary={name.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    )
                                }}
                            </Query>
                            <div>

                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="secondary">
                        close
                    </Button>
                    <Mutation mutation={ADD_USER_ORG} onCompleted={() => {
                        props.onClose()
                        props.refresh()
                    }}>
                    {(assgineOrganization, { loading, error, data }) => (
                    <Button variant="contained" onClick={() => {
                        assgineOrganization({
                            variables: {
                                userIds: selectedUser.map(e => e.id), roles: allRoles.filter(role => {
                                    return userRoles.includes(role.name);
                                }).map(element => element.id), organizationId: user.currentOrganizationId
                            }
                        });
                    }} color="primary">
                        Assign User
                    </Button>)}
                    </Mutation>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AssignUser;