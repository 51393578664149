import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbars from '../../../../core/error/Snackbars';

const RESET_PASSWORD = gql`
mutation resetPassword($userId: Long!, $password: String!) {
    resetPassword(
        userId: $userId,
        password: $password,
    )
}
`;
class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: props.userData ? props.userData.id : null,
            password: '',
            confirmPassword: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleSnackbars = this.toggleSnackbars.bind(this);
    }

    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
    }

    toggleSnackbars() {
        this.setState({
            showSnackbar: false
        })
    }

    validate(activeKey) {
        var form = document.getElementById("signup");
        form.reportValidity()
        if (form.checkValidity()) {
            this.setState({
                activeKey: activeKey,
            });
        }

    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        return (
            <div className="w-100">
                <Mutation mutation={RESET_PASSWORD}>
                    {(ResetPassword, { loading, error, data }) => (
                        <ValidatorForm id="login" name="form" onSubmit={e => {
                            e.preventDefault();
                            ResetPassword({ variables: { ...this.state } });
                        }}>
                            {error && <Snackbars status="error" message={error} showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />}
                            {data && data.resetPassword &&
                                <Snackbars status="success" message='Reset Password Successfully' showSnackbar={true} closeSnacker={true} />
                            }
                            
                            <div className="col-md-7 mb-4">
                                <TextValidator type="password"
                                    name="password"
                                    inputProps={
                                        { maxLength: 20, minLength: 6 }
                                    }
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    label="Password"
                                    validators={['required']}
                                    errorMessages={['This field is Required']} />
                            </div>
                            <div className="col-md-7 mb-5">
                                <TextValidator type="password"
                                    name="confirmPassword"
                                    inputProps={
                                        { maxLength: 20, minLength: 6 }
                                    }
                                    value={this.state.confirmPassword}
                                    onChange={this.handleChange}
                                    label="Confirm Password"
                                    validators={['isPasswordMatch', 'required']}
                                    errorMessages={['Password Mismatch', 'This field is Required']} />
                            </div>
                            <div className="col-md-5">
                                <Button color="primary" type="submit" variant="contained">
                                    {loading ? <CircularProgress disableShrink size={24} /> : 'Submit'}
                                </Button>
                            </div>
                        </ValidatorForm>
                    )}
                </Mutation>
            </div>
        );
    }
}

export default ChangePassword;