import ClinetServices from '../../../../core/service/clientUri';
import gql from 'graphql-tag';

var language = 'java';
var id = null
const GET_RULES_LIST = gql`
query rulesByLanguage($languageName: String!, $projectId: Long) {
    rulesByLanguage(languageName: $languageName, projectId: $projectId) {
        categoryName
        rules {
        ruleId
        ruleName
        description
        languageName
        categoryName
        externalInfoUrl
        message
        priority
        since
        example
        configurations {
            type
            properties
            enumValue
            items {
                type
                enumValue
            }
        }
        ruleOptions
        properties {
            property {
            name
            value
            }
        }
        options
        path
        enabled
        }
    }
}
`;

const LANGUAGES_BY_PROJECT_ID = gql`
query languagesByProjectId($projectId: Long!) {
    languagesByProjectId(projectId: $projectId) {
        languageName
        numOfRules
        numOfActiveRules
        description
        enabled
        extensions
        analyzerEngines
      }
}
`;


class rulesetServices {
    constructor(props) {
        this.getFromClintList = this.getFromClintList.bind(this);
        this.getLanguageFromClintList = this.getLanguageFromClintList.bind(this);
        this.getRulesList = this.getRulesList.bind(this);
        this.getLanguagesList = this.getLanguagesList.bind(this);
    }

    async getFromClintList(client) {
        var response = await client.query({
            query: GET_RULES_LIST,
            variables: { languageName: language, projectId: !!id ? id : 0 }
        });
        return await response.data.rulesByLanguage;
    }

    async getLanguageFromClintList(client) {
        var response = await client.query({
            query: LANGUAGES_BY_PROJECT_ID,
            variables: { projectId: !!id ? id : 0 }
        });
        return await response.data.languagesByProjectId;
    }

    getRulesList(query, projectId) {
        if (query) {
            language = query;
        }
        id = projectId;

        return this.getFromClintList(ClinetServices.getClienUri()).then(res => {
            return {
                data: res ? res : [],
            }
        }, error => {
            return {
                data: [],
            }
        });
    }

    getLanguagesList(query, projectId) {
        id = projectId;

        return this.getLanguageFromClintList(ClinetServices.getClienUri()).then(res => {
            return {
                data: res ? res : [],
            }
        }, error => {
            return {
                data: [],
            }
        });
    }

}

export default rulesetServices;