import React, { Component } from 'react';

class CurrentRuleConfigvalues extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        if ((this.props.data && !this.props.data.ruleOptions) ||
            (this.props.data && this.props.data.ruleOptions && !this.props.data.ruleOptions.length)) {
            return null;
        }
        return (
            <div className="w-100">
                {!this.props.isMoreInfo
                    ? <h4 className="pt-2">Changed Configuration</h4>
                    : <h6 className="mt-4">Changed Configuration</h6>
                }
                {
                    this.props.data && this.props.data.ruleOptions && this.props.data.ruleOptions.map((data, i) => {
                        if (Array.isArray(data)) {
                            return (<div key={i}>
                                <p>
                                    <b>Array Option(s)</b>
                                    <div>
                                        <ul>
                                            {data.map((n, index) => {
                                                return <li key={index}>{n}</li>
                                            })}
                                        </ul>
                                    </div>
                                </p>
                            </div>);
                        } else if (typeof data === 'string') {
                            return (<div key={i}>
                                <p><b>String Option(s)</b>
                                    <div>{data}</div></p>
                            </div>);
                        } else if (typeof data === 'object') {
                            var a = [];
                            for (var key in data) {
                                a.push({
                                    name: key,
                                    value: data[key]
                                })
                            }

                            return (
                                <div className="w-100" key={i}>
                                    <div><p><b>Object Option(s)</b></p>
                                        <table className="table w-100">
                                            <tbody>
                                                {a.map((data, i) => {
                                                    return (<tr key={i}>
                                                        <td>{data.name}</td>
                                                        <td>{data.value}</td>
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </table></div>
                                </div>
                            );
                        }
                        return <p>No Configuration to Show</p>;
                    })
                }
            </div>
        );
    }
}

export default CurrentRuleConfigvalues;