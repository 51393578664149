import React, { Component } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import DiffView from './DiffView';
import ErrorType from '../../../core/error/simpleError';

const GET_DIFF_BY_FILE = gql`
query diff($reviewUrl: String!, $filePath: String!) {
   diff(reviewUrl: $reviewUrl, filePath: $filePath) {
    fromHash
    toHash
    status
    contextLines
    whitespace
    truncated
    diffs {
      source {
        toString
        components
        parent
        name
        extension
      }
      destination {
        toString
        components
        parent
        name
        extension
      }
      hunks {
        context
        sourceLine
        sourceSpan
        destinationLine
        destinationSpan
        segments {
          type
          lines {
            source
            destination
            line
            truncated
          }
          truncated
        }
        truncated
      }
      truncated
    }

    }
}
`;

class StashView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSn: false,
        }
        this.handleClose = this.handleClose.bind(this);
        this.getCommentObject = this.getCommentObject.bind(this);
    }

    handleClose() {
        this.props.onUpdate();
        this.props.onRefresh();
    }

    getCommentObject(data) {
        var commentObj = {};
        if (data && data.length) {
            data.forEach((e) => {
                commentObj[e.location.lines.begin] ? commentObj[e.location.lines.begin].push(e) : commentObj[e.location.lines.begin] = [e]
            });
        }
        return commentObj;
    }
    
    render() {
        if (!this.props.stashComments) return null;
        if (this.props.stashComments && !this.props.stashComments.filePath) {
            return (
                <ErrorType type="FileSelect" size={450} head={"Choose a file"} content={'Please select any file to view the diff'} />
            )
        }
        return (
            <Query query={GET_DIFF_BY_FILE} fetchPolicy="no-cache" variables={{ filePath: this.props.stashComments ? this.props.stashComments.filePath : '', reviewUrl: this.props.stashComments ? this.props.stashComments.diffUrl : '' }}>
            {({ loading, error, data, refetch }) => {
                if (loading) return <div className="loading"><CircularProgress size={50} /></div>; 
                if (error) return  (
                    <div className="text-center p-4">
                        <ErrorType type="serverError" size={450} head={"Internal Server Error"} content={error.message.split(':') && error.message.split(':').length && error.message.split(':')[2] 
                            ? error.message.split(':')[2].includes('404') ? 'The Branch were deleted so you cannot access the Diff' : error.message.split(':')[2]
                            : 'Something Went Wrong...!'} />
                    </div>);
                return (
                    <DialogContent className="p-0">
                        {!loading || !error ? <DiffView refresh={() => refetch()} reviewId={this.props.reviewId} data={data && data.diff && data} state={this.props.state} comments={this.getCommentObject(this.props.stashComments.comments)} /> : ''}
                    </DialogContent>
                    )
                }}
            </Query>
        );
    }
}

export default StashView;