import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import linters from './../data/linterConfig';
import Chip from '@material-ui/core/Chip';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import projectService from '../../project/services/project-service';
import projectRules from './../service/projectRules';
import rulesList from './../../../core/rules/'
import { Divider } from '@material-ui/core';

const projectSer = new projectService();
const projectRule = new projectRules();
function Linter(props) {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({});

    const handleClickOpen = (data, key) => {
        data.key = key;
        setData(data)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h3>Linter Configuration in your IDE</h3>
                    <p>This is a complete configuration setup for your IDE to use Linting Tools by default its have <b>golbal configuration</b> files and you can switch based on your broject</p>
                </div>
                {Object.keys(linters).map(data => {
                    return (
                        <div className="col-md-4 my-3">
                            <div className="p-3 shadow rounded cursor-pointer" onClick={() => handleClickOpen(linters[data], data)}>
                                <h4>{data}</h4>
                                <p>you can use the {linters[data].isVs ? `${data}.json` : `${data}.xml`} for Setup in your {linters[data].isVs ? "Visual studio" : "eclipse"} it will help to reduse your QBot Comment</p>
                                <p><Chip
                                    color={linters[data].isVs ? "primary" : "secondary"}
                                    label={linters[data].isVs ? "Visual studio" : "eclipse"}
                                /></p>
                                <div>Click to see More Information</div>
                            </div>
                        </div>
                    )
                })}
                <LinterInfo open={open} data={data} handleClose={handleClose} />
            </div>
        </div>
    )
}

function LinterInfo(props) {

    const [option, setOption] = React.useState([]);
    const [rules, setRules] = React.useState({});

    const handleChange = (e) => {
        var filterBy = { projectName: e.target.value };
        projectSer.getProjectList({ pageSize: 7, page: 0 }, filterBy).then(res => {
            setOption(res.data || []);
        })
    }

    const chnageProject = (e, i) => {
        if (i) {
            if (props.data.keyCode === "php") {
                projectRule.getrulesByProjectPhp(i && i.id).then(data => {
                    setRules(data);
                })
            } else if (props.data.keyCode === "openapi") {
                projectRule.getrulesByProjectSpec(i && i.id).then(data => {
                    setRules(data);
                })
            }
            else {
                projectRule.getrulesByProject(i && i.id).then(data => {
                    setRules(data);
                })
            }
        }
    };

    const convertRules = (data, key) => {
        var rl;
        if (rulesList[key] && rulesList[key].rules) {
            rl = rulesList[key].rules;
            Object.keys(rl).forEach(k => {
                if (data[key][k]) {
                    if (data[key][k].ruleOptions && data[key][k].ruleOptions.length) {
                        rl[k] = ["error", ...data[key][k].ruleOptions];
                    }
                } else {
                    delete rl[k];
                }
            });
            rulesList[key].rules = rl;
            return rulesList[key];
        }
        rl = rulesList[key];
        Object.keys(rl || {}).forEach(k => {
            if (data[key][k]) {
                if (data[key][k].ruleOptions && data[key][k].ruleOptions.length) {
                    rl[k] = data[key][k].ruleOptions[0];
                }
            } else {
                delete rl[k];
            }
        });
        rulesList[key] = rl;
        return rulesList[key];
    }

    const getLink = (key) => {
        var link = "";
        var rule = {};
        try {
            rule = (key !== "pmd") && rules.json ? convertRules(JSON.parse(rules.json), key) : rulesList[key];
        } catch (err) {
            rule = {};
        }

        link = (key === "pmd" || key === "php")
            ? "data:text/xml;charset=utf-8," + encodeURIComponent(rules.xml)
            : "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(rule))
        if (key === "openapi") {
            link = "data:text/json;charset=utf-8," + encodeURIComponent(rules.json)
        }
        else if (key === "php") {
            link = "data:text/xml;charset=utf-8," + encodeURIComponent(rules.xml)
        }
        return link;
    }

    const getName = (key) => {
        switch (key) {
            case 'pmd':
                return "pmd.xml";
            case 'style':
                return ".stylelintrc.json";
            case 'html':
                return ".htmlhintrc";
            case 'javascript':
                return ".eslintrc.json";
            case 'typescript':
                return "tslint.json";
            case 'php':
                return ".phplint.xml";
            case 'openapi':
                return ".spectral.json";
            default:
                return "rules";
        }
    }

    return (
        <Dialog
            open={props.open}
            fullWidth={true}
            maxWidth={"md"}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.data.key} Configuration
<Autocomplete
                    id="combo-box-demo"
                    className="float-right"
                    options={option}
                    onChange={chnageProject}
                    getOptionLabel={(option) => option.projectName}
                    style={{ width: 200 }}
                    size="small"
                    renderInput={(params) => <TextField {...params} label="Project" onChange={handleChange} variant="standard" />}
                />
            </DialogTitle>
            <DialogContent>
                {props.data.isVs ? <p>launch VS Code Quick Open <b>(Ctrl+P)</b>, paste the following command, and press enter.</p> : null}
                {props.data.isVs
                    ? <div>
                        <SyntaxHighlighter language="javascript" >
                            {props.data.vsCode}
                        </SyntaxHighlighter>
                        <SyntaxHighlighter language="javascript" >
                            {props.data.code}
                        </SyntaxHighlighter>
                    </div>
                    : <div dangerouslySetInnerHTML={{ __html: props.data.code }}></div>}
                <p>{props.data.content}</p>
                Use below link to configure your {props.data.key} in your IDE <br />
                <Divider />
            </DialogContent>
            <DialogActions>

                <Button onClick={props.handleClose} color="primary" autoFocus>
                    close
</Button>

                <a download={getName(props.data.keyCode)}
                    rel="noopener noreferrer" href={getLink(props.data.keyCode)}>
                    <Button variant="contained" color="primary">
                        Download
</Button>
                </a>
            </DialogActions>
        </Dialog>
    )
}

export default Linter;