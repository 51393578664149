import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbars from '../../../../core/error/Snackbars';
import preferenceService from '../../../../core/service/preference';

const Update_Organization_Mail = gql`
mutation updateOrganizationMappingId($userId: Long!,$organizationId: Long!, $orgUserMapId: String!) {
    updateOrganizationMappingId(
        userId: $userId,
        organizationId: $organizationId,
        orgUserMapId: $orgUserMapId,
    )
}
`;

const user = preferenceService.getValue('users', 'local');
class UpdateOrganizationID extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: props.userData ? props.userData.id : null,
            organizationId: user.currentOrganizationId,
            orgUserMapId: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleSnackbars = this.toggleSnackbars.bind(this);
    }

    toggleSnackbars() {
        this.setState({
            showSnackbar: false
        })
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        return (
            <div className="w-100">
                <Mutation mutation={Update_Organization_Mail}>
                    {(UpdateOrganizationID, { loading, error, data }) => (
                        <ValidatorForm id="login" name="form" onSubmit={e => {
                            e.preventDefault();
                            UpdateOrganizationID({ variables: { ...this.state } });
                        }}>
                            {error && <Snackbars status="error" message={error} showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />}
                            {data && data.updateOrganizationMappingId &&
                                <Snackbars status="success" message='Updated Organization Mail ID Successfully' showSnackbar={true} closeSnacker={true} />
                            }
                            <div className="col-md-7 mb-4">
                                <TextValidator type="text"
                                    name="orgUserMapId"
                                    value={this.state.orgUserMapId}
                                    onChange={this.handleChange}
                                    label="User Organization Mail ID"
                                    helperText={"Example: organizationUser@ghx.com"}
                                    validators={['required']}
                                    errorMessages={['This field is Required']} />
                            </div>
                            <div className="col-md-5">
                                <Button color="primary" type="submit" variant="contained">
                                    {loading ? <CircularProgress disableShrink size={24} /> : 'Submit'}
                                </Button>
                            </div>
                        </ValidatorForm>
                    )}
                </Mutation>
            </div>
        );
    }
}

export default UpdateOrganizationID;