import ClinetServices from '../../../core/service/clientUri';
import gql from 'graphql-tag';


var id = 0;

const GET_PROJECT_VIEW = gql`
    query projectRulesets($id: Long!) {
        projectRulesets(projectId: $id) {
            xml
            json
        }
    }
`;

const GET_PHP_RULESET = gql`
    query phpRulesets($id: Long!) {
        phpRulesets(projectId: $id) {
            xml
            json
        }
    }
`;

const GET_SPEC_RULESET = gql`
    query openapiRulesets($id: Long!) {
        openapiRulesets(projectId: $id) {
            xml
            json
        }
    }
`;

class ProjectRules {
    constructor(props) {
        this.getFromClintList = this.getFromClintList.bind(this);
        this.getrulesByProject = this.getrulesByProject.bind(this);
        this.getFromClintListPhp = this.getFromClintListPhp.bind(this);
        this.getrulesByProjectPhp = this.getrulesByProjectPhp.bind(this);
        this.getFromClintListSpec = this.getFromClintListSpec.bind(this);
        this.getrulesByProjectSpec = this.getrulesByProjectSpec.bind(this);
    }

    async getFromClintList(client) {
        let response = await client.query({
            query: GET_PROJECT_VIEW,
            variables: { id: id }
        });
        return await response.data.projectRulesets;
    }

    getrulesByProject(projectId) {
        id = projectId;
        return this.getFromClintList(ClinetServices.getClienUri()).then(res => {
            return res
        }, error => {
            return {}
        });
    }

    async getFromClintListPhp(client) {
        let response = await client.query({
            query: GET_PHP_RULESET,
            variables: { id: id }
        });
        return await response.data.phpRulesets;
    }

    getrulesByProjectPhp(projectId) {
        id = projectId;
        return this.getFromClintListPhp(ClinetServices.getClienUri()).then(res => {
            return res
        }, error => {
            return {}
        });
    }

    async getFromClintListSpec(client) {
        let response = await client.query({
            query: GET_SPEC_RULESET,
            variables: { id: id }
        });
        return await response.data.openapiRulesets;
    }

    getrulesByProjectSpec(projectId) {
        id = projectId;
        return this.getFromClintListSpec(ClinetServices.getClienUri()).then(res => {
            return res
        }, error => {
            return {}
        });
    }
}



export default ProjectRules;