import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import RuleSets from './Rulesets';
import { forceCheck } from 'react-lazyload';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            className="w-100"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        className: `float-right`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


class RulesetCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            data: [],
            RuleSetByCat: this.props.data,
            languageName: this.props.languageName,
            ruleFlag: this.props.ruleFlag,
            selectAll: false,
            classes: makeStyles(theme => ({
                root: {
                    flexGrow: 1,
                    backgroundColor: theme.palette.background.paper,
                    display: 'flex',
                    height: 224,
                },
                tabs: {
                    borderRight: `1px solid ${theme.palette.divider}`,
                },
            }))
        }
        this.handleChange = this.handleChange.bind(this);
        this.getAllData = this.getAllData.bind(this);
        this.setRuleSet = this.setRuleSet.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    componentDidUpdate() {
        forceCheck();
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    cleanData() {
        this.child.cleanData();
    }

    getAllData(data) {
        var rules = [];
        data.forEach((category) => {
            if (category.rules)
                rules = rules.concat(category.rules);
        })
        return rules.sort(function (a, b) {
            var ruleA = a.ruleName.toLowerCase(), ruleB = b.ruleName.toLowerCase()
            if (ruleA < ruleB) //sort string ascending
                return -1
            if (ruleA > ruleB)
                return 1
            return 0 //default return value (no sorting)
        })

    }

    setRuleSet(data, config, value, idList) {
        var temp = this.state.RuleSetByCat;
        this.state.RuleSetByCat.forEach((dd, index) => {
            if (dd.rules) {
                dd.rules.forEach((rul, i) => {
                    if (rul.ruleName === value.ruleName) {
                        temp[index].rules[i].enabled = !temp[index].rules[i].enabled;
                        this.setState({
                            RuleSetByCat: temp,
                        })
                    }
                });
            }
        });
        this.props.category(temp, config, idList);
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue,
        });
    }


    render() {        
        return (
            <div className="p-5">
                <div className="category-tab-container mb-5">
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={this.state.value}
                        onChange={this.handleChange}
                        aria-label="Vertical tabs example"
                        className="category-tabs"
                    >
                        <Tab label="All" {...a11yProps(0)} />
                        {this.props.data ? this.props.data.map((data, index) => {
                            return <Tab key={index} label={data.categoryName} {...a11yProps(index + 1)} />
                        }) : null}
                    </Tabs>
                    <TabPanel value={this.state.value} index={0}>
                        <RuleSets refresh={(e) => {
                            this.props.refresh && this.props.refresh(e)
                        }} projectId={this.props.projectId} reviewFlag={this.props.reviewFlag} ruleFlag={this.state.ruleFlag} languageName={this.state.languageName} data={this.props.data ? this.getAllData(this.props.data) : []} onRef={ref => (this.child = ref)} isQuery={true} ruleSets={this.setRuleSet} />
                    </TabPanel>
                    {this.props.data ? this.props.data.map((data, index) => {
                        return (
                            this.state.value === index + 1 && <TabPanel key={index} value={this.state.value} index={index + 1}>
                                {
                                    <RuleSets projectId={this.props.projectId} keyss={index + 1} data={data.rules ? data.rules : []} config={{
                                        categoryName: data.categoryName,
                                        path: data.path
                                    }} reviewFlag={this.props.reviewFlag}
                                        refresh={(e) => {
                                            this.props.refresh && this.props.refresh(e)
                                        }} ruleSets={this.setRuleSet} ruleFlag={this.state.ruleFlag} onRef={ref => (this.child = ref)} isQuery={true} />
                                }
                            </TabPanel>
                        )
                    }) : null}
                </div>
            </div>
        )
    }


}

export default RulesetCategory;