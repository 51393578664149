
import React from 'react';
import { serverSentEventConnect } from 'react-server-sent-event-container';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import Badge from '@material-ui/core/Badge';
import Auth from '../service/auth';
var actulaCount = 0;
var user = JSON.parse(localStorage.getItem('users'));
const Event = ({ message, eventSource }) => (
    <span>
        <Badge badgeContent={message > 0 ? message : null} color="primary">
            <NotificationsActiveOutlinedIcon />
        </Badge>
    </span>
    );
const onOpen = () => { };
function pushNotification(count) {
    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
    }
    else if (Notification.permission === "granted") {
        new Notification("Hello " + user.name, {
            body: "You have " + count + " action items",
            icon: "https://qbot.mookambikainfo.com/images/Q.jpg"
        });
    }
    else if (Notification.permission !== 'denied') {
        Notification.requestPermission(function (permission) {
            if (permission === "granted") {
                new Notification("Hi " + user.name + " Thanks for allowing the permission");
            }
        });
    }
}
function getCount(data) {
    var currentOrgData = data[user.currentOrganizationId];
    var count = 0;
    if (Auth.hasRole(user, ['ROLE_APPROVER'])) {
        count += currentOrgData.Approver;
    }
    count += currentOrgData[user.id] || 0;
    if (count !== actulaCount && actulaCount !== 0) {
        if (count > actulaCount) {
            pushNotification(count)
        }
        actulaCount = count;
    } else {
        if (actulaCount === 0) {
            actulaCount = count;
        }
    }
    return count;
}
const onMessage = (event, props) => {
    const item = JSON.parse(event.data);
    props.update({ 'message': getCount(item) });
};
const eventObj = {
    someevent: (event, props) => {
        const item = JSON.parse(event.data);
        props.update({ 'message': getCount(item) });
    }
}
var url = '/code-review-service/notification?orgId=' + 0 + '&projectGroupId=' + 0 +'&userId=' + 0 + '&hasApprover=' + false;
if (user && user.currentOrganizationId && user.id ) {
    url = '/code-review-service/notification?orgId=' + user.currentOrganizationId + '&projectGroupId=' + user.currentProjectGroupId + '&userId=' + user.id + '&hasApprover=' + Auth.hasRole(user, ['ROLE_APPROVER']);
}
else if (user && user.id) {
    url = '/code-review-service/notification?orgId=' + 0 + '&projectGroupId=' + 0 + '&userId=' + user.id + '&hasApprover=' + false;
}

const onError = (event, props, source) => {
    console.log("Error happened while receiving notification");
    if (event.target.readyState === source.CONNECTING) {
        console.log('SSE CONNECTING ' + '(' + event.target.readyState + ')')
      }
    if (event.target.readyState === source.CLOSED) {
        console.log('SSE closed ' + '(' + event.target.readyState + ')')
      }
}

export default serverSentEventConnect(url, false, onOpen, onMessage, onError, eventObj)(Event);
