import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Snackbars from './../../../core/error/Snackbars';
import gql from 'graphql-tag';

const COPY_PROJECT_RULES = gql`
mutation copyProjectRules($fromProjectId: Long,
    $toProjectId: Long)
    {copyProjectRules(fromProjectId:$fromProjectId,
        toProjectId:$toProjectId)
    }
`;

class CopyProjectRules extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showSnackbar: false,
            fromProjectId: props.data.fromProjectId,
            toProjectId: props.data.toProjectId
        }
        this.handelChange = this.handelChange.bind(this);
        this.toggleSnackbars = this.toggleSnackbars.bind(this);
    }

    componentWillReceiveProps(currentProps) {
        this.setState({
            ...currentProps.data
        })
    }

    handelChange(e) {
        this.setState({
            comment: e.target.value
        })
    }
    toggleSnackbars() {
        this.setState({
            showSnackbar: !this.state.showSnackbar
        })
    }

    render() {
        return (
            <Mutation mutation={COPY_PROJECT_RULES} onCompleted={() => { this.props.handleReset() }}>
                {(copyRule, { loading, error, data }) => {
                    return (
                        <div className="comment-content p-3">
                            <div className="ml-5 pl-4 w-100">
                                <div className="comments d-flex">
                                    <span className="inner-comment-content">
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            copyRule({
                                                variables: {
                                                    fromProjectId: this.state.fromProjectId,
                                                    toProjectId: this.state.toProjectId
                                                }
                                            })
                                        }}>
                                            {error && error.message.split(":") && error.message.split(":").length <= 3 && error.message.split(":")[2] &&
                                                <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={this.toggleSnackbars} />}
                                            {data && data.copyProjectRules && <Snackbars status="success" message="Rule Copied Successfully" showSnackbar={true} closeSnackBar={this.toggleSnackbars} />}
                                            <Button disabled={this.state.fromProjectId && this.state.toProjectId ? false : true} variant="contained" color="primary" type="submit">Copy</Button>
                                            <span className="secondary pl-4"></span>
                                            <Button variant="text" color="secondary" onClick={() => { this.props.handleReset() }}>Reset</Button>
                                        </form>
                                    </span>
                                </div>
                            </div>
                        </div>)
                }}
            </Mutation>
        );
    }
}

export default CopyProjectRules;