export default {
    "heading": "Release List",
    "content": [
        {
            "heading": "Release 1 (Initial Release) (18th Nov 2019)",
            "features": [
                {
                    "li": "Code Review Engine",
                    "sub-li": [
                        "Reads code from code repository (stash)",
                        "Analyzes files with predefined rules",
                        "Processes the reported issues"
                    ]
                },
                {
                    "li": "Code Review User Interface",
                    "sub-li": [
                        "Add Review screen to accept the review URL",
                        "Review Details screen to view the details about a review",
                        "Home screen to view various charts to",
                        "executives",
                        "Project configuration screen to configure the project"
                    ]
                },
                {
                    "li": "Project Configuration",
                    "sub-li": []
                },
                {
                    "li": "User Management",
                    "sub-li": []
                },
                {
                    "li": "Executive Dashboard",
                    "sub-li": []
                }
            ]
        },
        {
            "heading": "Release 2 (18th Dec 2019)",
            "features": [
                {
                    "li": "Global Rule Management",
                    "sub-li": [
                        "QBot Admins can now manage the rules globally. They will be able to enable/disable rule(s) across all projects/set of selected projects. They will be able to enable/disable rule(s) which should come as default when a language is selected in the Project Rule Configuration window."
                    ]
                },
                {
                    "li": "Manual Review Comments",
                    "sub-li": [
                        "This feature provides an ability for reviewers/developers to comment on a particular line of code.",
                        "Developers can address the comments and also be able to reply to the comment.",
                        "It will be useful for business case validation and also required for other languages such as JS / TS / Python, etc Since QBot supports only Java / POM / SQL / XML as of now.",
                        "We can get rid of the G sheet to keep the manual comments."
                    ]
                },
                {
                    "li": "QBot Skype Integration",
                    "sub-li": [
                        "Developers can get help from QBot - 'A new Skype Bot that makes developer life easy' to review an URL. QBot will be added as one of the members in the existing 'Peer Code Review' group and will be able to pick the URL up, do the review and provide the review comments summary via the Skype group. Developers can go and view the detailed review comments in the QBot portal."
                    ]
                },
                {
                    "li": "Ignore Comments Notification",
                    "sub-li": [
                        "A notification message will be pushed to all reviewers when a developer requests to ignore a particular comment. Reviewers are notified via the \"Notification Icon\" and the Reviewer can act upon the request quickly without the need to check for the approval queue frequently."
                    ]
                }
            ],
            "enhancements": [
                {
                    "li": "The Project Manager can now choose the development languages(Global rules) using the 'Rule Configuration' button in the project’s grid. This feature eliminates the admin intervention to set up the projects in QBot. But still, only admin can be able to add/remove the ruleset for any particular project.",
                    "sub-li": []
                },
                {
                    "li": "Review and re-submit review requests with progress information.",
                    "sub-li": []
                },
                {
                    "li": "Diff view - Added more details like line number in the comment section.",
                    "sub-li": []
                },
                {
                    "li": "Admin Page - Users’ search feature.",
                    "sub-li": []
                },
                {
                    "li": "Projects - Filter search feature.",
                    "sub-li": []
                },
                {
                    "li": "Review URL format helptext.",
                    "sub-li": []
                },
                {
                    "li": "New labels to let the developers know when the manual review is required.",
                    "sub-li": []
                },
                {
                    "li": "404 pages when the review information is not found.",
                    "sub-li": []
                },
                {
                    "li": "Showing approval comments in diff. views.",
                    "sub-li": []
                },
                {
                    "li": "Show all files in the review response irrespective of whether the file is reported with comments.",
                    "sub-li": []
                },
                {
                    "li": "Show only the latest response in the review details screen.",
                    "sub-li": []
                },
                {
                    "li": "Server-side pagination support for My Reviews screen.",
                    "sub-li": []
                }
            ],
            "bugFix": [
                {
                    "li": "Ignore comments issues (Parallel approval)",
                    "sub-li": []
                },
                {
                    "li": "Project creation issue",
                    "sub-li": []
                },
                {
                    "li": "Review requests bugs",
                    "sub-li": []
                },
                {
                    "li": "File name copy issue in diff view",
                    "sub-li": []
                }
            ]
        },
        {
            "heading": "Release 3 (27th Jan 2020)",
            "features": [
                {
                    "li": "Project grouping ( Projects & Repositories)",
                    "sub-li": []
                },
                {
                    "li": "Supporting JavaScript / TypeScript / Style / HTML - using lint engine",
                    "sub-li": []
                }
            ],
            "enhancements": [
                {
                    "li": "Manual review workflow (End to End)",
                    "sub-li": []
                },
                {
                    "li": "Manual/auto comments highlights in the diff and metadata (Fast-tracking)",
                    "sub-li": []
                },
                {
                    "li": "Simplify the project creation including language selections (Quick project setup)",
                    "sub-li": []
                },
                {
                    "li": "Bulk ignores request and approval.",
                    "sub-li": []
                },
                {
                    "li": "Showing rule configuration in the comment section.",
                    "sub-li": []
                },
                {
                    "li": "Bot conversation improvements.",
                    "sub-li": []
                },
                {
                    "li": "Notification to the developer when Manual comments added.",
                    "sub-li": []
                }
            ],
            "bugFix": [
                {
                    "li": "Pagination issues in projects and reviews grids.",
                    "sub-li": []
                }
            ]
        },
        {
            "heading": "Release 4 (18th Feb 2020)",
            "features": [
                {
                    "li": "Python  Integration (PYLint)",
                    "sub-li": [
                        "QBot Engine has got its new sibling PyLinter to analyze Python code.",
                        "PyLinter uses PyLint as its core engine which has around 350+ built-in rules. This helps us analyze the code with the most widely used patterns and industry-standard practices."
                    ]
                },
                {
                    "li": "Tree view",
                    "sub-li": [
                        "Earlier we didn't have the diff changes in a tree view like stash or GitHub.",
                        "We have implemented the tree view to see the file changes in a single view. In Tree view, you can see the file changes with the status below:Added,ModifiedDeleted"
                    ]
                }
            ],
            "enhancements": [
                {
                    "li": "Edit option to update rule details",
                    "sub-li": [
                        "In Rule Configuration, we have a rule edit option which helps us to modify the rule details.",
                        "We can edit the rule description, message, example, and default rule options."
                    ]
                },
                {
                    "li": "Enriched language details",
                    "sub-li": [
                        "We have added some more details about a language and its metadata. On hovering the language name in the Global and Project Rule Management, you can see the details in a popup modal."
                    ]
                },
                {
                    "li": "Performance improvements",
                    "sub-li": [
                        "Lazy Load & Rule Service Improvements which helps us to improve the UI performance and service level improvements to fetch and update rules at global and project levels"
                    ]
                },
                {
                    "li": "PMD upgrade (6.20.0)",
                    "sub-li": []
                },
                {
                    "li": "Security improvements - Token refresh feature",
                    "sub-li": []
                },
                {
                    "li": "Project Group Filter",
                    "sub-li": []
                }
            ],
            "bugFix": [
                {
                    "li": "Comment indication fix",
                    "sub-li": []
                },
                {
                    "li": "Adding language default rules on the fly (without the need to configure manually)",
                    "sub-li": []
                },
                {
                    "li": "Rule details improvements on Diff View",
                    "sub-li": []
                }
            ]
        },
        {
            "heading": "Release 5 (31 Mar 2020)",
            "features": [
                {
                    "li": "New project dashboard",
                    "sub-li": []
                },
                {
                    "li": "New user dashboard",
                    "sub-li": []
                },
                {
                    "li": "Enriched executive dashboard",
                    "sub-li": [
                        "Violated rules by languages by month",
                        "Completed vs Addressed PRs by projects",
                        "Reviews vs Comments by date range"
                    ]
                },
                {
                    "li": "Developer notification on manual comments",
                    "sub-li": []
                },
                {
                    "li": "Peer reviewer notification on manual review",
                    "sub-li": []
                },
                {
                    "li": "Search option in rule management",
                    "sub-li": []
                },
                {
                    "li": "Zoom option for charts",
                    "sub-li": []
                },
                {
                    "li": "Enhanced Bot conversation",
                    "sub-li": []
                }
            ],
            "bugFix": [
                {
                    "li": "Decoding Exception",
                    "sub-li": []
                },
                {
                    "li": "Change rule service to accept extension instead of language name",
                    "sub-li": []
                },
                {
                    "li": "Table pagination fix",
                    "sub-li": []
                }
            ],
            "enhancements": [
                {
                    "li": "Review details page enrichment",
                    "sub-li": []
                },
                {
                    "li": "Option to complete manual review when no comments are required",
                    "sub-li": []
                },
                {
                    "li": "Notification card enrichment",
                    "sub-li": []
                },
                {
                    "li": "Info to developer to mark the review as 'Manual Comments Addressed'",
                    "sub-li": []
                },
                {
                    "li": "Updated JS examples",
                    "sub-li": []
                },
                {
                    "li": "Updated missed PMD configurations",
                    "sub-li": []
                },
                {
                    "li": "Bot update to BotFramework 4",
                    "sub-li": []
                },
                {
                    "li": "Formatted comment in the comment section",
                    "sub-li": []
                },
                {
                    "li": "Monthly Report enhancements based on each project branch exclusions",
                    "sub-li": []
                },
                {
                    "li": "Admin - Groom rules for each project",
                    "sub-li": []
                }
            ]
        },
        {
            "heading": "Release 6 (11 May 2020)",
            "features": [
                {
                    "li": "Release Notes Feature - To keep track all the release note details.",
                    "sub-li": []
                },
                {
                    "li": "Code review using IDE - Able to check the code quality in IDE by Qbot rules.",
                    "sub-li": []
                },
                {
                    "li": "Engine and Service health check status in one place.",
                    "sub-li": []
                },
                {
                    "li": "Pattern-based review exclusion by repositories, Since some of the applications, are committing binaries and minified files in the source.",
                    "sub-li": []
                }
            ],
            "bugFix": [
                {
                    "li": "Bot and Skype mapping issue while adding new users issue resolved.",
                    "sub-li": []
                },
                {
                    "li": "Project dashboard navigation issue resolved.",
                    "sub-li": []
                }
            ],
            "enhancements": [
                {
                    "li": "Manual reviewer notification modified.",
                    "sub-li": []
                },
                {
                    "li": "Groom rules for each project (based on ignored comments)",
                    "sub-li": []
                },
                {
                    "li": "Project and User quality report has been enhanced to support custom filters with export options.",
                    "sub-li": []
                },
                {
                    "li": "Bulk reject for ignoring comments.",
                    "sub-li": []
                },
            ]
        },
        {
            "heading": "Release 7 (15 July 2020)",
            "features": [
                {
                    "li": "Multi-tendency support.",
                    "sub-li": []
                },
                {
                    "li": "GitLab integeration with QBot.",
                    "sub-li": []
                },
                {
                    "li": "Webhook implementation with QBot.",
                    "sub-li": []
                },
                {
                    "li": "Timezone changes to support all the browser timezones.",
                    "sub-li": []
                }
            ],
            "bugFix": [
                {
                    "li": "Duplicate data showing in notification card and count.",
                    "sub-li": []
                },
                {
                    "li": "Ruleset file name extension missing for some files.",
                    "sub-li": []
                },
                {
                    "li": "Repo creation validation issues.",
                    "sub-li": []
                }
            ],
            "enhancements": [
                {
                    "li": "Missed PR report configuration added in Project collection.",
                    "sub-li": []
                },
                {
                    "li": "Repository user details configuration added in User",
                    "sub-li": []
                },
                {
                    "li": "Support all email-ids in user registeration and authentication",
                    "sub-li": []
                },
            ],
            "minorRelease": [
                {
                    "heading": "Release 7.1 (07 August 2020)",
                    "bugFix": [
                        {
                            "li": "Wiki page project search issue.",
                            "sub-li": []
                        },
                        {
                            "li": "Dashboard date filters not shown in manager role.",
                            "sub-li": []
                        },
                        {
                            "li": "Project group delete issue.",
                            "sub-li": []
                        },
                        {
                            "li": "Pagination issue in Project and Project group.",
                            "sub-li": []
                        },
                        {
                            "li": "Home page project group filter not shown all records.",
                            "sub-li": []
                        },
                        {
                            "li": "Comments count showing negative value.",
                            "sub-li": []
                        },
                        {
                            "li": "Handled the file with out extension and filename having space while review and response.",
                            "sub-li": []
                        }
                    ]
                }
            ]
        },
        {
            "heading": "Release 8 (29th September 2020)",
            "features": [
                {
                    "li": "Monitoring the repo changes in SCM especially token expire / validation/permission and repo changes.",
                    "sub-li": []
                },
                {
                    "li": "Pull back missed PRs (No longer required Missed PR monitoring).",
                    "sub-li": []
                },
                {
                    "li": "Bulk upload user organization mapping using template & feature enable/disable by organization settings.",
                    "sub-li": []
                },
                {
                    "li": "Send a notification to the appropriate bot group and alert manager(s) when there is a broken connectivity.",
                    "sub-li": []
                }
            ],
            "enhancements": [
                {
                    "li": "Token validation check when project updates.",
                    "sub-li": []
                },
            ],
            "bugFix": [
                {
                    "li": "Project name and group search issue fixed.",
                    "sub-li": []
                }
            ]
        },
        {
            "heading": "Release 9 (6th November 2020)",
            "features": [
                {
                    "li": "Introducing openapi standard review.",
                    "sub-li": []
                },
                {
                    "li": "Added a provision to delete the repository which is no longer required.",
                    "sub-li": []
                }
            ],
            "enhancements": [
                {
                    "li": "Improved the notification performance.",
                    "sub-li": []
                },
                {
                    "li": "Quarter based report support for project and user.",
                    "sub-li": []
                },
                {
                    "li": "Comments column added in contributors report.",
                    "sub-li": []
                }
            ],
            "minorRelease": [
                {
                    "heading": "Release 9.1 (30th November 2020)",
                    "features": [
                        {
                            "li": "Integrated with JIRA workflow for defect tracking.",
                            "sub-li": []
                        }
                    ],
                    "enhancements": [
                        {
                            "li": "Improved the accuracy of review request URL.",
                            "sub-li": []
                        },
                        {
                            "li": "Added comments,unresolved comments and unresolved reviews columns in developer's grid view report.",
                            "sub-li": []
                        }
                    ],
                    "bugFix": [
                        {
                            "li": "Improved the accuracy of projects and developers report based on review request type filter.",
                            "sub-li": []
                        }
                    ]
                },
                {
                    "heading": "Release 9.2 (14th December 2020)",
                    "features": [
                        {
                            "li": "Branch exclude support (Can configure Source and Destination branch to skip the review).",
                            "sub-li": []
                        }
                    ],
                    "enhancements": [
                        {
                            "li": "Pull back missed post-commit review from Bitbucket repo.",
                            "sub-li": []
                        }
                    ],
                    "bugFix": [
                        {
                            "li": "Blank screen for new user login is fixed.",
                            "sub-li": []
                        },
                        {
                            "li": "Login with bad credentials error message will show for multiple attempts.",
                            "sub-li": []
                        }
                    ]
                }
            ]
        },
        {
            "heading": "Release 10 (11th January 2021)",
            "features": [
                {
                    "li": "Introducing the Password reset option.",
                    "sub-li": []
                },
                {
                    "li": "Introducing php standard review.",
                    "sub-li": []
                },
                {
                    "li": "Introducing the scheduler management for system-users.",
                    "sub-li": []
                }
            ],
            "enhancements": [
                {
                    "li": "Display the Organization id mapped in UserManagement if mapping enabled organizations.",
                    "sub-li": []
                },
                {
                    "li": "Performance improvement - Reduce the login page load time.",
                    "sub-li": []
                },
                {
                    "li": "Displaying the active and total count of rulsets in project repository and global rule management.",
                    "sub-li": []
                }
            ],
            "bugFix": [
                {
                    "li": "Validate repository URL while creating the new repository.",
                    "sub-li": []
                },
                {
                    "li": "Graph issues- Reviews Vs Comments By Date Range NPE issue cleared.",
                    "sub-li": []
                },
                {
                    "li": "Updating the RuleSet count dynamic at SuperAdmin.",
                    "sub-li": []
                },
                {
                    "li": "Validation added while deselecting all the rules project level.",
                    "sub-li": []
                }
            ],
            "minorRelease": [
                {
                    "heading": "Release 10.1 (2nd March 2021)",
                    "enhancements": [
                        {
                            "li": "Provision to View Qbot user manual in wiki page.",
                            "sub-li": []
                        },
                        {
                            "li": "Update organization id from user management grid if the user organization mapping enabled.",
                            "sub-li": []
                        },
                        {
                            "li": "Missed PR cron status updation and optimization.",
                            "sub-li": []
                        },
                        {
                            "li": "Exception handling in code review linter.",
                            "sub-li": []
                        },
                        {
                            "li": "User Management server side pagination added.",
                            "sub-li": []
                        },
                        {
                            "li": "Implemented User and OrganizationMapping id export option.",
                            "sub-li": []
                        }
                    ],
                    "bugFix": [
                        {
                            "li": "Validating regex pattern while saving in branch exclusion.",
                            "sub-li": []
                        },
                        {
                            "li": "Updation on the global rules management tab regarding the impact in repo count after a change in rulesets.",
                            "sub-li": []
                        },
                        {
                            "li": "Repository access token auto refresh.",
                            "sub-li": []
                        },
                        {
                            "li": "To refresh the system global rule management page after a change in rulesets.",
                            "sub-li": []
                        },
                        {
                            "li": "ProjectGroupName sorting not working issue.",
                            "sub-li": []
                        },
                        {
                            "li": "Qbot Style lint not working issue.",
                            "sub-li": []
                        },
                        {
                            "li": "Node module exception handled while reviewing",
                            "sub-li": []
                        }
                    ]
                }
            ]
        },
        {
            "heading": "Release 11 (5th April 2021)",
            "features": [
                {
                    "li": "Search options added for Review tab in Approval and manual review required.",
                    "sub-li": []
                },
                {
                    "li": "Webhook GitLab configuration.",
                    "sub-li": []
                },
                {
                    "li": "Linter Ruleset file generation for PHP & OpenAPI.",
                    "sub-li": []
                },
                {
                    "li": "Restoring the deleted User.",
                    "sub-li": []
                }
            ],
            "enhancements": [
                {
                    "li": "Dashboard default date Today selected.",
                    "sub-li": []
                },
                {
                    "li": "Flush files clearing cron from code review linter.",
                    "sub-li": []
                },
                {
                    "li": "My statistics tab added in users page.",
                    "sub-li": []
                },
                {
                    "li": "Validations added to Scheduler management.",
                    "sub-li": []
                },
                {
                    "li": "Missed PR retrying cron call.",
                    "sub-li": []
                },
                {
                    "li": "Tooltip added for startTime field in SchedulerManagement.",
                    "sub-li": []
                },
                {
                    "li": "Ignored/Skipped rules count with projects-wise report API.",
                    "sub-li": []
                },
                {
                    "li": "Added loggers for code-review-linter and botkit.",
                    "sub-li": []
                }
            ],
            "bugFix": [
                {
                    "li": "No Organization found logo to display for newly registered user.",
                    "sub-li": []
                },
                {
                    "li": "Modified on Date initialization at Review tabs.",
                    "sub-li": []
                }
            ],
            "minorRelease": [
                {
                    "heading": "Release 11.1 (12th May 2021)",
                    "features": [
                        {
                            "li": "Date filter provided into Reviews tab.",
                            "sub-li": []
                        },
                        {
                            "li": "Highlighted the files which are excluded in the review response.",
                            "sub-li": []
                        },
                        {
                            "li": "Removal of stash diff modal to view review response, replace diff modal by tree view.",
                            "sub-li": []
                        },
                        {
                            "li": "Provided the Email update feature to System & Admin.",
                            "sub-li": []
                        }
                    ],
                    "enhancements": [
                        {
                            "li": "Performance enhancement - Default loading of all reviews restricted in review details page.",
                            "sub-li": []
                        },
                        {
                            "li": "Updation of the Commit message for Commit Url Review(Git & Stash).",
                            "sub-li": []
                        },
                        {
                            "li": "Addition of the images for qbot unsupported languages.",
                            "sub-li": []
                        }
                    ],
                    "bugFix": [
                        {
                            "li": "Rules count mismatching at Language Management tab.",
                            "sub-li": []
                        },
                        {
                            "li": "Report mismatch(total number of comments is lesser than unresolved comments for some reports) issue fix.",
                            "sub-li": []
                        },
                        {
                            "li": "Disabled the future date under the custom date range in the user's Tab.",
                            "sub-li": []
                        },
                        {
                            "li": "Unsupported files are restricted to review.",
                            "sub-li": []
                        }
                    ]
                },
                {
                    "heading": "Release 11.2 (8th July 2021)",
                    "features": [
                        {
                            "li": "Repository based Report have introduced.",
                            "sub-li": []
                        },
                        {
                            "li": "Flag based Push-Notification added in org management.",
                            "sub-li": []
                        },
                        {
                            "li": "Project token cascading update to repos level with verification status.",
                            "sub-li": []
                        }
                    ],
                    "enhancements": [
                        {
                            "li": "Fine tune the filter in the review summary.",
                            "sub-li": []
                        },
                        {
                            "li": "Admin can see the review request orginated palces like Web Console/SkypeBot/WebHook/Cron.",
                            "sub-li": []
                        },
                        {
                            "li": "Fine tune the Missed-PR cron for failure cases.",
                            "sub-li": []
                        },
                        {
                            "li": "Identification added for deleted repository in the review response grid and view.",
                            "sub-li": []
                        }
                    ],
                    "bugFix": [
                        {
                            "li": "Mismatch search key response fixed.",
                            "sub-li": []
                        },
                        {
                            "li": "Projects group name filter at repository tab fixed.",
                            "sub-li": []
                        },
                        {
                            "li": "Support enhanced for customised diff URL patterns.",
                            "sub-li": []
                        },
                        {
                            "li": "Support for Duplicate UserName fixed.",
                            "sub-li": []
                        }
                    ]
                }
            ]
        },
        {
            "heading": "Release 12 (6th Oct 2021)",
            "features": [
                {
                    "li": "QBot Desktop plugin, It will review and generate comments before commiting the code.",
                    "sub-li": []
                },
                {
                    "li": "Added Java 8 best practices using Code-pedagogue (new engine).",
                    "sub-li": []
                },
                {
                    "li": "Pull the remote comments from repository and keep single place for all the comments.",
                    "sub-li": []
                },
                {
                    "li": "Added Global project level filter.",
                    "sub-li": []
                }
            ],
            "enhancements": [
                {
                    "li": "Redefined the review grid status filtering and sorting.",
                    "sub-li": []
                },
                {
                    "li": "Project name column added at Review grid.",
                    "sub-li": []
                },
                {
                    "li": "Filter placeholder added at User Management Tab.",
                    "sub-li": []
                },
                {
                    "li": "Deleted repository name strike out on code quality report.",
                    "sub-li": []
                }
            ],
            "bugFix": [
                {
                    "li": "Bug fix - Issuer URL added on JWT token.(security improvements).",
                    "sub-li": []
                },
                {
                    "li": "Update button enable/disable bug handled in Projects tab.",
                    "sub-li": []
                },
                {
                    "li": "Review Statistics Chart Display bug fixed.",
                    "sub-li": []
                },
                {
                    "li": "Language rules count mismatch issue fixed in repository rule configuration.",
                    "sub-li": []
                }
            ],
            "technical_improvements": [
                {
                    "li": "Qbot supported file extension migrated to db.",
                    "sub-li": []
                },
                {
                    "li": "Push notification emitter enhancement.",
                    "sub-li": []
                },
                {
                    "li": "PMD version(6.39.0) updated.",
                    "sub-li": []
                }
            ]
        },
        {
            "heading": "Release 13 (25th Jul 2022)",
            "features": [
                {
                    "li": "New integration - Azure devops rep (SCM).",
                    "sub-li": [
                        "PRs reviewing.",
                        "Webhook configured.",
                        "Repo Manual Comments added.",
                        "Reviewer comments pulling while review.",
                        "Missed PR added for AzureDev Repo."
                    ]
                },
                {
                    "li": "Copy rules management added.",
                    "sub-li": []
                },
                {
                    "li": "Login user auditing system added",
                    "sub-li": []
                },
                {
                    "li": "Converted to Containerized build and deployment.",
                    "sub-li": []
                }
            ],
            "enhancements": [
                {
                    "li": "Filter added for large report handling.",
                    "sub-li": []
                }
            ],
            "bugFix": [
                {
                    "li": "User profile updated with unregistered domain fixed",
                    "sub-li": []
                }
            ]
        }
    ]
}