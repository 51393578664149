import React, { Component } from 'react';
import moment from 'moment';

class AppFooter extends Component {
    render() {
        var date =moment().format('YYYY');
        return (
            <footer>
                <p className="p-3">&copy; {date} Sri Mookambika Infosolutions Private Ltd. All Rights Reserved.</p>
            </footer>
        );
    }
}

export default AppFooter;