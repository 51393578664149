import React, { Component } from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import { AgGridReact } from 'ag-grid-react';
import { avatarImage } from '../../components/service/avatar-image';
import './modal.css';



class ReviewCommitId extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            commits: this.props.reviews || [],
            openSn: false,
            defaultColDef: {
                sortable: true,
                resizable: true
            },
            columnDefs: [{
                headerName: "Committer", field: "committer", suppressSizeToFit: false, width: 200, cellRenderer: function (params) {
                    const avatarImg = avatarImage.getImageLocation(params.data && params.data.committer && params.data.committer.name.length);
                    let name = params.data && params.data.committer && params.data.committer.name;
                    let Img = `<img src= "${avatarImg}"  alt="Avatar" height="80%" class="pr-3"/>`  + name + '';
                    return `<span title=  "${name}" >` + Img + '</span>'
                }
            }, {
                headerName: "Commit Id", field: "commitId", suppressSizeToFit: true, width: 350, cellRenderer: (params) => {
                    return `<span title= "${params.data.commitId}" >` + params.data.commitId + '</span>'
                }
            }, {
                headerName: "Message", field: "committerMessage", suppressSizeToFit: false, width: 350, cellRenderer: (params) => {
                    return `<span title= "${params.data.committerMessage}" >` + (params.data.committerMessage ? params.data.committerMessage : 'No Message Found') + "</span>"
                }
            }, {
                headerName: "Commit Date", field: "createdOn", suppressSizeToFit: true, width: 200, cellRenderer: function (params) {
                    return `<span title= "${moment(params.data.createdOn).format('YYYY-MMM-DD HH:mm A')}" >` + moment(params.data.createdOn).format('YYYY-MMM-DD HH:mm A') + '</span>';
                }, sort: 'desc'
            }, {
                headerName: "Issues", field: "jiraIds", suppressSizeToFit: false, width: 150, cellRenderer: (params) => {
                    let jiraKey = params.data.jiraIds !== null ? params.data.jiraIds : '';
                    return `<span title= "${jiraKey}" >` + jiraKey + '</span>'
                },
            },
            ],
        }
        this.handleClose = this.handleClose.bind(this);
        this.copyText = this.copyText.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
    }

    handleClose() {
        this.props.onUpdate();
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
    }

    closeSnackbar() {
        this.setState({
            openSn: false
        })
    }

    copyText(event) {
        const copyText = event.target.innerHTML;
        const textField = document.createElement('textarea');
        textField.innerText = copyText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({ openSn: true })
    }

    render() {
        return (
            <div>

                <Dialog
                    open={this.props.showModal}
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth={"lg"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle>{'Commits'}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <div className="row p-3">
                            <div className="col-md-12">
                                <div
                                    className="ag-theme-material"
                                    style={{
                                        height: 'calc(100vh - 310px)', width: '100%'
                                    }}
                                >
                                    <AgGridReact
                                        columnDefs={this.state.columnDefs}
                                        rowData={[this.state.commits]}
                                        animateRows={true}
                                        defaultColDef={this.state.defaultColDef}
                                        onGridReady={this.onGridReady}
                                        paginationAutoPageSize={true}>
                                    </AgGridReact>

                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary" autoFocus>
                            close
                    </Button>
                    </DialogActions>

                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.openSn}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Copied!</span>}
                    action={[
                        <Button key="undo" color="secondary" size="small" onClick={this.closeSnackbar}>
                            Ok
                    </Button>,
                    ]}
                />

            </div>
        );
    }
}

export default ReviewCommitId;
