import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbars from '../../../../core/error/Snackbars';
import {avatarImage} from '../../../components/service/avatar-image';
import gql from 'graphql-tag';


const img = avatarImage.getImageLocation();
const USER_COMMENT = gql`
    mutation ignoreReviewComment(
        $reviewId: String!,
        $commentId: String!,
        $userId: String!,
		$userName: String!,
		$fullName: String!,
		$emailId: String,
		$message: String) {
            ignoreReviewComment(
                reviewId: $reviewId,
                commentId: $commentId,
                userId: $userId,
                userName: $userName,
                fullName: $fullName,
                emailId: $emailId,
                message: $message
            ) {
                message
                type
                createdOn
                user {
                    id
                    username
                    name
                    emailId
                }
        }
    }
`;

var updatedData = [];
class IgnoreComment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: ''
        }
        this.handelChange = this.handelChange.bind(this);
    }

    handelChange(e){
        this.setState({
            message: e.target.value
        })
    }

    render() {
        return (
            <Mutation mutation={USER_COMMENT} onCompleted={() => {this.props.updateComments(updatedData)}}>
                    {(ignoreReviewComment, { loading, error, data }) => {
                        if (data) {
                            updatedData = data;
                        }
                        return (
                            <div className="row p-3">
                                <div className="ml-5 pl-4" style={{width: '100%'}}>
                                    <div className="comments d-flex">
                                        <div className="avatar">
                                            <img src={img} className="align-self-start" alt="Avatar" width="30" />
                                        </div>
                                        <span className="inner-comment-content">
                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                var users = JSON.parse(localStorage.getItem('users'));
                                                ignoreReviewComment({
                                                    variables: {
                                                        reviewId: this.props.reviewId,
                                                        commentId: this.props.data.commentId || this.props.lineId,
                                                        message: this.state.message,
                                                        userId: users.id,
                                                        fullName: users.name,
                                                        userName: users.username,
                                                        emailId: users.emailId,
                                                    }
                                                })
                                            }}>
                                                {error && <Snackbars status="error" message={error} showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />}
                                                <h6 className="text-primary">{JSON.parse(localStorage.getItem('users')).name}</h6>
                                                <textarea className="add-comments-text border-0 w-100" placeholder="Justification (Optional)" onChange={this.handelChange} value={this.state.message}></textarea>
                                                <hr className="p-0 m-0 pb-2"></hr>
                                                <p className="actions">
                                                    <Button type="submit">{ loading ? <CircularProgress disableShrink size={24} /> : 'Request'}</Button>
                                                    <span className="secondary cursor-pointer pl-4" onClick={() => {this.props.onCancel()}}><i className="material-icons align-middle icon">close</i>Cancel</span>
                                                </p>
                                            </form>
                                        </span>
                                    </div>
                                </div>
                            </div>)
                    }}
            </Mutation>
        );
    }
}

export default IgnoreComment;