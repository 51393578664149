import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { AgGridReact } from 'ag-grid-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import '../admin.css';
import EditLanguageManagement from './edit-language-management';
import Error500 from '../../../core/error/error';
import languageServices from '../services/language-service';

const GET_All_LANGUAGES = gql`
query languages {
    languages {
        languageName
        numOfRules
        numOfActiveRules
        description
        enabled
        extensions
        analyzerEngines
      }
}
`;

class LanguageManagement extends Component {
    languageList;
    constructor(props) {
        super(props)
        this.languageServices = new languageServices();
        this.tableRef = React.createRef();
        this.state = {
            showEditLanguageModal: false,
            filter:'',
            defaultColDef: {
                sortable: true,
                resizable: true,
            },
            languageList: [],
            columnDefs: [{
                headerName: "Language Name", field: "languageName", suppressSizeToFit: false,
                getQuickFilterText: function (params) {
                    return params.data.languageName;
                }
            }, {
                headerName: "No of Rules", field: "numOfActiveRules", suppressSizeToFit: false
            }, {
                headerName: "Description", field: "description", suppressSizeToFit: false
            }, {
                headerName: "Analyzer Engines", field: "analyzerEngines", suppressSizeToFit: false, cellRenderer: function (params) {
                    if (params.data.analyzerEngines) {
                        var analyzerEngines = params.data.analyzerEngines.toString().replace('[', '').replace(']', '');
                        return analyzerEngines;
                    }
                }
            }, {
                headerName: "Extensions", field: "extensions", suppressSizeToFit: false, cellRenderer: function (params) {
                    if (params.data.extensions) {
                        var extension = params.data.extensions.toString().replace('[', '').replace(']', '');
                        return extension;
                    }
                }
            }, {
                headerName: "Language Enable", field: "enabled", suppressSizeToFit: false, cellRenderer: function (params) {
                    return '<div class="custom-control custom-switch"> <input type="checkbox" class="custom-control-input" id="customSwitch"' + (params.data.enabled ? 'checked' : '') + '><label class="custom-control-label" for="customSwitch1"></label></div>';
                }
            }, {
                headerName: "Action", suppressSizeToFit: false, cellRenderer: function (params) {
                    var LanguageConfigBtn = "<div><Tooltip title='Edit Language' placement='top'><button class='btn btn-link " + (params.data.languages && params.data.languages.length ? "" : "text-danger") + " '><i class='material-icons'>more_horiz</i></button></Tooltip></div>";
                    return LanguageConfigBtn;
                }
            }]
        }
        this.onGridReady = this.onGridReady.bind(this);
        this.onFilterLanguageChanged = this.onFilterLanguageChanged.bind(this);
        this.handleEditDialog = this.handleEditDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.refreshLanguageRules = this.refreshLanguageRules.bind(this);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        params.api.sizeColumnsToFit();
    }

    onFilterLanguageChanged(event) {
        this.setState({filter: event.target.value})
        this.gridApi.setQuickFilter(event.target.value);
    }

    refreshLanguageRules() {
        return this.languageServices.getRefreshLanguageRules();
    }

    handleEditDialog(grid) {
        if (grid) {
            this.languageList = grid.data;
        }
        if (grid && grid.colDef.headerName === 'Action') {
            this.setState({ showEditLanguageModal: true });
        }
        if (!grid) {
            this.setState({ showEditLanguageModal: false });
        }
    }

    handleClose() {
        this.setState({ showEditLanguageModal: false });
    }

    render() {
        return (
            <div className="col-md-12 user-management">
                <Query query={GET_All_LANGUAGES}>
                    {({ loading, error, data, refetch }) => {
                        if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                        if (error) return <div className="text-center">
                            <Error500 />
                        </div>
                        return (
                            <div className="row">
                                <div className="col-md-12">
                                    <div
                                        className="ag-theme-material container-fluid pt-1 pl-0 pr-0 pb-0"
                                        {...this.gridApi ? this.gridApi.sizeColumnsToFit() : ''}
                                        style={{
                                            height: 'calc(100vh - 150px)', width: '100%'
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-md-3 mb-2">
                                                <TextField type="text"
                                                    label="Search"
                                                    id="filter-language-text-box"
                                                    value={this.state.filter}
                                                    onChange={this.onFilterLanguageChanged}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            rowData={data.languages}
                                            pagination={true}
                                            animateRows={true}
                                            onGridReady={this.onGridReady}
                                            defaultColDef={this.state.defaultColDef}
                                            suppressRowClickSelection={true}
                                            onCellClicked={this.handleEditDialog}
                                            paginationAutoPageSize={true}>
                                        </AgGridReact>
                                        {this.state.showEditLanguageModal && <EditLanguageManagement languageList={this.languageList} showModal={this.state.showEditLanguageModal} onUpdate={this.handleEditDialog} refresh={() => refetch()} history={this.props.history}></EditLanguageManagement>}
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </Query>
            </div>
        );
    }
}

export default LanguageManagement;