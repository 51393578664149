import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import ReviewServices from './services/review-services';
import AddReviewUrl from './modals/AddReviewUrl-modal';
import { UTCtoLocalTime } from '../../core/service/timezone';
import CustomDatePickerManual from '../.././core/CustomFilter/customDatePickerManual';
import './reviews.css';
import CustomSearchManual from '../.././core/CustomFilter/customSearchManual';
import StatusDetails from './modals/StatusDetails-modal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { resetFilterService } from '../../core/login/services/reset-filter';
import preferenceService from '../../core/service/preference';

const style = {
    border: 'none',
}

class GetManualReviews extends Component {
    projectId;
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.reviewServices = new ReviewServices();
        this.state = {
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true
            },
            showModal: false,
            showEditModal: false,
            filterRefresh: false,
            showStatusDetail: false,
            dynamicPageSize: 7,
            columnDefs: [{
                title: "ID", field: "id", filterPlaceholder: 'search', filtering: true, filterCellStyle: style, headerStyle: style, defaultFilter: preferenceService.getValue("manualGridValues").idManual, filterComponent: (props) => <CustomSearchManual {...props} />, render: params => {
                    var url = "/review-details/" + params.id;
                    var iconToShow;
                    if (params.status === 'Completed') {
                        if (params.manualReviewStatus) {
                            iconToShow = <Link to={{ pathname: url, state: { fromManualReviewGrid: true } }} ><i className="material-icons align-middle text-primary" title={params.status + ' (' + params.manualReviewStatus + ')'}>done_all</i></Link>
                        } else {
                            iconToShow = <Link to={{ pathname: url, state: { fromManualReviewGrid: true } }} ><i className="material-icons align-middle text-success" title={params.status}>done_all</i></Link>
                        }
                    } else {
                        if (params.manualReviewStatus) {
                            iconToShow = <Link to={{ pathname: url, state: { fromManualReviewGrid: true } }} ><i className="material-icons align-middle text-primary" title={params.status + ' (' + params.manualReviewStatus + ')'}>create</i></Link>
                        } else {
                            iconToShow = <Link to={{ pathname: url, state: { fromManualReviewGrid: true } }} ><i className="material-icons align-middle text-danger" title={params.status}>create</i></Link>
                        }
                    }
                    return <div>{iconToShow} <Link to={{ pathname: url, state: { fromManualReviewGrid: true } }} >{params.id}</Link></div>;
                }
            }, {
                title: "Review URL", field: "reviewUrl", filterPlaceholder: 'search', filtering: true, filterCellStyle: style, headerStyle: style, defaultFilter: preferenceService.getValue("manualGridValues").reviewUrlManual, filterComponent: (props) => <CustomSearchManual {...props} />, render: params => {
                    return <Tooltip title={params.reviewUrl} key={params.id}><div>{params.reviewUrl}</div></Tooltip>
                }
            }, {
                title: "Repo Name", field: "repoName", filterCellStyle: style, headerStyle: style, filterPlaceholder: 'search', filtering: true, defaultFilter: preferenceService.getValue("manualGridValues").repoNameManual, filterComponent: (props) => <CustomSearchManual {...props} />, render: params => {
                    if (params.isRepoDeleted) {
                        return <Tooltip placement="top" title={params.isRepoDeleted && "Repo Deleted"}><div className="crossed-line">{params.repoName}</div></Tooltip>
                    } else {
                        return <div>{params.repoName}</div>
                    }
                }
            }, {
                title: "Project Name", field: "projectName", filterCellStyle: style, headerStyle: style, filterPlaceholder: 'search', filtering: true, defaultFilter: preferenceService.getValue("manualGridValues").projectNameManual, filterComponent: (props) => <CustomSearchManual {...props} />, render: function (params) {
                    return params.projectName;
                }
            }, {
                title: "Created On", field: "createdOn", filtering: true, filterCellStyle: style, headerStyle: style, type: "datetime", defaultFilter: preferenceService.getValue("manualGridValues").createdOnManual, filterComponent: (props) => <CustomDatePickerManual {...props} />, render: function (params) {
                    return UTCtoLocalTime(params.createdOn, 'YYYY-MMM-DD HH:mm A');
                }
            }, {
                title: "Modified On", field: "modifiedOn", filtering: true, filterCellStyle: style, headerStyle: style, type: "datetime", defaultFilter: preferenceService.getValue("manualGridValues").modifiedOnManual, filterComponent: (props) => <CustomDatePickerManual {...props} />, render: function (params) {
                    return (params.modifiedOn) ? UTCtoLocalTime(params.modifiedOn, 'YYYY-MMM-DD HH:mm A') : '';
                }
            }, {
                title: "Requested User", field: "name", filterPlaceholder: 'search', filtering: true, filterCellStyle: style, headerStyle: style, defaultFilter: preferenceService.getValue("manualGridValues").nameManual, filterComponent: (props) => <CustomSearchManual {...props} />, render: function (params) {
                    return params.requestedUser.name;
                }
            }]
        }
        this.gridApi = null;
        this.onGridReady = this.onGridReady.bind(this);
        this.addNewUrl = this.addNewUrl.bind(this);
        this.showStatusDetail = this.showStatusDetail.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
    }

    getData(query) {
        let fields = ["idManual", "reviewUrlManual", "repoNameManual", "projectNameManual", "statusManual", "createdOnManual", "modifiedOnManual", "nameManual"];
        const reviewGridValues = {};
        if (query && query.filters && query.filters.length > 0) {
            query.filters.forEach(filter => {
                if (filter && filter.column && filter.column.field && filter.column.tableData && filter.column.tableData.filterValue) {
                    reviewGridValues[filter.column.field + "Manual"] = filter.column.tableData.filterValue;
                    fields.splice(fields.indexOf(filter.column.field + "Manual"), 1);
                }
            })
        }
        fields.forEach(field => { reviewGridValues[field] = "" });
        preferenceService.setValue("manualGridValues", reviewGridValues);
        if (this.props.fromClose) {
            query.page = parseInt(localStorage.getItem('manualGridQueryPage'));
            query.pageSize = parseInt(localStorage.getItem('manualGridQueryPageSize'));
            this.setState({
                dynamicPageSize: query.pageSize
            })
            this.props.onFromCloseChange();
        }
        localStorage.setItem('manualGridQueryPage', JSON.stringify(query.page));
        localStorage.setItem('manualGridQueryPageSize', JSON.stringify(query.pageSize));
        if (query.filters.length > 0) {
            this.setState({ filterRefresh: true });
        }
        if (query.filters) {
            const arrayToObject = (filter) =>
                Object.assign({}, ...filter.map(item => ({ [item.column.field]: item.column.tableData.filterValue })))
            const filter = arrayToObject(query.filters);
            return this.reviewServices.getManualRequiredReviewsList(query, filter);
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        params.api.sizeColumnsToFit();
    }

    addNewUrl() {
        this.setState({
            showModal: !this.state.showModal,
        }, () => {
            if (!this.state.showModal)
                this.tableRef.current && this.tableRef.current.onQueryChange();
        });

    }

    filterRefresh() {
        this.setState({ filterRefresh: false });
    }

    showStatusDetail() {
        if (!this.state.showStatusDetail) {
            this.setState({ showStatusDetail: true });
        }
    }

    resetFilter() {
        resetFilterService.resetForManualReviewGrid();
        if (this.tableRef.current && this.tableRef.current.props && this.tableRef.current.props.columns && this.tableRef.current.props.columns.length > 0) {
            this.tableRef.current.props.columns.forEach(column => {
                if (column.tableData && column.tableData.filterValue) {
                    column.tableData.filterValue = "";
                }
            })
        }
        if (this.tableRef.current) {
            this.tableRef.current.onQueryChange();
        }
    }

    handleCancel() {
        this.setState({ showStatusDetail: false });
    }

    render() {
        return (
            <div className="post-list">
                <div >
                    <div
                        className="ag-theme-material"
                        style={{
                            width: '100%', paddingBottom: '30px',
                        }}
                    >
                        <div className="row">
                            <div className="review-list-container">
                                <Button className="addbutton p-0 pr-3 btn-link text-primary bg-white border-0 shadow-none" onClick={() => {
                                    this.tableRef.current && this.tableRef.current.onQueryChange()
                                }}>
                                    <Tooltip title="Refresh" placement="top">
                                        <i className="material-icons text-primary">refresh</i>
                                    </Tooltip>
                                </Button>
                                {/* {this.state.filterRefresh ?
                                    <Button className="addbutton p-0 pr-4 btn-link filterRefresh bg-white border-0 shadow-none" onClick={() => { this.filterRefresh() }}>
                                        <Tooltip title="Filter Refresh" placement="top">
                                            <i className="material-icons align-middle">filter_alt</i>
                                        </Tooltip>
                                    </Button>
                                    : <Button className="addbutton p-0 pr-4 btn-link text-primary bg-white border-0 shadow-none">
                                        <Tooltip title="Filter Refresh" placement="top">
                                            <i className="material-icons align-middle">filter_alt</i>
                                        </Tooltip>
                                    </Button>
                                } */}
                                <Button className="addbutton p-0 pr-4 btn-link text-primary bg-white border-0 shadow-none" onClick={() => { this.setState({ showStatusDetail: true }) }}>
                                    <Tooltip title="Status View" placement="top">
                                        <VisibilityIcon className="material-icons align-middle">status</VisibilityIcon>
                                    </Tooltip>
                                </Button>
                                {resetFilterService.checkFilterPresentInManualGrid() && <Button className="addbutton p-0 pr-4 btn-link text-primary bg-white border-0 shadow-none" onClick={() => this.resetFilter()}>
                                    <Tooltip title="Reset Filter" placement="top">
                                        <i className="material-icons align-middle text-danger">restore</i>
                                    </Tooltip>
                                </Button>}
                                {!resetFilterService.checkFilterPresentInManualGrid() && <Button className="addbutton p-0 pr-4 btn-link text-primary bg-white border-0 shadow-none" onClick={() => this.resetFilter()}>
                                    <Tooltip title="Reset Filter" placement="top">
                                        <i className="material-icons align-middle text-primary">restore</i>
                                    </Tooltip>
                                </Button>}
                                <Button className="addbutton p-0 pr-4 btn-link text-primary bg-white border-0 shadow-none" onClick={() => { this.addNewUrl() }}>
                                    <Tooltip title="Add Review" placement="top">
                                        <i className="material-icons align-middle">add_circle</i>
                                    </Tooltip>
                                </Button>
                            </div>
                        </div>

                        <MaterialTable
                            title="Reviews"
                            tableRef={this.tableRef}
                            columns={this.state.columnDefs}
                            data={query => this.getData(query)}
                            options={{
                                filtering: true,
                                toolbar: false,
                                headerStyle: {
                                    padding: '5px',
                                    fontSize: '12px',
                                    fontWeight: "bold",
                                    color: 'black',
                                    border: 'none',
                                },
                                pageSize: this.state.dynamicPageSize,
                                pageSizeOptions: [7, 10, 20, 30],
                            }}
                        />
                        <AddReviewUrl showModal={this.state.showModal} onUpdate={this.addNewUrl} />
                        <StatusDetails editModal={this.state.showStatusDetail} handleClose={this.handleCancel} />
                    </div>
                </div>
            </div>
        );
    }
}

export default GetManualReviews;