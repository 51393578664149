import React from 'react';

function Summary(props) {
    return (
        <div className="wiki-summary">
            <div className="container">
                <h3>{props.content.heading}</h3>
                <div className="overview mt-3">
                    {props.content.content && props.content.content.map(data => {
                        return (
                            <div>
                                <h5>{data.head}</h5>
                                {data.content.map(cont => {
                                    return <p>{cont}</p>
                                })}
                                <br></br>
                            </div>
                        )
                    })}
                    <div className="features mb-5">
                        <h5>Features</h5>
                        <ul>
                            {
                                props.content.feature && props.content.feature.map(data => {
                                    if (!data) {
                                        return null;
                                    }
                                    return (
                                        <li>
                                            {data && data.li}
                                            <ul>
                                                {data && data["sub-li"].map(con => {
                                                    return <li>{con}</li>;
                                                })}
                                            </ul>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Summary;