import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import coreService from './../AppCore'
import { authHeader, refreshHeader } from '../login/services/auth-header';

const ClientService = {
    getClienUri: () => {
        return new ApolloClient({
            uri: coreService.rootContext + 'graphql',
            cache: new InMemoryCache({
                addTypename: false
              }),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
                query: {
                    fetchPolicy: 'no-cache',
                },
            },
            request: (operation) => {
                operation.setContext({
                    headers: refreshHeader().Authorization ? refreshHeader() : authHeader()
                })
            }
        });
    }
};

export default ClientService;