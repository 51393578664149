import React, { Component } from 'react';
import { DialogActions, DialogContent, DialogTitle, Dialog, Button, Select, MenuItem, Checkbox, ListItemText, Input, Toolbar } from '@material-ui/core';
import preferenceService from '../../../../core/service/preference';
import { authHeader } from '../../../../core/login/services/auth-header';
import Snackbars from '../../../../core/error/Snackbars';
import ConfirmDialog from '../../../../qBot/admin/userManagement/users-action/ConfirmDialog';

const user1 = preferenceService.getValue('users', 'local');
class UploadFile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null,
            excetion: '',
            uploaded: true,
            completed: this.props.completed,
            success: '',
            tableData: []
        }
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onClickUpload = this.onClickUpload.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.closeAll = this.closeAll.bind(this);
    }

    closeAll = () => {
        this.setState({
            completed: !this.state.completed,
            file: {},
            completed: false,
            uploaded: true
        });
        this.props.onClose();
    }

    onChangeHandler = event => {
        this.setState({
            file: event.target.files[0],
            loaded: 0,
        })
    }


    onClickUpload = () => {
        const user = preferenceService.getValue('users', 'local');
        let data = new FormData();
        this.setState(
            {
                uploaded: true,
                completed: false
            }
        )
        data.append('excelDataFile', this.state.file);
        fetch(`/code-review-service/api/user/userOrgMapping?organizationId=${user.currentOrganizationId}`, {
            headers: authHeader(),
            method: 'PUT',
            body: data
        }).then(async (response) => response.text().then(text => {
            const data = text && JSON.parse(text);
            if (data) {
                this.setState({ tableData: data })
            }
            const error = (data && data.message) || response.statusText;
            if (!response.ok) {
                this.setState(
                    {
                        exception: error,
                        uploaded: false
                    })
            }
            if (response.ok) {
                this.setState({
                    success: 'File uploaded successfully',
                    completed: true
                })
            }
        }))
    }

    handleClose = () => {
        this.setState({
            file: {},
            completed: false,
            uploaded: true
        });
        this.props.onClose()
    }

    render() {
        return (
            <div>
                <Dialog open={this.props.showModal} fullWidth={true} maxWidth={"sm"} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Upload {user1.repoColumnHeader}</DialogTitle>
                    <div className="text-center">
                        <DialogActions>
                            <DialogContent dividers>
                                <div>
                                    <label>Please Upload xls File Of {user1.repoColumnHeader}</label>
                                    <br />
                                    <input type="file" name="file" required onChange={this.onChangeHandler} />
                                </div>
                                <br />
                                <div>
                                    <Button onClick={this.onClickUpload} variant="contained" color="primary">
                                        Upload
                            </Button>
                                    {!this.state.uploaded &&
                                        <Snackbars status="error" message={this.state.exception} showSnackbar={true} closeSnackBar={false} />
                                    }
                                </div>
                            </DialogContent>
                        </DialogActions>
                    </div>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary" >
                            close
                    </Button>
                    </DialogActions>
                </Dialog>
                <ConfirmDialog showModal={this.state.completed} tableValue={this.state.tableData} onClose={this.closeAll} />
            </div>
        )
    }
}



export default UploadFile;