import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Dropdown from 'react-bootstrap/Dropdown';
import Error500 from '../../../../src/core/error/error';
import gql from 'graphql-tag';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import UserAnalysisGrid from './components/grid';
import { withStyles } from "@material-ui/core/styles";
import { LocalToUTCTime } from '../../../core/service/timezone';
import { Query } from 'react-apollo';
import { authHeader } from '../../../core/login/services/auth-header';

const user = JSON.parse(localStorage.getItem('users'));

const homePageStyle = theme => ({
    root: {
        width: "300px"
    },
    selected: {
        backgroundColor: "#3f51b5 !important",
        color: "#ffffff"
    }
});

const USERS_SUMMARY_Analysis = gql`
    query userSummary($userId: String!, $sortBy: String, $direction: Direction, $filter: String!, $startDate: Long, $endDate: Long, $category: String!) {
        users: userSummary(userId: $userId, sortBy: $sortBy, direction: $direction, filter: $filter, startDate: $startDate, endDate: $endDate, category: $category) {
            _id
            data {
                id
                developer {
                    id
                    username
                    name
                    emailId
                }
                numOfReviews
                repositories
                numOfLinesAdded
                numOfUnresolvedComments
                unresolvedReviewRequestCount
                unresolvedReviewRequest
                numOfRepositories
                qualityPercentage
                languages {
                    language
                    percentage
                }
            }
            lastUpdatedDate
        }
    }`;

const GET_STATUS_STATS_BYDATE = gql`
    query getStatusStatsByDate($userId: String, $startDate: Long, $endDate: Long, $category: String!) {
        getStatusStatsByDate (userId: $userId, startDate: $startDate, endDate: $endDate, category: $category) {
            id
            count
          }
    }
    `;

const SUMMARY_FILTER_OPTIONS = gql`
    query summaryFilterOptions {
        filter: summaryFilterOptions
     }`;

class MyStatistics extends Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef();
        this.state = {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            dateLabel: 'Today',
            label: '',
            sortBy: 'numOfLinesAdded',
            direction: 'DESC',
            filter: '',
            category: 'PULL REQUEST',
        }
        this.handleDropDown = this.handleDropDown.bind(this);
    }

    getDateOptions(picker) {
        let seletedDate = '';
        if (picker.startDate.format('YYYY-MM-DD') === picker.endDate.format('YYYY-MM-DD')) {
            seletedDate = picker.startDate.format('YYYY-MM-DD');
        }
        else {
            seletedDate = picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD');
        }

        return {
            dateObj: picker,
            label: seletedDate
        }

    };

    ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    reviewByDate(event, picker, refetch) {
        let data = this.getDateOptions(picker);
        if (picker.chosenLabel !== 'Date Range' && data && data.dateObj) {
            this.setState({
                dateLabel: data.label,
                groupByDateFilter: null,
                startDate: data.dateObj.startDate.format('YYYY-MM-DD'),
                endDate: data.dateObj.endDate.format('YYYY-MM-DD')
            }, () => {
                refetch();
            });
        } else {
            this.setState({
                dateLabel: 'Date Range',
                groupByDateFilter: null,
                startDate: null,
                endDate: null,
            }, () => {
                refetch();
            });
        }
    }

    handleDropDown(e) {
        this.setState({
            category: e
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <Query query={SUMMARY_FILTER_OPTIONS}>
                {({ loading, error, data }) => {
                    if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                    if (error) return <div className="text-center">
                        <Error500 />
                    </div>
                    const { filter } = data;

                    return (
                        <div className="col-md-12 user-management mt-3">
                            <Query fetchPolicy="no-cache" query={USERS_SUMMARY_Analysis} variables={{ userId: user.id, sortBy: this.state.sortBy, startDate: LocalToUTCTime(this.state.startDate, 'x'), endDate: LocalToUTCTime(this.state.endDate, 'x'), direction: this.state.direction, filter: this.state.filter || (filter && filter[0]), category: this.state.category ? this.state.category : "" }}>
                                {({ loading, error, data, refetch }) => {
                                    if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                                    if (error) return <div className="text-center">
                                        <Error500 />
                                    </div>
                                    const { users } = data || { users: { data: [] } };
                                    return (

                                        <div className="row">
                                            <div className="project-icon-container p-0">
                                                <Button>
                                                    <Dropdown >
                                                        <Dropdown.Toggle variant="primary" >
                                                            {this.state.category}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('PULL REQUEST');
                                                            }}>PULL REQUEST</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('COMPARE');
                                                            }}>COMPARE</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('COMMIT ID');
                                                            }}>COMMIT ID</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('ALL');
                                                            }}>ALL</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown >
                                                </Button>
                                                {
                                                    <DateRangePicker containerStyles={{ display: "inline-block", marginRight: "10px" }}
                                                        ranges={this.ranges}
                                                        maxDate={moment().format('MM/DD/YYYY')}
                                                        onApply={(event, picker) => this.reviewByDate(event, picker, refetch)}>
                                                        <div className={`custom-select dateRange ${this.state.startDate && classes.selected}`}>
                                                            {this.state.dateLabel}
                                                        </div>
                                                    </DateRangePicker>
                                                }
                                                <IconButton className="mr-2 " color="primary" onClick={() => refetch()}>
                                                    <Tooltip title="Refresh" placement="top">
                                                        <RefreshIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            </div>
                                            <Query fetchPolicy="no-cache" query={GET_STATUS_STATS_BYDATE} variables={{ userId: user.id, startDate: LocalToUTCTime(this.state.startDate, 'x'), endDate: LocalToUTCTime(this.state.endDate, 'x'), category: this.state.category ? this.state.category : "" }}>
                                                {({ loading, error, data, refetch }) => {
                                                    if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                                                    if (error) return <div className="text-center">
                                                        <Error500 />
                                                    </div>
                                                    return (
                                                        <div className="col-md-12 user-management mt-3">
                                                            <div
                                                                className="container-fluid pt-1 pl-0 pr-0 pb-0 w-100"
                                                            >
                                                                <UserAnalysisGrid users={users.data} review={data} />
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            </Query>
                                        </div>
                                    );
                                }}
                            </Query>
                        </div>
                    );
                }}
            </Query>
        );
    }
}

export default withStyles(homePageStyle)(MyStatistics);
