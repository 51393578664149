import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';

function GridView(props) {

    const [showLanguages, setShowLanguages] = useState({});
    const clickMoreLanguages = (event, i) => {
        var showLanguage = showLanguages;
        showLanguage[i + ""] = event.currentTarget;
        setShowLanguages({ ...showLanguage });
    }

    const handleClose = () => {
        setShowLanguages({});
    }

    if (!props.users) {
        return null;
    }

    return (
        <Grid container spacing={1}>
            {props.users.length ?
                props.users.map((value, index) => (
                    <Grid key={index} item xs={12} sm={6} md={3}>
                        <Box boxShadow={3} className="p-3 m-2">
                            <div className="row">
                                <div className="col-12">
                                    <Tooltip placement="top" id="placement" title={value.developer.name}><h4 className="float-left user-max-width heading-wrap text-overflow-ellipsis">{value.developer.name}</h4></Tooltip>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-5">
                                            <h6 className="text-overflow-ellipsis">Lines of Code</h6>
                                            <h6><Tooltip placement="top" id="placement" title={value.numOfLinesAdded}><span className="secondary">{value.numOfLinesAdded.toString().length >= 4 ? Math.trunc(value.numOfLinesAdded / 1000) + 'K' : value.numOfLinesAdded}</span></Tooltip></h6>
                                        </div>
                                        <div className="col-7 text-right">
                                            <h6 className="text-overflow-ellipsis">Languages Worked</h6>
                                            {value.languages && <h6 className="secondary cursor-pointer text-overflow-ellipsis" onClick={(e) => clickMoreLanguages(e, index)}>{value.languages.map(value => value.language).join(', ')}</h6>}
                                            {showLanguages[index] && <Popover
                                                open={Boolean(showLanguages[index])}
                                                anchorEl={showLanguages[index]}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}>
                                                <div className="p-2 text-center">
                                                    <div className="text-center">
                                                        <List>
                                                            {value.languages.map((value, index) => (
                                                                <ListItem key={index}>
                                                                    <ListItemIcon style={{ 'minWidth': '30px' }}>
                                                                        <img alt={value.language} width="20" src={"/images/language/" + value.language + ".png"} />
                                                                    </ListItemIcon>
                                                                    {value.language + ' (' + value.percentage + '%)'}
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </div>
                                                </div>
                                            </Popover>}
                                        </div>

                                        <div className="col-md-12">

                                            <p className="p-0 m-0 text-bold">Code Quality ({value.qualityPercentage.toFixed(2)}%)</p>
                                            <Slider
                                                value={+value.qualityPercentage.toFixed(2)}
                                                getAriaValueText={(value) => value + "%"}
                                                aria-labelledby="discrete-slider-always"
                                                valueLabelDisplay="auto"
                                                className={value.qualityPercentage > 50
                                                    ? value.qualityPercentage > 75
                                                        ? "heigh-quality"
                                                        : "medium-quality"
                                                    : "low-quality"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row text-bold">
                                        <div className="col-6 align-middle">
                                            <LibraryBooksOutlinedIcon className="align-middle" />
                                            <span className="align-middle"> {value.numOfRepositories} {value.numOfRepositories === 1 ? 'Repo' : 'Repos'}</span>
                                        </div>
                                        <div className="col-6 align-middle text-right">
                                            <RateReviewOutlinedIcon className="align-middle" />
                                            <span className="align-middle"> {value.numOfReviews} {value.numOfReviews === 1 ? 'Review' : 'Reviews'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Grid>))
                : <div className="text-center chart-align-top w-100">
                    <div className="col-md-12">
                        <img src="/images/statistic.jpg" alt="No record Found" width="90px" />
                        <h3>No Record found</h3>
                    </div>
                </div>
            }
        </Grid>
    )
}

export default GridView;