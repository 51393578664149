import React, { Component } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './users.css'
import Users from './user';
import Projects from './project';
import MyStatistics from './statistics/index';
import Auth from '../../core/service/auth';
import {resetFilterService} from '../../core/login/services/reset-filter';

const user = JSON.parse(localStorage.getItem('users'));

class Index extends Component {
    userId;
    constructor(props) {
        super(props);
        resetFilterService.resetFilters();
        this.state = {
            activeKey: "home",
            developerFilter: 'PULL REQUEST'
        }
        this.handleChange = this.handleChange.bind(this);
        this.updateDeveloperFilter = this.updateDeveloperFilter.bind(this);
    }

    handleChange = (key) => {
        this.setState({
            activeKey: key
        })
    };

    updateDeveloperFilter = (currentFilter) => {
        this.setState({
            developerFilter: currentFilter
        })
    }

    render() {
        return (
            <div className="pl-3 pr-3 users-page">
                <Tabs id="controlled-tab-example" activeKey={this.state.activeKey} onSelect={k => (this.handleChange(k))}>
                    <Tab eventKey="home" title="My Statistics">
                        <MyStatistics />
                    </Tab>
                    {Auth.hasRole(user, ['ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN']) &&
                        <Tab eventKey="projects" title="Projects">
                            <Projects developerFilter={this.state.developerFilter} updateFilter={this.updateDeveloperFilter} />
                        </Tab>
                    }
                    {Auth.hasRole(user, ['ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN']) &&
                        <Tab eventKey="profile" title="Developers">
                            <Users updateFilter={this.updateDeveloperFilter} />
                        </Tab>
                    }
                </Tabs>
            </div>
        );
    }
}

export default Index;