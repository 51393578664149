import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import MaterialTable from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import './../admin.css';
import Index from './users-action/index';
import Error500 from '../../../core/error/error';
import Auth from './../../../core/service/auth';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AssignUser from './users-action/AssigneUser';
import UploadFile from './users-action/UploadFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { authHeader } from '../../../core/login/services/auth-header';
import GetAppIcon from '@material-ui/icons/GetApp';
import RestoreIcon from '@material-ui/icons/Restore';

import preferenceService from '../../../core/service/preference';
import UserServices from './services/userManagement-service';
import RestoreUser from './users-action/RestoreUser'
var user = JSON.parse(localStorage.getItem('users'));
const GET_USER_LIST = gql`
query RegisteredUser {
    users {
            id
            name
            username
            email
            roles {
                id
                name
            }
            organizations {
                organizationId,
                OrganizationName,
                orgUserMapId,
                roles {
                    id
                    name
                }
            }
      }
}
`;

const user1 = preferenceService.getValue('users', 'local');
class UserManagement extends Component {
    userId;
    constructor(props) {
        super(props)
        this.tableRef = React.createRef();
        this.UserServices = new UserServices();
        this.state = {
            showEditModal: false,
            completed: false,
            showUploadFile: false,
            showRestoreUser: false,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                isDesktop: window.innerWidth > 600,
            },
            userData: [],
            columnDefs: [{
                title: "Name", field: "name", filtering: true, filterPlaceholder: 'search'
            }, {
                title: "User Name", field: "username", filtering: true, filterPlaceholder: 'search'
            }, {
                title: "Email", field: "email", filtering: true, filterPlaceholder: 'search'
            }, {
                title: "Organizations", field: "organizationName", hidden: !Auth.hasRole(user, ['ROLE_SUPER_ADMIN']), filtering: true, filterPlaceholder: 'search'
            }, {
                title: user1.repoColumnHeader, field: "orgUserMapId", hidden: !user1.userOrgMapFlag, filtering: true, filterPlaceholder: 'search'
            },
            ]
        }
        this.gridApi = null;
        this.onGridReady = this.onGridReady.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.getData = this.getData.bind(this);
        this.handleEditDialog = this.handleEditDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleAssignModalClose = this.handleAssignModalClose.bind(this);
        this.handleUploadFileClose = this.handleUploadFileClose.bind(this);
        this.handleRestoreUserClose = this.handleRestoreUserClose.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleOpeningModal = this.handleOpeningModal.bind(this);
    }

    onGridReady(params) {
        if ((window.innerWidth > 600)) {
            params.api.sizeColumnsToFit();
        }

        this.gridApi = params.api;
    }

    onFilterTextBoxChanged(event) {
        this.setState({ filter: event.target.value })
        this.gridApi.setQuickFilter(event.target.value);
    }

    getData(query) {
        const arrayToObject = (filter) =>
            Object.assign({}, ...filter.map(item => ({ [item.column.field]: item.column.tableData.filterValue })))
        const filter = arrayToObject(query.filters);
        return this.UserServices.getUserList(query, filter);
    }

    handleEditDialog(grid) {
        if (grid) {
            this.setState({
                userData: grid,
                showEditModal: true
            })
        }
        if (!grid) {
            this.setState({ showEditModal: false });
        }
    }

    handleClose() {
        this.setState({ showEditModal: false });
    }

    handleOpeningModal() {
        this.setState({ completed: false })
    }

    handleAssignModalClose() {
        this.setState({ showAssignModal: false });
    }

    handleUploadFileClose() {
        this.setState({ showUploadFile: !this.state.showUploadFile });
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleRestoreUserClose() {
        this.setState({ showRestoreUser: !this.state.showRestoreUser });
    }

    handleDownload(organizationId) {
        fetch(`/code-review-service/api/user/userOrgMappingDownload?organizationId=${organizationId}`, {
            headers: authHeader()
        }).then(async res => {
            if (res.ok) {
                let data = await res.blob();
                var a = document.createElement("a");
                a.href = URL.createObjectURL(data);
                a.download = "UserOrgMappingDetails.xls";
                a.click();
                return "success";
            }
        });
    }

    render() {

        return (
            <div className="project-list post-list">
                <div >
                    <div
                        className="ag-theme-material"
                        style={{
                            width: '100%', paddingBottom: '30px',
                        }}
                    >
                        <div className="row">
                            <div className="project-list-container">
                                {user1.userOrgMapFlag &&
                                    <Tooltip title="User Organization Mapping Upload" placement="top">
                                        <CloudUploadIcon showModal={this.state.showUploadFile}
                                            onClose={this.handleUploadFileClose} onClick={this.handleUploadFileClose} className="align-middle mr-3" color="primary" />
                                    </Tooltip>
                                }
                                {user1.userOrgMapFlag &&
                                    <Tooltip title="User Organization Mapping Details" placement="top">
                                        <GetAppIcon onClick={() => this.handleDownload(user.currentOrganizationId)} className="align-middle mr-3" color="primary" />
                                    </Tooltip>}
                                {!Auth.hasRole(user, ['ROLE_SUPER_ADMIN']) &&
                                    < Tooltip title="Assign Users" placement="top">
                                        <AddCircleIcon onClick={() => {
                                            this.setState({
                                                showAssignModal: true
                                            })
                                        }} className="align-middle mr-3" color="primary" />
                                    </Tooltip>
                                }
                                <Tooltip title="Refresh" placement="top">
                                    <RefreshIcon onClick={() => this.tableRef.current && this.tableRef.current.onQueryChange()} className="align-middle" color="primary" />
                                </Tooltip>
                                {Auth.hasRole(user, ['ROLE_SUPER_ADMIN']) &&
                                    <Tooltip title="Restore User" placement="top">
                                        <RestoreIcon showModal={this.state.showRestoreUser}
                                            onClose={this.handleRestoreUserClose} onClick={this.handleRestoreUserClose} className="align-middle mr-3" color="primary" />
                                    </Tooltip>
                                }
                            </div>
                        </div>
                        <MaterialTable
                            title="User Management"
                            tableRef={this.tableRef}
                            columns={this.state.columnDefs}
                            data={query => this.getData(query)}
                            options={{
                                filtering: true,
                                toolbar: false,
                                headerStyle: {
                                    padding: '5px',
                                    fontSize: '12px',
                                    fontWeight: "bold",
                                    color: 'black',
                                    border: 'none',
                                },
                                pageSize: 7,
                                actionsColumnIndex: -1,
                                pageSizeOptions: [7, 10, 20, 30],
                            }}
                            actions={[
                                {
                                    icon: 'more_horiz',
                                    tooltip: 'Edit Project Group',
                                    onClick: (event, rowData) => { this.handleEditDialog(rowData) },
                                }
                            ]}
                            components={{
                                Action: props => {
                                    return (
                                        <button className={'btn btn-link p-0 ' + (props.action.icon !== 'edit' ? "" : "text-danger")}
                                            onClick={(event) => props.action.onClick(event, props.data)}>
                                            <Tooltip title={props.action.tooltip}>
                                                <i className="material-icons align-middle pr-4">{props.action.icon}</i>
                                            </Tooltip>
                                        </button>
                                    )
                                },
                            }}
                        />
                        <Index userData={this.state.userData} showModal={this.state.showEditModal} onClose={this.handleClose} refresh={() => this.tableRef.current && this.tableRef.current.onQueryChange()} history={this.props.history}></Index>
                        <UploadFile completed={this.state.completed} userData={this.state.userData} showModal={this.state.showUploadFile} onClose={this.handleUploadFileClose} history={this.props.history} ></UploadFile>
                        <RestoreUser showModal={this.state.showRestoreUser} onClose={this.handleRestoreUserClose} ></RestoreUser>
                        <AssignUser userData={this.state.userData} showModal={this.state.showAssignModal} onClose={this.handleAssignModalClose} history={this.props.history} />
                    </div>
                </div>
            </div>
        );
    }
}

export default UserManagement;