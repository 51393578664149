import React from 'react';
import Menus from './components/Menus';
import WikiPage from './components/Wiki';
import './wiki.css';

function Wiki() {
    const [content, setContent] = React.useState(false);
    const [isRelease, setIsRelease] = React.useState(false);
    const changeContent = (data, isRelease) => {
        setIsRelease(!!isRelease);
        setContent(data);
    }
    return (
        <div className="wiki row m-0">
            <div className="col-md-3 p-0">
                <div className="container-fluid">
                    <Menus changeContent={changeContent}/>
                </div>
            </div>
            <div className="col-md-9 p-0">
                <div className="container-fluid mt-3">
                    <WikiPage content={content} isRelease={isRelease} />
                </div>
            </div>
        </div>
    )
}

export default Wiki;