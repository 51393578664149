import React from 'react';
import './language-bar.css';

const color = [
    '#eb4d4b',
    '#22a6b3',
    '#6ab04c',
    '#e056fd',
    '#89CF4E',
    '#DA8542',
    '#7E504E',
    '#CFBE11',
    '#265693',
    '#5C853D',
];

const roundTo = require('round-to');
class LanguageBar extends React.Component {

    render() {
        const parent = this.props;
        var bars = parent.readings && parent.readings.length && parent.readings.map(function (item, i) {
            if (item.percentage > 0) {
                return (
                    <div className="bar" style={{ 'backgroundColor': color[i], 'width': item.fullPercentage || item.percentage - 0.01 + '%' }} key={i}>

                    </div>
                )
            } else {
                return null;
            }
        }, this);

        var legends = parent.readings && parent.readings.length && parent.readings.map(function (item, i) {
            if (item.percentage > 0) {
                return (
                    <div className="legend" key={i}>
                        <span className="dot" style={{ 'backgroundColor': color[i] }}></span>
                        <span className="label">{item.language} {roundTo.up(item.percentage, 2)}%</span>
                    </div>
                )
            } else {
                return null;
            }
        }, this);

        return (
            <div className="multicolor-bar">
                <div className="bars">
                    {bars === '' ? '' : bars || ''}
                </div>
                <div className="legends">
                    {legends === '' ? '' : legends || ''}
                </div>
            </div>
        );
    }
}
export default LanguageBar;