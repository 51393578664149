import React, { Component } from 'react';
import './../common.css';

class Error500 extends Component {
    render() {
        return (
            <section className="page_500">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                    <div className="col-sm-10 text-center">
                    <div className="four_zero_four_bg">
                        <h1 className="text-center ">500</h1>
                    </div>
                    
                    <div className="contant_box_404">
                    <h3 className="h2">
                    Internal Server Error
                    </h3>
                    
                    <p>the page you are looking for not available!</p>
                    
                    <a href="/home" className="link_404">Retry</a>
                </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Error500;