import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LockIcon from '@material-ui/icons/Lock';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './index.css'
import ChangePassword from './change-password';
import DeleteUser from './delete-user';
import ChangeRole from './change-role';
import UpdateOrganizationID from './update-organization-id';
import preferenceService from '../../../../core/service/preference';
import Auth from '../../../../core/service/auth';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import UpdateUserMailId from './updateUserMailId';


const user = preferenceService.getValue('users', 'local');
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
      component="div"
      className="w-100"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class Index extends Component {
    userId;
    constructor(props) {
        super(props)
        this.state = {
            showEditModal: false,
            showDeleteModal: false,
            showEditLanguageModal: false,
            showModal: false,
            deleteModal: false,
            activeKey: 0,
            open: false,
            classes: makeStyles(theme => ({
                root: {
                flexGrow: 1,
                backgroundColor: theme.palette.background.paper,
                display: 'flex',
                height: 500,
                },
                tabs: {
                borderRight: `1px solid ${theme.palette.divider}`,
                },
                }))
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDeleteDialog = this.handleDeleteDialog.bind(this);
        this.handleDeleteClose = this.handleDeleteClose.bind(this);
    }

    handleChange = (event, newValue) => {
        this.setState({
            activeKey: newValue
        })
    };

    handleDialog() {
        this.props.onClose();
        this.props.refresh();
    }

    handleDeleteClose() {
        this.setState({ showDeleteModal: false });
        this.props.onClose();
        this.props.refresh();
    }

    handleCancel() {
        this.setState({ showDeleteModal: false });
    }

    handleDeleteDialog() {
        this.setState({ showDeleteModal: true, userData: this.props.userData });
    }

    render() {
        return (
            <div className="p-2">
                <Dialog open={this.props.showModal} fullWidth={true} maxWidth={"md"} onClose={this.handleDialog} aria-labelledby="form-dialog-title">
                         <DialogTitle id="form-dialog-title"><b>{this.props.userData.name} ( {this.props.userData.email} )</b></DialogTitle>
                    <DialogContent dividers>
                        <span className="root">
                            <Tabs
                                value={this.state.activeKey}
                                onChange={this.handleChange}
                                orientation="vertical"
                                variant="scrollable"
                                scrollButtons="on"
                                indicatorColor="primary"
                                textColor="primary"
                                className="tabs"
                                aria-label="scrollable force tabs example"
                            >
                                <Tab label="Change Password" icon={<LockIcon />} />
                                <Tab label="Change Role" icon={<PersonPinIcon />} />
                                <Tab label="Change Mail Id" icon={<ContactMailIcon/>} />
                                {user.userOrgMapFlag && <Tab label="Change Organization ID" icon={<MailOutlineIcon />} />}
                            </Tabs>
                        <TabPanel value={this.state.activeKey} index={0}>
                            <ChangePassword userData={this.props.userData}/>
                        </TabPanel>
                        <TabPanel value={this.state.activeKey} index={1}>
                            <ChangeRole onClose={this.handleDialog} userData={this.props.userData} refresh={this.props.refresh}/>
                        </TabPanel>
                        <TabPanel value={this.state.activeKey} index={2}>
                            <UpdateUserMailId onClose={this.handleDialog} userData={this.props.userData} />
                        </TabPanel>
                        {user.userOrgMapFlag && <TabPanel value={this.state.activeKey} index={3}>
                            <UpdateOrganizationID onClose={this.handleDialog} userData={this.props.userData} />
                        </TabPanel> }
                        
                        </span>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.handleDeleteDialog} color="secondary">
                            {
                                Auth.hasRole(user, ['ROLE_SUPER_ADMIN'])
                                    ? "Delete"
                                    : "Remove User"
                            }
                        </Button>
                        <Button onClick={this.handleDialog} color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <DeleteUser userData={this.props.userData}
                            isSUAdmin={Auth.hasRole(user, ['ROLE_SUPER_ADMIN'])}
                            orgId={user.currentOrganizationId}
                            onClick={this.handleDialog} handleCancel={this.handleCancel}
                            deleteModal={this.state.showDeleteModal}
                            onClose={this.handleDeleteClose}
                            history={this.props.history} />
            </div>
        );
    }
}

export default Index;