import React, { Component } from 'react';
import { DialogActions, DialogContent, DialogTitle, Dialog, Button, Select, MenuItem, Checkbox, ListItemText, Input, Toolbar } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Mutation } from 'react-apollo';
import Snackbars from '../../core/error/Snackbars';
import gql from 'graphql-tag';


const DELETE_PROJECT = gql`
    mutation deleteProject($id: Long!) {
        deleteProject(id: $id)
    }
`;

class DeleteProject extends Component {
    constructor(props) {
        super(props)
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleCancel() {
        this.props.onClose();
    }

    handleDelete() {
        this.props.onClose();
        this.props.refresh();
    }


    render() {
        return (
            <div>
                <Dialog open={this.props.showDeleteModal} fullWidth={true} maxWidth={"xs"} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Delete Project</DialogTitle>
                    <div className="text-center">
                        <DialogActions>
                            <DialogContent dividers>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete the project {this.props.repoName}?
                                </DialogContentText>
                            </DialogContent>
                        </DialogActions>
                    </div>
                    <DialogActions>
                        <Button onClick={this.handleCancel} color="primary">
                            Cancel
                            </Button>
                        <Mutation mutation={DELETE_PROJECT} >
                            {(deleteProject, { loading, error, data }) => {
                                if (data && data.deleteProject) {
                                    this.handleDelete();
                                }
                                return (
                                    <Button onClick={() => {
                                        deleteProject({ variables: { id: this.props.projectId } })
                                    }} color="secondary" >
                                        {
                                            (data && !data.deleteProjectGroup) &&
                                            <Snackbars status="error" message={"Already some projects was configured"} showSnackbar={true} closeSnackBar={false} />
                                        }
                                                            Delete
                                    </Button>
                                );
                            }}
                        </Mutation>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default DeleteProject;