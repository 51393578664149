import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbars from '../../core/error/Snackbars';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from '@material-ui/core';
import projectServices from './services/project-service';

import './project.css';
import preferenceService from '../../core/service/preference';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const ADD_PROJECT_GROUP = gql`
    mutation updateProjectGroup(
        $id: Long,
        $projectGroupName: String!,
        $organizationName: String!,
        $repoAccessToken: String,
        $description: String,
        $ownerName: String,
        $ownerEmailAddress: String
        $reviewers: [UserInput]) {
        updateProjectGroup(
            id: $id,
            projectGroupName: $projectGroupName,
            organizationName: $organizationName,
            repoAccessToken: $repoAccessToken,
            description: $description,
            ownerName: $ownerName,
            ownerEmailAddress: $ownerEmailAddress,
            reviewers: $reviewers) {
            id
        }
    }
`;

const GET_USER_LIST = gql`
query RegisteredUser {
    users {
            id
            name
            username
            email
            roles {
                id
                name
            }
      }
}
`;
class ProjectAddGroup extends Component {
    constructor(props, context) {
        super(props, context);
        this.projectService = new projectServices();
        this.state = {
            projectGroupName: '',
            organizationName: '',
            repoAccessToken: '',
            description: '',
            ownerName: '',
            ownerEmailAddress: '',
            reviewers: [],
            id: null,
            disabled: false,
            submitted: false,
        };

        this.handleDialog = this.handleDialog.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAddDialogClose = this.handleAddDialogClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReviewersList = this.handleReviewersList.bind(this);
    }

    handleDialog() {
        this.props.onUpdate();
        this.props.refresh();
    }

    handleAddDialogClose() {
        this.setState({
            projectGroupName: '',
            organizationName: '',
            repoAccessToken: '',
            description: '',
            ownerName: '',
            ownerEmailAddress: '',
            reviewers: []
        });
        this.props.onUpdate();
        this.props.refresh();
    }

    componentDidMount() {
        // custom rule will have name 'isKeyLength'
        ValidatorForm.addValidationRule('isKeyLength', (value) => {
            if (value.length !== 4) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isNameLength', (value) => {
            if (value.length < 4 || value.length > 100) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isOrganizationLength', (value) => {
            if (value.length < 3 || value.length > 100) {
                return false;
            }
            return true;
        });
        const s = preferenceService.getValue('users', 'local');
        this.setState({
            organizationName: s.organizations.filter(e => (e.organizationId === s.currentOrganizationId)).map(e => e.organizationName).toString()
        })
    }

    handleReviewersList(event, item) {
        this.setState({
            reviewers: item
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'select' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit() {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });
    }

    submit = () => {
        this.form.submit();
    }

    validatorListener = (result) => {
        this.setState({ disabled: !result });
    }

    render() {
        return (
            <Mutation mutation={ADD_PROJECT_GROUP} onCompleted={() => this.handleDialog()}>
                {(createProjectGroup, { loading, error }) => (
                    <ValidatorForm id="projectAddGroup"
                        ref={(r) => { this.form = r; }}
                        onSubmit={e => {
                            if (!this.state.id) {
                                delete this.state.id;
                            }
                            this.setState({ submitted: true }, () => {
                                setTimeout(() => this.setState({ submitted: false }), 1000);
                            });
                            createProjectGroup({ variables: { ...this.state } });
                        }}
                        instantValidate
                    >
                        <Dialog maxWidth={"md"} fullWidth={true}
                            fullScreen={window.innerWidth < 600}
                            open={this.props.showModal}>
                            <DialogTitle id="customized-dialog-title" onClose={!this.state.id ? this.handleAddDialogClose : this.handleDialog}>{!this.state.id ? 'Add Project Group' : 'Edit Project Group'}</DialogTitle >
                            {loading ? <LinearProgress color="primary" /> : <Divider />}
                            <DialogContent className="project-group-edit-container">
                                {loading && <div className="loading"><CircularProgress size={50} /></div>}
                                {error &&
                                    <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={false} />
                                }
                                <div className="row">
                                    <div className="col-md-6 mt-3 mb-3">
                                        <TextValidator type="text"
                                            id="projectGroupName"
                                            name="projectGroupName"
                                            label="Project Group Name"
                                            value={this.state.projectGroupName}
                                            onChange={this.handleInputChange}
                                            validators={['required', 'isOrganizationLength']}
                                            errorMessages={['This field is Required', 'should be minimum 4 characters and maximum 100 characters']}
                                            validatorListener={this.validatorListener} />
                                    </div>
                                    <div className="col-md-6 mt-3 mb-3">
                                        <TextValidator type="text"
                                            name="organizationName"
                                            id="organizationName"
                                            label="Organization Name"
                                            disabled
                                            value={this.state.organizationName}
                                            onChange={this.handleInputChange}
                                            validators={['required', 'isOrganizationLength']}
                                            errorMessages={['This field is Required', 'should be minimum 3 characters and maximum 100 characters']}
                                            validatorListener={this.validatorListener} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <TextField
                                            name="description"
                                            id="description"
                                            label="Description"
                                            multiline={true}
                                            rows={1}
                                            onChange={this.handleInputChange}
                                            value={this.state.description}
                                            rowsMax={4}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Tooltip title="Need help on how to generate user token?" placement="top" style={{ position: 'absolute', right: 14, top: 21, width: 20, height: 20, zIndex: 1 }}>
                                            <Link href="https://confluence.atlassian.com/bitbucketserver/personal-access-tokens-939515499.html" target="_blank"><InfoIcon color="primary" /></Link>
                                        </Tooltip>
                                        <TextValidator type="text"
                                            name="repoAccessToken"
                                            pattern="[a-zA-Z0-9]+"
                                            label="Personal Access Token"
                                            value={this.state.repoAccessToken}
                                            helperText="The token provided here will be applied to all projects associated with this project group at the time of project creation"
                                            onChange={event => { this.handleInputChange(event) }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <TextValidator type="text"
                                            name="ownerName"
                                            placeholder="Enter project manager name"
                                            label="Manager Name"
                                            pattern="[a-zA-Z0-9_-\s]+"
                                            value={this.state.ownerName}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <TextValidator type="email"
                                            name="ownerEmailAddress"
                                            label="Manager Email Address"
                                            placeholder="Enter project manager email address"
                                            validators={['isEmail']}
                                            errorMessages={['Email is not Valid']}
                                            value={this.state.ownerEmailAddress}
                                            onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                <Query query={GET_USER_LIST}>
                                    {({ loading, error, data, refetch }) => {
                                        if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                                        if (error) return <div className="text-center">
                                            <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={false} />
                                        </div>
                                        return (
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <Autocomplete
                                                        multiple
                                                        options={data.users.map(item => {
                                                            delete item.roles;
                                                            delete item.__typename;
                                                            return item;
                                                        })}
                                                        value={this.state.reviewers}
                                                        getOptionLabel={option => option.name}
                                                        id="disable-open-on-focus"
                                                        name="reviewers"
                                                        disableOpenOnFocus
                                                        renderInput={params => <TextField {...params} name="reviewers" label="Reviewers" margin="normal" />
                                                        }
                                                        onChange={this.handleReviewersList}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }}
                                </Query>
                            </DialogContent>
                            <Divider />
                            <DialogActions>
                                <Button onClick={this.handleDialog} color="secondary">
                                    Close
                                </Button>
                                <Button color="primary" type="submit" variant="contained" disabled={loading} onClick={this.submit}>
                                    {loading ? <CircularProgress disableShrink size={24} /> : 'Submit'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </ValidatorForm>
                )}
            </Mutation>
        )

    }
}

export default ProjectAddGroup
