import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import release from './../data/release';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import GavelIcon from '@material-ui/icons/Gavel';
import ForumIcon from '@material-ui/icons/Forum';
import MenuBookIcon from '@material-ui/icons/MenuBook';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function SelectedListItem(props) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(0);


    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    return (
        <div className={classes.root}>
            <div className="wiki-menus mt-3">
                <h4>Wiki Menu</h4>
            </div>
            <List component="nav" aria-label="Menus">
                <ListItem
                    button
                    selected={selectedIndex === 1}
                    onClick={(event) => {
                        props.changeContent(event, true);
                        handleListItemClick(event, 1);
                    }}
                >
                    <ListItemIcon>
                        <MenuBookIcon />
                    </ListItemIcon>
                    <ListItemText primary="User Manual" />
                </ListItem>
                <ListItem
                    button
                    selected={selectedIndex === 2}
                    onClick={(event) => {
                        props.changeContent(event, false);
                        handleListItemClick(event, 2);
                    }}
                >
                    <ListItemIcon>
                    <i className="material-icons" style={{ cursor: "pointer" }}>assessment</i>
                    </ListItemIcon>
                    <ListItemText primary="QualityBot Configuration" />
                </ListItem>
            </List>
            <List component="nav" aria-label="Menus">
                <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={(event) => {
                        props.changeContent(false, true);
                        handleListItemClick(event, 0);
                    }}
                >
                    <ListItemIcon>
                        <GavelIcon />
                    </ListItemIcon>
                    <ListItemText primary="Rule Configuration in IDE" />
                </ListItem>

                <ListItem
                    button
                    onClick={(event) => {
                        window.open(
                            'https://docs.google.com/forms/d/e/1FAIpQLSede3Q-XyUXabh3frlSOlw4lS_0PmxVSJflTFThw6V03uzkXg/viewform',
                            '_blank'
                        );
                    }}
                >
                    <ListItemIcon>
                        <ForumIcon />
                    </ListItemIcon>
                    <ListItemText primary="Rule Change Request" />
                </ListItem>
            </List>
            <Divider />
            <List component="nav" aria-label="secondary mailbox folder">
                {release.content.map((data, i) => {
                    return (
                        <ListItem
                            button
                            selected={selectedIndex === i + 3}
                            onClick={(event) => {
                                props.changeContent(data, true);
                                handleListItemClick(event, i + 3);
                            }}
                        >
                            <ListItemIcon>
                                <NewReleasesIcon />
                            </ListItemIcon>
                            <ListItemText primary={`Release ${i + 1}`} />
                        </ListItem>)
                })}
            </List>
        </div >
    );
}