import React, { Component } from 'react';

class DefaultValue extends Component {
    userId;
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        if (this.props.data && !this.props.data.length) {
            return <div>{!this.props.isMoreInfo
                ? <h4 className="pt-2">Default Configuration</h4>
                : <h6 className="mt-4">Default Configuration</h6>
            }
                <p>No Configuration to Show</p>
            </div>;
        }
        return (
            <div className="w-100">
                {!this.props.isMoreInfo
                    ? <h4 className="pt-2">Default Configuration</h4>
                    : <h6 className="mt-4">Default Configuration</h6>
                }
                {
                    this.props.data && this.props.data.map((data, i) => {

                        if (data.items && data.items.enumValue && data.type === 'array') {
                            return (<div key={i}>
                                {data.items && data.items.enumValue && !!data.items.enumValue.length && <p>This rule has the following array options</p>}
                                <div>
                                    <ul>
                                        {data.items && data.items.enumValue && data.items.enumValue.map(data => {
                                            return <li>{data}</li>;
                                        })}
                                    </ul>
                                </div>
                            </div>);
                        } else if (data.enumValue && (data.type === 'string' || data.type === 'number' || !!data.enumValue.length)) {
                            return (<div key={i}>
                                {data.enumValue && !!data.enumValue.length && <p>This rule has the following String options</p>}
                                <div>
                                    <ul>
                                        {data.enumValue && data.enumValue.map((data, index) => {
                                            return <li key={index}>{data}</li>;
                                        })}
                                    </ul>
                                </div>
                            </div>);
                        } else if (data.type === 'object' && Object.keys(data.properties).length) {
                            var a = [];
                            for (var key in data.properties) {
                                if (key !== 'version') {
                                    a.push({
                                        "Name": key,
                                        "Type": data.properties[key].type,
                                        "DefaultValue": data.properties[key].default,
                                        "MultiValued": data.properties[key].multiValued
                                    })
                                }
                            }

                            return (
                                <div className="w-100" key={i}>
                                    {!!a.length && <p>This rule has the following object options.</p>}
                                    <table className="table w-100">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Default value</th>
                                                <th>Multi Value?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {a.map((data, i) => {
                                                return (<tr key={i}>
                                                    <td>{data.Name}</td>
                                                    <td>{data.Type}</td>
                                                    <td>{data.DefaultValue}</td>
                                                    <td>{data.MultiValued}</td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            );
                        }
                        return <p>No Configuration to Show</p>;
                    })
                }
            </div>
        );
    }
}

export default DefaultValue;