import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import UserManagement from './userManagement/';
import CronJobManagement from './cronJobsManagement/';
import RuleManagement from './RuleManagement';
import LanguageManagement from './languageManagement/';
import OrgManagement from './orgManagement/';
import Auth from '../../core/service/auth';
import preferenceService from '../../core/service/preference';
import RepoRuleManagement from './repoRuleManagement';
import { resetFilterService } from '../../core/login/services/reset-filter';


let user = preferenceService.getValue('users', 'local');

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            className="w-100"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={1}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const data = [
    {
        component: OrgManagement,
        role: ['ROLE_SUPER_ADMIN'],
        text: 'Organization Management'
    },
    {
        component: UserManagement,
        role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        text: 'User Management'
    },
    {
        component: LanguageManagement,
        role: ['ROLE_SUPER_ADMIN'],
        text: 'Language Management'
    },
    {
        component: RuleManagement,
        role: ['ROLE_SUPER_ADMIN'],
        text: 'Global Rule Management'
    },
    {
        component: CronJobManagement,
        role: ['ROLE_SUPER_ADMIN'],
        text: 'Scheduler Management'
    },
    {
        component: RepoRuleManagement,
        role: ['ROLE_SUPER_ADMIN'],
        text: 'Copy Rule Management'
    }
]

class ManageUsersRoles extends Component {
    constructor(props) {
        super(props);
        resetFilterService.resetFilters();
        this.state = {
            showEditModal: false,
            showModal: false,
            showEditLanguageModal: false,
            activeKey: 0,
            open: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
    }

    handleChange = (event, newValue) => {
        this.setState({
            activeKey: newValue
        })
    };

    handleDialog() {
        this.props.onClose();
        this.props.refresh();
    }

    render() {
        let i = 0;
        return (
            <div className="pl-3 pr-3">
                <Tabs
                    value={this.state.activeKey}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="auto"

                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    {
                        data.map((data) => {
                            if (Auth.hasRole(user, data.role)) {
                                return <Tab className="admin-managment" label={data.text} />
                            }
                        })
                    }
                </Tabs>
                {

                    data.map((data) => {

                        if (Auth.hasRole(user, data.role)) {
                            let index = i;
                            i++;
                            return <TabPanel className="admin-tabs p-0" value={this.state.activeKey} index={index}>
                                {(this.state.activeKey === index) && <data.component />}
                            </TabPanel>
                        }
                    })
                }
            </div>
        );
    }
}

export default ManageUsersRoles;