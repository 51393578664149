import React, { Component } from 'react';
import '../modals/modal.css';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';

class StatusDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
		this.handleClose = this.handleClose.bind(this);
	}

	handleClose = () => {
		this.props.handleClose();
	}
	render() {
		return (
			<Dialog
				open={this.props.editModal}
				aria-labelledby="simple-dialog-title">
				<DialogTitle id="customized-dialog-title">
					<b>Status Details :</b>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
					<List component="nav" aria-label="main mailbox folders">
						<li><i className="material-icons align-middle text-danger">create</i><b>New:</b>&ensp;When the review URL is added for the review and code changes not yet started analyzing.</li>
						<li><i className="material-icons align-middle text-danger">create</i><b>In Progress:</b>&ensp;After adding the review URL when analyzer starts analyzing the code changes.</li>
						<li><i className="material-icons align-middle text-danger">create</i><b>Address Review Comments:</b>&ensp;when the review is completed with the comments provided by the analyzer.</li>
						<li><i className="material-icons align-middle text-success">done_all</i><b>Completed:</b>&ensp;User review has been completed with zero review comments / All comments resolved.</li>
						<li><i className="material-icons align-middle text-primary">done_all</i><b>Manual Review Required:</b>&ensp;While reviewing the changes when analyzer detects an unsupported language/excluded files/can't able to analyze.</li>
						<li><i className="material-icons align-middle text-primary">done_all</i><b>Manual Comments Added:</b>&ensp;When a reviewer added comments for resolving the code based on improving code quality.</li>
						<li><i className="material-icons align-middle text-primary">done_all</i><b>Manual Comments Addressed:</b>&ensp;When the user resolved and fixed based on the manual comments provided by the reviewer.</li>
						<li><i className="material-icons align-middle text-success">done_all</i><b>Manual Review Completed:</b>&ensp;When the reviewer approved all the manual comments addressed by the user.</li>
					</List>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color="secondary" >
						close
                    </Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default StatusDetails;