export const avatarImage = {
    getImageLocation
};

function getImageLocation(data) {
    var id = 0;
    if (data) {
        id =data;
    } else {
        id = JSON.parse(localStorage.getItem('users')) && JSON.parse(localStorage.getItem('users')).id;
    }
    var root = "/images/avatar/";
    return root + Math.floor(id%10) + '.svg';
}