import ClinetServices from '../../../core/service/clientUri';
import gql from 'graphql-tag';

var ruleId = 0
var languageName = ''
var projectGroupName = ''
var repoAccessToken = ''

const GET_PROJECT_LANGUAGENAME = gql`
query projectsByLanguageName($languageName: String!) {
    projectsByLanguageName (languageName: $languageName) {
            id
            projectName
            projectKey
            repoGroupName
            repoName
            repoUserToken
            createdOn
            repoLanguages
            organizationName
        }
    
}
`;

const GET_PROJECT_GROUPNAME = gql`
query projectsByProjectGroupNameAndStatusCheck($projectGroupName: String, $repoAccessToken: String) {
    projectsByProjectGroupNameAndStatusCheck (projectGroupName: $projectGroupName, repoAccessToken: $repoAccessToken) {
            id
            projectName
            projectKey
            repoGroupName
            repoName
            repoUserToken
            isRepoAccessValid
            versionControl
            createdOn
            repoLanguages
            organizationName
        }
    
}
`;

const PROJECTS_BY_RULE_ID = gql`
query projectsByRuleId($ruleId: Long!) {
    projectsByRuleId (ruleId: $ruleId) {
            id
            projectName
            projectKey
            repoGroupName
            repoName
            repoUserToken
            createdOn
            repoLanguages
        }
    
}
`;

class projectService {
    constructor(props) {
        this.getFromClintList = this.getFromClintList.bind(this);
        this.getFromClintLanguageNameList = this.getFromClintLanguageNameList.bind(this);
        this.getProjectList = this.getProjectList.bind(this);
    }

    async getFromClintList(client) {
        var response = await client.query({
            query: PROJECTS_BY_RULE_ID,
            variables: { ruleId: ruleId }
        });
        return await response.data.projectsByRuleId;
    }

    async getFromClintLanguageNameList(client) {
        var response = await client.query({
            query: GET_PROJECT_LANGUAGENAME,
            variables: { languageName: languageName }
        });
        return await response.data.projectsByLanguageName;
    }

    async getFromClintProjectGroupName(client) {
        try {
        const response = await client.query({
            query: GET_PROJECT_GROUPNAME,
            variables: { projectGroupName: projectGroupName, repoAccessToken: repoAccessToken }
        });
        return await response.data.projectsByProjectGroupNameAndStatusCheck;
      } catch (error) {
        console.error(error);
      }
    }

    async getProjectList(query) {
        if (query && query.ruleId) {
            ruleId = query.ruleId;
        }
        else if (query && query.languageName) {
            languageName = query.languageName;
        }
        else if (query && query.projectGroupName && query.repoAccessToken) {
            projectGroupName = query.projectGroupName;
            repoAccessToken = query.repoAccessToken;
        }

        if (query.ruleId) {
            return this.getFromClintList(ClinetServices.getClienUri()).then(res => {
                return {
                    data: res,
                }
            }, error => {
                return {
                    data: []
                }
            });
        } else if (query.languageName) {
            return this.getFromClintLanguageNameList(ClinetServices.getClienUri()).then(res => {
                return {
                    data: res,
                }
            }, error => {
                return {
                    data: []
                }
            });
        } else if(query.projectGroupName && query.repoAccessToken) {
            return this.getFromClintProjectGroupName(ClinetServices.getClienUri()).then(res => {
                return {
                    data: res,
                }
            }, error => {
                return {
                    data: []
                }
            });
        }
    }
}

export default projectService;