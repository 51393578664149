import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import './index.css'

const DELETE_USER = gql`
mutation deleteUser($userId: Long!) {
    deleteUser(
        userId: $userId,
    )
}
`;

const REMOVE_USER_ORG = gql`
mutation removeOrganization($userId: Long, $organizationId: Long) {
    removeOrganization(
        userId: $userId,
        organizationId: $organizationId
    )
}
`;

class DeleteUser extends Component {
    userId;
    constructor(props, context) {
        super(props, context)
        this.state = {
            showDeleteModal: false,
            deleteModal: false,
            open: false,
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmitObject = this.handleSubmitObject.bind(this);
    }

    handleClose() {
        this.props.onClose();
    }

    handleSubmitObject() {
        const userId = this.props.userData.id;
        const organizationId = this.props.orgId;

        return this.props.isSUAdmin ? {userId} : { userId, organizationId };
    }

    render() {
        return (
            <div className="p-2">
                <Dialog open={this.props.deleteModal} fullWidth={false} onClose={this.props.handleCancel} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" className="text-danger">Delete Confirmation!</DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to delete this user?
                    </DialogContent>
                    <DialogActions>
                        <Mutation mutation={this.props.isSUAdmin ? DELETE_USER : REMOVE_USER_ORG}
                            onCompleted={() => { this.handleClose(); }}>
                            {(deleteUser, { loading }) => (
                                <Button onClick={() => {
                                    deleteUser({ variables: this.handleSubmitObject() });
                                }} variant="contained" color="secondary" disabled={loading}>
                                    {loading ? <CircularProgress disableShrink size={24} /> : 'Yes'}
                                </Button>
                            )}
                        </Mutation>
                        <Button autoFocus onClick={this.props.handleCancel} color="default">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default DeleteUser;