import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbars from '../../../core/error/Snackbars';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';

import '../../project/project.css';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const UPDATE_LANGUAGE = gql`
    mutation updateLanguage(
        $languageName: String!,
        $description: String,
        $icon: String,
        $enabled: Boolean,
        $extensions: String,
        $analyzerEngines: String,
        ) {
            updateLanguage(
                languageName: $languageName,
                description: $description,
                icon: $icon,
                enabled: $enabled,
                extensions: $extensions,
                analyzerEngines: $analyzerEngines) 
    }
`;

const GET_BY_LANGUAGE = gql`
    query languageByLanguageName($languageName: String!) {
        languageByLanguageName(languageName: $languageName) {
            languageName
            numOfRules
            numOfActiveRules
            description
            icon
            enabled
            extensions
            analyzerEngines
        }
    }
`;

class EditLanguageManagement extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            languageName: '',
            numOfRules: '',
            description: '',
            icon: '',
            enabled: false,
            analyzerEngines: '',
            extensions: '',
            submitted: false,
        };

        this.handleDialog = this.handleDialog.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleDialog() {
        this.props.onUpdate();
        this.props.refresh();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'select' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit() {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });
    }

    submit = () => {
        this.form.submit();
    }

    validatorListener = (result) => {
        this.setState({ disabled: !result });
    }



    render() {
        return (
            <Query fetchPolicy="no-cache" query={GET_BY_LANGUAGE} variables={{ languageName: this.props.languageList && this.props.languageList.languageName }}>
                {({ loading, error, data }) => {
                    this.isError = error;
                    if (!this.state.languageName || this.state.languageName !== data.languageByLanguageName.languageName) {
                        if (data && data.languageByLanguageName) {
                            this.setState({
                                lastData: data.languageByLanguageName
                            });
                            for (var key in data.languageByLanguageName) {
                                this.setState({
                                    [key]: data.languageByLanguageName[key]
                                });
                            }
                        }
                    }
                    return (
                        <Mutation mutation={UPDATE_LANGUAGE} onCompleted={() => this.handleDialog()}>
                            {(updateLangauge, { loading, error }) => (
                                <ValidatorForm id="updateLangauge"
                                    ref={(r) => { this.form = r; }}
                                    onSubmit={e => {
                                        if (!this.state.languageName) {
                                            delete this.state.languageName;
                                        }
                                        this.setState({ submitted: true }, () => {
                                            setTimeout(() => this.setState({ submitted: false }), 1000);
                                        });
                                        updateLangauge({ variables: { languageName: this.state.languageName, description: this.state.description, icon: null, enabled: this.state.enabled, extensions: this.state.extensions.toString().replace('[', '').replace(']', ''), analyzerEngines: this.state.analyzerEngines.toString().replace('[', '').replace(']', '') } });
                                    }}
                                    instantValidate
                                >
                                    <Dialog maxWidth={"sm"} fullWidth={true}
                                        fullScreen={window.innerWidth < 600}
                                        open={this.props.showModal}>
                                        <DialogTitle id="customized-dialog-title"
                                            onClose={this.handleDialog}>{'Edit Language'} <Tooltip title={this.state.enabled ? 'Language Enabled' : 'Language Disabled' } placement="left"><Switch
                                                name="enabled"
                                                className="text-right mr-5"
                                                checked={this.state.enabled}
                                                onChange={this.handleInputChange}
                                                value={this.state.enabled}
                                                color="primary"
                                            /></Tooltip></DialogTitle >
                                        {loading ? <LinearProgress color="primary" /> : <Divider />}
                                        <DialogContent className="project-group-edit-container">
                                            {loading && <div className="loading"><CircularProgress size={50} /></div>}
                                            {error &&
                                                <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={false} />
                                            }
                                            <div className="row">
                                                <div className="col-md-6 mt-4 mb-4">
                                                    <div className="mb-2">Language Name</div>
                                                    <div><strong>{this.state.languageName}</strong></div>
                                                </div>
                                                <div className="col-md-6 mt-4 mb-4">
                                                    <div className="mb-2">No of Rules</div>
                                                    <div><strong>{this.state.numOfActiveRules}</strong></div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <TextValidator
                                                        name="description"
                                                        id="description"
                                                        label="Description"
                                                        multiline={true}
                                                        rows={1}
                                                        onChange={this.handleInputChange}
                                                        value={this.state.description || ''}
                                                        rowsMax={4}
                                                        validators={['required']}
                                                        errorMessages={['This field is Required']}
                                                        validatorListener={this.validatorListener}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <TextValidator type="text"
                                                        name="extensions"
                                                        label="Extensions"
                                                        value={this.state.extensions ? this.state.extensions.toString().replace('[', '').replace(']', '') : ''}
                                                        helperText="Example: .js,.java,.ts"
                                                        onChange={this.handleInputChange}
                                                        validators={['required']}
                                                        errorMessages={['This field is Required']}
                                                        validatorListener={this.validatorListener}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <TextValidator type="text"
                                                        name="analyzerEngines"
                                                        label="Analyzer Engines"
                                                        helperText="Example: PMD v6.18.0, ESLint v5.20.1"
                                                        value={this.state.analyzerEngines ? this.state.analyzerEngines.toString().replace('[', '').replace(']', '') : ''}
                                                        onChange={this.handleInputChange}
                                                        validators={['required']}
                                                        errorMessages={['This field is Required']}
                                                        validatorListener={this.validatorListener}
                                                    />
                                                </div>
                                            </div>
                                        </DialogContent>
                                        <Divider />
                                        <DialogActions>
                                            <Button onClick={this.handleDialog} color="secondary">
                                                Close
                                        </Button>
                                            <Button color="primary" type="submit" variant="contained" disabled={loading} onClick={this.submit}>
                                                {loading ? <CircularProgress disableShrink size={24} /> : 'Update'}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </ValidatorForm>
                            )}
                        </Mutation>
                    )
                }}
            </Query>
        )
    }
}

export default EditLanguageManagement
