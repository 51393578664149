import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import '../common.css';
import FilterListIcon from '@material-ui/icons/FilterList';
import preferenceService from '../../core/service/preference';

const status = [
  { label: 'All', value: 'All' },
  { label: 'New', value: 'New' },
  { label: 'In Progress', value: 'In Progress' },
  { label: 'Address Review Comments', value: 'Address Review Comments' },
  { label: 'Completed', value: 'Completed' }
];

class CustomStatusPicker extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fieldData: ""
    }
  };

  componentWillReceiveProps() {
    this.setState({
      fieldData: preferenceService.getValue('reviewGridValues')[this.props.columnDef.field]
    })
  }

  handleChange = (event) => {
    if (event.target.value === 'All') {
      this.props.onFilterChanged(this.props.columnDef.tableData.id, '')
    } else {
      this.props.onFilterChanged(this.props.columnDef.tableData.id, event.target.value)
    }
    this.setState({
      fieldData: event.target.value
    });
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <Select
            value={this.state.fieldData === "" ? "All" : this.state.fieldData}
            onChange={this.handleChange}
            IconComponent={() => (
              <FilterListIcon />
            )}
          >
            {
              status.map(e => {
                return <MenuItem value={e.value}>{e.label}</MenuItem>
              })
            }
          </Select>
        </div>
      </div>
    );
  }
}

export default CustomStatusPicker