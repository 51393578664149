import React from 'react';

function Release(props) {

    return (
        <div className="container">
            <div className="releases">
                <h3>{props.content.heading}</h3>
                <div className="features">
                    <h5>Features</h5>
                    <ul>
                        {
                            props.content.features.map(data => {
                                if (!data) {
                                    return null;
                                }
                                return (
                                    <li>
                                        {data && data.li}
                                        <ul>
                                            {data && data["sub-li"].map(con => {
                                                return <li>{con}</li>;
                                            })}
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="enhancements">
                    {props.content.enhancements && <h5>Enhancements</h5>}
                    <ul>
                        {
                            props.content.enhancements && props.content.enhancements.map(data => {
                                if (!data) {
                                    return null;
                                }
                                return (
                                    <li>
                                        {data && data.li}
                                        <ul>
                                            {data && data["sub-li"].map(con => {
                                                return <li>{con}</li>;
                                            })}
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="bugFix">
                    {props.content.bugFix && <h5>Bug Fixes</h5>}
                    <ul>
                        {
                            props.content.bugFix && props.content.bugFix.map(data => {
                                if (!data) {
                                    return null;
                                }
                                return (
                                    <li>
                                        {data && data.li}
                                        <ul>
                                            {data && data["sub-li"].map(con => {
                                                return <li>{con}</li>;
                                            })}
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="technical_improvements">
                    {props.content.technical_improvements && <h5>Technical Improvements</h5>}
                    <ul>
                        {
                            props.content.technical_improvements && props.content.technical_improvements.map(data => {
                                if (!data) {
                                    return null;
                                }
                                return (
                                    <li>
                                        {data && data.li}
                                        <ul>
                                            {data && data["sub-li"].map(con => {
                                                return <li>{con}</li>;
                                            })}
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            {props.content.minorRelease && props.content.minorRelease.map(data => (<div className="releases mt-4">
                <h4>{data.heading}</h4>
                <div className="features">
                    {data.features &&  <h5>Features</h5> }
                    <ul>
                        {
                            data.features && data.features.map(data => {
                                if (!data) {
                                    return null;
                                }
                                return (
                                    <li>
                                        {data && data.li}
                                        <ul>
                                            {data && data["sub-li"].map(con => {
                                                return <li>{con}</li>;
                                            })}
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="enhancements">
                    {data.enhancements && <h5>Enhancements</h5>}
                    <ul>
                        {
                            data.enhancements && data.enhancements.map(data => {
                                if (!data) {
                                    return null;
                                }
                                return (
                                    <li>
                                        {data && data.li}
                                        <ul>
                                            {data && data["sub-li"].map(con => {
                                                return <li>{con}</li>;
                                            })}
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="bugFix">
                    {data.bugFix && <h5>Bug Fixes</h5>}
                    <ul>
                        {
                            data.bugFix && data.bugFix.map(data => {
                                if (!data) {
                                    return null;
                                }
                                return (
                                    <li>
                                        {data && data.li}
                                        <ul>
                                            {data && data["sub-li"].map(con => {
                                                return <li>{con}</li>;
                                            })}
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>))}
        </div>
    )
}

export default Release;