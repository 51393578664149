import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Chart, Bar } from 'react-chartjs-2';
import zoom from 'chartjs-plugin-zoom'
import moment from 'moment';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import InputLabel from '@material-ui/core/InputLabel';
import Skeleton from '@material-ui/lab/Skeleton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import './../../home/home.css';
import { LocalToUTCTime, LocalDate } from '../../../core/service/timezone';
var user = JSON.parse(localStorage.getItem('users'));
const REVIEWS_BY_USERS = gql`
query reviewsByUsers($projectName: String,
    $projectGroupName: String,
    $startDate: Long,
    $endDate: Long) {
    reviewsByUser (projectName: $projectName, projectGroupName: $projectGroupName, startDate: $startDate, endDate: $endDate) {
        id
        reviews {
            status
            count
        }
    }
    projectsByProjectGroupName(projectGroupName: $projectGroupName) {
        projectName
    }
    projectGroupsWithoutPagination {     
        projectGroupName
    }
}`;

var projectFlag = false;
var today = new Date();

class ReviewsByUsersChart extends Component {
    constructor(props) {
        super(props);
        this.getDateOptions = this.getDateOptions.bind(this);
        this.reviewByDate = this.reviewByDate.bind(this);
        this.resetZoom = this.resetZoom.bind(this);
        this.chartDragOn = this.chartDragOn.bind(this);
        this.chartDragOff = this.chartDragOff.bind(this);
        this.state = {
            projectName: null,
            projectGroupName: null,
            dateLabel: 'Today',
            drag: true
        };

        this.prepareReviewsByUsersData = function (data) {
            if (data) {
                var datasets = [
                    {
                        data: [],
                        backgroundColor: 'rgba(255,99,132,1)',
                        borderColor: 'rgba(255,99,132,1)',
                        label: 'Needs Rework',
                    },
                    {
                        data: [],
                        backgroundColor: 'rgba(75,192,192,1)',
                        borderColor: 'rgba(75,192,192,1)',
                        label: 'Resolved',
                    },
                ];
                data.forEach((data) => {
                    if (data.reviews.length && data.reviews.length === 1) {
                        data.reviews.push({
                            count: 0
                        })
                    }
                    data.reviews.forEach((data, index) => {
                        datasets[index].data.push(data.count);
                    });
                });
            }

            return {
                responsive: true,
                maintainAspectRatio: false,
                labels: data.map((data) => { return data.id }),
                datasets: datasets
            }
        };
    }

    ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    getDateOptions(picker) {
        var seletedDate = '';

        if (picker.startDate.format('MMMM D, YYYY') === picker.endDate.format('MMMM D, YYYY')) {
            seletedDate = picker.startDate.format('YYYY-MM-DD');
        }
        else {
            seletedDate = picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD');
        }

        return {
            dateObj: picker,
            label: seletedDate
        }

    };

    reviewByDate(event, picker) {
        var data = this.getDateOptions(picker);
        if (picker.chosenLabel !== 'Today' && data.dateObj) {
            this.setState({
                dateLabel: data.label,
                startDate: data.dateObj.startDate.format('YYYY-MM-DD'),
                endDate: data.dateObj.endDate.format('YYYY-MM-DD')
            });
        } else {
            this.setState({
                dateLabel: 'Today',
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            })
        }
    }

    componentWillMount() {
        Chart.plugins.register(zoom)
    }

    resetZoom() {
        this.barChart.chartInstance.resetZoom();
    }

    chartDragOn() {
        this.setState({
            drag: false
        })
    }

    chartDragOff() {
        this.setState({
            drag: true
        })
    }

    render() {
        return (
            <Query fetchPolicy="no-cache" query={REVIEWS_BY_USERS} variables={{
                projectGroupName: user.currentProjectGroupName,
                projectName: this.state.projectName,
                startDate: this.state.startDate ? LocalToUTCTime(this.state.startDate, 'x') : LocalDate(),
                endDate: this.state.endDate ? LocalToUTCTime(this.state.endDate, 'x') : LocalDate()
            }}>
                {({ loading, error, data, refetch }) => {
                    if (error) return (
                        <div className="text-center"></div>
                    )


                    if (loading) return (
                        <div className="row p-2">
                            <div className="col-sm-12 chart-container p-4 bg-white my-0">
                                <div className="row mb-4">
                                    <div className="col-lg-10">
                                        <Skeleton variant="text" width={"150px"} />
                                        <Skeleton variant="text" width={"100px"} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                </div>
                                <Skeleton variant="rect" width={"100%"} height={"235px"} />
                            </div>
                        </div>
                    );

                    if (!projectFlag && data.projectGroupsWithoutPagination.length) {
                        this.setState({
                            projectGroupName: data.projectGroupsWithoutPagination[0].projectGroupName
                        })
                        projectFlag = true;
                        refetch()
                    }



                    return (
                        <div className="row px-2">
                            <div className="col-sm-12 chart-container p-4 bg-white">
                                <div className="row pr-0">
                                    <div className="col-lg-6 pr-0">
                                        <h4>Reviews By Users</h4>
                                    </div>
                                    <div className="col-lg-6 pr-0">
                                        {data.reviewsByUser && <Tooltip title={'Reset Zoom'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.resetZoom()}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {data.reviewsByUser && this.state.drag && <Tooltip title={'Select the Chart to Magnify'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.chartDragOn()}>
                                                <BlurOnIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {data.reviewsByUser && !this.state.drag && <Tooltip title={'Scroll the Chart to Magnify'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.chartDragOff()}>
                                                <BlurOffIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </div>
                                </div>
                                <div className="row pr-0">
                                    <div className="col-md-12 pr-0">
                                        <div className="row">
                                            <div className="col-lg-3 mt-2 mb-2">
                                                <InputLabel>Repo Name</InputLabel>
                                                <Select
                                                    name="projectNameId"
                                                    className="w-100"
                                                    value={this.state.projectName ? this.state.projectName : 'ALL'}
                                                    onChange={event => {
                                                        this.setState({ projectName: event.target.value !== 'ALL' ? event.target.value : null }, () => {
                                                            refetch();
                                                        });
                                                    }}
                                                >
                                                    <MenuItem value="ALL" key="0">All</MenuItem>
                                                    {data && data.projectsByProjectGroupName ?
                                                        data.projectsByProjectGroupName.map((project, index) => {
                                                            return (
                                                                <MenuItem key={index + 1} value={project.projectName}>{project.projectName}</MenuItem>
                                                            );
                                                        })
                                                        : ''}
                                                </Select>
                                            </div>
                                            <div className="col-lg-5 mt-2 mb-2 custom-select-style">
                                                <InputLabel>
                                                    Date Range
                                                </InputLabel>
                                                <DateRangePicker containerStyles={{ display: "block" }}
                                                    ranges={this.ranges}
                                                    maxDate={moment().format('MM/DD/YYYY')}
                                                    onApply={this.reviewByDate}>
                                                    <div className="custom-select dateRange">
                                                        {this.state.dateLabel}
                                                    </div>
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 pr-0" style={{ width: '100%', height: '235px' }}>
                                        {data.reviewsByUser.length ? <Bar data={this.prepareReviewsByUsersData(data ? data.reviewsByUser : [])}
                                            ref={(ref) => this.barChart = ref}
                                            options={{
                                                maintainAspectRatio: false,
                                                legend: false,
                                                pan: {
                                                    enabled: true,
                                                    mode: 'x',
                                                    speed: 10,
                                                    threshold: 10
                                                },
                                                zoom: {
                                                    enabled: true,
                                                    drag: this.state.drag,
                                                    mode: 'xy'
                                                },
                                                scales: {
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true
                                                        }
                                                    }]
                                                }
                                            }} /> : <div className="text-center chart-align-top">
                                                <img src="/images/statistic.jpg" width="90" alt="noData" /><br />
                                                Not enough data to display the chart
                                      </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                }
            </Query>
        );
    }
}

export default ReviewsByUsersChart;