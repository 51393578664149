import React, { Component } from 'react';
import { Chart, Bar } from 'react-chartjs-2';
import zoom from 'chartjs-plugin-zoom'
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import Skeleton from '@material-ui/lab/Skeleton';
import ReviewStatusByProject from './review-status-by-project';
import Tooltip from '@material-ui/core/Tooltip';

import './../../home/home.css';

const REVIEW_STATUS_BY_PROJECT_GROUP = gql`
query reviewStatusByProjectGroup {
    reviewStatusByProjectGroup {
        _id
        totalPRCount
        status  {
            name
            count
        }
    }
}`;

class PRStatusByProjects extends Component {
    constructor(props) {
        super(props);
        this.resetZoom = this.resetZoom.bind(this);
        this.chartDragOn = this.chartDragOn.bind(this);
        this.chartDragOff = this.chartDragOff.bind(this);
        this.handleProjectChartModel = this.handleProjectChartModel.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.state = {
            projectName: null,
            projectGroupName: null,
            projectGroup: '',
            drag: true,
            showProjectModel: false
        };

        this.prepareReviewStatusByProjectGroupData = function (data) {
            if (data) {
                var datasets = [
                    {
                        data: [],
                        backgroundColor: '#f5005799',
                        borderColor: '#f5005700',
                        label: 'Needs Rework',
                    },
                    {
                        data: [],
                        backgroundColor: '#12C53999',
                        borderColor: '#12C53900',
                        label: 'Resolved',
                    },
                    {
                        data: [],
                        backgroundColor: '#2678CB99',
                        borderColor: '#2678CB00',
                        label: 'New',
                    },
                ];
                data.forEach((data) => {
                    if (data.status.length && data.status.length === 1) {
                        data.status.push({
                            count: 0
                        })
                    }
                    data.status.forEach((data, index) => {
                        datasets[index].data.push(data.count);
                    });
                });
            }

            return {
                responsive: true,
                maintainAspectRatio: false,
                labels: data.map((data) => { return data._id }),
                datasets: datasets
            }
        };
    }

    componentWillMount() {
        Chart.plugins.register(zoom)
    }

    handleProjectChartModel(projectGroup) {
        this.setState({ showProjectModel: true, projectGroup: projectGroup });
    }

    handleDialog() {
        this.props.onClose();
        this.props.refresh();
    }

    handleCancel() {
        this.setState({ showProjectModel: false });
    }

    resetZoom() {
        this.barChart.chartInstance.resetZoom();
    }

    chartDragOn() {
        this.setState({
            drag: false
        })
    }

    chartDragOff() {
        this.setState({
            drag: true
        })
    }

    render() {
        return (
            <Query fetchPolicy="no-cache" query={REVIEW_STATUS_BY_PROJECT_GROUP}>
                {({ loading, error, data, refetch }) => {
                    if (error) return (
                        <div className="text-center"></div>
                    )
                    if (loading) return (
                        <div className="row p-2">
                            <div className="col-sm-12 chart-container p-4 bg-white my-0">
                                <div className="row mb-4">
                                    <div className="col-lg-10">
                                        <Skeleton variant="text" width={"150px"} />
                                        <Skeleton variant="text" width={"100px"} />
                                    </div>
                                </div>
                                <Skeleton variant="rect" width={"100%"} height={"233px"} />
                            </div>
                        </div>
                    );
                    return (
                        <div className="chart-container p-4 bg-white">
                            <div className="row pr-0">
                                <div className="col-lg-7 pr-0">
                                    <h4>Review Status By Projects</h4>
                                </div>
                                <div className="col-lg-5 pr-0">
                                    {data.reviewStatusByProjectGroup && <Tooltip title={'Reset Zoom'} placement="top" id="placement">
                                        <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.resetZoom()}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>}
                                    {data.reviewStatusByProjectGroup && this.state.drag && <Tooltip title={'Select the Chart to Magnify'} placement="top" id="placement">
                                        <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.chartDragOn()}>
                                            <BlurOnIcon />
                                        </IconButton>
                                    </Tooltip>}
                                    {data.reviewStatusByProjectGroup && !this.state.drag && <Tooltip title={'Scroll the Chart to Magnify'} placement="top" id="placement">
                                        <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.chartDragOff()}>
                                            <BlurOffIcon />
                                        </IconButton>
                                    </Tooltip>}
                                </div>
                            </div>
                            <div className="row pr-0">
                                <div className="col-md-12 pr-0" style={{ width: '100%', height: '233px' }}>
                                    {data.reviewStatusByProjectGroup.length ? <Bar data={this.prepareReviewStatusByProjectGroupData(data ? data.reviewStatusByProjectGroup : [])}
                                        ref={(ref) => this.barChart = ref}
                                        options={{
                                            maintainAspectRatio: false,
                                            pan: {
                                                enabled: true,
                                                mode: 'x',
                                                speed: 10,
                                                threshold: 10
                                            },
                                            zoom: {
                                                enabled: true,
                                                drag: this.state.drag,
                                                mode: 'xy'
                                            },
                                            scales: {
                                                yAxes: [{
                                                    ticks: {
                                                        beginAtZero: true
                                                    }
                                                }]
                                            },
                                            onClick: (e, item) => {
                                                item.forEach((data) => {
                                                    this.setState({
                                                        projectGroup: data._model.label
                                                    });
                                                    this.handleProjectChartModel(this.state.projectGroup)
                                                })
                                            }
                                        }} /> : <div className="text-center chart-align-top">
                                            <img src="/images/statistic.jpg" width="90" alt="noData" /><br />
                                            Not enough data to display the chart
                                      </div>
                                    }
                                </div>
                            </div>
                            <ReviewStatusByProject projectGroup={this.state.projectGroup} onClick={this.handleDialog} handleCancel={this.handleCancel} showProjectModel={this.state.showProjectModel} onClose={this.handleDeleteClose}></ReviewStatusByProject>
                        </div>
                    )
                }
                }
            </Query>
        );
    }
}

export default PRStatusByProjects;