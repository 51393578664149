import React from 'react';
import Header from './../common/Header';
import ProjectTable from './ProjectTable';
import Quality from './../common/Quality';

import LanguageBar from '../../../components/language-bar/language-bar';
class Chart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (!this.props.data) {
            return null
        }
        return (
            <div className="container p-5" style={{ margin: '0 0 0 -30px' }}>
                <Header name={this.props.data.id} report={this.props.dateFilter.startDate
                    ? this.props.dateFilter.startDate + " - " + this.props.dateFilter.endDate
                    : this.props.filter} isQuater={!!this.props.dateFilter.startDate} />
                <Quality type={'Contributors'} data={this.props.data} />

                <div className="row m-0">
                    <div className="col-12 pt-5 my-2 border-top">
                        <div className="mb-4">
                            <LanguageBar readings={this.props.data.languages} />
                        </div>
                        <ProjectTable report={this.props.data.id} users={this.props.data.users && this.props.data.users.data} data={this.props.data.reviewStatusByProject} />
                    </div>

                </div>
            </div>
        )
    }
}

export default Chart;