import React, { Component } from 'react';
import Collapse from '@material-ui/core/Collapse';
import MoreInfo from './MoreInfo';
import moment from 'moment';
import IgnoreComment from './IgnoreComment';
import ApproveComment from './ApproveComment';
import RejectComment from './RejectComment';
import ReplyComment from './ReplyComment';
import preferenceService from './../../../../core/service/preference'
import { avatarImage } from '../../../components/service/avatar-image';
import CommentList from './CommentList';

const img = avatarImage.getImageLocation();
class ReviewComment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            removeStatus: false,
            ignoreStatus: false,
            replyStatus: false
        };
        this.moreInfo = this.moreInfo.bind(this);
        this.showComments = this.showComments.bind(this);
        this.hideComment = this.hideComment.bind(this);
        this.updatedIgnoreComment = this.updatedIgnoreComment.bind(this);
        this.updatedApproveComment = this.updatedApproveComment.bind(this);
        this.updatedRejectComment = this.updatedRejectComment.bind(this);
        this.updatedReplyComment = this.updatedReplyComment.bind(this);
    }

    hideComment(cmt) {
        this.setState({
            [cmt.commentId + 'Ignore']: false,
            [cmt.commentId + 'Approved']: false,
            [cmt.commentId + 'Rejected']: false,
            [cmt.commentId + 'Reply']: false,
        });
    }

    updatedIgnoreComment(data, cmt) {
        if (data && data.ignoreReviewComment) {
            this.setState({
                [cmt.commentId + 'Ignore']: false,
                [cmt.commentId]: data.ignoreReviewComment,
                ignoreStatus: true,
            });
        }
    }

    updatedApproveComment(data, cmt) {
        if (data && data.approveReviewComment) {
            this.setState({
                [cmt.commentId + 'Approved']: false,
                [cmt.commentId]: data.approveReviewComment,
                removeStatus: true,
            });
        }
    }

    updatedRejectComment(data, cmt) {
        if (data && data.rejectReviewComment) {
            this.setState({
                [cmt.commentId + 'Rejected']: false,
                [cmt.commentId]: data.rejectReviewComment,
                removeStatus: true,
            });
        }
    }

    updatedReplyComment(data, cmt) {
        if (data && data.replyReviewComment) {
            this.setState({
                [cmt.commentId + 'Reply']: false,
                [cmt.commentId]: data.replyReviewComment,
                replyStatus: true,
            });
        }
    }

    moreInfo(name) {
        this.setState({
            [name]: !this.state[name],
        });
    }

    showComments(name) {
        this.setState({
            [name]: !this.state[name],
        });
    }

    render() {
        return (
            <div className={this.props.data.status === 'APPROVED' ? 'approved-comments-section' : 'ignore-comments-section comment'}>
                <div className="d-flex">
                    <div className="avatar">
                        {(this.props.data.manualComment || this.props.data.repoManualComment) ?
                            <img src={this.props.data.repoManualComment ? "/images/stash.png" : avatarImage.getImageLocation(this.props.data.user.id)} alt="User Profile" className="align-self-start" width="30" />
                            : <img src="/images/Q.jpg" className="align-self-start" alt="User Profile" width="30" />
                        }
                    </div>
                    <span className="comment-content">
                        <h6 className="text-primary">
                            {(this.props.data.manualComment || this.props.data.repoManualComment) ?
                                <span></span> : <span>QBot</span>
                            }
                            <span>{this.props.data.user && this.props.data.user.name}</span>
                            <span className="line-style text-right ml-2">
                                <span className="secondary">End Line:</span>
                                {this.props.data.location.lines.end}
                            </span>
                            <span className="line-style text-right">
                                <span className="secondary">Start Line:</span>
                                {this.props.data.location.lines.begin}
                            </span>
                        </h6>
                        <p className="p-0">{this.props.data.description}</p>
                        <hr className="p-0 m-0 pb-2"></hr>
                        <p className="actions">
                            {/* Replay Comments */}
                            {this.props.data.manualComment ?
                                <span className="secondary cursor-pointer" onClick={() => {
                                    this.setState({ [this.props.data.commentId + 'Reply']: true })
                                }}>
                                    <i className="material-icons align-middle icon">reply</i>Reply Comment
                                </span> : null
                            }

                            {/* Ignore Comments */}
                            {!this.props.data.manualComment && !this.props.data.repoManualComment && !this.props.state && this.props.data.status !== 'APPROVED' && this.props.data.checkName !== "None" ?
                                <span className="secondary cursor-pointer" onClick={() => { this.setState({ [this.props.data.commentId + 'Ignore']: true, [this.props.data.commentId + 'Approved']: false }) }}>
                                    <i className="material-icons align-middle icon">add_alert</i>Ignore Comment
                                </span> : null
                            }

                            {/* Approve Comments */}
                            {preferenceService.getValue('users', 'local').grantedAuthorities.map(e => e.authority).includes('ROLE_APPROVER') && this.props.state && !this.props.data.manualComment && !this.state.removeStatus ?
                                <span className="secondary cursor-pointer" onClick={() => { this.setState({ [this.props.data.commentId + 'Ignore']: false, [this.props.data.commentId + 'Approved']: true }) }}>
                                    <i className="material-icons align-middle icon">done_all</i>Approve
                                </span> : null
                            }

                            {/* Reject Comments */}
                            {preferenceService.getValue('users', 'local').grantedAuthorities.map(e => e.authority).includes('ROLE_APPROVER') && this.props.state && !this.props.data.manualComment && !this.state.removeStatus ?
                                <span className="secondary cursor-pointer pl-4" onClick={() => { this.setState({ [this.props.data.commentId + 'Ignore']: false, [this.props.data.commentId + 'Rejected']: true }) }}>
                                    <i className="material-icons align-middle icon">close</i>Reject
                                </span> : null
                            }

                            {/* More Info */}
                            {(!this.props.data.manualComment && !this.props.data.repoManualComment && this.props.data.checkName !== "None") ?
                                <span className="secondary pl-3 cursor-pointer" onClick={() => { this.moreInfo(this.props.data.checkName) }}>
                                    {!this.state[this.props.data.checkName]
                                        ? <span><i className="material-icons p-0 align-middle icon">expand_more</i> More info</span>
                                        : <span><i className="material-icons p-0 align-middle icon">expand_less</i> Less info</span>
                                    }
                                </span> : null
                            }

                            {/* Comment Date Info */}
                            {this.props.data.manualComment ? <span className="secondary pl-3 float-right cursor-pointer" onClick={() => { this.moreInfo(this.props.data.checkName) }}>
                                <span className="text-muted float-right cursor-pointer" title="Created Date">
                                    {moment(this.props.data.createdOn).format('YYYY/MM/DD HH:mm A')}</span>
                            </span> : null
                            }

                            {/* Comment List */}
                            {((this.state[this.props.data.commentId] && this.state[this.props.data.commentId].length) || (this.props.data.actions && this.props.data.actions.length)) ? <span className="secondary pl-3 float-right cursor-pointer" onClick={() => { this.showComments(this.props.reviewId) }}>
                                {!this.state[this.props.reviewId]
                                    ? <span><i className="material-icons align-middle icon">expand_less</i> Hide Comments</span>
                                    : <span><i className="material-icons align-middle icon">expand_more</i> Show Comments</span>
                                }
                            </span> : null}
                        </p>
                    </span>
                </div>
                <Collapse in={this.state[this.props.data.checkName]} timeout="auto" unmountOnExit>
                    <MoreInfo data={this.props.data} />
                </Collapse>
                {this.state[this.props.data.commentId + 'Ignore']
                    ? <IgnoreComment reviewId={this.props.reviewId} state={this.props.state} data={this.props.data} type={'IN_REVIEW'} onCancel={() => { this.hideComment(this.props.data) }} updateComments={(e) => { this.updatedIgnoreComment(e, this.props.data) }} />
                    : null
                }
                {this.state[this.props.data.commentId + 'Approved']
                    ? <ApproveComment reviewId={this.props.reviewId} state={this.props.state} data={this.props.data} type={'APPROVED'} onCancel={() => { this.hideComment(this.props.data) }} updateComments={(e) => { this.updatedApproveComment(e, this.props.data) }} />
                    : null
                }
                {this.state[this.props.data.commentId + 'Rejected']
                    ? <RejectComment reviewId={this.props.reviewId} state={this.props.state} data={this.props.data} type={'REJECTED'} onCancel={() => { this.hideComment(this.props.data) }} updateComments={(e) => { this.updatedRejectComment(e, this.props.data) }} />
                    : null
                }
                {this.state[this.props.data.commentId + 'Reply']
                    ? <ReplyComment reviewId={this.props.reviewId} state={this.props.state} data={this.props.data} type={'REPLY'} onCancel={() => { this.hideComment(this.props.data) }} updateComments={(e) => { this.updatedReplyComment(e, this.props.data) }} />
                    : null
                }
                <Collapse in={!this.state[this.props.reviewId]} timeout="auto" unmountOnExit>
                    {this.state[this.props.data.commentId]
                        ? this.state[this.props.data.commentId] && this.state[this.props.data.commentId].map((data, index) => {
                            return <CommentList key={index} data={data} state={this.props.state} onCancel={this.hideComment} />
                        })
                        : this.props.data.actions && this.props.data.actions.map((data, index) => {
                            return <CommentList key={index} data={data} state={this.props.state} onCancel={this.hideComment} />
                        })
                    }
                </Collapse>
            </div>
        );
    }
}

export default ReviewComment;