import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import TreeView from './../../components/diff-view/tree/TreeView';
import '../modals/modal.css';
import ErrorType from '../../../core/error/simpleError';

const GET_DIFF = gql`
query changes($reviewUrl: String!, $filesLimit: Int) {
    changes(reviewUrl: $reviewUrl, filesLimit: $filesLimit) {
        values {
            path {
                components
                parent
                name
                extension
                toString
            }
            type
        }
    }
}
`;

class StashModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSn: false,
        }
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.onUpdate();
        this.props.onRefresh();
    }


    render() {
        let defaultFilePath = this.props.defaultFilePath;
        return (
            <div>
                <Dialog
                    fullScreen={true}
                    open={this.props.showModal}
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth={"lg"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle>
                        Tree View
                    </DialogTitle>
                    <DialogContent dividers={true} className="p-0">
                        <div className="p-0">
                            <Query query={GET_DIFF} fetchPolicy="no-cache" variables={{ reviewUrl: this.props.data ? this.props.data : '', filesLimit: this.props.response && this.props.response.numOfAnalyzedFiles }}>
                                {({ loading, error, data, refetch }) => {
                                    if (loading) return <div className="loading"><CircularProgress size={50} /></div>;
                                    if (error) return (
                                        <div className="text-center p-4">
                                            <ErrorType type="serverError" size={450} head={"Internal Server Error"} content={error.message.split(':') && error.message.split(':').length && error.message.split(':')[2]
                                                ? error.message.split(':')[2].includes('404') ? 'The Branch were deleted so you cannot access the Diff' : error.message.split(':')[2]
                                                : 'Something Went Wrong...!'} />
                                        </div>);
                                    return <TreeView data={data.changes.values} filePath={defaultFilePath ? defaultFilePath : this.props.filePath} state={this.props.state} reviewId={this.props.reviewId} reviewUrl={this.props.data} response={this.props.response} />
                                }}
                            </Query>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary" autoFocus>
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default StashModal;