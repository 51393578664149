import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';
import Avatar from "@material-ui/core/Avatar";
import ReviewResponse from './review-response';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import ManualCommentsAddressed from './modals/manualComments/ManualCommentsAddressed';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Error404 from '../../core/error/404';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ReviewStatistic from './modals/reviews-statistic';
import ReviewCommits from './modals/reviews-commits';
import ReviewCommitId from './modals/reviews-commitId'
import ReviewCommitters from './modals/committers';
import authorService from './services/author-service';

import LanguageBar from '../components/language-bar/language-bar';
import StatusWatcher from './modals/reviewStatus/StatusWatcher';
import Auth from '../../core/service/auth';
import StashTreeModal from './stashView/StashTree-modal';
import RuleSetModal from '../project/modals/ruleset-modal';
import { UTCtoLocalTime } from '../../core/service/timezone';

var user = JSON.parse(localStorage.getItem('users'));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

var GET_REVIEW_BY_ID = gql`
    query reviewById($id: String, $isPreviousResponse: Boolean, $previousResponseCount: Int,  $isApprovel: Boolean, $manualReviewStatus: String) {
        review: reviewById(id: $id) {
            id
            projectName
            projectId
            reviewRefId
            reviewUrl
            repoGroupName
            repoName
            sourceBranchName
            manualReviewActions
            {
                message
                user {
                    id
                    name
                    username
                }
                type
                createdOn
              }
            hasManualComment
            destinationBranchName
            pullRequestId
            commitId
            lastReviewFailureMessage
            manualReviewStatus
            status
            requestedUser {
                id
                username
                name
                emailId
            }
            author {
                name
                emailAddress
                displayName
            }
            committer {
                name
                emailAddress
                displayName
            }
            jiraIds
            committerMessage
            commits {
                id
                authorTimestamp
                committerTimestamp
                message
                properties {
                    jiraKey
                }
                author {
                    name
                    emailAddress
                    displayName
                }
                committer {
                    name
                    emailAddress
                    displayName
                }
                properties {
                    jiraKey
                }
            }
            createdOn
            modifiedOn
            isRepoDeleted
          }
          responses: reviewResponsesById(id: $id,  isPreviousResponse: $isPreviousResponse, previousResponseCount: $previousResponseCount, showOnlyInReviewComments: $isApprovel, manualReviewStatus: $manualReviewStatus) {
              reviewResponseId
              reviewRequestId
              analyzedFiles
              excludedFiles
              unSupportedFiles
              numOfAnalyzedFiles
              numOfLinesAdded
              requestedSource
              hasComments
              numOfComments
              numberOfAttempts
              nextResponseId
              languagePercentage {
                language
                percentage
              }
              commentsByFilePath {
                filePath
                isExcluded
                isUnSupported
                manualComment
                repoManualComment
                numOfManualComments
                numOfRepoManualComments
                isFirstAttempt
                comments {
                  type
                  commentId
                  manualComment
                  repoManualComment
                  user {
                    id
                    name
                    username
                  }
                  createdOn
                  checkName
                  ruleOptions
                  description
                  categories
                  location {
                    path
                    lines {
                      begin
                      end
                    }
                  }
                  status
                  reviewFailed
                  severity
                  actions {
                    message
                    user {
                        id
                        name
                        username
                    }
                    type
                    createdOn
                  }
                  remediationPoints
                }
              }
              createdOn
              changedFiles
            }
    }
`;

const ADD_REVIEW = gql`
mutation ($reviewUrl: String!, $userId: String!, $userName: String!, $fullName: String!) {
    review(reviewUrl: $reviewUrl, userId: $userId, userName: $userName, fullName: $fullName){
        id
        reviewUrl
    }
}
`;

class ReviewDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            message: 'Copied!',
            showPopup: null,
            request: false,
            id: this.props.match.params.id,
            approve: this.props.match.params.approve,
            values: this.props.values,
            statisticOpen: false,
            commitsOpen: false,
            committersOpen: false,
            manualApprovedOpen: false,
            manualRejectedOpen: false,
            manualAddressedOpen: false,
            showTreeModal: false,
            showRuleSetModal: false,
            isPreviousResponse: false,
            previousResponseCount: 1,
            defaultFilePath: undefined,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getJiraKeys = this.getJiraKeys.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.handleCommitsDialog = this.handleCommitsDialog.bind(this);
        this.handleCommittersDialog = this.handleCommittersDialog.bind(this);
        this.handlePopupClick = this.handlePopupClick.bind(this);
        this.handlePopupClose = this.handlePopupClose.bind(this);
        this.handleManualApprovedDialog = this.handleManualApprovedDialog.bind(this);
        this.handleManualRejectedDialog = this.handleManualRejectedDialog.bind(this);
        this.handleManualAddressedDialog = this.handleManualAddressedDialog.bind(this);
        this.getReverseOrder = this.getReverseOrder.bind(this);
        this.handelTreeModal = this.handelTreeModal.bind(this);
        this.handleRuleConfigurationDialog = this.handleRuleConfigurationDialog.bind(this);
    }

    handelTreeModal(filePath) {
        if (filePath && !filePath.dispatchConfig) {
            this.setState({
                defaultFilePath: filePath,
            })
        }
        this.setState({
            showTreeModal: !this.state.showTreeModal
        })
    }

    handleDialog(event) {
        this.setState({
            statisticOpen: !this.state.statisticOpen
        })
    }

    handleCommitsDialog(event) {
        this.setState({
            commitsOpen: !this.state.commitsOpen
        })
    }

    handleCommittersDialog(event) {
        this.setState({
            committersOpen: !this.state.committersOpen
        })
    }

    handleManualApprovedDialog() {
        this.setState({
            manualApprovedOpen: !this.state.manualApprovedOpen
        })
    }

    handleRuleConfigurationDialog(projectId) {
        if (projectId) {
            this.project = projectId;
            this.setState({ showRuleSetModal: true });
        } else {
            this.setState({ showRuleSetModal: false });
        }
    }

    handleManualRejectedDialog() {
        this.setState({
            manualRejectedOpen: !this.state.manualRejectedOpen
        })
    }

    handleManualAddressedDialog(isReviewComments, isApprover) {
        this.setState({
            manualAddressedOpen: !this.state.manualAddressedOpen,
            isReviewComments: isReviewComments,
            approvePR: isApprover
        })
    }

    handlePopupClose() {
        this.setState({ showPopup: null });
    }

    handlePopupClick(event) {
        this.setState({ showPopup: event.currentTarget, request: true });
    }

    handleClick(value) {
        var copyText = value;
        copyText = copyText.replace(/&amp;/g, '&');
        var textField = document.createElement('textarea');
        textField.innerText = copyText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({ open: true });
    }

    getJiraKeys(data) {
        var jiraKeys = [];
        if (data.jiraIds && data.jiraIds.length) {
            jiraKeys = data.jiraIds.map((key) => {
                return key;
            })
        } else if (data.commits && data.commits.length) {
            data.commits.forEach((commit) => {
                if (commit.properties && commit.properties.jiraKey && commit.properties.jiraKey.length) {
                    commit.properties.jiraKey.forEach((key) => {
                        jiraKeys.push(key);
                    });
                } else {
                    return '';
                }
            })
        }

        return [...new Set(jiraKeys)];
    }

    getReverseOrder(data) {
        var dd = JSON.parse(JSON.stringify(data));
        return dd.reverse();
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ open: false });
    }

    render() {
        return (
            <div className="container-fluid px-4">
                <Query query={GET_REVIEW_BY_ID} fetchPolicy="no-cache" variables={{ id: this.state.id, isPreviousResponse: this.state.isPreviousResponse, previousResponseCount: this.state.previousResponseCount, responseId: null, isApprovel: this.state.approve || false, manualReviewStatus: this.state.manualReviewStatus }}>
                    {({ loading, error, data, refetch }) => {
                        var iconToShow;
                        if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                        if (error) return <Error404 />
                        if (data.review === null) return <Error404 />
                        if (data.review.status === 'Completed') {
                            if (data.review.manualReviewStatus) {
                                iconToShow = <i className="material-icons align-middle text-primary" title={data.review.status + ' (' + data.review.manualReviewStatus + ')'}>done_all</i>
                            } else {
                                iconToShow = <i className="material-icons align-middle text-success" title={data.review.status}>done_all</i>
                            }
                        } else {
                            if (data.review.manualReviewStatus) {
                                iconToShow = <i className="material-icons align-middle text-primary" title={data.review.status + ' (' + data.review.manualReviewStatus + ')'}>create</i>
                            } else {
                                iconToShow = <i className="material-icons align-middle text-danger" title={data.review.status}>create</i>
                            }
                        }
                        return (

                            <div className="review-details">
                                <ReviewStatistic showModal={this.state.statisticOpen} onUpdate={this.handleDialog} modalData={data.responses} reviews={data.review} />
                                <ReviewCommitters showModal={this.state.committersOpen} onUpdate={this.handleCommittersDialog} modalData={data.responses} reviews={data.review} />
                                {data.review.commitId !== null ? <ReviewCommitId showModal={this.state.commitsOpen} onUpdate={this.handleCommitsDialog} reviews={data.review} />
                                    : <ReviewCommits showModal={this.state.commitsOpen} onUpdate={this.handleCommitsDialog} modalData={data.responses} commits={data.review.commits} />}
                                <ManualCommentsAddressed showModal={this.state.manualAddressedOpen} isReviewComments={this.state.isReviewComments} isApprovel={this.state.approvePR || this.state.approve} data={data} reviewId={data.review.id} isManualReviewStatus={data.review.manualReviewStatus} refresh={() => { refetch() }} onUpdate={this.handleManualAddressedDialog} />
                                <div className="review-details-fixed-header">
                                    <h4>
                                        <div className="row">
                                            <div className="col-md-9 review-button">
                                                <span className="mr-3">Review Details</span>
                                                <span className={"mr-2 badge badge-" + (data.review.status === 'Completed'
                                                    ? 'success'
                                                    : data.review.status === 'In Progress'
                                                        ? 'warning'
                                                        : 'danger'
                                                )}>
                                                    {data.review.status}
                                                </span>
                                                {data.review.manualReviewStatus &&
                                                    <span className="mr-2 badge badge-primary">{data.review.manualReviewStatus}</span>
                                                }
                                                {this.state.request && <Popover
                                                    open={Boolean(this.state.showPopup)}
                                                    anchorEl={this.state.showPopup}
                                                    onClose={this.handlePopupClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}>
                                                    <List>
                                                        <ListItemLink onClick={this.handleManualAddressedDialog}>
                                                            <ListItemText primary="Manual Comments Addressed" />
                                                        </ListItemLink>
                                                    </List>
                                                </Popover>}
                                            </div>
                                            <div className="col-md-3 col-12 text-right">
                                                {data.review && data.review.lastReviewFailureMessage &&
                                                    <Tooltip title={data.review.lastReviewFailureMessage} placement="top">
                                                        <i className="material-icons text-danger mr-3" style={{ cursor: "pointer" }}>error</i>
                                                    </Tooltip>
                                                }
                                                {data.review.manualReviewStatus && data.review.manualReviewStatus === 'Manual Review Required' && Auth.hasRole(user, ['ROLE_APPROVER']) && <Tooltip title="Approve Review" placement="top">
                                                    <i className="material-icons text-primary mr-3" style={{ cursor: "pointer" }} onClick={() => { this.handleManualAddressedDialog(false, true) }}>check_circle_outline</i>
                                                </Tooltip>}
                                                {data.review.manualReviewStatus && data.review.manualReviewStatus === 'Manual Comments Addressed' && <Tooltip title="Approve / Reject Manual Review" placement="top">
                                                    <i className="material-icons text-primary mr-3" style={{ cursor: "pointer" }} onClick={() => { this.handleManualAddressedDialog() }}>check_circle_outline</i>
                                                </Tooltip>}
                                                {data.review.manualReviewStatus && data.review.manualReviewStatus === 'Manual Comments Added' && <Tooltip title="Request to approve manual comments" placement="top">
                                                    <i className="material-icons text-primary mr-3" style={{ cursor: "pointer" }} onClick={() => { this.handleManualAddressedDialog() }}>check_circle_outline</i>
                                                </Tooltip>}
                                                {data.review.manualReviewStatus && data.review.manualReviewStatus === 'Manual Review Completed' && <Tooltip title="Manual Review Audits" placement="top">
                                                    <i className="material-icons text-success mr-3" style={{ cursor: "pointer" }} onClick={() => { this.handleManualAddressedDialog() }}>check_circle_outline</i>
                                                </Tooltip>}
                                                {!!(data.responses[0] && data.responses[0].numOfComments && !this.state.approve) && <Tooltip title="Bulk Ignore Comments" placement="top">
                                                    <i className="material-icons text-secondary mr-3" style={{ cursor: "pointer" }} onClick={() => { this.handleManualAddressedDialog(true, false) }}>report_off</i>
                                                </Tooltip>}
                                                {!!(data.responses[0] && data.responses[0].numOfComments && this.state.approve) && <Tooltip title="Bulk Approve Comments" placement="top">
                                                    <i className="material-icons text-primary mr-3" style={{ cursor: "pointer" }} onClick={() => { this.handleManualAddressedDialog(true, true) }}>done_all</i>
                                                </Tooltip>}
                                                <Mutation mutation={ADD_REVIEW} onCompleted={() => { refetch(); this.setState({ message: 'Success', statusWatcher: true, approveWatcher: true, rejectWatcher: true }) }}>
                                                    {(review, { loading, error }) => {
                                                        if (error) {
                                                            var errorMessage = '';
                                                            if (error.message && error.message.includes('401')) {
                                                                errorMessage = "The user configured for the project repository doesn't have sufficient privilege to read the code. Please contact the administrator."
                                                            } else if (error.message && error.message.includes('UnknownHostException')) {
                                                                errorMessage = "VPN is not configured in the server. Please contact the administrator."
                                                            } else {
                                                                errorMessage = error.message.split(":")[2];
                                                            }
                                                            return (<Tooltip title={errorMessage} placement="top"><i className="material-icons text-primary mr-3" style={{ cursor: "pointer" }} onClick={() => {
                                                                var reviewRequest = {};
                                                                reviewRequest.reviewUrl = data.review.reviewUrl;
                                                                reviewRequest.userId = data.review.requestedUser.id;
                                                                reviewRequest.userName = data.review.requestedUser.username;
                                                                reviewRequest.fullName = data.review.requestedUser.name;
                                                                review({ variables: { ...reviewRequest } })
                                                            }}>report</i>
                                                            </Tooltip>
                                                            )

                                                        }
                                                        return (<Tooltip title="Resubmit the Request" placement="top">

                                                            {loading
                                                                ? <CircularProgress size={24} className="mb-1 mr-3" />
                                                                : <i className="material-icons text-primary mr-3" style={{ cursor: "pointer" }} onClick={() => {
                                                                    var reviewRequest = {};
                                                                    reviewRequest.reviewUrl = data.review.reviewUrl;
                                                                    reviewRequest.userId = data.review.requestedUser.id;
                                                                    reviewRequest.userName = data.review.requestedUser.username;
                                                                    reviewRequest.fullName = data.review.requestedUser.name;
                                                                    review({ variables: { ...reviewRequest } })
                                                                }} >flip_camera_android</i>
                                                            }
                                                        </Tooltip>
                                                        )
                                                    }}
                                                </Mutation>
                                                <Tooltip title="Current Rule Configuration" placement="top">
                                                    <i className="material-icons text-primary mr-3" style={{ cursor: "pointer" }} onClick={(e) => this.handleRuleConfigurationDialog(data.review.projectId)}>vertical_split</i>
                                                </Tooltip>
                                                <Tooltip title="Review Statistics" placement="top">
                                                    <i className="material-icons text-primary" style={{ cursor: "pointer" }} onClick={() => { this.handleDialog() }}>assessment</i>
                                                </Tooltip>
                                                <Tooltip className="refresh" title="Refresh" placement="top">
                                                    <i className="material-icons text-primary" style={{ cursor: "pointer" }} onClick={() => { refetch() }}>refresh</i>
                                                </Tooltip>
                                                <Tooltip title="Close" placement="top">
                                                    <Link to={{ pathname: this.props.location.state && this.props.location.state.fromManualReviewGrid ? `/reviews/manualReviewStatus` : (this.props.location.state && this.props.location.state.fromApproverGrid ? `/reviews/approve` : `/reviews`), state: { fromClose: true }, style: { textDecoration: 'none' } }}><i className="material-icons text-primary">clear</i></Link>
                                                </Tooltip>

                                            </div>
                                        </div>
                                    </h4>
                                    {data.review.manualReviewStatus && data.review.manualReviewStatus === 'Manual Comments Added' && <div className="col-md-12 text-success alert-style"><span><i className="material-icons text-default">info</i> Please don't forget to click the <i className="material-icons text-primary">check_circle_outline</i> icon after you addressed all manual comments to notify the reviewers!</span></div>}
                                    {data.review.manualReviewStatus && data.review.manualReviewStatus === 'Manual Review Required' && Auth.hasRole(user, ['ROLE_APPROVER']) && <div className="col-md-12 text-success alert-style"><span><i className="material-icons text-default">info</i> Please don't forget to click the <i className="material-icons text-primary">check_circle_outline</i> icon if there are no comments for manual review!</span></div>}
                                    <hr></hr>
                                </div>
                                {this.state.statusWatcher && <StatusWatcher id={data.review.id} onConnect={client => { this.client = client }} isReviewStatus={true} disconnected={(data) => {
                                    this.setState({ open: data }, () => {
                                        refetch();
                                    })
                                }} />}
                                <div className="row m-2 p-2 review-comments-container shadow">
                                    <div className="col-lg-12 pb-3">
                                        <div className="row pt-4 ss">
                                            <div className="col-sm-4 h-100">
                                                <label>Review URL</label>
                                            </div>
                                            <div className="col-sm-9 h-100 icon-font-size d-flex">
                                                <span className="review-url-align">
                                                    <Tooltip placement="bottom" title={data.review.reviewUrl} id="placement">
                                                        <span onClick={(e) => this.handleClick(data.review.reviewUrl)}>{data.review.reviewUrl}</span>
                                                    </Tooltip>
                                                </span>
                                                <Tooltip title="Copy Url" placement="top">
                                                    <span className="ml-1" onClick={(e) => this.handleClick(data.review.reviewUrl)}><i className="material-icons align-middle">file_copy</i></span>
                                                </Tooltip>
                                            </div>
                                            <div className="col-sm-1">
                                                <Tooltip title="Total Commits" placement="top" onClick={() => { this.handleCommitsDialog() }}>
                                                    <span className="total total-cmt">{data.review.commits ? data.review.commits.length : 1}
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-4 col-6">
                                                <label>Request ID</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item>
                                                        {data.review.id}&nbsp;
                                                    </Grid>
                                                    <Grid item className="icon-font-size">
                                                        {iconToShow}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="col-lg-4 col-6">
                                                <label>Requested User</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                {data.review.requestedUser.name} <Tooltip title={data.review.requestedUser.emailId} placement="top">
                                                    <div className="email-id">({data.review.requestedUser.emailId})</div>
                                                </Tooltip>
                                            </div>
                                            <div className="col-lg-4 col-6">
                                                <label>Project Name</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                {data.review.projectName}
                                            </div>
                                            <div className="col-lg-4 col-6">
                                                <label>Repo Name</label>
                                            </div>
                                            {data.review.isRepoDeleted &&
                                                <div className="col-lg-8 col-6 crossed-line">
                                                    {data.review.repoName}
                                                </div>}
                                            {!data.review.isRepoDeleted &&
                                                <div className="col-lg-8 col-6">
                                                    {data.review.repoName}
                                                </div>}
                                            <div className="col-lg-4 col-6">
                                                <label>Repo Group Name</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                {data.review.repoGroupName}
                                            </div>
                                            <div className="col-lg-4 col-6">
                                                <label>Created On</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                {UTCtoLocalTime(data.review.createdOn, 'YYYY-MMM-DD HH:mm A')}
                                            </div>
                                            <div className="col-lg-4 col-6">
                                                <label>Modified On</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                {(data.review.modifiedOn) ? UTCtoLocalTime(data.review.modifiedOn, 'YYYY-MMM-DD HH:mm A') : '-'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-4 col-6">
                                                <label>Status</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                {data.review.status}
                                            </div>
                                            <div className="col-lg-4 col-6">
                                                <label>Commit ID</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                <Tooltip title={data.review.commitId || ''} placement="bottom" id="placement">
                                                    <span >{data.review.commitId || '-'}</span>
                                                </Tooltip>
                                            </div>
                                            <div className="col-lg-4 col-6">
                                                <label>Pull Request ID</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                {data.review.pullRequestId || '-'}
                                            </div>
                                            <div className="col-lg-4 col-6">
                                                <label>Source Branch Name</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                {data.review.sourceBranchName || '-'}
                                            </div>
                                            <div className="col-lg-4 col-6">
                                                <label>Destination Branch Name</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                {data.review.destinationBranchName || '-'}
                                            </div>
                                            <div className="col-lg-4 col-6">
                                                <label>Total Reviews</label>
                                            </div>
                                            <div className="col-lg-8 col-6">
                                                {(data.responses && data.responses[0] && data.responses[0].numberOfAttempts) || '-'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                       <div className="row mt-2">
                                             <div className="col-sm-11 review-details-jiraId">
                                                {this.getJiraKeys(data.review).map((key, index) => {
                                                    var workflowurl = 'https://workflow.ghx.com/browse/' + key;
                                                    return key !== '' ?
                                                        <a key={index} href={workflowurl} target="_blank" rel="noopener noreferrer">
                                                            <Chip color="primary" key={index}
                                                                className="m-1 cursor-pointer" label={key}
                                                                avatar={
                                                                    <Avatar>
                                                                        <ConfirmationNumberIcon />
                                                                    </Avatar>
                                                                } /></a>
                                                        : '';
                                                })}
                                            </div> 
                                            <div className="col-sm-1">
                                                <Tooltip title="Total Committers" placement="bottom"
                                                    onClick={() => { this.handleCommittersDialog() }}>
                                                    <span className="total total-commiters">{authorService.getAuthor(data.review).length}</span>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    open={this.state.open}
                                    autoHideDuration={2000}
                                    onClose={this.handleClose}
                                    ContentProps={{
                                        'aria-describedby': 'message-id',
                                    }}
                                    message={<span id="message-id">{this.state.message ? this.state.message : "Copied!"}</span>}
                                    action={[
                                        <Button key="undo" color="secondary" size="small" onClick={this.handleClose}>
                                            Ok
                                        </Button>,
                                    ]}
                                />
                                <div className="col-md-12">
                                    {data.responses && data.responses.length
                                        ? <LanguageBar readings={data.responses[data.responses.length - 1].languagePercentage} />
                                        : null}

                                </div>
                                {(!data.responses.length) ? '' :
                                    <div className="row mt-3">
                                        <div className="col-md-11 col-6">
                                            <h4 className="ml-0 mt-4" >Comments</h4>
                                        </div>
                                        <div className="col-md-1 col-6">
                                            <div className="w-100 pl-3">
                                                <div className="treeView-icon">
                                                    <Tooltip title="Tree View">
                                                        <Button color="primary" size="small" onClick={this.handelTreeModal} variant="outlined" aria-label="add">
                                                            <AccountTreeOutlinedIcon />
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <StashTreeModal showModal={this.state.showTreeModal} defaultFilePath={this.state.defaultFilePath} data={data.review.reviewUrl} onUpdate={this.handelTreeModal} state={this.state.approve} reviewId={data.review.id} handlePreviousResponse={() => { this.handlePreviousResponse() }} response={data.responses[0]} onRefresh={() => { refetch() }} />
                                <ReviewResponse responses={this.state.approve ? [data.responses[0]] : data.responses} diffUrl={data.review.reviewUrl} state={this.state.approve} reviewId={data.review.id} refreshPage={() => { refetch() }}
                                    isApprovel={this.state.approve || false} previousResponseCount={this.state.previousResponseCount} manualReviewStatus={this.state.manualReviewStatus} treeView={(filePath) => { this.handelTreeModal(filePath) }}></ReviewResponse>
                                <RuleSetModal subtitle={"Current Configuration"} showModal={this.state.showRuleSetModal} onUpdate={this.handleRuleConfigurationDialog} reviewFlag={true} review={this.project} refresh={() => refetch()} />
                            </div>
                        );
                    }}
                </Query>
            </div>
        );
    }
}

export default ReviewDetails;