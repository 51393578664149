import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import examplePDF from './usermanual.pdf';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

export default function UserManual(props) {
  
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="all-page-container">
      <Document
        file={examplePDF}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          window.innerWidth > 800
            ? <Page width={900} key={`page_${index + 1}`} pageNumber={index + 1} />
            : <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))
        }
      </Document>
    </div>
  );
}