import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbars from '../../core/error/Snackbars';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import InfoIcon from '@material-ui/icons/Info';
import Select from '@material-ui/core/Select';
import Stepper from '@material-ui/core/Stepper';
import { Link } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import projectServices from './services/project-service';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import './project.css';

var repoAccessToken = '';
var ruleConfigRequest = {};
var projectId = '';
var projectGroupObj = [];

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const GET_LANGUAGE_LIST = gql`
query languages {
    languages {
        languageName
        numOfRules
        description
        enabled
        extensions
        analyzerEngines
      }
}
`;

const ADD_PROJECT = gql`
    mutation updateProject(
        $id: Long,
        $projectGroupId: Long!,
        $projectName: String!,
        $projectKey: String!,
        $repoGroupName: String!,
        $repoName: String!,
        $excludePattern: [String],
        $versionControl: String!,
        $repoUserToken: String,
        $branchExclutionsPattern:String!
        ) {
        updateProject(
            id: $id,
            projectGroupId: $projectGroupId,
            projectName: $projectName,
            projectKey: $projectKey,
            repoGroupName: $repoGroupName,
            repoName: $repoName,
            excludePattern: $excludePattern,
            versionControl: $versionControl,
            repoUserToken: $repoUserToken,
            branchExclutionsPattern :$branchExclutionsPattern
            ) {
            id
        }
    }
`;

const UPDATE_PROJECT_RULES_BY_LANGUAGES = gql`
    mutation updateProjectLanguages(
        $projectId: Long!,
        $languageNames: [String]!,
        ) {
        updateProjectLanguages(
            projectId: $projectId,
            languageNames: $languageNames,
            )
    }
`;

const GET_PROJECT_GROUP_LIST = gql`
query projectGroups($pageSize: Int, $pageNumber: Int, $filterBy: ProjectGroupInput) {
    projectGroups(pageNumber: $pageNumber, pageSize: $pageSize, filterBy: $filterBy) {
        content {
          __typename
          ... on ProjectGroup {
            id
            projectGroupName
            repoAccessToken
            }
        }
        totalPages
    }
}
`;

const GET_PROJECT = gql`
    query projectById($id: Long!) {
        projectById(id: $id) {
            id
            projectName
            projectKey
            versionControl
            repoGroupName
            repoName
            excludePattern
            repoLanguages
            repoUserToken
            branchExclutionsPattern
            projectGroupId
            projectGroupName
        }
    }
`;

const GET_PROJECT_GROUP_ID = gql`
    query projectGroupById($id: Long!) {
        projectGroupById(id: $id) {
            id
            projectGroupName
            repoAccessToken
        }
    }
`;

class ProjectEdit extends Component {
    constructor(props, context) {
        super(props, context);
        this.projectService = new projectServices();
        this.state = {
            step: 0,
            hasError: false,
            projectGroupError: false,
            languageError: false,
            projectGroupId: '',
            projectName: '',
            projectKey: '',
            repoGroupName: '',
            repoName: '',
            languageNames: [],
            languages: [],
            repoUserToken: '',
            excludePattern: '',
            repoAccessToken: '',
            repoUrl: '',
            versionControl: '',
            useGlobalRuleset: true,
            useOrganizationRuleset: false,
            useRepoGroupRuleset: false,
            id: null,
            disabled: false,
            submitted: false,
            isValidateToken: null,
            isLoading: false,
            selectedLanguageChanged: false,
            branchExclutionsPattern:''
        };
        this.handleDialog = this.handleDialog.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputToken = this.handleInputToken.bind(this);
        this.getRuleSet = this.getRuleSet.bind(this);
        this.handleAddDialogClose = this.handleAddDialogClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.removeLanguage = this.removeLanguage.bind(this);
    }

    removeLanguage(chip) {
        this.setState({
            languages: this.state.languages.filter(el => el !== chip),
            selectedLanguageChanged: true
        })
    }

    getRuleSet() {
        this.setState({
            ruleSetLanguage: this.state.languageNames,
            languageError: false
        });
        this.state.languageNames.forEach(name => {
            ruleConfigRequest[name] = [];
        });
    }

    updateRuleSet(data) {
        var tLang = data[0].rules[0].languageName;
        ruleConfigRequest[tLang] = data;
    }

    getInitialState() {
        return {
            isDialog: false,
        };
    }

    handleDialog() {
        this.setState({
            step: 1,
        });
        for (var key in this.state.lastData) {
            this.setState({
                [key]: this.state.lastData[key]
            });
        }
    }

    handleDialogClose() {
        this.setState({
            step: 0,
            languageError: false,
            languageNames: [],
        });
        this.props.onUpdate();
        this.props.refresh();
    }

    handleAddDialogClose() {
        this.setState({
            step: 0,
            projectGroupId: '',
            projectName: '',
            projectKey: '',
            repoGroupName: '',
            repoName: '',
            repoUserToken: '',
            excludePattern: '',
            repoAccessToken: '',
            repoUrl: '',
            languageNames: [],
            isValidateToken: null,
            hasError: false,
            versionControl: 'stash',
            branchExclutionsPattern:''
        });
        this.props.onUpdate();
        this.props.refresh();
    }

    componentDidMount() {
        // custom rule will have name 'isKeyLength'
        //this.props.onRef(this);
        ValidatorForm.addValidationRule('isKeyLength', (value) => {
            if (value.length !== 4) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isNameLength', (value) => {
            if (value.length < 4 || value.length > 100) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isOrganizationLength', (value) => {
            if (value.length < 3 || value.length > 100) {
                return false;
            }
            return true;
        });
    }

    handleInputChange(event, repoUserToken) {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'select' ? target.checked : target.value;
        const name = target.name;
        
        if (target.name === 'projectGroupId') {
            this.value = event.target.value;
        }

        this.setState({
            [name]: value, hasError: false
        });

        if (name === 'repoUrl' || (name === 'repoGroupName' && name === 'repoName')) {
            var repoNamet = '(?<=repos/).*';
            var repoName = '(?<=repos/).*?(?=/)';
            var repoGroupName = '(?<=projects/).*?(?=/)';
            if (value.match(repoName) && value.match(repoName).length) {
                this.setState({
                    repoGroupName: value.match(repoGroupName)[0],
                    repoName: value.match(repoName)[0],
                    projectName: value.match(repoGroupName)[0] + "-" + value.match(repoName)[0],
                    projectKey: (value.match(repoGroupName)[0] + value.match(repoName)[0]).substring(0, 4).toUpperCase().replace(' ', '').replace('-', '')
                });
            } else if (value.match(repoNamet) && value.match(repoNamet).length) {
                this.setState({
                    repoGroupName: value.match(repoGroupName)[0],
                    repoName: value.match(repoNamet)[0],
                    projectName: value.match(repoGroupName)[0] + "-" + value.match(repoNamet)[0],
                    projectKey: (value.match(repoGroupName)[0] + value.match(repoNamet)[0]).substring(0, 4).toUpperCase().replace(' ', '').replace('-', '')
                });
            }

            var tokenParams = {
                repoGroupName: value.match(repoGroupName) && value.match(repoGroupName)[0],
                repoName: (value.match(repoName) && value.match(repoName)[0]) || (value.match(repoNamet) && value.match(repoNamet)[0]),
                repoAccessToken: repoUserToken,
                versionControl: this.state.versionControl,
            }
            if (tokenParams) {
                this.setState({ isLoading: true })
                return this.projectService.getProjectRepoToken(tokenParams).then((response) => {
                    if (response) {
                        this.setState({ isLoading: false })
                        this.setState({ isValidateToken: response.data })
                        return response;
                    }
                });
            }
        }

        if (name === 'repoGroupName' && name === 'repoName') {
            this.setState({
                projectName: repoGroupName,
                projectKey: repoName
            });
        }

        if (name === 'projectName' && !this.state.id) {
            this.setState({
                projectKey: value.substring(0, 4).toUpperCase().replace(' ', '').replace('-', '')
            });
        }

        if (name === 'projectKey') {
            this.setState({
                projectKey: value.substring(0, 4).toUpperCase().replace(' ', '').replace('-', ''),
            });
        }

        if (this.state.projectGroupId) {
            this.setState({ hasError: false });
        }
    }

    handleProjectGroup(event, projectGroupList) {
        var repoUserToken = null;
        if (!this.state.repoUserToken && !this.props.id) {
            projectGroupList.forEach(data => {
                if (data.id === event.target.value) {
                    repoUserToken = data.repoAccessToken;
                }
            })
            var tokenQueryParams = {
                repoGroupName: this.state.repoGroupName,
                repoName: this.state.repoName,
                repoAccessToken: repoUserToken,
                versionControl: this.state.versionControl || 'gitlab'
            }
            if (tokenQueryParams) {
                this.setState({ isLoading: true })
                return this.projectService.getProjectRepoToken(tokenQueryParams).then((response) => {
                    if (response) {
                        this.setState({ isLoading: false })
                        this.setState({ isValidateToken: response.data })
                        return response;
                    }
                });
            }
        }
    }
    handleInputToken(repoGroupName, repoName, repoUserToken) {
        var tokenParams = {
            repoGroupName: repoGroupName,
            repoName: repoName,
            repoAccessToken: repoUserToken,
            versionControl: this.state.versionControl
        }
        if (tokenParams) {
            this.setState({ isLoading: true })
            return this.projectService.getProjectRepoToken(tokenParams).then((response) => {
                if (response) {
                    this.setState({ isLoading: false })
                    this.setState({ isValidateToken: response.data })
                    return response;
                }
            });
        }
    }

    handleSubmit() {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });
    }

    submit = () => {
        this.projectGroupError = true;
        this.setState({ hasError: false });
        this.form.submit();
        let { step } = this.state;
        if (step < 0) {
            step++;
        }
        this.setState({ step });
        if (!this.state.projectGroupId) {
            this.setState({ hasError: true });
        }
    }

    languageSubmit = () => {
        if (!this.state.languageNames || !this.state.languageNames
            || ((this.state.languageNames && this.state.languageNames.length === 0)
                && (this.state.languages && this.state.languages.length === 0))) {
            this.setState({ hasError: true });
        } else if (this.state.languageNames.length > 0) {
            this.setState({ languageError: true });
            this.form.submit();
        } else {
            this.setState({ languageError: true });
            this.form.submit();
        }
    }

    prevStep = () => {
        let { step } = this.state;
        if (step > 0) {
            step--;
        }
        this.setState({ step });
    }

    nextStep = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                let { step } = this.state;
                if (step < 1) {
                    step++;
                }
                this.setState({ step });
            }
        });
    }

    validatorListener = (result) => {
        this.setState({ disabled: !result });
    }

    renderStep = () => {
        const { step, hasError } = this.state;

        let content = null;
        switch (step) {
            case 0:
                content = [
                    <div key={step}>
                        <div className="row mt-5">
                            <div className="col-md-6 mb3">
                                <Query query={GET_PROJECT_GROUP_LIST} fetchPolicy="no-cache" variables={{ pageSize: 1000, pageNumber: 0 }}>
                                    {({ loading, error, data }) => {
                                        if (data && data.projectGroups && data.projectGroups.content) {
                                            projectGroupObj = data.projectGroups.content;
                                        }
                                        if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                                        if (error) return <div className="text-center text-danger">
                                            Service Not Found!
                                        </div>
                                        return (
                                            <FormControl className="project mb3" error={hasError}>
                                                <InputLabel>Project Group Name</InputLabel>
                                                <Select
                                                    name="projectGroupId"
                                                    value={this.state.projectGroupId || ''}
                                                    onChange={event => { this.handleInputChange(event, this.state.repoAccessToken); this.handleProjectGroup(event, projectGroupObj) }}
                                                >
                                                    {data.projectGroups.content.map(name => (
                                                        <MenuItem key={name.id} value={name.id}>{name.projectGroupName}</MenuItem>
                                                    ))}
                                                </Select>
                                                {hasError && <FormHelperText>This is required!</FormHelperText>}
                                                {data.projectGroups.content.length === 0 && <FormHelperText className="Mui-error">No Record Found!</FormHelperText>}
                                            </FormControl>
                                        );
                                    }}
                                </Query>
                            </div>
                            <div className="col-md-6 mb-3">
                                <FormControl className="project">
                                    <InputLabel>Version Control</InputLabel>
                                    <Select value={this.state.versionControl} disabled onChange={this.handleInputChange}>
                                        <MenuItem value="">Select Version Control</MenuItem>
                                        <MenuItem value="stash">stash</MenuItem>
                                        <MenuItem value="gitlab">gitlab</MenuItem>
                                        <MenuItem value="azuregit">azuregit</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <TextValidator type="text"
                                    name="repoGroupName"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label="Repository Group Name"
                                    value={this.state.repoGroupName}
                                    onChange={this.handleInputChange}
                                    errorMessages={['This field is Required']}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <TextValidator type="text"
                                    name="repoName"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label="Repository Name"
                                    value={this.state.repoName}
                                    onChange={this.handleInputChange}
                                    errorMessages={['This field is Required']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <TextValidator type="text"
                                    id="projectName"
                                    name="projectName"
                                    label="Project Name"
                                    value={this.state.projectName}
                                    disabled={this.state.id ? true : false}
                                    onChange={this.handleInputChange}
                                    validators={['required', 'isOrganizationLength']}
                                    errorMessages={['This field is Required', 'should be minimum 4 characters and maximum 100 characters']}
                                    validatorListener={this.validatorListener} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <TextValidator type="text"
                                    name="projectKey"
                                    label="Project Key"
                                    disabled={this.state.id ? true : false}
                                    value={this.state.projectKey}
                                    onChange={this.handleInputChange}
                                    validators={['required', 'isKeyLength']}
                                    errorMessages={['This field is Required', 'should be exactly 4 characters']}
                                    validatorListener={this.validatorListener} />
                            </div>
                        </div>
                        <div className="row">
                            {this.state.repoUserToken &&
                                <div className="col-md-6 mb-3">
                                    {!this.state.isLoading && this.state.repoUserToken && !this.state.isValidateToken && <Tooltip title="Need help on how to generate user token?" placement="top" style={{ position: 'absolute', right: 20, zIndex: 10, top: 20, width: 20, height: 20 }}>
                                        <Link href={
                                            (this.state.versionControl === 'gitlab') 
                                                ? "https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html"
                                                : "https://confluence.atlassian.com/bitbucketserver/personal-access-tokens-939515499.html"
                                        } target="_blank">
                                            <InfoIcon color="secondary" />
                                        </Link>
                                    </Tooltip>}
                                    <TextValidator type="text"
                                        name="repoUserToken"
                                        pattern="[a-zA-Z0-9]+"
                                        label="Personal Access Token"
                                        value={this.state.repoUserToken}
                                        onChange={event => { this.handleInputChange(event); this.handleInputToken(this.state.repoGroupName, this.state.repoName, event.target.value) }}
                                        validators={['required']}
                                        errorMessages={['This field is Required']}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                {!this.state.isLoading && this.state.repoUserToken && this.state.isValidateToken && <Tooltip title="User Token is Valid" placement="top"><IconButton className="success" type="submit" aria-label="Token True">
                                                    <CheckCircleOutlineIcon />
                                                </IconButton></Tooltip>}
                                                {this.state.isLoading && <div className="loading"><CircularProgress size={15} /></div>}
                                            </InputAdornment>,
                                        }}
                                        validatorListener={this.validatorListener} />
                                </div>
                            }
                            {(this.state.projectGroupId && !this.state.repoUserToken) &&
                                <div className="col-md-6 mb-3">
                                    <Query query={GET_PROJECT_GROUP_ID} fetchPolicy="no-cache" variables={{ id: !this.state.id ? this.value : this.state.projectGroupId }}>
                                        {({ loading, error, data }) => {
                                            if (data && data.projectGroupById) {
                                                if (repoAccessToken !== data.projectGroupById.repoAccessToken) {
                                                    repoAccessToken = data.projectGroupById.repoAccessToken;
                                                    this.setState({
                                                        repoUserToken: data.projectGroupById.repoAccessToken
                                                    })
                                                }
                                            }
                                            if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                                            if (error) return <div className="text-center text-danger">
                                                Service Not Found!
                                        </div>
                                            return (
                                                <span>
                                                    <Tooltip title="Need help on how to generate user token?" placement="top" style={{ position: 'absolute', right: -7, top: 21, width: 20, height: 20 }}>
                                                        <Link href="https://confluence.atlassian.com/bitbucketserver/personal-access-tokens-939515499.html" target="_blank"><InfoIcon color="primary" /></Link>
                                                    </Tooltip>
                                                    <TextValidator type="text"
                                                        name="repoUserToken"
                                                        pattern="[a-zA-Z0-9]+"
                                                        label="Personal Access Token"
                                                        value={this.state.repoUserToken}
                                                        onChange={event => { this.handleInputChange(event); this.handleInputToken(this.state.repoGroupName, this.state.repoName, event.target.value) }}
                                                        validators={['required']}
                                                        errorMessages={['This field is Required']}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                {!this.state.isLoading && this.state.repoUserToken && this.state.isValidateToken === true && <Tooltip title="User Token is Valid" placement="top"><IconButton className="success" type="submit" aria-label="Token True">
                                                                    <CheckCircleOutlineIcon />
                                                                </IconButton></Tooltip>}
                                                                {!this.state.isLoading && this.state.repoUserToken && this.state.isValidateToken === false && <Tooltip title="User Token is not Valid" placement="top"><IconButton color="secondary" type="submit" aria-label="Token False">
                                                                    <CancelOutlinedIcon />
                                                                </IconButton></Tooltip>}
                                                                {this.state.isLoading && <div className="loading"><CircularProgress size={15} /></div>}
                                                            </InputAdornment>,
                                                        }}
                                                        validatorListener={this.validatorListener} />
                                                </span>
                                            );
                                        }}
                                    </Query>
                                    {!this.state.isLoading && !this.state.repoGroupName && this.state.repoUserToken && this.state.isValidateToken === false && <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled" id="projectName-helper-text">Repository Group Name is Required</p>}
                                    {!this.state.isLoading && this.state.repoName && this.state.repoGroupName && this.state.repoUserToken && this.state.isValidateToken === false && <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled" id="projectName-helper-text">Please provide valid user token</p>}
                                    {!this.state.isLoading && this.state.repoUserToken && this.state.isValidateToken === true && <p className="MuiFormHelperText-root success MuiFormHelperText-filled" id="projectName-helper-text">User Token Verified</p>}
                                </div>
                            }
                            <div className="col-md-6 mb-3">
                                <TextValidator type="text"
                                    name="excludePattern"
                                    label="Exclude Files Pattern"
                                    helperText={"Example: '.js,*.min.js,projectName/src/main/webapp/locale/' -- please use comma ' , '"}
                                    value={this.state.excludePattern}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <TextValidator type="text" name="branchExclutionsPattern"
                                    label={`Branch Exclutions`}
                                    helperText={"Example: '<source_br>.*:<destination1_br.*>,<destination2_br.*>;'"}
                                    value={this.state.branchExclutionsPattern}
                                    onChange={this.handleInputChange}
                                />
                            </div >
                      </div>

                    </div>
                ];
                break;
            case 1:
                content = [
                    <div key={step}>
                        <div className="row">
                            <div className="offset-md-3 col-md-6 mb3 mt-5">
                                <Query query={GET_LANGUAGE_LIST}>
                                    {({ loading, error, data, refetch }) => {
                                        if (loading) return null;
                                        if (error) return null;
                                        return (
                                            <FormControl error={hasError}>
                                                <InputLabel htmlFor="select-multiple-checkbox">Choose languages</InputLabel>
                                                <Select
                                                    multiple
                                                    value={this.state.languageNames}
                                                    onChange={event => {
                                                        this.setState({
                                                            languageNames: event.target.value,
                                                            hasError: false
                                                        }, () => {
                                                            refetch()
                                                        })
                                                    }
                                                    }
                                                    onClose={this.getRuleSet}
                                                    input={<Input id="select-multiple-checkbox" />}
                                                    renderValue={selected => selected.join(', ')}
                                                >
                                                    {data.languages.filter(name => {
                                                        return !(this.state.languages && this.state.languages.includes(name.languageName))
                                                    }).filter(data => {
                                                        return data.enabled
                                                    }).map(language => (
                                                        <MenuItem key={language.languageName} value={language.languageName}>
                                                            <ListItemIcon style={{ minWidth: "30px" }}>
                                                                <img src={"images/language/" + language.languageName + ".png"} width="20px" alt={language.languageName} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={language.languageName.toUpperCase()} />

                                                            <Checkbox checked={this.state.languageNames.indexOf(language.languageName) > -1} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {hasError && <FormHelperText>This is required!</FormHelperText>}
                                            </FormControl>
                                        )
                                    }}
                                </Query>
                                <div className="text-center mt-2">
                                    {this.state.languages && this.state.languages.map(name => {
                                        return (<Chip icon={<img src={"images/language/primary/" + name + ".png"} width="17px" alt={name} />} color="primary" className="chip" variant="outlined" key={name} label={name} onDelete={() => this.removeLanguage(name)} />)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                ];
                break;
            default: return content = [];
        }
        return content;
    }

    render() {
        const { step, disabled, submitted } = this.state;
        return (
            <Query fetchPolicy="no-cache" query={GET_PROJECT} variables={{ id: this.props.projectId || 0 }}>
                {({ loading, error, data, refetch }) => {
                    this.refetch = refetch;
                    this.isError = error;
                    if (!this.state.id || this.state.id !== data.projectById.id) {
                        if (data && data.projectById) {
                            this.state.languageError ? this.setState({
                                step: 0,
                                lastData: data.projectById,
                                projectGroupId: data.projectById && data.projectById.projectGroup && data.projectById.projectGroup.id,
                                languageNames: data.projectById && data.projectById.repoLanguages,
                                repoUserToken: data.projectById && data.projectById.repoUserToken,
                                versionControl: data.projectById && data.projectById.versionControl
                            }) :
                                this.setState({
                                    step: 0,
                                    lastData: data.projectById,
                                    projectGroupId: data.projectById && data.projectById.projectGroup && data.projectById.projectGroup.id,
                                    languages: data.projectById && data.projectById.repoLanguages,
                                    repoUserToken: data.projectById && data.projectById.repoUserToken,
                                    versionControl: data.projectById && data.projectById.versionControl
                                });
                            
                            for (var key in data.projectById) {
                                if (key === 'excludePattern') {
                                    this.setState({
                                        [key]: data.projectById[key] ? data.projectById[key].join(',') : ''
                                    });
                                } else {
                                    this.setState({
                                        [key]: data.projectById[key]
                                    });
                                }
                            }

                            var tokenParams = {
                                repoGroupName: data.projectById.repoGroupName,
                                repoName: data.projectById.repoName,
                                repoAccessToken: data.projectById.repoUserToken,
                                versionControl: data.projectById && data.projectById.versionControl || this.state.versionControl
                            }
                            if (tokenParams) {
                                this.setState({ isLoading: true })
                                this.projectService.getProjectRepoToken(tokenParams).then((response) => {
                                    if (response) {
                                        this.setState({ isLoading: false })
                                        this.setState({ isValidateToken: response.data })
                                        return response;
                                    }
                                });
                            }
                        }
                    }

                    return (
                        <Mutation mutation={this.state.step === 0 ? ADD_PROJECT : UPDATE_PROJECT_RULES_BY_LANGUAGES} onCompleted={() => this.state.step === 0 ? this.handleDialog() : this.handleDialogClose()}>
                            {(updateProjectLanguages, { loading, error, data }) => {
                                projectId = this.props.projectId;
                                if (data && data.updateProject && data.updateProject.id) {
                                    projectId = data.updateProject.id
                                }
                                return (
                                    <ValidatorForm id="projectAdd"
                                        ref={(r) => { this.form = r; }}
                                        onSubmit={e => {
                                            if (!this.state.id) {
                                                delete this.state.id;
                                            }
                                            this.setState({ submitted: true }, () => {
                                                setTimeout(() => this.setState({ submitted: false }), 1000);
                                            });
                                            const { id, projectName, projectKey, repoGroupName, repoName, versionControl, projectGroupId, repoUserToken } = this.state;
                                            const excludePattern = this.state.excludePattern ? this.state.excludePattern.split(',') : [];
                                            const branchExclutionsPattern = this.state.branchExclutionsPattern ? this.state.branchExclutionsPattern : "";
                                            updateProjectLanguages({
                                                variables: this.state.step === 0
                                                    ? { id, projectName, projectKey, repoGroupName, repoName, versionControl, projectGroupId, repoUserToken, excludePattern, branchExclutionsPattern }
                                                    : { projectId: projectId, languageNames: this.state.languageNames.concat(this.state.languages || []) }
                                            });
                                        }}
                                        instantValidate
                                    >
                                        <Dialog maxWidth={"md"} fullWidth={true}
                                            fullScreen={window.innerWidth < 600}
                                            open={this.props.showModal}>
                                            <DialogTitle id="customized-dialog-title" onClose={!this.state.id ? this.handleAddDialogClose : this.handleDialogClose}>{!this.state.id ? 'Add Repositories' : 'Edit Repositories'}</DialogTitle >
                                            {loading ? <LinearProgress color="primary" /> : <Divider />}
                                            <DialogContent className="project-edit-container">
                                                <Stepper activeStep={this.state.step} alternativeLabel className="p-0 pb-2 pt-2">
                                                    {['Repositories Details', 'Select Languages'].map((label, index) => (
                                                        <Step key={index}>
                                                            <StepLabel key={index}>{label}</StepLabel>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                                {loading && <div className="loading"><CircularProgress size={50} /></div>}
                                                {error &&
                                                    <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={false} />
                                                }
                                                {this.renderStep()}
                                            </DialogContent>
                                            <Divider />
                                            <DialogActions>
                                                <Button
                                                    variant="contained"
                                                    onClick={this.prevStep}
                                                    style={{ marginRight: '16px' }}
                                                    disabled={step === 0}
                                                >
                                                    previous
                                                </Button>
                                                {this.props.projectId
                                                    ? <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            this.setState({
                                                                step: 1
                                                            })
                                                        }}
                                                        disabled={step === 1}
                                                        style={{ marginRight: '16px' }}>
                                                        Next
                                                    </Button>
                                                    : ""
                                                }
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    type="submit"
                                                    onClick={() => step === 0 ? this.submit() : this.languageSubmit()}
                                                    disabled={disabled || submitted || (step === 0 && this.state.isValidateToken === false) ||
                                                        (step === 1 && ((this.state.languageNames && !this.state.languageNames.length) && (!this.state.selectedLanguageChanged)))}
                                                >
                                                    {
                                                        (submitted && 'Your form is submitted!')
                                                        || (step < 1 ? 'Save and Continue' : 'Submit')
                                                    }
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </ValidatorForm>
                                )
                            }}
                        </Mutation>
                    )
                }}
            </Query>
        )
    }
}

export default ProjectEdit
