const navBar = [
    {
        path: '/home',
        text: 'Home',
        icon: 'home',
        role: ['ROLE_EXECUTIVE', 'ROLE_ADMIN']
    },
    {
        path: '/reviews',
        text: 'Reviews',
        icon: 'rate_review',
        role: ['ROLE_USER', 'ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN']
    },
    {
        path: '/users',
        text: 'Users',
        icon: 'record_voice_over',
        role: ['ROLE_USER', 'ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN']
    },
    {
        path: '/projects',
        text: 'Projects',
        icon: 'pie_chart',
        role: ['ROLE_EXECUTIVE', 'ROLE_MANAGER', 'ROLE_ADMIN']
    },
    {
        path: '/admin',
        text: 'Admin',
        icon: 'dashboard',
        isSuperAdmin: true,
        role: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
]

export {
    navBar
} 