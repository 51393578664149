import React from 'react';
import ReactMinimalPieChart from 'react-minimal-pie-chart';

function CodeQualityChart(props) {

    return (
        <ReactMinimalPieChart
            animate={false}
            style={{ height: '200px' }}
            animationDuration={500}
            animationEasing="ease-out"
            cx={50}
            cy={80}
            background="#bfbfbf"
            data={[
                {
                    color: '#2F80ED',
                    title: 'Code Quality',
                    value: props.codeQuality,

                },

            ]}
            label={(chart) => {
                return props.codeQuality && props.codeQuality.toFixed(0) + '%';
            }}
            labelPosition={0}
            labelStyle={{
                fontFamily: 'sans-serif',
                fontSize: '16px'
            }}
            lengthAngle={-180}
            lineWidth={15}
            onClick={undefined}
            onMouseOut={undefined}
            onMouseOver={undefined}
            paddingAngle={0}
            radius={50}
            totalValue={100}
            rounded
            startAngle={0}
            viewBoxSize={[
                80,
                70
            ]}
        />
    )
}

export default CodeQualityChart;