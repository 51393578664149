import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';

import RuleSetByProject from './../../components/ruleset-modal/Index'
import './modal.css';

class RuleSetModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSn: false,
        }
        this.handleClose = this.handleClose.bind(this);
        this.copyText = this.copyText.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);

    }

    handleClose() {
        this.props.onUpdate();
        this.props.refresh();
    }

    closeSnackbar() {
        this.setState({
            openSn: false,
        })
    }

    copyText(event) {
        var copyText = event.target.innerHTML;
        var textField = document.createElement('textarea');
        textField.innerText = copyText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({ openSn: true })
    }



    render() {
        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.props.showModal}
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth={"lg"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">

                    <DialogTitle>Project Rule Configuration <div style={{fontSize: '10px'}}>{this.props.subtitle}</div></DialogTitle>
                    
                    <Divider />
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12 pt-4">
                               <RuleSetByProject reviewFlag={this.props.reviewFlag} projectId={(this.props.project && this.props.project.id) || this.props.review} project={this.props.project} />
                            </div>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            close
                        </Button>
                    </DialogActions>

                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.openSn}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Copied!</span>}
                    action={[
                        <Button key="undo" color="secondary" size="small" onClick={this.closeSnackbar}>
                            Ok
                    </Button>,
                    ]}
                />
            </div>
        );
    }
}

export default RuleSetModal;