import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import EditRule from './component/RuleEdit'
import EditRuleConfiguration from './component/RuleConfigurationEdit'


const styles = theme => ({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {},
}));

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function RuleEdit(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(props.projectId ? 1 : 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDialog = (event) => {
        props.onClose();
        props.onUpdate();
    };

    return (

        <Dialog maxWidth={"md"} fullWidth={true}
            fullScreen={window.innerWidth < 600}
            open={props.showModal}
            className="rule-confguration-edit">
            <DialogTitle id="customized-dialog-title"
                onClose={handleDialog}>
                {'Edit Rule'}
            </DialogTitle >
            <Divider />
            <DialogContent>
                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className="px-4">
                            {!props.projectId && <Tab label="Rule Details" {...a11yProps(0)} />}
                            {!props.projectId && <Tab label="Rule Configuration" {...a11yProps(1)} />}
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0} className="m-0 p-0">
                        <EditRule ruleList={props.ruleList} projectId={props.projectId} close={handleDialog} />
                    </TabPanel>
                    <TabPanel value={value} index={1} className="m-0 p-0">
                        <EditRuleConfiguration ruleList={props.ruleList} projectId={props.projectId} close={handleDialog} />
                    </TabPanel>

                </div>
            </DialogContent>
        </Dialog>
    );
}