import ClinetServices from '../../../core/service/clientUri';
import gql from 'graphql-tag';

var pageSize = 10;
var pageNumber = 0;
var filterBy = {};
var sortBy = 'languageName'

const GET_All_LANGUAGES = gql`
query languages {
    languages {
        languageName
        numOfRules
        description
        enabled
        extensions
      }
}
`;

const REFRESH_LANGUAGE_RULES = gql`
query refreshLanguages {
    refreshLanguages {
        languageName
        numOfRules
        description
        icon
        enabled
        extensions
      }
}
`;

class languageServices {
    constructor(props) {
        this.getFromClintList = this.getFromClintList.bind(this);
        this.getLanguageList = this.getLanguageList.bind(this);
        this.getRefreshFromClintList = this.getRefreshFromClintList.bind(this);
        this.getRefreshLanguageRules = this.getRefreshLanguageRules.bind(this);
    }

    async getFromClintList(client) {
        var response = await client.query({
            query: GET_All_LANGUAGES,
            variables: { pageSize: pageSize, pageNumber: pageNumber, filterBy: filterBy, sortBy: sortBy, direction: 'ASC' }
        });
        return await response.data.languages;
    }

    async getLanguageList(query) {
        if (query) {
            pageSize = query.pageSize;
            pageNumber = query.page;
        }

        return this.getFromClintList(ClinetServices.getClienUri()).then(res => {
            return {
                data: res,
                page: res.number,
                totalCount: res.totalElements,
            }
        }, error => {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    }

    async getRefreshFromClintList(client) {
        var response = await client.query({
            query: REFRESH_LANGUAGE_RULES
        });
        return await response.data.languages;
    }

    async getRefreshLanguageRules() {
        return this.getRefreshFromClintList(ClinetServices.getClienUri()).then(res => {
            return {
                data: res
            }
        }, error => {
            return {
                data: []
            }
        });
    }

}

export default languageServices;