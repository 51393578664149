import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import './project.css';
import ErrorType from '../../core/error/simpleError';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const GET_PROJECT_VIEW = gql`
    query projectRulesets($id: Long!) {
        projectRulesets(projectId: $id) {
            xml
            json
        }
    }
`;

const GET_PHP_RULESET = gql`
    query phpRulesets($id: Long!) {
        phpRulesets(projectId: $id) {
            xml
            json
        }
    }
`;

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class ProjectFileView extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            activeKey: 0,
        };
        this.handleDialog = this.handleDialog.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleJsonData = this.handleJsonData.bind(this);
    }

    handleDialog() {
        this.props.onUpdate();
        this.props.refresh();
        this.props.onClose();
    }

    handleJsonData(data){
        let parsedData = JSON.parse(data);
        delete parsedData["java8"];
        return JSON.stringify(parsedData, null, 2);
    }

    handleChange = (event, newValue) => {
        this.setState({
            activeKey: newValue
        })
    };

    render() {
        return (
            <Query query={GET_PROJECT_VIEW} fetchPolicy="no-cache" variables={{ id: this.props.projectId }}>
                {({ loading, error, data, refetch }) => {
                    return (
                        <Dialog maxWidth={"md"} fullWidth={true}
                            fullScreen={window.innerWidth < 600}
                            open={this.props.showViewModal}
                            onEnter={() => { refetch() }}
                            onClose={() => { this.handleDialog() }}>
                            <DialogTitle id="customized-dialog-title" onClose={() => { this.handleDialog() }}>Rulesets</DialogTitle >
                            <DialogContent className="project-edit-container">
                                <Tabs
                                    value={this.state.activeKey}
                                    onChange={this.handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="scrollable force tabs example"
                                >
                                    <Tab className="admin-managment" label="PMD Configuration" />
                                    <Tab className="admin-managment" label="UI & Python Configuration" />
                                    <Tab className="admin-managment" label="PHP Configuration" />
                                    <Tab className="admin-managment" label="Java8 Configuration" />
                                </Tabs>
                                <Divider />
                                <TabPanel className="admin-tabs p-3" value={this.state.activeKey} index={0}>
                                    {loading && <div className="loading"><CircularProgress size={50} /></div>}
                                    {data && data.projectRulesets && !data.projectRulesets.xml
                                        ? <ErrorType type="fileNotFound" size={450}
                                            head="File Not Found" />
                                        : <code style={{ whiteSpace: 'pre-wrap' }}>
                                            {data && data.projectRulesets && data.projectRulesets.xml}
                                        </code>
                                    }

                                </TabPanel>
                                <TabPanel className="admin-tabs p-3" value={this.state.activeKey} index={1}>
                                    {loading && <div className="loading"><CircularProgress size={50} /></div>}
                                    {data && data.projectRulesets && !data.projectRulesets.json 
                                        ? <ErrorType type="fileNotFound" size={450}
                                            head="File Not Found" />
                                        : <code style={{ whiteSpace: 'pre-wrap' }}>
                                            {data && data.projectRulesets  && this.handleJsonData(JSON.stringify(JSON.parse(data.projectRulesets.json), null, 2))}
                                        </code>
                                    }
                                </TabPanel>
                                <Query query={GET_PHP_RULESET} fetchPolicy="no-cache" variables={{ id: this.props.projectId }}>
                                    {({ loading, error, data, refetch }) => {
                                        return(
                                        <TabPanel className="admin-tabs p-3" value={this.state.activeKey} index={2}>
                                            {loading && <div className="loading"><CircularProgress size={50} /></div>}
                                            {data && data.phpRulesets && !data.phpRulesets.xml
                                                ? <ErrorType type="fileNotFound" size={450}
                                                    head="File Not Found" />
                                                : <code style={{ whiteSpace: 'pre-wrap' }}>
                                                    {data && data.phpRulesets && data.phpRulesets.xml}
                                                </code>
                                            }
                                        </TabPanel>
                                        )
                                    }}
                                </Query>
                                <TabPanel className="admin-tabs p-3" value={this.state.activeKey} index={3}>
                                    {loading && <div className="loading"><CircularProgress size={50} /></div>}
                                    {data && data.projectRulesets && !data.projectRulesets.json 
                                        ? <ErrorType type="fileNotFound" size={450}
                                            head="File Not Found" />
                                        : <code style={{ whiteSpace: 'pre-wrap' }}>
                                            {data && data.projectRulesets && data.projectRulesets.json && data.projectRulesets.json && JSON.parse(data.projectRulesets.json).java8 && JSON.stringify(JSON.parse(data.projectRulesets.json).java8, null, 2)}
                                        </code>
                                    }
                                </TabPanel>
                            </DialogContent>
                            <Divider />
                            <DialogActions>
                                <Button color="secondary"
                                    onClick={() => { this.handleDialog(); refetch() }}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                }}
            </Query>
        )
    }
}

export default ProjectFileView
