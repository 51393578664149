import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Chart, Bar } from 'react-chartjs-2';
import moment from 'moment';
import zoom from 'chartjs-plugin-zoom'
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import { LocalToUTCTime, UTCtoLocalTime, LocalDate } from './../../../core/service/timezone';
import './../../home/home.css';
const REVIEW_AND_COMMENTS_BY_DURATION = gql`
query reviewsAndCommentsByDuration($projectGroupName: String, $startDate: Long, $endDate: Long, $timeZoneOffset: Long) {
    reviewsAndCommentsByDuration(projectGroupName: $projectGroupName, startDate: $startDate, endDate: $endDate, timeZoneOffset : $timeZoneOffset) {
        _id
		numOfPrs
		numOfComments
		createdOn
    }
    projectGroupsWithoutPagination {
        id
        projectGroupName
    }
}`;

class ReviewsCommentsByDateRange extends Component {
    constructor(props) {
        super(props);
        this.getDateOptions = this.getDateOptions.bind(this);
        this.reviewByDate = this.reviewByDate.bind(this);
        this.state = {
            projectGroup: 0,
            dateLabel: 'Today',
            drag: true,
            timeZoneOffset: new Date().getTimezoneOffset()
        };

        this.prepareReviewsCommentsByDateRange = function (data) {
            var comments = data.map((data) => data.numOfComments);
            var prs = data.map((data) => data.numOfPrs);

            return {
                labels: data.map((data, index) => { return data.createdOn ? UTCtoLocalTime(data.createdOn, 'D MMM') : data.month.split(" ")[0].slice(0, 3) + '-' + data.month.split(" ")[1].slice(2, 4) }),
                datasets: [
                    {
                        label: 'Comments',
                        fill: false,
                        yAxisID: 'left-y-axis',
                        backgroundColor: '#e83e8c',
                        borderColor: '#e83e8c',
                        data: comments
                    },
                    {
                        label: 'PRs',
                        fill: false,
                        yAxisID: 'right-y-axis',
                        backgroundColor: '#6610f2',
                        borderColor: '#6610f2',
                        data: prs
                    }
                ]
            };
        }

        this.resetZoom = this.resetZoom.bind(this);
        this.chartDragOn = this.chartDragOn.bind(this);
        this.chartDragOff = this.chartDragOff.bind(this);
    }

    ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    componentWillMount() {
        Chart.plugins.register(zoom)
    }

    resetZoom() {
        this.lineChart.chartInstance.resetZoom();
    }

    chartDragOn() {
        this.setState({
            drag: false
        })
    }

    chartDragOff() {
        this.setState({
            drag: true
        })
    }

    getDateOptions(picker) {
        var seletedDate = '';

        if (picker.startDate.format('MMMM D, YYYY') === picker.endDate.format('MMMM D, YYYY')) {
            seletedDate = picker.startDate.format('YYYY-MM-DD');
        }
        else {
            seletedDate = picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD');
        }

        return {
            dateObj: picker,
            label: seletedDate
        }

    };

    reviewByDate(event, picker) {
        var data = this.getDateOptions(picker);
        if (picker.chosenLabel !== 'Today' && data.dateObj) {
            this.setState({
                dateLabel: data.label,
                startDate: data.dateObj.startDate.format('YYYY-MM-DD'),
                endDate: data.dateObj.endDate.format('YYYY-MM-DD'),
            });
        } else {
            this.setState({
                dateLabel: 'Today',
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            })
        }
    }

    render() {
        return (
            <Query fetchPolicy="no-cache" query={REVIEW_AND_COMMENTS_BY_DURATION} variables={{
                startDate: this.state.startDate ? LocalToUTCTime(this.state.startDate, 'x') : LocalDate(),
                endDate: this.state.endDate ? LocalToUTCTime(this.state.endDate, 'x') : LocalDate(),
                timeZoneOffset: this.state.timeZoneOffset
            }}>
                {({ loading, error, data, refetch }) => {
                    if (error) return (
                        <div className="text-center"></div>
                    )
                    if (loading) return (
                        <div className="row p-2">
                            <div className="col-sm-12 chart-container p-4 bg-white my-0">
                                <div className="row mb-4">
                                    <div className="col-lg-10">
                                        <Skeleton variant="text" width={"150px"} />
                                        <Skeleton variant="text" width={"100px"} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                </div>
                                <Skeleton variant="rect" width={"100%"} height={"235px"} />
                            </div>
                        </div>
                    );
                    return (
                        <div className="row px-2">
                            <div className="col-md-12 chart-container p-4 mb-3 ml-0 bg-white">
                                <div className="row mb-4">
                                    <div className="col-lg-9">
                                        <h4>Reviews Vs Comments By Date Range</h4>
                                    </div>
                                    <div className="col-lg-3 p-0">
                                        {data.reviewsAndCommentsByDuration && <Tooltip title={'Reset Zoom'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.resetZoom()}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {data.reviewsAndCommentsByDuration && this.state.drag && <Tooltip title={'Select the Chart to Magnify'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.chartDragOn()}>
                                                <BlurOnIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {data.reviewsAndCommentsByDuration && !this.state.drag && <Tooltip title={'Scroll the Chart to Magnify'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.chartDragOff()}>
                                                <BlurOffIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 mb-4 align-middle">
                                        <InputLabel>Date Range</InputLabel>
                                        <DateRangePicker containerStyles={{ display: "block" }}
                                            ranges={this.ranges}
                                            maxDate={moment().format('MM/DD/YYYY')}
                                            onApply={this.reviewByDate}>
                                            <div className="custom-select dateRange">
                                                {this.state.dateLabel}
                                            </div>
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <div style={{ height: '400px' }}>
                                    {data.reviewsAndCommentsByDuration.length ? <Bar data={this.prepareReviewsCommentsByDateRange(data ? data.reviewsAndCommentsByDuration : [])}
                                        id="lineChart"
                                        ref={(ref) => this.lineChart = ref}
                                        options={{
                                            maintainAspectRatio: false,
                                            width: "400",
                                            height: "400",
                                            pan: {
                                                enabled: true,
                                                mode: 'x',
                                                speed: 10,
                                                threshold: 10
                                            },
                                            zoom: {
                                                enabled: true,
                                                drag: this.state.drag,
                                                mode: 'xy'
                                            },
                                            scales: {
                                                yAxes: [{
                                                    id: 'left-y-axis',
                                                    type: 'linear',
                                                    position: 'left',
                                                    ticks: {
                                                        beginAtZero: true
                                                    }
                                                },
                                                {
                                                    id: 'right-y-axis',
                                                    type: 'linear',
                                                    position: 'right',
                                                    ticks: {
                                                        beginAtZero: true
                                                    }
                                                }]
                                            }
                                        }} /> : <div className="pt-5 text-center" style={{ height: '192px' }}>
                                            <img src="/images/statistic.jpg" width="90" alt="noData" /><br />
                                            Not enough data to display the chart
                              </div>}
                                </div>
                            </div>
                        </div>
                    )
                }
                }
            </Query>
        );
    }
}

export default ReviewsCommentsByDateRange;