import React, { Component, useRef, useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Bar } from 'react-chartjs-2';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Snackbars from '../../../../src/core/error/Snackbars';
import { colorPalette } from '../../components/service/color-service';
import ProjectInfo from './components/ProjectInfo';
import '../../users/users.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { LocalToUTCTime } from '../../../core/service/timezone';
import PdfSelect from './PdfSelect';


const ref = React.createRef();
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});



const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, onShow, filter, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
            <PdfSelect filter={filter} dateFilter={props.dateFilter} value={props.projectdetails} category={props.category}
                  data={props} />
        </MuiDialogTitle>
    );
});

const PROJECT_SUMMARY = gql`
    query ($repoName: String, $projectGroupName: String, $filter: String!, $startDate: Long, $endDate: Long, $category: String!) {
        users: userSummary(repoName: $repoName, projectGroupName: $projectGroupName, sortBy: "numOfLinesAdded", filter: $filter, startDate: $startDate, endDate: $endDate, category: $category) {
            data {
                id
                developer {
                    id
                    username
                    name
                    emailId
                }
                numOfReviews
                repositories
                numOfLinesAdded
                numOfUnresolvedComments
                unresolvedReviewRequestCount
                unresolvedReviewRequest
                numOfReviewAttempts
                numOfRepositories
                qualityPercentage
                languages {
                    language
                    percentage
                    fullPercentage
                }
            }
        }
    }`;

class ViewProjectDetails extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            openRepo: false
        }

        this.prepareReviewStatusByProject = function (data) {
            if (data) {
                var datasets = [
                    {
                        data: [],
                        backgroundColor: colorPalette.chartColors('cool', data.length),
                        label: 'Needs Rework',
                    },
                    {
                        data: [],
                        backgroundColor: colorPalette.chartColors('warm', data.length),
                        label: 'Resolved',
                    },
                    {
                        data: [],
                        backgroundColor: colorPalette.chartColors('neon', data.length),
                        label: 'New',
                    },
                ];
                data.forEach((data) => {
                    if (data.status.length && data.status.length === 1) {
                        data.status.push({
                            count: 0
                        })
                    }
                    data.status.forEach((data, index) => {
                        datasets[index].data.push(data.count);
                    });
                });
            }

            return {
                responsive: true,
                maintainAspectRatio: false,
                labels: data.map((data) => { return data._id }),
                datasets: datasets
            }
        };
        this.prepareLanguagesUsed = function (data) {
            var datasets = [
                {
                    data: data.map((data) => { return data.percentage.toFixed(2) }),
                    backgroundColor: colorPalette.chartColors(null, data.length),
                    borderWidth: 1,
                    color: '#000000'
                }
            ];

            return {
                responsive: true,
                maintainAspectRatio: false,
                labels: data.map((data) => { return data.language }),
                datasets: datasets
            }
        };
        this.handleDialogFirst = this.handleDialogFirst.bind(this);
        this.handleRepoOpen = this.handleRepoOpen.bind(this);
    }

    handleDialogFirst() {
        this.props.onClose();
    }

    handleRepoOpen() {
        this.setState({ openRepo: true });
    }


    render() {
        return (
            <Query fetchPolicy="no-cache" query={PROJECT_SUMMARY} variables={{
                projectGroupName: this.props.projectDetails.id,
                sortBy: this.props.sortBy, filter: this.props.filter,
                startDate: LocalToUTCTime(this.props.dateFilter.startDate, 'x'),
                endDate: LocalToUTCTime(this.props.dateFilter.endDate, 'x'),
                category: this.props.developerFilter
            }}>
                {({ loading, error, data }) => {
                    this.isError = error;
                    if (loading || error) {
                        return (
                            <Dialog maxWidth={"lg"} fullWidth={true}
                                height="100vh"
                                ref={ref}
                                className="customize-dialog-height"
                                fullScreen={window.innerWidth < 600}
                                open={this.props.showModal}>
                                <DialogTitle id="customized-dialog-title"
                                    filter={this.props.filter}
                                    dateFilter={this.props.dateFilter || {}}
                                    category={this.props.developerFilter}
                                    onClose={this.handleDialogFirst} projectdetails={
                                        {
                                            ...this.props.projectDetails,
                                            ...data,
                                        }}>
                                    {this.props.projectDetails.id}
                                </DialogTitle >
                                {loading ? <LinearProgress color="primary" /> : <Divider />}
                                <DialogContent className="project-group-edit-container">
                                    {loading && <div className="loading"><CircularProgress size={50} /></div>}
                                    {error &&
                                        <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={false} />
                                    }
                                </DialogContent>
                            </Dialog>
                        )
                    }

                    return (
                        <Dialog maxWidth={"lg"} fullWidth={true}
                            height="100vh"
                            ref={ref}
                            className="customize-dialog-height"
                            fullScreen={window.innerWidth < 600}
                            open={this.props.showModal}>
                            <DialogTitle id="customized-dialog-title"
                                filter={this.props.filter}
                                dateFilter={this.props.dateFilter || {}}
                                category={this.props.developerFilter}
                                onClose={this.handleDialogFirst} projectdetails={
                                    {
                                        ...this.props.projectDetails,
                                        ...data,
                                    }}>

                                {this.props.projectDetails.id}
                            </DialogTitle >
                            {loading ? <LinearProgress color="primary" /> : <Divider />}
                            <DialogContent className="project-group-edit-container">
                                {loading && <div className="loading"><CircularProgress size={50} /></div>}
                                {error &&
                                    <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={false} />
                                }
                                <div className="row">
                                    <div className="col-md-12">
                                        <ProjectInfo data={data} projectDetails={this.props.projectDetails} />
                                    </div>
                                </div>
                                <Divider className="my-2 px-2" />
                                <div className="row">
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-lg-12 pr-0 mb-3">
                                                <h5>Languages</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12" style={{ width: '100%', height: '212px' }}>
                                                {this.props.projectDetails && this.props.projectDetails.languages && this.props.projectDetails.languages.length ?
                                                    <Bar data={this.prepareLanguagesUsed(this.props.projectDetails.languages ? this.props.projectDetails.languages : [])}
                                                        ref={(ref) => this.barChart = ref}
                                                        options={{
                                                            maintainAspectRatio: false,
                                                            legend: false,
                                                            plugins: {
                                                                labels: {
                                                                    render: 'value',
                                                                    fontColor: '#000'
                                                                }
                                                            },
                                                            tooltips: {
                                                                callbacks: {
                                                                    title: function (tooltipItem, data) {
                                                                        return data['labels'][tooltipItem[0]['index']];
                                                                    },
                                                                    label: function (tooltipItem, data) {
                                                                        return `${data['datasets'][0]['data'][tooltipItem['index']]}%`;
                                                                    }
                                                                }
                                                            },
                                                            scales: {
                                                                yAxes: [{
                                                                    ticks: {
                                                                        beginAtZero: true
                                                                    }
                                                                }]
                                                            }
                                                        }} />
                                                    : <div className="text-center chart-align-top">
                                                        <img src="/images/statistic.jpg" width="90" alt="noData" /><br />
                                                        Not enough data to display the chart
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <Divider />
                            <DialogActions>
                                <Button onClick={this.handleDialogFirst} color="secondary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                }}
            </Query>
        )
    }
}

export default ViewProjectDetails
