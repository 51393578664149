import React, { Component } from 'react';
import {avatarImage} from '../../../components/service/avatar-image';
import moment from 'moment';

const img = avatarImage.getImageLocation();

const statusList = {
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    IN_REVIEW: 'In Review',
}

class CommentList extends Component {

    constructor(props) {
        super(props);
        this.state = {}

        this.getStatus =function(data) {
            return statusList[data];
        }

        this.getComment = function (data) {
            return data !== 'IN_REVIEW' ? "Comment has been " + statusList[data] : 'Please ignore the comment';
        }
    }

    render() {
        return (
            <div className="row pl-3 pr-3">
                <div className="ml-5 pl-4" style={{width: '100%'}}>
                {this.props.data && <div className="comments d-flex">
                        <div className="avatar">
                            <img src={avatarImage.getImageLocation(this.props.data.user.id)} alt="User Profile" className="align-self-start" width="30" />
                        </div>
                        <span className="inner-comment-content">
                            <h6 className="text-primary">{this.props.data.user.name}</h6>
                            <p className="p-0">{this.props.data.message || this.getComment(this.props.data.type)}</p>
                            <hr className="p-0 m-0"></hr>
                            <p className="actions pt-2">
                                {this.props.data.type !== null && <span>Status: <span className="secondary cursor-pointer">{this.getStatus(this.props.data.type)}</span></span>}
                                <span className="text-muted float-right cursor-pointer" title="Created Date">{moment(this.props.data.createdOn).format('YYYY/MM/DD HH:mm A')}</span>
                            </p>
                        </span>
                    </div>}
                </div>
            </div>
        );
    }
}

export default CommentList;