import React, { Component } from 'react';
import 'react-quill/dist/quill.snow.css';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProjectList from '../../../projects/ProjectList';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import { Mutation } from 'react-apollo';

const UPDATE_PROJECT_RULE_OPTION = gql`
mutation updateProjectRuleOption(
    $projectId: Long!
    $ruleId: Long!,
    $ruleOptions: [Object]!,
   ) {
    updateProjectRuleOption(
            projectId: $projectId,
            ruleId: $ruleId,
            ruleOptions: $ruleOptions)
}
`;

const UPDATE_RULE_CONFIGURATION = gql`
    mutation updateGlobalRuleOption(
        $ruleId: Long!,
        $ruleOptions: [Object]!,
        $updateExistingProjects: Boolean,
        $projectIds: [Long]
       ) {
        updateGlobalRuleOption(
                ruleId: $ruleId,
                ruleOptions: $ruleOptions,
                updateExistingProjects: $updateExistingProjects,
                projectIds: $projectIds)
    }
`;



function getDataTypeValues(type, key) {
    var dataTypes = {
        boolean: ['true', 'false'],
        Integer: [],
        integer: [],
        number: [],
        string: []
    }
    if(key === 'xpath'){
        return [];
    }
    return dataTypes[type] || ["never", "always"];
}

class EditRuleConfiguration extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ruleArgument: null,
            ruleObject: null,
            ruleData: [],
            configuration: 'new',
        };
        this.getRulesByType = this.getRulesByType.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeObject = this.handleChangeObject.bind(this);
        this.getList = this.getList.bind(this);
    }

    getList(data) {
        this.setState({
            selectedProjectList: data
        })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'select' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        if (name !== 'configuration') {
            if (this.state.ruleObject) {
                this.setState({
                    ruleData: [this.state.ruleObject, value]
                });
            } else {
                this.setState({
                    ruleData: [value]
                });
            }
        }
    }

    handleChangeObject(event) {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'select' ? target.checked : target.value;
        const name = target.name;
        var temp = this.state.ruleObject ? this.state.ruleObject : {};
        temp[name] = value;
        this.setState({
            ruleObject: temp,
        });
        if (this.state.ruleArgument) {
            this.setState({
                ruleData: [this.state.ruleArgument, temp]
            });
        } else {
            this.setState({
                ruleData: [temp]
            });
        }

    }


    getRulesByType(configuration) {
        if (configuration !== null) {
            return configuration.map((data, i) => {
                if ((data.type === 'string' || data.type === null) && data.enumValue) {
                    return (
                        <FormControl key={i}>
                            <InputLabel id="rule-arguments">Rule Aruments</InputLabel>
                            <Select labelid="rule-arguments"
                                id="ruleList"
                                name="ruleArgument"
                                value={this.state.ruleArgument || []}
                                onChange={this.handleChange}
                            >
                                {data.enumValue && data.enumValue.map((data, index) => {
                                    return (
                                        <MenuItem key={index} value={data}>{data}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    )
                } else if (data.type === 'object') {
                    return Object.keys(data.properties).map((key, i) => {
                        if (key !== 'version') {
                            if (getDataTypeValues(data.properties[key].type, key).length) {
                                return (
                                    <FormControl key={i}>
                                        <InputLabel id={"rule-arguments-" + i}>{key}</InputLabel>
                                        <Select labelid={"rule-arguments-" + i}
                                            name={key}
                                            value={(this.state.ruleObject && this.state.ruleObject[key]) || []}
                                            onChange={this.handleChangeObject}
                                        >
                                            {getDataTypeValues(data.properties[key].type).map((data, i) => {
                                                return (
                                                    <MenuItem key={i} value={data}>{data}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                )
                            } else if ((data.properties[key].type === 'number') || (data.properties[key].type === 'integer')) {
                                return <div className="mt-3">
                                    <TextField value={(this.state.ruleObject && this.state.ruleObject[key]) || []} max={data.properties[key].max} type="number" label={key} name={key} onChange={this.handleChangeObject} />
                                </div>
                            } else {
                                return <div className="mt-3">
                                    <TextField value={(this.state.ruleObject && this.state.ruleObject[key]) || data.properties[key].default}
                                               type="text" label={key} name={key} onChange={this.handleChangeObject} 
                                               helperText={(data.properties[key] && data.properties[key].multiValued && "Multivalue: " + data.properties[key].multiValued)} />

                                </div>
                            }
                        } else {
                            return null;
                        }


                    });
                } else if ((data.type === 'number') || (data.type === 'integer')) {
                    return (
                        <TextField key={i} value={this.state.ruleArgument} name="ruleArgument" type="number" onChange={this.handleChange} />
                    )
                } else if (data.type === 'array') {
                    return (
                        <FormControl key={i}>
                            <InputLabel id="rules-mutiple-checkbox-label">Rule Arguments</InputLabel>
                            <Select
                                labelid="rules-mutiple-checkbox-label"
                                id="rules-mutiple-checkbox"
                                multiple
                                name="ruleArgument"
                                value={this.state.ruleArgument || []}
                                onChange={this.handleChange}
                                input={<Input />}
                                renderValue={selected => selected.join(', ')}
                            >
                                {data.items.enumValue.map(name => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={this.state.ruleArgument ? this.state.ruleArgument.indexOf(name) > -1 : [].indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                } else {
                    return null;
                }
            });
        }
    }


    render() {
        const noRule = this.getRulesByType(this.props.ruleList.configurations) && Array.isArray(this.props.ruleList.configurations) && !!this.props.ruleList.configurations.length;
        if (!noRule) {
            return (
                <div className="text-center w-100">
                    <img alt={"configuration"} src={"/images/status/noRule.png"} width="300px" />
                    <h6>No Rule Configuration found</h6>
                </div>
            )
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="config text-center">
                            <img alt={"configuration"} src={"/images/status/configuration.png"} />
                        </div>
                    </div>
                    <div className="col-md-7">
                        {this.getRulesByType(this.props.ruleList.configurations)
                            ? <div>
                                <h6>Rule Configuration</h6>                                
                                {this.getRulesByType(this.props.ruleList.configurations)}
                            </div>
                            : null
                        }
                        {
                            this.props.ruleList.ruleOptions && this.props.ruleList.ruleOptions.map((data, i) => {
                                var head = <h6 className="pt-4">Changed Configuration</h6>;
                                if (Array.isArray(data)) {
                                    return (<div key={i}>
                                        {head}
                                        <div>{data.join(', ')}</div>
                                    </div>);
                                } else if (typeof data === 'string') {
                                    return (<div key={i}>
                                        {head}
                                        <div>{data}</div>
                                    </div>);
                                } else if (typeof data === 'object') {
                                    var a = [];
                                    for (var key in data) {
                                        a.push({
                                            name: key,
                                            value: data[key]
                                        })
                                    }

                                    return (
                                        <div className="w-100" key={i}>
                                            {head}
                                            <table className="table w-100">
                                                {a.map((data, index) => {
                                                    return (<tr key={index}>
                                                        <td>{data.name}</td>
                                                        <td>{data.value}</td>
                                                    </tr>)
                                                })

                                                }
                                            </table>
                                        </div>
                                    );
                                }
                                return null;
                            })
                        }
                        {!this.props.projectId && (!!this.state.ruleArgument || !!this.state.ruleObject)
                            && <div className="rule-edit-config">
                                <div className="pt-3 pl-3">
                                    <RadioGroup className="pl-4" aria-label="Configuration" name="configuration" value={this.state.configuration} onChange={this.handleChange}>
                                        <FormControlLabel value="new" control={<Radio />} label="Apply the changes only to new project configurations" />
                                        <FormControlLabel value="all" control={<Radio />} label="Apply the changes to all existing projects" />
                                        <FormControlLabel value="byProject" control={<Radio />} label="Apply the changes only to selected projects" />
                                    </RadioGroup>
                                    {this.state.configuration === 'byProject'
                                        ?
                                        <div className="project-list pt-2">
                                            <ProjectList getList={this.getList} ruleId={this.props.ruleList.ruleId} />
                                        </div>
                                        : null
                                    }

                                </div>
                            </div>
                        }
                        {(!!this.state.ruleArgument || !!this.state.ruleObject)
                            ? <div className="pt-4 float-right">
                                {!this.props.projectId
                                    ? <Mutation mutation={UPDATE_RULE_CONFIGURATION} onCompleted={this.props.close}>
                                        {(updateGlobalRuleOption, { loading, error }) => (
                                            <Button onClick={(e) => {
                                                updateGlobalRuleOption({
                                                    variables: {
                                                        ruleId: this.props.ruleList.ruleId,
                                                        ruleOptions: this.state.ruleData,
                                                        updateExistingProjects: this.state.configuration === 'all',
                                                        projectIds: this.state.configuration === 'byProject' ? this.state.selectedProjectList : []
                                                    }
                                                })
                                            }} variant="contained" color="primary">
                                                Save
                                        </Button>)}
                                    </Mutation>
                                    : <Mutation mutation={UPDATE_PROJECT_RULE_OPTION} onCompleted={this.props.close}>
                                        {(updateProjectRuleOption, { loading, error }) => (
                                            <Button onClick={(e) => {
                                                updateProjectRuleOption({
                                                    variables: {
                                                        ruleId: this.props.ruleList.ruleId,
                                                        ruleOptions: this.state.ruleData,
                                                        projectId: this.props.projectId
                                                    }
                                                })
                                            }} variant="contained" color="primary">
                                                Save
                                        </Button>)}
                                    </Mutation>
                                }

                            </div>
                            : null
                        }
                    </div>


                </div>
            </div>
        )
    }
}

export default EditRuleConfiguration
