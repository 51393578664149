import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Doughnut } from 'react-chartjs-2';
import moment from 'moment';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import { colorPalette } from './../service/color-service';
import './../../home/home.css';
import { LocalToUTCTime, LocalDate } from '../../../core/service/timezone';

const REVIEWS_BY_PROJECTS = gql`
query reviewsByProjects($status: Status!,
    $startDate: Long,
    $endDate: Long) {
        reviewsByProject (status: $status, startDate: $startDate, endDate: $endDate) {
        id
        reviews
    }
}`;

class ReviewsByProjectsChart extends Component {
    constructor(props) {
        super(props);
        this.getDateOptions = this.getDateOptions.bind(this);
        this.reviewByDate = this.reviewByDate.bind(this);
        this.state = {
            status: 'ALL',
            dateLabel: 'Today'
        };

        this.prepareReviewsByProjectsData = function (data) {
            return {
                labels: data.map((data) => { return data.id }),
                datasets: [{
                    data: data.map((data) => { return data.reviews }),
                    borderWidth: 1,
                    backgroundColor: colorPalette.chartColors(null, data.length)
                }]
            }
        };
    }

    ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    getDateOptions(picker) {
        var seletedDate = '';

        if (picker.startDate.format('MMMM D, YYYY') === picker.endDate.format('MMMM D, YYYY')) {
            seletedDate = picker.startDate.format('YYYY-MM-DD');
        }
        else {
            seletedDate = picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD');
        }

        return {
            dateObj: picker,
            label: seletedDate
        }

    };

    reviewByDate(event, picker) {
        var data = this.getDateOptions(picker);
        if (picker.chosenLabel !== 'Today' && data.dateObj) {
            this.setState({
                dateLabel: data.label,
                startDate: data.dateObj.startDate.format('YYYY-MM-DD'),
                endDate: data.dateObj.endDate.format('YYYY-MM-DD')
            });
        } else {
            this.setState({
                dateLabel: 'Today',
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            })
        }
    }

    render() {
        return (
            <Query fetchPolicy="no-cache" query={REVIEWS_BY_PROJECTS} variables={{
                status: this.state.status,
                startDate: this.state.startDate ? LocalToUTCTime(this.state.startDate, 'x') : LocalDate(),
                endDate: this.state.endDate ? LocalToUTCTime(this.state.endDate, 'x') : LocalDate()
            }}>
                {({ loading, error, data, refetch }) => {
                    if (error) return (
                        <div className="text-center"></div>
                    )
                    if (loading) return (
                        <div className="row p-2">
                            <div className="col-sm-12 chart-container p-4 bg-white my-0">
                                <div className="row mb-4">
                                    <div className="col-lg-10">
                                        <Skeleton variant="text" width={"150px"} />
                                        <Skeleton variant="text" width={"100px"} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                </div>
                                <Skeleton variant="rect" width={"100%"} height={"235px"} />
                            </div>
                        </div>
                    );
                    return (
                        <div className="chart-container p-4 mt-3 mb-1 ml-0 bg-white">
                            <div className="row mb-4">
                                <div className="col-lg-10">
                                    <h4>Reviews By Projects</h4>
                                </div>
                                <div className="col-lg-2 p-0">
                                    {data.reviewsByProject && <Tooltip title={'Refresh'} placement="top" id="placement">
                                        <IconButton className="text-right" color="primary" aria-label="close" onClick={() => {
                                            this.setState(() => {
                                                refetch();
                                            })
                                        }}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        name="status"
                                        className="w-100"
                                        value={this.state.status}
                                        onChange={event => {
                                            this.setState({ status: event.target.value }, () => {
                                                refetch();
                                            })
                                        }}
                                    >
                                        <MenuItem value="ALL">All</MenuItem>
                                        <MenuItem value="COMPLETED">Resolved</MenuItem>
                                        <MenuItem value="ADDRESS_REVIEW_COMMENTS">Needs Rework</MenuItem>
                                    </Select>
                                </div>
                                <div className="col-lg-6 mb-4 align-middle">
                                    <InputLabel>Date Range</InputLabel>
                                    <DateRangePicker containerStyles={{ display: "block" }}
                                        ranges={this.ranges}
                                        maxDate={moment().format('MM/DD/YYYY')}
                                        onApply={this.reviewByDate}>
                                        <div className="custom-select dateRange">
                                            {this.state.dateLabel}
                                        </div>
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div>
                                {data.reviewsByProject.length ? <Doughnut data={this.prepareReviewsByProjectsData(data ? data.reviewsByProject : [])}
                                    options={{
                                        legend: { display: false },
                                        maintainAspectRatio: true,
                                    }} /> : <div className="text-center pt-5 graph-height" >
                                        <img src="/images/statistic.jpg" width="90" alt="noData" /><br />
                                            Not enough data to display the chart
                              </div>}
                            </div>
                        </div>
                    )
                }
                }
            </Query>
        );
    }
}

export default ReviewsByProjectsChart;