import React from 'react';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Slider from '@material-ui/core/Slider';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Tooltip from '@material-ui/core/Tooltip';

function ProjectInfo(props) {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
                <Box boxShadow={3} className="p-3 m-1">
                    <div>
                        <div className="row">
                            <div className="col-md-7">
                                <h6 className="float-left text-overflow-ellipsis">Lines of Code</h6>
                            </div>
                            <div className="col-md-5">
                                <div className="float-right repos-badge">{props.projectDetails.numOfRepositories === 1 ? props.projectDetails.numOfRepositories + ' Repo' : props.projectDetails.numOfRepositories + ' Repos'}</div>
                            </div>
                            <div className="col-md-6">
                                <h4 className="success-green">{props.projectDetails.numOfLinesAdded}</h4>
                            </div>
                            <div className="col-md-12">
                                <p className="p-0 m-0 text-bold">Code Quality ({props.projectDetails.qualityPercentage.toFixed(2)}%)</p>
                                <Slider
                                    value={props.projectDetails.qualityPercentage.toFixed(2)}
                                    getAriaValueText={(value) => value}
                                    aria-labelledby="discrete-slider-always"
                                    valueLabelDisplay="auto"
                                    className={props.projectDetails.qualityPercentage > 50
                                        ? props.projectDetails.qualityPercentage > 75
                                            ? "heigh-quality"
                                            : "medium-quality"
                                        : "low-quality"
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row text-bold">
                            <div className="col-6 align-middle">
                                <GroupOutlinedIcon className="align-middle" />
                                <span className="align-middle"> {props.projectDetails.numOfUsers} {props.projectDetails.numOfUsers === 1 ? 'User' : 'Users'}</span>
                            </div>
                            <div className="col-6 align-middle text-right">
                                <RateReviewOutlinedIcon className="align-middle" />
                                <span className="align-middle"> {props.projectDetails.numOfReviews} {props.projectDetails.numOfReviews === 1 ? 'Review' : 'Reviews'}</span>
                            </div>
                        </div>
                    </div>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Box boxShadow={3} className="p-3 m-1">
                    <div className="col-12">
                        <div className="row">
                            <h6 className="text-overflow-ellipsis">Developers</h6>
                            <div className="repo-list-style">
                                <PerfectScrollbar>
                                    {props.data && props.data.users && props.data.users.data && props.data.users.data.length ? <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="table-head-style">Name (Quality)</TableCell>
                                                <Tooltip title="Lines of Code" placement="top"><TableCell className="table-head-style" align="right">LOC</TableCell></Tooltip>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.data.users.data.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">{row.developer.name + ' (' + row.qualityPercentage.toFixed(2) + '%)'}</TableCell>
                                                    <TableCell align="right">{row.numOfLinesAdded}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                        : <div className="w-100 text-center">
                                            No Record Found
                                        </div>
                                    }
                                </PerfectScrollbar>
                            </div>

                        </div>
                    </div>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Box boxShadow={3} className="p-3 m-1">
                    <div className="col-12">
                        <div className="row">
                            <h6 className="text-overflow-ellipsis">Languages</h6>
                            <div className="repo-list-style">
                                <PerfectScrollbar>
                                    {props.projectDetails.languages.map((value, index) => (
                                        <ListItem key={index} className="px-0 pad-style">
                                            <ListItemIcon style={{ 'minWidth': '30px' }}>
                                                <img alt={value.language} width="20" src={"/images/language/" + value.language + ".png"} />
                                            </ListItemIcon>
                                            <ListItemText className="capitalize" primary={value.language + ' ('+ value.percentage + '%)'} />
                                        </ListItem>
                                    ))}
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>
                </Box>
            </Grid>
        </Grid>
    )
}

export default ProjectInfo;