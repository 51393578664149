import React from 'react';
//import moment from 'moment';

function Header(props) {
    return (
        <div className="head text-center">
            <h1 className="m-0">QBot {`Quality Report ${props.project === undefined ? "" : `- ${props.project}`}`} <br/> {"("+props.name+")"} </h1>
            <p>{props.report}</p>
        </div>
    )
}

export default Header;