import React from 'react';
import Header from '../common/Header';
import ProjectTable from '../project-report/ProjectTable';
import Quality from '../common/Quality';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { LocalToUTCTime } from '../../../../core/service/timezone';

const REPOSITORY_SUMMARY = gql`
query ($repoName: String, $projectGroupName: String, $filter: String!, $startDate: Long, $endDate: Long, $category: String!) {
    projects: projectSummary(repoName: $repoName, projectGroupName: $projectGroupName, filter: $filter, startDate: $startDate, endDate: $endDate, category: $category) {
            data {
                id
                projectId
                numOfReviews
                repositories
                numOfLinesAdded
                numOfComments
                numOfUsers
                numOfRepositories
                qualityPercentage
                deleted
                languages {
                    language
                    percentage
                }
                reviewRequestIds
            }
            lastUpdatedDate
        }
    }`;

const REPOSITORY_USER_SUMMARY = gql`
    query ($repoName: String, $projectGroupName: String, $startDate: Long, $endDate: Long, $category: String!) {
        users: repositorySummary(repoName: $repoName, projectGroupName: $projectGroupName, startDate: $startDate, endDate: $endDate, category: $category) {
            data {
                id
                developer {
                    id
                    username
                    name
                    emailId
                }
                numOfReviews
                repositories
                numOfLinesAdded
                numOfUnresolvedComments
                unresolvedReviewRequestCount
                unresolvedReviewRequest
                numOfReviewAttempts
                qualityPercentage
                deleted
                languages {
                    language
                    percentage
                    fullPercentage
                }
            }
        }
    }`;



class RepoChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    getQualityValue(data, users) {
        var numOfLinesAdded = 0;
        var qualityPercentage = 0;
        var numOfUsers = users.length;
        var numOfReviews = 0;

        data.forEach(elm => {
            qualityPercentage += +elm.qualityPercentage;
            numOfLinesAdded += +elm.numOfLinesAdded;
            numOfReviews += +elm.numOfReviews;
        })
        qualityPercentage = qualityPercentage / data.length;
        return {
            numOfUsers,
            qualityPercentage,
            numOfLinesAdded,
            numOfReviews,
            repo: data
        }
    }

    render() {
        return (
            <Query fetchPolicy="no-cache" query={REPOSITORY_SUMMARY} variables={{
                repoName: this.props.value,
                children: this.children,
                filter: this.props.filter,
                startDate: LocalToUTCTime(this.props.dateFilter.startDate, 'x'),
                endDate: LocalToUTCTime(this.props.dateFilter.endDate, 'x'),
                category: this.props.category
            }}>
                {({ loading, error, data }) => {
                    this.isError = error;
                    if (loading || error) {
                        return (
                            <div>
                                <h1>Error</h1>
                            </div>
                        )
                    }
                    const { projects } = data || { projects: { data: [] } }
                    return (
                        <div className="container p-5" style={{ margin: '0 0 0 -30px' }}>
                            <Query fetchPolicy="no-cache" query={REPOSITORY_USER_SUMMARY} variables={{
                                repoName: this.props.value,
                                filter: this.props.filter,
                                startDate: LocalToUTCTime(this.props.dateFilter.startDate, 'x'),
                                endDate: LocalToUTCTime(this.props.dateFilter.endDate, 'x'),
                                category: this.props.category
                            }}>
                                {({ loading, error, data }) => {
                                    this.isError = error;
                                    if (loading || error) {
                                        return (
                                            <div>
                                                <h1>Error</h1>
                                            </div>
                                        )
                                    }
                                    const { users } = data || { users: { data: [] } };
                                    return (
                                        <div className="container p-5" style={{ margin: '0 0 0 -30px' }}>
                                            <Header project={this.props.children} name={this.props.value} report={this.props.dateFilter.startDate
                                                ? this.props.dateFilter.startDate + " - " + this.props.dateFilter.endDate
                                                : this.props.filter} isQuater={!!this.props.dateFilter.startDate} />
                                            <Quality type={'Contributors'} data={this.getQualityValue(projects.data, users.data)} users={users.data} />
                                            <div className="row m-0">
                                                <div className="col-12 pt-5 my-2 border-top">
                                                    <ProjectTable data={projects.data} users={users.data} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }}
                            </Query>

                        </div>
                    )
                }}
            </Query>


        )
    }
}

export default RepoChart;
