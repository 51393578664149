import React from 'react';
import MaterialTable from 'material-table';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';


const columnDefs = [
    {
        title: "Project Name", field: "id"
    }, {
        title: "# Lines", field: "numOfLinesAdded"
    }, {
        title: "# Repositories", field: "numOfRepositories"
    }, {
        title: "# Requests", field: "numOfReviews"
    },
     {
        title: "Code Quality", field: "qualityPercentage", render: function (params) {
            return (<Tooltip title={params.qualityPercentage + '%'} placement="top"><div className={params.qualityPercentage > 50
                ? params.qualityPercentage > 75
                    ? "heigh-quality"
                    : "medium-quality"
                : "low-quality"
            }><LinearProgress variant="determinate" value={params.qualityPercentage} />{params.qualityPercentage.toFixed(2)}%</div></Tooltip>);
        }
    }
];

const contributersColumnDefs = [ {
    title: "Email", field: "developer.email", render: function (params) {
        return params.developer.emailId
    }
}, {
    title: "# Lines", field: "numOfLinesAdded"
}, {
    title: "# Requests", field: "numOfReviews"
}, {
    title: "# Comments(unresolved)", field: "numOfUnresolvedComments"
},
 {
    title: "Code Quality", field: "qualityPercentage", render: function (params) {
        return (<Tooltip title={params.qualityPercentage + '%'} placement="top"><div className={params.qualityPercentage > 50
            ? params.qualityPercentage > 75
                ? "heigh-quality"
                : "medium-quality"
            : "low-quality"
        }><LinearProgress variant="determinate" value={params.qualityPercentage} />{params.qualityPercentage.toFixed(2)}%</div></Tooltip>);
    }
}]

function Report(props) {
    return (
        <div className="col-12 py-5 my-2 border-top">
            <div className="py-2">
                <h2 className="ml-4 mb-2">Projects</h2>
                <MaterialTable
                    title="Repos"
                    columns={columnDefs}
                    data={props.data}
                    options={{
                        filtering: false,
                        toolbar: false,
                        headerStyle: {
                            padding: '5px',
                            fontSize: '12px',
                            fontWeight: "bold",
                            color: 'black',
                        },
                        paging: false
                    }}
                />
            </div>
            <div>
                <h2>Contributors</h2>
                <MaterialTable
                    title="Contributs"
                    columns={contributersColumnDefs}
                    data={props.users}
                    options={{
                        filtering: false,
                        toolbar: false,
                        headerStyle: {
                            padding: '5px',
                            fontSize: '12px',
                            fontWeight: "bold",
                            color: 'black',
                        },
                        paging: false
                    }}
                />
            </div>
        </div>
    );
}

export default Report;