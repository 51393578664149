import React, { Component, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import ViewProjectDetails from './../modal/ViewProjectDetails';
import Error500 from '../../../../src/core/error/error';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import IconButton from '@material-ui/core/IconButton';
import GridComp from './component/Grid';
import ListView from './component/ListView';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ReactToPrint from 'react-to-print';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import { MainReport } from './../components';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { LocalToUTCTime } from '../../../core/service/timezone';
import Dropdown from 'react-bootstrap/Dropdown'
import { authHeader } from '../../../core/login/services/auth-header';
import GetAppIcon from '@material-ui/icons/GetApp';

const user = JSON.parse(localStorage.getItem('users'));
const homePageStyle = theme => ({
    root: {
        width: "300px"
    },
    selected: {
        backgroundColor: "#3f51b5 !important",
        color: "white"
    }
});

const PROJECT_SUMMARY = gql`
    query projectSummary($sortBy: String, $direction: Direction, $filter: String!, $startDate: Long, $endDate: Long, $category: String!) {
        projects: projectSummary(sortBy: $sortBy, direction: $direction, filter: $filter, startDate: $startDate, endDate: $endDate, category:$category) {
            _id
            data {
                id
                projectId
                numOfReviews
                repositories
                numOfLinesAdded
                numOfComments
                numOfUsers
                numOfRepositories
                qualityPercentage
                deleted
                languages {
                    language
                    percentage
                }
                reviewRequestIds
            }
            lastUpdatedDate
        }
    }`;

const SUMMARY_FILTER_OPTIONS = gql`
    query summaryFilterOptions {
        filter: summaryFilterOptions
     }`;

const PdfExport = (props) => {
    const componentRef = useRef();
    return (
        <span>
            <ReactToPrint
                trigger={() => (
                    <IconButton className={"mr-2 floate-right default"} color="primary">
                        <Tooltip title="Export PDF" placement="top">
                            <PictureAsPdfIcon />
                        </Tooltip>
                    </IconButton>
                )}
                content={() => {
                    return componentRef.current;
                }}
            />
            <div style={{ display: "none" }}>
                {props.PdfExport && <MainReport developerFilter={props.developerFilter} data={props.data} filter={props.filter} dateFilter={props.dateFilter} ref={componentRef} show={true} />}
            </div>
        </span>
    )
}

class Projects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            projectDetails: [],
            values: [],
            label: '',
            flag: true,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            showPopup: null,
            sortBy: 'numOfLinesAdded',
            direction: 'DESC',
            filter: '',
            defaultFilter: '',
            dateLabel: 'Today',
            sort: 'LINES OF CODE',
            PdfExport: true,
            category: 'PULL REQUEST'
        }
        this.handleDialog = this.handleDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.viewType = this.viewType.bind(this);
        this.sortByProject = this.sortByProject.bind(this);
        this.filterByProject = this.filterByProject.bind(this);
        this.handlePopupClose = this.handlePopupClose.bind(this);
        this.handlePopupClick = this.handlePopupClick.bind(this);
        this.handleDropDown = this.handleDropDown.bind(this);
        this.handleDropDownProject = this.handleDropDownProject.bind(this);
        this.sortByUsers = this.sortByUsers.bind(this);
    }

    ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    handleDropDown(e) {
        this.setState({
            category: e
        })
        this.props.updateFilter(e);
    }

    handleDropDownProject(e) {
        this.setState({
            sort: e
        })
    }

    handleDialog(value) {
        this.setState({ showModal: true, projectDetails: value });
    }

    handlePopupClose() {
        this.setState({ showPopup: null });
    }

    handlePopupClick(event, filter) {
        this.setState({
            showPopup: event.currentTarget,
            defaultFilter: filter
        });
    }

    handleClose() {
        this.setState({ showModal: false });
    }

    sortByProject(refetch, direction, sortBy, filter) {
        if (this.state.sortBy === sortBy) {
            this.setState({
                sortBy: sortBy,
                direction: direction === 'DESC' ? 'ASC' : 'DESC',
                filter: filter
            });
        } else {
            this.setState({
                sortBy: sortBy,
                direction: 'DESC'
            });
        }
        refetch();
    }

    sortByUsers(refetch, direction, sortBy, filter) {
        if (this.state.sortBy === sortBy) {
            this.setState({
                sortBy: sortBy,
                direction: direction === 'DESC' ? 'ASC' : 'DESC',
                filter: filter
            });
        } else {
            this.setState({
                sortBy: sortBy,
                direction: 'DESC',
                showPopup: null
            });
        }
        refetch();
    }

    filterByProject(refetch, direction, sortBy, filter) {
        this.setState({
            sortBy: sortBy,
            direction: direction,
            filter: filter,
            startDate: null,
            dateLabel: 'Date Range',
            endDate: null,
            showPopup: null,
        }, () => {
            refetch();
        });
    }

    viewType() {
        this.setState({
            flag: !this.state.flag
        })
    }


    getDateOptions(picker) {
        var seletedDate = '';
        if (picker.startDate.format('YYYY-MM-DD') === picker.endDate.format('YYYY-MM-DD')) {
            seletedDate = picker.startDate.format('YYYY-MM-DD');
        }
        else {
            seletedDate = picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD');
        }

        return {
            dateObj: picker,
            label: seletedDate
        }

    };

    reviewByDate(event, picker, refetch) {
        var data = this.getDateOptions(picker);

        if (picker.chosenLabel !== 'Date Range' && data && data.dateObj) {
            var start = moment(data.dateObj.startDate);
            var end = moment(data.dateObj.endDate);

            //Difference in number of days
            var days = end.diff(start, 'days') + 1;
            if (days <= 100) {
                this.setState({
                    dateLabel: data.label,
                    groupByDateFilter: null,
                    startDate: data.dateObj.startDate.format('YYYY-MM-DD'),
                    endDate: data.dateObj.endDate.format('YYYY-MM-DD')
                }, () => {
                    refetch();
                });
            } else {
                this.setState({
                    dateLabel: 'choose between 100 days',
                });
            }
        } else {
            this.setState({
                dateLabel: 'Date Range',
                groupByDateFilter: null,
                startDate: null,
                endDate: null,
            }, () => {
                refetch();
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Query query={SUMMARY_FILTER_OPTIONS}>
                {({ loading, data, error }) => {
                    if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                    if (error) return <div className="text-center">
                        <Error500 />
                    </div>
                    const { filter } = data;

                    return (
                        <div className="col-md-12 user-management mt-3">
                            <Query query={PROJECT_SUMMARY} variables={{ sortBy: this.state.sortBy, startDate: this.state.startDate ? LocalToUTCTime(this.state.startDate, 'x') : null, endDate: this.state.endDate ? LocalToUTCTime(this.state.endDate, 'x') : null, direction: this.state.direction, filter: this.state.filter || (filter && filter[0]), category: this.state.category }}>
                                {({ loading, error, data, refetch }) => {
                                    if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                                    if (error) return <div className="text-center">
                                        <Error500 />
                                    </div>
                                    const { projects } = data || { projects: { data: [] } };
                                    return (
                                        <div className="row">
                                            <div className="project-icon-container p-0">
                                                <Button>
                                                    <Dropdown >
                                                        <Dropdown.Toggle variant="primary" >
                                                            {this.state.category}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('PULL REQUEST');
                                                            }}>PULL REQUEST</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('COMPARE');
                                                            }}>COMPARE</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('COMMIT ID');
                                                            }}>COMMIT ID</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDown('ALL');
                                                            }}>ALL</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown >
                                                </Button>
                                                <Button>
                                                    <Dropdown >
                                                        <Dropdown.Toggle variant="primary" >
                                                            {this.state.sort}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDownProject('LINES OF CODE');
                                                                this.sortByUsers(refetch, this.state.direction, 'numOfLinesAdded', this.state.filter);
                                                            }}>
                                                                {this.state.sortBy == "numOfLinesAdded" ? this.state.direction === 'DESC' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon /> : ''}
                                                                LINES OF CODE</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDownProject('QUALITY');
                                                                this.sortByUsers(refetch, this.state.direction, 'qualityPercentage', this.state.filter)
                                                            }}>
                                                                {this.state.sortBy == "qualityPercentage" ? this.state.direction === 'DESC' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon /> : ''}
                                                                QUALITY</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {
                                                                this.handleDropDownProject('REVIEWS');
                                                                this.sortByUsers(refetch, this.state.direction, 'numOfReviews', this.state.filter)
                                                            }}>
                                                                {this.state.sortBy == "numOfReviews" ? this.state.direction === 'DESC' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon /> : ''}
                                                                REVIEWS</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown >
                                                </Button>
                                                {
                                                    <DateRangePicker containerStyles={{ display: "inline-block", marginRight: "10px" }}
                                                        ranges={this.ranges}
                                                        maxDate={moment().format('MM/DD/YYYY')}
                                                        onApply={(event, picker) => this.reviewByDate(event, picker, refetch)}>
                                                        <div className={`custom-select dateRange ${this.state.startDate && classes.selected}`}>
                                                            {this.state.dateLabel}
                                                        </div>
                                                    </DateRangePicker>
                                                }
                                                <PdfExport developerFilter={this.props.developerFilter} PdfExport={true} data={projects.data} dateFilter={{
                                                    startDate: this.state.startDate,
                                                    endDate: this.state.endDate
                                                }} filter={this.state.filter || (filter && filter[0])} />
                                                <IconButton className="button-style active mr-2" onClick={() => this.viewType()}>
                                                    {!this.state.flag ? <Tooltip title="Grid View" placement="top">
                                                        <AppsOutlinedIcon />
                                                    </Tooltip> :
                                                        <Tooltip title="List View" placement="top">
                                                            <FormatListBulletedOutlinedIcon />
                                                        </Tooltip>}
                                                </IconButton>
                                                <IconButton className="mr-2 default" onClick={() => refetch()}>
                                                    <Tooltip title="Refresh" placement="top">
                                                        <RefreshIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            </div>
                                            <div className="col-md-12 p-0">
                                                <div className="container-fluid pt-1 pl-0 pr-0 pb-0">
                                                    {this.state.flag
                                                        ? <Grid container spacing={1}>
                                                            {projects.data.length
                                                                ? projects.data.map((value, index) => (
                                                                    <GridComp value={value} key={index} index={index} handleDialog={this.handleDialog} />
                                                                ))
                                                                : <div className="text-center chart-align-top w-100">
                                                                    <div className="col-md-12">
                                                                        <img src="/images/statistic.jpg" alt="No record Found" width="90px" />
                                                                        <h3>No Record found</h3>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Grid>
                                                        : <ListView data={projects.data} handleDialog={this.handleDialog} />
                                                    }
                                                    {this.state.showModal
                                                        ? <ViewProjectDetails
                                                            projectDetails={this.state.projectDetails}
                                                            sortBy={this.state.sortBy}
                                                            developerFilter={this.props.developerFilter}
                                                            dateFilter={{
                                                                startDate: this.state.startDate,
                                                                endDate: this.state.endDate
                                                            }}
                                                            values={this.state.values}
                                                            filter={this.state.filter || (filter && filter[0])}
                                                            showModal={this.state.showModal}
                                                            onClose={this.handleClose} >
                                                        </ViewProjectDetails>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }}
                            </Query>
                        </div>
                    );
                }}
            </Query>
        );
    }
}

export default withStyles(homePageStyle)(Projects);
