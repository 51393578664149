import React from 'react';
import SockJsClient from 'react-stomp';
import LinearProgress from '@material-ui/core/LinearProgress';
import { authHeader, refreshHeader } from './../../../../core/login/services/auth-header';
import SuccessReviewStatus from './SuccessReviewStatus';
import './reviewStatus.css';

class StatusWatcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isCompleted: false,
            progressMessage: 'Reading code from repository',
            progress: 25,
        }
        this.onMessageRecive = this.onMessageRecive.bind(this);
        this.onMessageSend = this.onMessageSend.bind(this);
    }

    onMessageRecive(msg, topic) {
        if (this.props.id === msg.reviewRequestId) {
            this.setState({
                progressMessage: msg.progressMessage || 'Reading code from repository',
                progress: this.state.progress + 25,
            })

            if (this.props.isReviewStatus && (msg.errorMessage || (msg.progressMessage && msg.progressMessage.includes('wrong')))) {
                this.clientRef.disconnect();
                this.props.disconnected(false);
                this.setState({
                    progress: 100,
                    isCompleted: false,
                    isLoading: false,
                    isError: false,
                    data: msg,
                })
            } else if (msg.errorMessage || (msg.progressMessage && msg.progressMessage.includes('wrong'))) {
                this.clientRef.disconnect();
                this.props.disconnected(false);
                this.setState({
                    isCompleted: false,
                    isLoading: false,
                    isError: true,
                    data: msg,
                })
            }

            if ((msg.status === 'Address Review Comments' || msg.status === 'Completed') && !this.props.isReviewStatus) {
                this.clientRef.disconnect();
                this.setState({
                    isCompleted: true,
                    isLoading: false,
                    data: msg,
                })
            } else if ((msg.status === 'Address Review Comments' || msg.status === 'Completed') && this.props.isReviewStatus) {
                this.props.disconnected(true);
                this.setState({
                    progress: 100,
                    isCompleted: false,
                    isLoading: false,
                    isError: false,
                })
            }
        }
    }

    onMessageSend() {
        if (this.clientRef) {
            this.clientRef.sendMessage('/topic/status');
        }
    }

    render() {
        return (
            <div>
                <SockJsClient url='/code-review-service/review-status' topics={['/topic/status']}
                    onMessage={this.onMessageRecive}
                    headers={authHeader().Authorization ? refreshHeader() : authHeader()}
                    onConnect={() => { this.props.onConnect(this) }}
                    onDisconnect={() => { this.setState({ clientConnected: false }) }}
                    debug={false}
                    ref={(client) => { this.clientRef = client }} />
                {this.props.isReviewStatus && this.state.progress < 100
                    ? <div className="w-100 text-center">
                        <span>{this.state.progressMessage}</span>
                        <LinearProgress className="m-2" variant="buffer" value={this.state.progress} valueBuffer={this.state.progress + 10} color={this.state.progress > 75 ? "primary" : "secondary"} />
                    </div>
                    : <div>
                        {this.state.isLoading &&
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div className="status-loading-box bg-white">
                                        <img alt="loading" src="/images/status/loading.gif" />
                                        <h4 className="heading">
                                            {this.state.progressMessage}
                                            <p>Please wait while we build the report for you</p>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.isError &&
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div className="status-loading-box bg-white">
                                        <img alt="loading" src="/images/status/error.gif" />
                                        <h4 className="heading">
                                            {this.state.progressMessage}
                                            <p>Please try after sometime.</p>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.isCompleted &&
                            <SuccessReviewStatus data={this.state.data} />
                        }
                    </div>
                }
            </div>
        );
    }
}

export default StatusWatcher;