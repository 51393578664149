import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import AppServerSent from '../../core/header/server-sent-event-connect';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Auth from './../service/auth';
import Popover from '@material-ui/core/Popover';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CommentIcon from '@material-ui/icons/Comment';
import Badge from '@material-ui/core/Badge';
import preferenceService from '../service/preference';
import AppLogOut from '../../core/login/logout';
import { avatarImage } from '../../qBot/components/service/avatar-image';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { authHeader } from '../login/services/auth-header';
import { loginService } from '../login/services/login-service';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ReviewServices from '../../qBot/reviews/services/review-services';
import { resetFilterService } from '../login/services/reset-filter';
import InputLabel from '@material-ui/core/InputLabel';

var pageSize = 7;
var pageNumber = 0;
var filterBy = {};
let user = preferenceService.getValue('users', 'local');
let id = 2;
let projectGroupName = "All";
let projectGroupId = 0;

const GET_PULL_REQUEST_LIST = gql`
query notificationStats($userId: String, $isApprover: Boolean) {
    notificationStats(userId: $userId, isApprover: $isApprover) {
        users {
            id
            repoGroupName
            repoName
            projectName
            createdOn
            comments
            status
            manualReviewStatus
            requestedUser
            {
                id
                username
                name
                emailId
            }
        }
        requests {
            id
            repoGroupName
            repoName
            projectName
            createdOn
            status
            manualReviewStatus
            comments
            requestedUser
            {
                id
                username
                name
                emailId
            }
        }
        reviews {
            id
            repoGroupName
            repoName
            projectName
            status
            manualReviewStatus
            createdOn
            comments
            requestedUser
            {
                id
                username
                name
                emailId
            }
        }
    }
}
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

class MyPullRequest extends Component {
    constructor(props) {
        super(props)
        this.reviewServices = new ReviewServices();
        this.state = {
            showRequest: null,
            showProfileRequest: null,
            activeKey: 0,
            request: false,
            projectGroupName: 'All',
            projectName: null,
            projectGroup: 0,
            tags: [],
            result: [],
            value: '',
            projectName: '',
            Roles: {
                ROLE_USER: 'User',
                ROLE_ADMIN: 'Admin',
                ROLE_MANAGER: 'Manager',
                ROLE_EXECUTIVE: 'Executive',
                ROLE_APPROVER: 'Approver',
                ROLE_SUPER_ADMIN: 'Super Admin'
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleProfileClick = this.handleProfileClick.bind(this);
        this.rolesList = this.rolesList.bind(this);
        this.roleList = preferenceService.getValue('users', 'local') ? preferenceService.getValue('users', 'local').emailId : null;
        this.onTagsChange = this.onTagsChange.bind(this);
    }

    onTagsChange = (event, values) => {
        resetFilterService.resetFilters();
        if (values != null) {
            this.setState({
                tags: values.id,
                projectGroup: values.id,
                projectName: values,
            });
            projectGroupName = values.projectGroupName;
            projectGroupId = values.id;
            fetch(`/code-review-service/api/users/switch/${user.currentOrganizationId}/${projectGroupId}/${projectGroupName}`, {
                headers: authHeader()
            }).then(async res => {
                let data = await res.json();
                preferenceService.setValue('user', data, 'local');
                loginService.getCurrentUser().then(() => {
                    window.location.href = (user.currentOrganizationId === 1) ? '/admin' : '/reviews';
                });
            });
        }
    }

    handleClose() {
        this.setState({ showRequest: null, showProfileRequest: null });
    }

    handleChange = (event, newValue) => {
        this.setState({
            activeKey: newValue
        })
    };

    handleClick(event) {
        this.setState({ showRequest: event.currentTarget, activeKey: 0, request: true });
    }

    handleProfileClick(event) {
        this.setState({ showProfileRequest: event.currentTarget });
    }

    rolesList() {
        this.setState({ roleList: this.roleList });
    }

    changeOrg(event) {
        let id = event.target.value;
        fetch(`/code-review-service/api/users/switch/${id}/${projectGroupId}/${projectGroupName}`, {
            headers: authHeader()
        }).then(async res => {
            let data = await res.json();
            preferenceService.setValue('user', data, 'local');
            loginService.getCurrentUser().then(() => {
                window.location.href = (id === 1) ? '/admin' : '/reviews';
            });
        });
    }

    render() {
        let { projects } = user;
        let { organizations } = user;
        let top = [];
        let id = [];
        user.projects.map(e => {
            top.push(e.projectGroupName);
            id.push(e.id);
        })

        const defaultProps = {
            options: projects,
            getOptionLabel: (option) => option.projectGroupName,
        };

        if (user.currentProjectGroupName == null) {
            user.currentProjectGroupName = "All"
        }
        return (
            <div className="d-flex">
                {user.currentOrganizationId != 1 ? <div className="mr-3">
                    <Tooltip title={projects.filter(e => user.currentProjectGroupName === e.projectGroupName).map(e => e.projectGroupName).toString()}>
                        <Autocomplete
                            {...defaultProps}
                            id="auto-complete"
                            autoComplete
                            includeInputInList
                            defaultValue={projects.find(v => v.projectGroupName == user.currentProjectGroupName ? user.currentProjectGroupName : '')}
                            onChange={
                                this.onTagsChange
                            }
                            style={{ width: 200 }}
                            renderInput={(params) => <TextField {...params} label="Project" margin="middle" />}
                        />
                    </Tooltip>
                </div> : ''}


                {(organizations && organizations.length > 0) &&
                    <FormControl className="align-self-start m-0" style={{ width: "100px" }}>
                        <InputLabel>Organization</InputLabel>
                        <Tooltip title={organizations.filter(e => user.currentOrganizationId === e.organizationId).map(e => e.organizationName).toString()}>
                            <Select
                                placeholder="user Name"
                                label="Project Name"
                                onChange={this.changeOrg}
                                defaultValue={user.currentOrganizationId}
                                disabled={organizations.length === 1}
                                className="w-100"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                            >
                                {
                                    organizations.map(e => {
                                        return <MenuItem value={e.organizationId}>{e.organizationName}</MenuItem>
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </FormControl>}

                <IconButton className="mr-3" color="inherit" edge="end" onClick={this.handleClick}><AppServerSent /></IconButton>
                <IconButton className="mr-3" color="inherit" edge="end" onClick={this.handleProfileClick}><img src={avatarImage.getImageLocation(user.id)} alt="User Profile" className="align-self-start" width="30" /></IconButton>
                {this.state.showProfileRequest &&
                    <Popover
                        open={Boolean(this.state.showProfileRequest)}
                        className="profile-menu"
                        anchorEl={this.state.showProfileRequest}
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
                        <div className="p-2 text-center">
                            <div className="text-center">
                                <IconButton className="m-0" color="inherit" edge="end"><img src={avatarImage.getImageLocation(user.id)} alt="User Profile" className="align-self-start" width="40" /></IconButton>
                                <Tooltip placement="top" id="placement" title={preferenceService.getValue('users', 'local').name}>
                                    <h6 className="m-0 p-0 profile-content-overflow">{preferenceService.getValue('users', 'local').name}</h6>
                                </Tooltip>
                                <Tooltip placement="top" id="placement" title={preferenceService.getValue('users', 'local').emailId}>
                                    <div className="profile-content-overflow">{preferenceService.getValue('users', 'local').emailId}</div>
                                </Tooltip>
                                <div className="profile-content-overflow secondary">{preferenceService.getValue('users', 'local') ? preferenceService.getValue('users', 'local').grantedAuthorities.map(roles => this.state.Roles[roles.authority]).join(', ') : null}</div>
                                <Divider className="m-2" />
                                <AppLogOut onClose={this.handleClose} />
                            </div>
                        </div>
                    </Popover>

                }
                {this.state.request &&
                    <Popover
                        open={Boolean(this.state.showRequest)}
                        className="notification-menu"
                        anchorEl={this.state.showRequest}
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
                        <h5 className="ml-3 mt-3 mb-3">Notification</h5>
                        <Query query={GET_PULL_REQUEST_LIST} variables={{ userId: user.id, isApprover: Auth.hasRole(user, ['ROLE_APPROVER']) }} fetchPolicy="no-cache">
                            {({ loading, error, data, refetch }) => {
                                if (loading) return <div className="text-center pb-5"><CircularProgress size={35} /></div>
                                if (error) return <div className="text-center pt-5 pb-5 secondary">
                                    Something Went wrong
                                </div>
                                return (
                                    <div>
                                        <Tabs
                                            value={this.state.activeKey}
                                            onChange={this.handleChange}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="scrollable force tabs example"
                                        >
                                            <Tab className="admin-managment" label={'Comments (' + ((data.notificationStats.users.length > 9) ? ("9+") : (data.notificationStats.users.length)) + ')'} />
                                            {Auth.hasRole(user, ['ROLE_APPROVER']) && <Tab className="admin-managment" label={'Approval (' + ((data.notificationStats.requests.length > 9) ? ("9+") : (data.notificationStats.requests.length)) + ')'} />}
                                            {Auth.hasRole(user, ['ROLE_APPROVER']) && <Tab className="admin-managment" label={'Reviews (' + ((data.notificationStats.reviews.length > 9) ? ("9+") : (data.notificationStats.reviews.length)) + ')'} />}
                                        </Tabs>
                                        <Divider />
                                        {data && data.notificationStats && Object.keys(data.notificationStats).map((key, i) => {
                                            let tabData = [];
                                            if (key === '__typename') {
                                                return null;
                                            }
                                            if (data.notificationStats[key]) {
                                                tabData = data.notificationStats[key];
                                            }
                                            if (tabData && tabData.length > 9) {
                                                tabData = tabData.slice(0, 9);
                                            }
                                            return (
                                                <TabPanel className="admin-tabs p-0" value={this.state.activeKey} key={i} index={i}>
                                                    <List className="pl-3 pr-3">
                                                        {(!data.notificationStats[key] || (data.notificationStats[key] && data.notificationStats[key].length === 0)) && <div className="w-100 p-3 text-center">No Record Found</div>}
                                                        {tabData && tabData.map((status, index) => (
                                                            <ListItemLink key={index} href={"/review-details/" + status.id + (key === 'requests' ? "/true" : "")}><ListItemText secondary={status.requestedUser.name + (status.manualReviewStatus ? " | " + status.manualReviewStatus : "")}
                                                                primary={<span><b>{status.id}</b> {' (' + status.repoGroupName + '/' + status.repoName + ')'}</span>} />
                                                                <ListItemSecondaryAction key={index}>
                                                                    <Badge badgeContent={status.comments} color="primary">
                                                                        <CommentIcon />
                                                                    </Badge>

                                                                </ListItemSecondaryAction>

                                                            </ListItemLink>
                                                        ))}

                                                    </List>
                                                    <Divider />
                                                    <br />
                                                    {data.notificationStats[key] && data.notificationStats[key].length > 9 && key === 'users' &&
                                                        <Link to="/reviews" onClick={this.handleClose}>
                                                            <Box textAlign='center'>

                                                                <Button variant="contained" color="primary">
                                                                    Show More
                                                                </Button>

                                                            </Box>
                                                        </Link>
                                                    }
                                                    {data.notificationStats[key] && data.notificationStats[key].length > 9 && key === 'requests' &&
                                                        <Link to="/reviews/approve" onClick={this.handleClose}>
                                                            <Box textAlign='center'>

                                                                <Button variant="contained" color="primary">
                                                                    Show More
                                                                </Button>

                                                            </Box>
                                                        </Link>
                                                    }
                                                    {data.notificationStats[key] && data.notificationStats[key].length > 9 && key === 'reviews' &&
                                                        <Link to="/reviews/manualReviewStatus" onClick={this.handleClose}>
                                                            <Box textAlign='center'>

                                                                <Button variant="contained" color="primary">
                                                                    Show More
                                                                </Button>

                                                            </Box>
                                                        </Link>
                                                    }
                                                    <br />

                                                </TabPanel>
                                            )

                                        })}
                                    </div>
                                );
                            }}
                        </Query>
                    </Popover>

                }
            </div>
        )
    }
}

export default MyPullRequest;
