import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Rating from '@material-ui/lab/Rating';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { colorPalette } from '../../../../qBot/components/service/color-service';
import '../../users.css';

function graph(value) {
    return {
        labels: ['NoOfComments', 'NoOfUnresolvedComments'],
        datasets: [{
            data: [value.numOfComments, value.numOfUnresolvedComments],
            borderWidth: 1,
            backgroundColor: colorPalette.chartColors(null, 2)
        }]
    }
}

function UserAnalysisGrid(props) {
    const reviewCount = props.review.getStatusStatsByDate.reduce((a, count) => a = a + count.count, 0);
    if (!props.users) {
        return null;
    }

    return (
        <Grid container spacing={1}>
            {props.users.length ?
                props.users.map((value, index) => (
                    <Grid item xs={12} sm={6} md={4}>
                        <Box boxShadow={3} className="p-3 m-1">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-7">
                                        <h6 className="text-overflow-ellipsis">Lines of Code</h6>
                                    </div>
                                    <div className="repo-list-style col-md-12">
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <h4 className="success-green"><Tooltip placement="top" id="placement" title={value.numOfLinesAdded}><span>{value.numOfLinesAdded.toString().length >= 4 ? Math.trunc(value.numOfLinesAdded / 1000) + 'K' : value.numOfLinesAdded}</span></Tooltip></h4>
                                                </TableRow>
                                                <TableRow>
                                                    <h6 className="p-0 m-0 text-bold">Code Quality ({value.qualityPercentage.toFixed(2)}%)</h6>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <Rating
                                                        name="hover-feedback"
                                                        value={value.qualityPercentage > 20
                                                            ? value.qualityPercentage > 40
                                                                ? value.qualityPercentage > 60
                                                                    ? value.qualityPercentage > 80
                                                                        ? 5
                                                                        : 4
                                                                    : 3
                                                                : 2
                                                            : 1
                                                        }
                                                        size='large'
                                                        readOnly />
                                                </TableRow>

                                            </TableBody>
                                        </Table>

                                    </div>

                                </div>
                            </div>
                        </Box>
                    </Grid>))
                : <div className="text-center chart-align-top w-100">
                    <div className="col-md-12">
                        <img src="/images/statistic.jpg" alt="No record Found" width="90px" />
                        <h3>No Record found</h3>
                    </div>
                </div>
            }
            {props.users.map((value, index) => (
                <Grid item xs={12} sm={6} md={4}>
                    <Box boxShadow={3} className="p-3 m-1">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-7">
                                    <h6 className="text-overflow-ellipsis">Repositories</h6>
                                </div>
                                <div className="col-md-5">
                                    <div className="float-right repos-badge">{value.numOfRepositories === 1 ? value.numOfRepositories + ' Repo' : value.numOfRepositories + ' Repos'}</div>
                                </div>
                                <div className="repo-list-style col-md-12">
                                    <PerfectScrollbar>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                            </TableHead>
                                            <TableBody>
                                                {value.repositories.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">{row}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>

                                    </PerfectScrollbar>
                                </div>

                            </div>
                        </div>
                    </Box>
                </Grid>))
            }
            {props.users.map((value, index) => (
                <Grid item xs={12} sm={6} md={4}>
                    <Box boxShadow={3} className="p-3 m-1">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-7">
                                    <h6 className="text-overflow-ellipsis">Languages</h6>
                                </div>
                                <div className="col-md-5">
                                    <div className="float-right repos-badge">{value.languages.length === 1 ? value.languages.length + ' language' : value.languages.length + ' languages'}</div>
                                </div>
                                <div className="repo-list-style">
                                    <PerfectScrollbar>
                                        {value.languages.map((value, index) => (
                                            <ListItem key={index} className="px-0 pad-style">
                                                <ListItemIcon className="list-icon">
                                                    <img alt={value.language} width="20" src={"/images/language/" + value.language + ".png"} />
                                                </ListItemIcon>
                                                <ListItemText className="capitalize" primary={value.language + ' (' + value.percentage + '%)'} />
                                            </ListItem>
                                        ))}
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Grid>))
            }
            {props.users.map((value, index) => (
                <Grid item xs={12} sm={6} md={4} >
                    <Box boxShadow={3} className="p-3 m-1">
                        <div className="col-12 grid-bargraph">
                            <h6 className="float-left user-max-width heading-wrap text-overflow-ellipsis">Review Statistics ({reviewCount === 1 ? 'Total Review : ' + reviewCount : 'Total Reviews : ' + reviewCount})</h6>
                            <Pie data={{
                                labels:
                                    props.review.getStatusStatsByDate.map((id) => {
                                        return id.id
                                    }),
                                datasets: [{
                                    data: props.review.getStatusStatsByDate.map((count) => {
                                        return count.count
                                    }),
                                    borderWidth: 1,
                                    backgroundColor: ['#9ACD32', '#FFA500', '#DC143C']
                                }]
                            }}
                                options={{
                                    maintainAspectRatio: true,
                                }} />
                        </div>
                    </Box>
                </Grid>))
            }
            {props.users.map((value, index) => (
                <Grid item xs={12} sm={6} md={4}>
                    <Box boxShadow={3} className="p-3 m-1">
                        <div className="col-12 grid-bargraph">
                            <h6 className="float-left user-max-width heading-wrap text-overflow-ellipsis">Comments Vs UnResolvedComments</h6>

                            <Pie data={graph(value)}
                                options={{
                                    maintainAspectRatio: true,
                                }}
                            />
                        </div>
                    </Box>
                </Grid>))
            }
            {props.users.map((value, index) => (
                <Grid item xs={12} sm={6} md={4} >
                    <Box boxShadow={3} className="p-3 m-1">
                        <div className="col-12 grid-bargraph">
                            <h6 className="float-left user-max-width heading-wrap text-overflow-ellipsis">Languages Used</h6>
                            <Bar
                                data={{
                                    labels: value.languages.map((data) => {
                                        return data.language
                                    }),
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    datasets: [{
                                        data: value.languages.map((data) => {
                                            return data.percentage
                                        }),
                                        backgroundColor: colorPalette.chartColors(null, value.languages.length),
                                        borderWidth: 1,
                                        color: '#000000'
                                    }]
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    legend: false,
                                    plugins: {
                                        labels: {
                                            render: 'value',
                                            fontColor: '#000'
                                        }
                                    },
                                    tooltips: {
                                        callbacks: {
                                            title: function (tooltipItem, data) {
                                                return data['labels'][tooltipItem[0]['index']];
                                            },
                                            label: function (tooltipItem, data) {
                                                return `${data['datasets'][0]['data'][tooltipItem['index']]}%`;
                                            }
                                        }
                                    },
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                    }
                                }} />
                        </div>

                    </Box>
                </Grid>))
            }
        </Grid>

    )
}

export default UserAnalysisGrid;
