import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import FolderIcon from '@material-ui/icons/Folder';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import projectService from './projectService';

class AllProjectList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            projectList: [],
            checked: [],
            filterData: [],
        }
        this.projectList = new projectService();
        this.handleToggle = this.handleToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.projectList.getProjectList({
            languageName: this.props.languageName && this.props.languageName,
            ruleId: this.props.ruleId && this.props.ruleId,
        }).then((res) => {
            this.setState({ loading: false, projectList: res.data, filterData: res.data });
        })
    }

    handleChange(e) {
        var data = this.state.projectList.filter(item => {
            return item.projectName.toLowerCase().includes(e.target.value.toLowerCase());
        });

        this.setState({
            searchText: e.target.value,
            filterData: data
        });

    }

    handleToggle = value => () => {
        const currentIndex = this.state.checked.indexOf(value);
        const newProject = [...this.state.checked];
        if (currentIndex === -1) {
            newProject.push(value);
        } else {
            newProject.splice(currentIndex, 1);
        }
        this.setState({ checked: newProject });
        this.props.getList(newProject);
    };

    render() {
        if (this.state.loading) {
            return <div className="loading"><CircularProgress size={40} /></div>;
        }
        return (
            <List className="p-0" subheader={<h4>Projects</h4>}>
                <ListItem className="p-0">
                    <TextField
                        className="m-0"
                        id="search"
                        label="Search"
                        margin="normal"
                        value={this.state.searchText}
                        onChange={this.handleChange}
                    />
                </ListItem>
                {this.state.filterData.map((data, index) => {
                    return (
                        <ListItem key={index} dense button role={undefined} onClick={this.handleToggle(data.id)}>
                            <ListItemIcon>
                                <FolderIcon />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-wifi" primary={`${data.projectName} (${data.organizationName})`} secondary={data.repoGroupName + '/' + data.repoName} />
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    onChange={this.handleToggle(data.id)}
                                    checked={this.state.checked.indexOf(data.id) !== -1}
                                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}

            </List>
        );
    }
}

export default AllProjectList;
