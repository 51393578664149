import React, { Component } from 'react';
import './../common.css';
import Auth from '../service/auth';
var user = JSON.parse(localStorage.getItem('users'));
class Error404 extends Component {
    render() {
        return (
            <section className="page_404">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                    <div className="col-sm-10 text-center">
                    <div className="four_zero_four_bg">
                        <h1 className="text-center ">404</h1>
                    </div>
                    
                    <div className="contant_box_404">
                    <h3 className="h2">
                    Look like you're lost
                    </h3>
                    
                    <p>The page that you are looking for is not available!</p>
                    
                    <a href={Auth.hasRole(user, ['ROLE_EXECUTIVE']) ? "/home" : "/reviews"} className="link_404">Go to Home</a>
                </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Error404;