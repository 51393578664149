import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

class CopyingRuleDetails extends Component {
	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
		this.getColor = this.getColor.bind(this);
	}

	getColor = (copyFlag) => {
		if (copyFlag === 'New') return 'green';
		if (copyFlag === 'Remove') return 'red';
		return '';
	};

	handleClose = () => {
		this.props.handleClose();
	}
	render() {
		return (
			<div>
				<Dialog open={this.props.showModal} fullWidth={true} maxWidth={"xl"} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">Rule details ({this.props.details.languageName}) </DialogTitle>
					<div className="text-center">
						<DialogActions>
							<DialogContent dividers>
								<table className="table w-100">
									<thead>
										<tr>
											<th>S.No</th>
											<th>Name</th>
											<th>Category</th>
											<th>Severity</th>
											<th>Path</th>
										</tr>
									</thead>
									<tbody>
										{this.props.details.ruleRequests.map((rule, i) => {
											return (<tr style={{ color: this.getColor(rule.copyFlag) }}>
												<td>{i + 1}</td>
												<td>{rule.ruleName}</td>
												<td>{rule.ruleCategory}</td>
												<td>{rule.ruleSeverity ? rule.ruleSeverity : '-'}</td>
												<td>{rule.rulePath ? rule.rulePath : '-'}</td>
											</tr>)
										})}
									</tbody>
								</table>
							</DialogContent>
						</DialogActions>
					</div>
					<DialogActions>
						<Button onClick={this.handleClose} color="secondary" >
							close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default CopyingRuleDetails;