import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';

import './login.css';
import Snackbars from './../error/Snackbars';
import { loginService } from './services/login-service';
import Auth from '../service/auth';

class Login extends React.Component {

    constructor(props) {
        super(props);
        if (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('users')).grantedAuthorities) {
            if (JSON.parse(localStorage.getItem('users')).grantedAuthorities.map(role => role.authority).includes('ROLE_EXECUTIVE')) {
                props.history.push("/home")
            } else {
                props.history.push("/reviews")
            }
        }

        this.state = {
            usernameOrEmail: '',
            password: '',
            submitted: false,
            loading: false,
            error: '',
            formErrors: {
                usernameOrEmail: "",
                password: "",
                error: "",
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleSnackbars = this.toggleSnackbars.bind(this);
    }

    toggleSnackbars() {
        this.setState({
            showSnackbar: false
        })
    }

    validate(activeKey) {
        var form = document.getElementById("login");
        form.reportValidity()
        if (form.checkValidity()) {
            this.setState({
                activeKey: activeKey,
            });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.setState({ error: ''});
    }

    onSubmit(event) {
        var currentPage = JSON.parse(localStorage.getItem('currentPage'));
        event.preventDefault();

        this.setState({ submitted: true });
        const { usernameOrEmail, password } = this.state;

        this.setState({ loading: true });
        loginService.login(usernameOrEmail, password)
            .then(
                user => {
                    loginService.getCurrentUser().then(users => {
                        if (currentPage && users.grantedAuthorities.length && !Auth.hasRole(users, ['ROLE_SUPER_ADMIN'])) {
                            window.location.href = currentPage
                        } else {
                            if (Auth.hasRole(users, ['ROLE_SUPER_ADMIN'])) {
                                window.location.href = "/admin"
                            } else if (Auth.hasRole(users, ['ROLE_ADMIN']) || Auth.hasRole(users, ['ROLE_EXECUTIVE'])) {
                                window.location.href = "/home"
                            } else {
                                window.location.href = "/reviews"
                            }
                        }
                    });
                },
                error => {
                    this.setState({
                        showSnackbar: true
                    })
                    this.setState({ error, loading: false })
                }
            );
    }

    render() {
        const { loading, error } = this.state;
        return (
            <div className="login-bg">
                <div className="login-container">
                    <div className="offset-md-8 col-md-4 login-box">
                        <div className="row">
                            <div className="col-md-12">
                                <ValidatorForm id="login" name="form" onSubmit={this.onSubmit}>
                                    <div className="offset-md-1 col-md-10 mt-5 mb-4 pb-3 text-center">
                                        <img src="/images/nav-logo.png" alt="Logo" />
                                    </div>
                                    <div className="col-md-10 offset-md-1">
                                        {error && error==="Bad credentials" && <Snackbars status="error" message="Username or password is incorrect" showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />}
                                        {error && error!="Bad credentials" && <Snackbars status="error" message={error} showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />}
                                        <TextValidator className="mb-1"
                                            name="usernameOrEmail"
                                            label="Email / User Name"
                                            type="text"
                                            validators={['required']}
                                            value={this.state.usernameOrEmail}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            errorMessages={['This field is Required']}
                                        />
                                    </div>
                                    <div className="col-md-10 pt-1 pb-1 offset-md-1">
                                        <TextValidator className="mb-1"
                                            name="password"
                                            label="Password"
                                            type="password"
                                            validators={['required']}
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            errorMessages={['This field is Required']}
                                        />
                                    </div>
                                    <div className="col-md-10 mt-5 offset-md-1 pb-1">
                                        <Button color="primary" type="submit" variant="contained" className="w-100" disabled={loading}>
                                            {loading ? <CircularProgress disableShrink size={24} /> : 'Login'}
                                        </Button>
                                    </div>
                                    <div className="col-md-10 mt-5 offset-md-1 pt-3 text-center">
                                        <a href="/signup">Create Account</a>
                                        <br></br>
                                        <br></br>
                                        <a href="/forgot-password">Forgot Password?</a>

                                    </div>
                                </ValidatorForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;