import React, { Component } from 'react';
import { Chart, Bar } from 'react-chartjs-2';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import zoom from 'chartjs-plugin-zoom'
import IconButton from '@material-ui/core/IconButton';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import Skeleton from '@material-ui/lab/Skeleton';

const REVIEW_STATUS_BY_PROJECT = gql`
query reviewStatusByProject($projectGroup: String) {
    reviewStatusByProject(projectGroup: $projectGroup) {
        _id
        totalPRCount
        status  {
            name
            count
        }
    }
}`;

class ReviewStatusByProject extends Component {
    userId;
    constructor(props, context) {
        super(props, context)
        this.resetZoom = this.resetZoom.bind(this);
        this.chartDragOn = this.chartDragOn.bind(this);
        this.chartDragOff = this.chartDragOff.bind(this);
        this.state = {
            showDeleteModal: false,
            deleteModal: false,
            open: false,
            drag: true,
        };

        this.prepareReviewStatusByProject = function (data) {
            if (data) {
                var datasets = [
                    {
                        data: [],
                        backgroundColor: '#f5005799',
                        borderColor: '#f5005700',
                        label: 'Needs Rework',
                    },
                    {
                        data: [],
                        backgroundColor: '#12C53999',
                        borderColor: '#12C53900',
                        label: 'Resolved',
                    },
                    {
                        data: [],
                        backgroundColor: '#2678CB99',
                        borderColor: '#2678CB00',
                        label: 'New',
                    },
                ];
                data.forEach((data) => {
                    if (data.status.length && data.status.length === 1) {
                        data.status.push({
                            count: 0
                        })
                    }
                    data.status.forEach((data, index) => {
                        datasets[index].data.push(data.count);
                    });
                });
            }

            return {
                responsive: true,
                maintainAspectRatio: false,
                labels: data.map((data) => { return data._id }),
                datasets: datasets
            }
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleSubmitObject = this.handleSubmitObject.bind(this);

    }

    handleClose() {
        this.props.onClose();
    }

    handleSubmitObject() {
        var userId = this.props.userData.id
        return userId;
    }

    componentWillMount() {
        Chart.plugins.register(zoom)
    }

    resetZoom() {
        this.barChart.chartInstance.resetZoom();
    }

    chartDragOn() {
        this.setState({
            drag: false
        })
    }

    chartDragOff() {
        this.setState({
            drag: true
        })
    }

    render() {
        return (
            this.props.showProjectModel && <Query fetchPolicy="no-cache" query={REVIEW_STATUS_BY_PROJECT} variables={{projectGroup: this.props.projectGroup}}>
                {({ loading, error, data, refetch }) => {
                    if (error) return (
                        <div className="text-center"></div>
                    )
                    if (loading) return (
                        <div className="row p-2">
                            <div className="col-sm-12 chart-container p-4 bg-white my-0">
                                <div className="row mb-4">
                                    <div className="col-lg-10">
                                        <Skeleton variant="text" width={"150px"} />
                                        <Skeleton variant="text" width={"100px"} />
                                    </div>
                                </div>
                                <Skeleton variant="rect" width={"100%"} height={"186px"} />
                            </div>
                        </div>
                    );
                    return (
                        <div className="p-2">
                            <Dialog open={this.props.showProjectModel} fullWidth={true} maxWidth={"sm"} onClose={this.props.handleCancel} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">
                                <div className="row pr-0">
                                <div className="col-lg-8 pr-0">
                                    <h4>Review Status By Repositories</h4>
                                </div>
                                <div className="col-lg-4 pr-0">
                                        {data.reviewStatusByProject && <Tooltip title={'Reset Zoom'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.resetZoom()}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {data.reviewStatusByProject && this.state.drag && <Tooltip title={'Select the Chart to Magnify'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.chartDragOn()}>
                                                <BlurOnIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {data.reviewStatusByProject && !this.state.drag && <Tooltip title={'Scroll the Chart to Magnify'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.chartDragOff()}>
                                                <BlurOffIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </div>
                                    </div>
                                </DialogTitle>
                                <DialogContent dividers>
                                    <div className="row pr-0">
                                        <div className="col-md-12 pr-0" style={{ width: '100%', height: '186px' }}>
                                            {<Bar data={this.prepareReviewStatusByProject(data ? data.reviewStatusByProject : [])}
                                                    ref={(ref) => this.barChart = ref}
                                                    options={{
                                                        maintainAspectRatio: false,
                                                        pan: {
                                                            enabled: true,
                                                            mode: 'x',
                                                            speed: 10,
                                                            threshold: 10
                                                        },
                                                        zoom: {
                                                            enabled: true,
                                                            drag: this.state.drag,
                                                            mode: 'xy'
                                                        },
                                                        scales: {
                                                            yAxes: [{
                                                                ticks: {
                                                                    beginAtZero: true
                                                                }
                                                            }]
                                                        },
                                                        onClick: (e, item) => {
                                                            item.forEach((data) => {
                                                                this.setState({
                                                                    projectGroup: data._model.label
                                                                })
                                                            })
                                                        }
                                                    }} />
                                            }
                                        </div>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus onClick={this.props.handleCancel} color="secondary">
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    )
                }
                }
            </Query>
        );
    }
}

export default ReviewStatusByProject;