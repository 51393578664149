import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import '../modals/modal.css';
import { avatarImage } from '../../components/service/avatar-image';

class AuthorCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            classes: makeStyles(theme => ({
                card: {
                    maxWidth: 345,
                },
                media: {
                    height: 0,
                    paddingTop: '56.25%', // 16:9
                },
                expand: {
                    transform: 'rotate(0deg)',
                    marginLeft: 'auto',
                    transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                    }),
                },
                expandOpen: {
                    transform: 'rotate(180deg)',
                },
                avatar: {
                    backgroundColor: red[500],
                },
                cardHeader: {
                    maxWidth: '150px',
                },
                cardTitle: {
                    overflow: 'hidden',
                }

            })),


        }
    }

    render() {
        if (!this.props.cardData) return '';
        return (
            <div>
                <Card className={this.state.classes.card}>
                <CardHeader
                    avatar={
                    <Avatar aria-label="recipe" className={this.state.classes.avatar}>
                        <img src={avatarImage.getImageLocation()} alt="Avatar" width="100%"/>
                    </Avatar>
                    }
                    action={
                    <IconButton aria-label="settings">
                        <i className="material-icons">more_vert</i>
                    </IconButton>
                    }
                    title={this.props.cardData.displayName}
                    subheader={this.props.cardData.emailAddress}
                />
                <CardActions disableSpacing>
                        <IconButton aria-label="share" color="secondary">
                            <i className="material-icons">fingerprint</i> 
                        </IconButton>
                        <b>{this.props.user}</b>
                </CardActions>
                </Card>
            </div>
        );
    }
}

export default AuthorCard;