import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { resetFilterService } from '../../core/login/services/reset-filter';
import ProjectList from './project-list';
import ProjectGroupList from './project-group-list';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            className="w-100"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={1}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class ProjectManagement extends Component {
    userId;
    constructor(props) {
        super(props);
        resetFilterService.resetFilters();
        this.state = {
            showEditModal: false,
            showModal: false,
            activeKey: 0,
            open: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
    }

    handleChange = (event, newValue) => {
        this.setState({
            activeKey: newValue
        })
    };

    handleDialog() {
        this.props.onClose();
        this.props.refresh();
    }

    render() {
        return (
            <div className="pl-3 pr-3">
                <Tabs
                    value={this.state.activeKey}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="auto"

                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >

                    <Tab className="admin-managment" label="Projects" />
                    <Tab className="admin-managment" label="Repositories" />
                </Tabs>
                <TabPanel className="admin-tabs p-0" value={this.state.activeKey} index={0}>
                    <ProjectGroupList />
                </TabPanel>
                <TabPanel className="admin-tabs p-0" value={this.state.activeKey} index={1}>
                    <ProjectList />
                </TabPanel>
            </div>
        );
    }
}

export default ProjectManagement;