import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import RuleSetLanguageTabs from './RulesetLanguageTabs';

const GET_All_LANGUAGES = gql`
query languages {
    languages {
        languageName
        numOfRules
        numOfActiveRules
        description
        enabled
        extensions
        analyzerEngines
      }
}
`;

const LANGUAGES_BY_PROJECT_ID = gql`
query languagesByProjectId($projectId: Long!) {
    languagesByProjectId(projectId: $projectId) {
        languageName
        numOfRules
        numOfActiveRules
        description
        enabled
        extensions
        analyzerEngines
      }
}
`;

class RuleManagement extends Component {
    userId;
    constructor(props) {
        super(props)
        this.state = {
            userData: [],
        }
        this.updateRuleSet = this.updateRuleSet.bind(this);
    }

    updateRuleSet(data) {
        if (this.props.projectId && this.props.saveByProject) {
            this.props.saveByProject(data);
        }
    }

    render() {
        return (
            <div className="col-md-12 p-0">
                <Query query={this.props.projectId ? LANGUAGES_BY_PROJECT_ID : GET_All_LANGUAGES} variables={{
                    projectId: this.props.projectId
                }}>
                    {({ loading, error, data, refetch }) => {
                        if (loading) return null;
                        if (error) return null;
                        return (
                            <div className="row rule-managment">
                                <div className="col-md-12 pb-5">
                                    <RuleSetLanguageTabs data={this.props.projectId ? data.languagesByProjectId : data.languages.filter(data => data.enabled)}
                                        onRef={ref => (this.child = ref)}
                                        projectId={this.props.projectId || null}
                                        project={this.props.project}
                                        reviewFlag={this.props.reviewFlag}
                                        ruleSet={this.updateRuleSet}
                                        refresh={() => refetch()} />
                                </div>
                            </div>
                        );
                    }}
                </Query>
            </div>
        );
    }
}

export default RuleManagement;