import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import moment from 'moment';
import { avatarImage } from '../../../components/service/avatar-image';

import './manualHistory.css';

class ManualHistoryList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {};
        this.getUserProfile = this.getUserProfile.bind(this);
    }

    getUserProfile(id) {
        return (
            <div className="avatar">
                <img src={avatarImage.getImageLocation(id)} alt="User Profile" className="align-self-start" width="30" />
            </div>
        )
    }
    render() {
        if (!this.props.data || this.props.isReviewComments) {
            return (
                <div className="">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="ml-5 mt-5">
                                <h5>Note</h5>
                                {this.props.isReviewComments && this.props.isApprovel && <p className="text-justify">You can use this action if you want to approve all requests that are sent to ignore the comments in a single shot. Please be mindful that after performing the action, all <b>QBot comments</b> that are sent for approval will be ignored for the request.</p>}
                                {this.props.isReviewComments && !this.props.isApprovel && <p className="text-justify">Tired of sending requests to ignore the comments individually when you want to merge the changes in case of hotfix or business urgency? You have come to the right place. You can send the request to ignore all <b>QBot comments</b> in a single shot.</p>}
                                {!this.props.isReviewComments && this.props.isManualReviewStatus === 'Manual Comments Added' && <p className="text-justify">This action can be performed when you addressed all manual comments added for the request. Reviewers will be notified when you perform this action to review the changes.</p>}
                                {!this.props.isReviewComments && this.props.isApprovel && (this.props.isManualReviewStatus === 'Manual Comments Addressed' || this.props.isManualReviewStatus === 'Manual Review Required') && <p className="text-justify">You can use this action to approve the review when you complete the manual review. The review will be marked as <b>Manual Review Completed</b>.</p>}
                            </div>
                        </div>
                        <div className="col-md-7 text-center">
                            <img src="/images/request.jpg" alt="request" className="manualHistory-list-info-img" />
                        </div>
                    </div>
                    
                </div>
            )
        }
        return (
            <div className="manual-comment-history">
                <Stepper activeStep={this.props.data.length} orientation="vertical">
                    {this.props.data.map((data, index) => (
                        <Step key={index}>
                            <StepLabel StepIconComponent={() => this.getUserProfile(data.user.id)}><h6 className="text-primary">{data.user.name}</h6></StepLabel>
                            <StepContent active={true}>
                                <span className="inner-comment-content">
                                    <p className="p-0">{data.message}</p>
                                    <hr className="p-0 m-0"></hr>
                                    <p className="actions pt-2 pb-0">
                                        {data.type !== null && <span>Status: <span className="secondary cursor-pointer">{data.type}</span></span>}
                                        <span className="text-muted float-right cursor-pointer" title="Created Date">{moment(data.createdOn).format('YYYY/MM/DD HH:mm A')}</span>
                                    </p>
                                </span>
                            </StepContent>
                           
                        </Step>
                    ))}
                </Stepper>
            </div>
        )
    }
}

export default ManualHistoryList;