import ClinetServices from '../../../core/service/clientUri';
import gql from 'graphql-tag';

var pageSize = 10;
var pageNumber = 0;
var filterBy = {};
var sortBy = 'projectName'
var sortGroupBy = 'projectGroupName'

const PROJECT_REPO_TOKEN = gql`
query validateRepoAccessToken($repoGroupName: String!, $repoName: String!, $repoAccessToken: String, $versionControl: String) {
    validateRepoAccessToken (repoGroupName: $repoGroupName, repoName: $repoName, repoAccessToken: $repoAccessToken, versionControl: $versionControl)
}`;

const GET_PROJECT_LIST = gql`
query projects($pageSize: Int, $pageNumber: Int, $sortBy: String, $direction: Direction, $filterBy: ProjectInput) {
      projects (pageNumber: $pageNumber, pageSize: $pageSize, sortBy: $sortBy, direction: $direction, filterBy: $filterBy) {
        content {
          __typename
          ... on Project {
            id
            projectName
            projectKey
            repoGroupName
            repoName
            repoUserToken
            isRepoAccessValid
            lastRepoValidCheckTime
            createdOn
            repoLanguages
            projectGroupId
            projectGroupName
            }
        }
        totalPages
        totalElements
        last
        first
        numberOfElements
        size
        number
        empty		
    }
}
`;

const GET_PROJECT_GROUP_LIST = gql`
query projectGroups($pageSize: Int, $pageNumber: Int, $sortBy: String, $direction: Direction, $filterBy: ProjectGroupInput) {
    projectGroups(pageNumber: $pageNumber, pageSize: $pageSize, sortBy: $sortBy, direction: $direction, filterBy: $filterBy) {
        content {
          __typename
          ... on ProjectGroup {
            id
            projectGroupName
            organizationName
            repoAccessToken
            description
            ownerName
            ownerEmailAddress
            createdOn
            }
        }
        totalPages
        totalElements
        last
        first
        numberOfElements
        size
        number
        empty		
    }
}
`;

class reviewServices {
    constructor(props) {
        this.getFromClintList = this.getFromClintList.bind(this);
        this.getProjectList = this.getProjectList.bind(this);
        this.getFromClintGroupList = this.getFromClintGroupList.bind(this);
        this.getProjectGroupList = this.getProjectGroupList.bind(this);
        this.getFromClintRepoToken = this.getFromClintRepoToken.bind(this);
        this.getProjectRepoToken = this.getProjectRepoToken.bind(this);
    }

    async getFromClintList(client) {
        var response = await client.query({
            query: GET_PROJECT_LIST,
            variables: { pageSize: pageSize, pageNumber: pageNumber, filterBy: filterBy, sortBy: sortBy, direction: 'ASC' }
        });
        return await response.data.projects;
    }

    async getFromClintGroupList(client) {
        var response = await client.query({
            query: GET_PROJECT_GROUP_LIST,
            variables: { pageSize: pageSize, pageNumber: pageNumber, filterBy: filterBy,sortBy: sortGroupBy, direction: 'ASC' }
        });
        return await response.data.projectGroups;
    }

    async getFromClintRepoToken(client, projectToken) {
        var response = await client.query({
            query: PROJECT_REPO_TOKEN,
            variables: { ...projectToken }
        });
        return await response.data.validateRepoAccessToken;
    }

    getProjectList(query, filter) {
        if (query) {
            pageSize = query.pageSize;
            pageNumber = query.page;
            filterBy = filter;
        }

        return this.getFromClintList(ClinetServices.getClienUri()).then(res => {
            return {
                data: res.content,
                page: res.number,
                totalCount: res.totalElements,
            }
        }, error => {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    }

    getProjectGroupList(query, filter) {
        if (query) {
            pageSize = query.pageSize;
            pageNumber = query.page;
            filterBy = filter;
        }

        return this.getFromClintGroupList(ClinetServices.getClienUri()).then(res => {
            return {
                data: res.content,
                page: res.number,
                totalCount: res.totalElements,
            }
        }, error => {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    }

    getProjectRepoToken(projectToken) {

        return this.getFromClintRepoToken(ClinetServices.getClienUri(), projectToken).then(res => {
            return {
                data: res,
            }
        }, error => {
            return {
                data: [],
            }
        });
    }

}

export default reviewServices;