import React, { Component } from 'react';
import './../common.css';

class ErrorType extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="text-center">
                <img src={"/images/error/" + this.props.type + ".png"} alt="Error" width={this.props.size} />
                <h3>{this.props.head}</h3>
                <p>{this.props.content}</p>
            </div>
        );
    }
}

export default ErrorType;