import React, { Component } from 'react';
import ApplicationSummary from '../components/charts/application-summary';
import ReviewsByDateRange from '../components/charts/reviews-by-date-range';
import ReviewsByUsers from '../components/charts/reviews-by-users';
import PRStatusByProjects from '../components/charts/pr-status-by-projects';
import ReviewsByProjects from '../components/charts/reviews-by-projects';
import ReviewsCommentsByDateRange from '../components/charts/reviews-comments-by-date-range';
import { resetFilterService } from '../../core/login/services/reset-filter';

class Home extends Component {

    constructor(props) {
        super(props);
        resetFilterService.resetFilters();
    }

    render() {
        return (
            <div className="warper bg-gray">
                <div className="w-100">
                    <div className="row m-0">
                        <div className="col-md-7">
                            <ReviewsByDateRange />
                            {/* <ViolatedRulesByLanguageByMonthChart /> */}
                            <ReviewsCommentsByDateRange />
                        </div>
                        <div className="col-md-5">
                            <div className="row right-container">
                                <div className="col-12">
                                    <ApplicationSummary />
                                </div>
                                <div className="col-12">
                                    <ReviewsByUsers />
                                </div>
                                <div className="col-12 p-2">
                                    <ReviewsByProjects />
                                </div>
                                <div className="col-12 p-2">
                                    <PRStatusByProjects />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;