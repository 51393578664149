import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

import StashView from './../../components/diff-view/Index';
import '../modals/modal.css';


class StashModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSn: false,
        }
        this.handleClose = this.handleClose.bind(this);
        this.getCommentObject = this.getCommentObject.bind(this);
    }

    handleClose() {
        this.props.onUpdate();
        this.props.onRefresh();
    }

    getCommentObject(data) {
        var commentObj = {};
        data.forEach((e) => {
            commentObj[e.location.lines.begin] ?  commentObj[e.location.lines.begin].push(e) : commentObj[e.location.lines.begin] = [e]
        });
        return commentObj;
    }
    
    render() {
        if (!this.props.stashComments) return null;
        return (
            <div>
                <Dialog
                    fullScreen={window.innerWidth < 600 || this.state.isFullScreen}
                    open={this.props.showModal}
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth={"lg"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle>
                        Diff View 
                        <span className="float-right cursor-pointer" onClick={() => {this.setState({isFullScreen : !this.state.isFullScreen})}}>
                            {this.state.isFullScreen ? <i className="material-icons">fullscreen_exit</i> : <i className="material-icons">fullscreen</i>}
                        </span>
                    </DialogTitle>
                    <Divider />
                        <StashView reviewId={this.props.reviewId} state={this.props.state} stashComments={this.props.stashComments} />
                    <Divider />
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary" autoFocus>
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default StashModal;