import React from 'react';
import MaterialTable from 'material-table';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';

class Report extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            RepoColumnDefs: [{
                title: "Repository Name", field: "_id"
            }, {
                title: "Total Reviews", field: "totalPRCount"
            }, {
                title: "Resolved", field: "status", render: function (params) {
                    return params.status[1].count
                }
            }, {
                title: "Needs Work", field: "status", render: function (params) {
                    return params.status[0].count
                }
            }, {
                title: "New", field: "status", render: function (params) {
                    return params.status[2].count
                }
            }],
            contributersColumnDefs: [{

               title: "Name", field: "developer.name", render: function (params) {
                    return params.developer.name
                }
            }, {
                title: "email", field: "developer.email", render: function (params) {
                    return params.developer.emailId
                }
            }, {
                title: "Lines of Code", field: "numOfLinesAdded"
            }, {
                title: "Reviews", field: "numOfReviews"
            }, {
                title: "Code Quality", field: "qualityPercentage", render: function (params) {
                    return (<Tooltip title={params.qualityPercentage + '%'} placement="top"><div className={params.qualityPercentage > 50
                        ? params.qualityPercentage > 75
                            ? "heigh-quality"
                            : "medium-quality"
                        : "low-quality"
                    }><LinearProgress variant="determinate" value={params.qualityPercentage} />{params.qualityPercentage.toFixed(2)}%</div></Tooltip>);
                }
            }]
        }
    }

    render() {
        return (
            <div >
                <div className="py-2">
                    <h2 className="ml-4 mb-2">Contributors</h2>
                    <MaterialTable
                        title="Contributs"
                        tableRef={this.tableRef}
                        onRowClick={(event, rowData) => { this.handleDialog(rowData) }}
                        columns={this.state.contributersColumnDefs}
                        data={this.props.users}
                        options={{
                            filtering: false,
                            toolbar: false,
                            headerStyle: {
                                padding: '5px',
                                fontSize: '12px',
                                fontWeight: "bold",
                                color: 'black',
                            },
                            paging: false
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default Report;