import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import BaseTree from './TreeChild';
import React, { Component } from 'react';

class TreeComponent extends Component {
    constructor(props) {
        super(props);
        this.updateFile = this.updateFile.bind(this);
    }

    updateFile(data) {
        this.props.updateFile(data)
    }

    render() {
        return (
            <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultExpanded={this.props.node}
                defaultEndIcon={<div style={{ width: 24 }} />}
            >
                <BaseTree updateFile={this.updateFile}  data={this.props.data} />
            </TreeView>
        );
    }
}

export default TreeComponent;