import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import rulesetService from './service/ruleset-service';
import RulesConfiguration from './SaveRulesConfiguration';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';

import RulesetCategory from './RulesetCategory'
import Snackbars from '../../../core/error/Snackbars';
import Auth from './../../../core/service/auth';

var user = JSON.parse(localStorage.getItem('users'));

var rules;
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function allOtherProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

class RuleSetLanguageTabs extends Component {
    constructor(props) {
        super(props);
        this.ruleService = new rulesetService();
        this.state = {
            value: 0,
            loading: true,
            showModal: false,
            ruleList: [],
            editRule: true,
            selectedRules: [],
            ruleSetByLanguage: props.data,
            queryLanguage: '',
            showSnackbar: false,
            showRequest: null,
            currentLanguage: null,
            changelog: {
                enabled: [],
                disabled: [],
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.setCategories = this.setCategories.bind(this);
        this.getData = this.getData.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleRequestClick = this.handleRequestClick.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.toggleSnackbars = this.toggleSnackbars.bind(this);

    }

    componentDidMount() {
        rules = null;
        this.handleChange(null, 0);
        this.props.onRef(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ruleSetByLanguage: nextProps.data
        });
    }

    refresh() {
        if (this.props.refresh) {
            this.props.refresh()
        }
    }

    toggleSnackbars() {
        this.setState({
            showSnackbar: !this.state.showSnackbar
        })
    }

    handleClose(showMessage) {
        rules = null;
        this.refresh();
        this.setState({
            showSnackbar: showMessage,
            showModal: !this.state.showModal,

            selectedRules: []
        });

        this.child.cleanData();
        this.setState({ruleSetByLanguage: []})
    }

    handleRequestClose() {
        this.setState({ showRequest: null });
    }

    handleRequestClick(event, data) {
        this.setState({ showRequest: event.currentTarget, currentLanguage: data });
    }

    handleChange = (event, newValue, d) => {
        this.setState({ value: newValue, queryLanguage: this.state.ruleSetByLanguage && this.state.ruleSetByLanguage[newValue] && this.state.ruleSetByLanguage[newValue].languageName });
        this.setState({ loading: true });
        this.ruleService.getLanguagesList(this.state.ruleSetByLanguage && this.state.ruleSetByLanguage[newValue] && this.state.ruleSetByLanguage[newValue].languageName, this.props.projectId).then((res) => {
            this.setState({ ruleSetByLanguage: res.data });
        });
        this.ruleService.getRulesList(this.state.ruleSetByLanguage && this.state.ruleSetByLanguage[newValue] && this.state.ruleSetByLanguage[newValue].languageName, this.props.projectId).then((res) => {
            this.setState({ ruleList: res.data }, () => {
                this.setState({ loading: false });
            });
        });
        if (rules) {
            this.setState({
                showModal: !this.state.showModal
            });
        }
    }

    getData(data) {
        return data[0] ? data[0].categories : [];
    }

    setCategories(data, config, idList) {
        rules = data;
        this.setState({
            selectedRules: idList,
            ruleData: data
        });
    }

    render() {
        return (
            <div className="ruleSet-tabs">
                <Snackbars status="success" message={"Rule Configuration saved Successfully.."} showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />
                <div>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {this.props.data ? this.state.ruleSetByLanguage.map((data, index) => {
                                return <Tab key={index} onMouseLeave={this.handleRequestClose} onMouseOver={(e) => { this.handleRequestClick(e, data) }} label={<span>{data.languageName || ''} ({data.numOfActiveRules}/{data.numOfRules})</span>} icon={<img alt={data.languageName} width="10" src={"/images/language/" + data.languageName + ".png"} />} {...allOtherProps(index)} />
                            }) : null}
                        </Tabs>
                    </AppBar>
                    <TabPanel key={0} value={0} index={0}>
                        <div>
                            {!this.state.loading
                                ? <RulesetCategory ruleFlag={this.state.editRule} refresh={(e) => {
                                    e && this.handleChange(null, this.state.value, null)
                                }} projectId={this.props.projectId}
                                    reviewFlag={this.props.reviewFlag}
                                    languageName={this.state.ruleSetByLanguage[0] && this.state.ruleSetByLanguage[0].languageName}
                                    data={this.state.ruleList} onRef={ref => (this.child = ref)}
                                    category={this.setCategories} />
                                : <div className="loading"><CircularProgress size={40} /></div>
                            }
                        </div>
                    </TabPanel>
                </div>
                {!this.props.reviewFlag || !!Auth.hasRole(user, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])
                    ? <div className="row">
                        <div className="col-md-12 text-right">
                            <Button disabled={!rules} onClick={(e) => { this.setState({ showModal: true }); }} color="primary" variant="contained" autoFocus>
                                Save Changes
                        </Button>
                        </div>
                    </div>
                    : null
                }

                {this.state.showModal && <RulesConfiguration showModal={this.state.showModal}
                    onUpdate={this.handleClose}
                    projectId={this.props.projectId}
                    project={this.props.project}
                    ruleData={this.state.ruleData}
                    data={this.state.selectedRules} />}

                {this.state.currentLanguage && <Popover
                    open={!!this.state.showRequest}
                    className="pointer-event"
                    anchorEl={this.state.showRequest}
                    onClose={this.handleRequestClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableRestoreFocus>
                    <div className="row" style={{ maxWidth: '500px' }}>
                        <div className="col-md-12">
                            <h4 className="m-3">Language Info</h4>
                            <Divider />
                        </div>

                        <div className="col-md-4">
                            <h6 className="ml-3 mt-3 mb-3">Language Name</h6>
                            <div className="ml-3 mt-3 mr-3">{this.state.currentLanguage && this.state.currentLanguage.languageName}</div>
                        </div>
                        <div className="col-md-4">
                            <h6 className="ml-3 mt-3 mb-3">Number of Rules</h6>
                            <div className="ml-3 mt-3 mr-3 ">{this.state.currentLanguage && this.state.currentLanguage.numOfActiveRules}/{this.state.currentLanguage.numOfRules}</div>
                        </div>
                        <div className="col-md-4">
                            <h6 className="ml-3 mt-3 mb-3">Extensions</h6>
                            <div className="mb-3 ml-3 mt-3 mr-3 ">{this.state.currentLanguage && this.state.currentLanguage.extensions && this.state.currentLanguage.extensions.map(data => {
                                return data
                            })}</div>
                        </div>
                        <div className="col-md-12">
                            <h6 className="ml-3 mt-3 mb-3">Description</h6>
                            <div className="mb-3 ml-3 mt-3 mr-3 ">{this.state.currentLanguage && this.state.currentLanguage.description}</div>
                        </div>
                    </div>
                </Popover>}
            </div>);
    }
}

export default RuleSetLanguageTabs;
