import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import '../modals/modal.css';


class CommentErrorModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSn: false,
        }
        this.handleClose = this.handleClose.bind(this);
        this.copyText = this.copyText.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
    }

    handleClose() {
        this.props.onUpdate();
    }

    closeSnackbar() {
        this.setState({
            openSn: false
        })
    }

    copyText(event) {
        var copyText = event.target.innerHTML;
        var textField = document.createElement('textarea');
        textField.innerText = copyText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({ openSn: true })
    }

    render() {
        if (!this.props.commentData) return '';
        return (
            <div>
                <Dialog
                    open={this.props.showModal}
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth={"sm"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">

                    <DialogTitle>{this.props.commentData && <Tooltip title="Rule Name" placement="top" aria-label="Language Name">
                        <span>{this.props.commentData.checkName || 'Manual Comments'}</span>
                    </Tooltip>}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="mt-4">Description</h6>
                                <div>{this.props.commentData.description}</div>
                                <h6 className="mt-4">Location</h6>
                                <DialogContentText>
                                    <span title="Click to Copy" onClick={this.copyText}>{this.props.commentData.location.path}</span>
                                </DialogContentText>
                                
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12 pl-3">
                                <p>
                                    <b>Start Line</b> <span className="secondary">{this.props.commentData.location.lines.begin}</span>
                                </p>
                                <p className="mt-4">
                                    <b>End Line</b> <span className="secondary">{this.props.commentData.location.lines.end}</span>
                                </p>
                            </div>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary" autoFocus>
                            close
                        </Button>
                    </DialogActions>

                </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={this.state.openSn}
                autoHideDuration={1000}
                onClose={this.closeSnackbar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">Copied!</span>}
                action={[
                    <Button key="undo" color="secondary" size="small" onClick={this.closeSnackbar}>
                        Ok
                </Button>,
                ]}
            />

            </div>
        );
    }
}

export default CommentErrorModal;