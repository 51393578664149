import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import CommentsAnalyzis from '../../components/charts/comments-analyzis';
import './modal.css';

class ReviewStatistic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSn: false,
            reviewRequestId: props.reviews.id
        }
        this.handleClose = this.handleClose.bind(this);
        this.copyText = this.copyText.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
    }

    handleClose() {
        this.props.onUpdate();
    }

    closeSnackbar() {
        this.setState({
            openSn: false
        })
    }

    copyText(event) {
        var copyText = event.target.innerHTML;
        var textField = document.createElement('textarea');
        textField.innerText = copyText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({ openSn: true })
    }

    render() {
        if (!this.state.reviewRequestId) return '';
        return (
            <div>
                <Dialog
                    open={this.props.showModal}
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth={"md"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle>{'Review Statistics'}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12">
                                <CommentsAnalyzis reviewRequestId={this.state.reviewRequestId} />
                            </div>

                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary" autoFocus>
                            close
                    </Button>
                    </DialogActions>

                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.openSn}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Copied!</span>}
                    action={[
                        <Button key="undo" color="secondary" size="small" onClick={this.closeSnackbar}>
                            Ok
                    </Button>,
                    ]}
                />

            </div>
        );
    }
}

export default ReviewStatistic;
