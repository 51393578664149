import htmlLint from './htmlLint';
import esLint from './esRules';
import tsLint from './tsRules';
import styleLint from './styleRules';

export default {
    html: htmlLint,
    javascript: esLint,
    typescript: tsLint,
    style: styleLint
}