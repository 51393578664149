import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../admin.css';
import Error500 from '../../../core/error/error';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import MaterialTable from 'material-table';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import LazyLoad from 'react-lazyload';
import Skeleton from '@material-ui/lab/Skeleton';
import { forceCheck } from 'react-lazyload';
import CopyProjectRules from './CopyProjectRules';
import Tooltip from '@material-ui/core/Tooltip';
import CopyingRuleDetails from './CopyingRuleDetails';
import Snackbars from './../../../core/error/Snackbars';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { empty } from 'apollo-link';

var style = {
    border: 'none',
}
var user = JSON.parse(localStorage.getItem('users'));

const GET_PROJECT_GROUP = gql`
query projectGroupsWithoutPagination {     
    projectGroupsWithoutPagination {  
        id
        projectGroupName
    }
}
`;

const GET_PROJECTS_BY_GROUP_ID = gql`
query projectsByProjectGroupId($projectGroupId : Long!) {     
    projectsByProjectGroupId(projectGroupId: $projectGroupId) {  
        id
        projectName
        repoName
        organizationId
        projectGroupId
        projectGroupName
    }
}
`;

const GET_LANGUAGES_BY_PROJECT_ID = gql`
query copyLanguagesReportByProjectIds($fromProjectId: Long!,$toProjectId: Long) {
    copyLanguagesReportByProjectIds(fromProjectId: $fromProjectId, toProjectId: $toProjectId) {
        languageName
        fromRepo
        toRepo
        newRulesCount
        removeRulesCount
        ruleRequests{
            ruleName
            ruleLanguage
            ruleCategory
            ruleSeverity
            rulePath
            copyFlag
        }
    }
}
`;

class RepoRuleManagement extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            fromRepo: [],
            allRepo: [],
            toRepo: [],
            projectGroupId: 0,
            fromProjectId: 0,
            updateRepo: true,
            toProjectId: 0,
            checked: [],
            details: [],
            filter: '',
            filterToRepo: '',
            filtered: [],
            showModal: false,
            columnDefs: [
                {
                    title: "Language", field: "languageName", render: function (params) {
                        return params.languageName;
                    }
                }, {
                    title: "From Repo", field: "fromRepo", render: function (params) {
                        return params.fromRepo;
                    }
                }, {
                    title: "ToRepo", field: "toRepo", render: function (params) {
                        return <div color="primary">{params.toRepo}(+{params.newRulesCount},-{params.removeRulesCount})</div>
                    }
                }
            ]
        }
        this.handleDataAdd = this.handleDataAdd.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.handleUpdateId = this.handleUpdateId.bind(this);
        this.handleFromNavigation = this.handleFromNavigation.bind(this);
        this.handleToNavigation = this.handleToNavigation.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleEditDialog = this.handleEditDialog.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.onFilterTextBoxChangedFromRepo = this.onFilterTextBoxChangedFromRepo.bind(this);
        this.onFilterTextBoxChangedToRepo = this.onFilterTextBoxChangedToRepo.bind(this);
    }

    handleDataAdd = (data) => {
        if (data && this.state.updateRepo) {
            this.setState({
                fromRepo: data.projectsByProjectGroupId,
                toRepo: data.projectsByProjectGroupId,
                allRepo: data.projectsByProjectGroupId,
                updateRepo: false
            })
        }
    };

    handleFromChange = (event, id) => {
        let tempVar = this.state.fromRepo.filter(opt => opt.id != id);
        this.setState({
            toRepo: tempVar,
            fromProjectId: id,
            checked: [],
            toProjectId: 0

        })
    };

    handleToChange = (event, id) => {
        this.setState({
            toProjectId: id
        })
    };

    handleUpdateId = (event) => {
        this.setState({
            projectGroupId: event.target.value,
            fromProjectId: 0,
            checked: [],
            toProjectId: 0,
            updateRepo: true
        })
    };

    handleFromNavigation = (e) => {
        const window = e.currentTarget;
        if (this.prev > window.scrollTop + 30) {
        } else if (this.prev < window.scrollTop + 30) {
            forceCheck();
        }
        this.prev = window.scrollTop;
    };

    handleToNavigation = (e) => {
        const window = e.currentTarget;
        if (this.prev > window.scrollTop + 30) {
        } else if (this.prev < window.scrollTop + 30) {
            forceCheck();
        }
        this.prev = window.scrollTop;
    };

    handleReset = () => {
        this.setState({
            projectGroupId: 0,
            fromProjectId: 0,
            checked: [],
            toProjectId: 0,
            updateRepo: false,
            filter: '',
            filterToRepo: '',
            updateRepo: true
        })

    };

    handleEditDialog = (grid) => {
        if (grid) {
            this.setState({
                details: grid,
                showModal: true
            })
        }
        if (!grid) {
            this.setState({ showModal: false });
        }
    }

    handleCancel = () => {
        if (this.state.showModal)
            this.setState({ showModal: false });
    }

    onFilterTextBoxChangedFromRepo(event) {
        let currentList = [];
        let newList = [];
        if (event.target.value !== "") {
            this.setState({
                filter: event.target.value
            })
            currentList = this.state.allRepo;
            newList = currentList.filter(data => {
                const searchResult = data.repoName.toLowerCase();
                const filter = event.target.value.toLowerCase();
                return searchResult.includes(filter);
            });
        } else {
            newList = this.state.fromRepo;
            this.setState({
                filter: ''
            })
        }
        this.setState({
            fromRepo: newList
        });
    }

    onFilterTextBoxChangedToRepo(event) {
        let currentListToRepo = [];
        let newListToRepo = [];
        if (event.target.value !== "") {
            this.setState({
                filterToRepo: event.target.value
            })
            currentListToRepo = this.state.allRepo;
            newListToRepo = currentListToRepo.filter(data => {
                const searchResultToRepo = data.repoName.toLowerCase();
                const filterToRepo = event.target.value.toLowerCase();
                return searchResultToRepo.includes(filterToRepo);
            });
        } else {
            newListToRepo = this.state.toRepo;
            this.setState({
                filterToRepo: ''
            })
        }
        this.setState({
            toRepo: newListToRepo
        });
    }

    render() {
        return (
            <div className="col-md-12 user-management">
                <div className="row">
                    <Query query={GET_PROJECT_GROUP}>
                        {({ loading, error, data, refetch }) => {
                            if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                            if (error) return (<div className="text-center">
                                <Error500 />
                            </div>);
                            return (<div>
                                <br />
                                <div className="row">
                                    <div className="space">
                                        <h5>Project Group :</h5>
                                    </div>
                                    <div className="space1">
                                        <Select
                                            onChange={this.handleUpdateId}>
                                            {
                                                data.projectGroupsWithoutPagination.map(e => {
                                                    return <MenuItem value={e.id}>{e.projectGroupName}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>)
                        }}
                    </Query>
                </div>
                <div className="col-md-12 mb-12">
                    <Query query={GET_PROJECTS_BY_GROUP_ID} fetchPolicy="no-cache" variables={{ projectGroupId: this.state.projectGroupId }}>
                        {({ loading, error, data }) => {
                            if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                            if (error) return (<div className="text-center">
                                <Error500 />
                            </div>);
                            return (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {this.handleDataAdd(data)}
                                                <br />
                                                <AccountTreeRoundedIcon color="primary" />
                                                <Button variant="text" color="primary"><b>From Repo</b></Button>
                                                <br />
                                                <TextField type="text"
                                                    label="Search"
                                                    id="filter-text-box"
                                                    value={this.state.filter}
                                                    onChange={this.onFilterTextBoxChangedFromRepo}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                <br />
                                                <List className="rules-list" component="nav" aria-label="main mailbox folders" onScroll={(e) => { this.handleFromNavigation(e) }}>
                                                    {this.state.fromRepo.map((value, index) => {
                                                        const labelId = { value };
                                                        return (
                                                            <LazyLoad key={index} height={300}
                                                                throttle
                                                                placeholder={
                                                                    <div className="row m-0 p-1">
                                                                        <div className="col-1 p-2">
                                                                            <Skeleton variant="rect" width={30} height={30} />
                                                                        </div>
                                                                        <div className="col-10 my-auto">
                                                                            <Skeleton variant="text" width={"100%"} />
                                                                        </div>
                                                                    </div>
                                                                }>
                                                                <div className="pointer">
                                                                    <ListItem
                                                                        selected={value.id.toString() == this.state.fromProjectId}
                                                                        key={value.id.toString()} value={value.repoName}
                                                                        onClick={(event) => this.handleFromChange(event, value.id)} dense button>
                                                                        <ListItemText id={labelId} primary={value.repoName} />
                                                                    </ListItem>
                                                                </div>
                                                            </LazyLoad>
                                                        );
                                                    })}
                                                </List>

                                            </div>
                                            <div className="col-md-3">
                                                <br />
                                                <AccountTreeRoundedIcon color="primary" />
                                                <Button variant="text" color="primary"><b>To Repo</b></Button>
                                                <br />
                                                <TextField type="text"
                                                    label="Search"
                                                    id="filter-text-box"
                                                    value={this.state.filterToRepo}
                                                    onChange={this.onFilterTextBoxChangedToRepo}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                <br />
                                                <List className="rules-list" component="nav" aria-label="main mailbox folders" onScroll={(e) => { this.handleFromNavigation(e) }}>
                                                    {this.state.toRepo.map((value, index) => {
                                                        const labelId = { value };
                                                        return (
                                                            <LazyLoad key={index} height={300}
                                                                throttle
                                                                placeholder={
                                                                    <div className="row m-0 p-1">
                                                                        <div className="col-1 p-2">
                                                                            <Skeleton variant="rect" width={30} height={30} />
                                                                        </div>
                                                                        <div className="col-10 my-auto">
                                                                            <Skeleton variant="text" width={"100%"} />
                                                                        </div>
                                                                    </div>
                                                                }>
                                                                <div className="pointer">
                                                                    <ListItem
                                                                        selected={value.id.toString() == this.state.toProjectId}
                                                                        key={value.id.toString()} value={value.repoName} onClick={(event) => this.handleToChange(event, value.id)} dense button>
                                                                        <ListItemText id={labelId} primary={value.repoName} />
                                                                    </ListItem>
                                                                </div>
                                                            </LazyLoad>
                                                        );
                                                    })}
                                                </List>
                                            </div>
                                            {this.state.fromProjectId > 0 && this.state.toProjectId > 0 ?
                                                <div className="col-md-6">
                                                    <br />
                                                    <h6>No of Rules report by Language</h6>
                                                    <Query query={GET_LANGUAGES_BY_PROJECT_ID} fetchPolicy="no-cache" variables={{ fromProjectId: this.state.fromProjectId, toProjectId: this.state.toProjectId }}>
                                                        {({ loading, error, data, refetch }) => {
                                                            if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                                                            if (error) return (
                                                                <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={this.toggleSnackbars} />)
                                                            return (<div>
                                                                <hr></hr>
                                                                <div className="row">
                                                                    <MaterialTable
                                                                        key={"languages"}
                                                                        title="languages"
                                                                        tableRef={this.tableRef}
                                                                        columns={this.state.columnDefs}
                                                                        data={data.copyLanguagesReportByProjectIds}
                                                                        options={{
                                                                            filtering: false,
                                                                            toolbar: false,
                                                                            actionsColumnIndex: -1,
                                                                            headerStyle: {
                                                                                padding: '5px',
                                                                                fontSize: '12px',
                                                                                fontWeight: "bold",
                                                                                color: 'black',
                                                                                border: 'none',
                                                                            }
                                                                        }}
                                                                        actions={[
                                                                            {
                                                                                icon: 'more_horiz',
                                                                                tooltip: 'View Rules',
                                                                                onClick: (event, rowData) => { this.handleEditDialog(rowData) },
                                                                            }
                                                                        ]}
                                                                        components={{
                                                                            View: props => {
                                                                                return (
                                                                                    <button className={'btn btn-link p-0 '}
                                                                                        onClick={(event) => props.action.onClick(event, props.data)}>
                                                                                        <Tooltip title={props.action.tooltip}>
                                                                                            <i className="material-icons align-middle pr-4">{props.action.icon}</i>
                                                                                        </Tooltip>
                                                                                    </button>
                                                                                )
                                                                            },
                                                                        }}
                                                                    />
                                                                    {this.state.showModal &&
                                                                        <CopyingRuleDetails details={this.state.details} showModal={this.state.showModal} handleClose={this.handleCancel} />
                                                                    }
                                                                </div>

                                                            </div>)
                                                        }}
                                                    </Query>
                                                </div> : ''}
                                        </div>

                                        <hr></hr>
                                        <div className="col-md-12 right">
                                            {<CopyProjectRules data={this.state} handleReset={this.handleReset} />}
                                        </div>
                                    </div>
                                </div>
                            );
                        }}
                    </Query>
                </div>
            </div>
        );
    }
}

export default RepoRuleManagement;