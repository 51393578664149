import React, { Component } from 'react';
import './admin.css';
import RuleSetLanguageTabs from './../components/ruleset-modal/Index';


class RuleManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="col-md-12 pb-3">
                <RuleSetLanguageTabs />
            </div>
        );
    }
}

export default RuleManagement;