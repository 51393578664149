import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import Error500 from '../../../../src/core/error/error';

const GET_REVIEW_RESPONSE_STATS = gql`
query getReviewResponseStats($reviewRequestId: String!)
  {
    getReviewResponseStats(reviewRequestId:$reviewRequestId)
    {
      id
      numOfComments
    }
  }`;
let value='';
class CommentsAnalyzis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewRequestId: props.reviewRequestId,
        };
        this.prepareData = this.prepareData.bind(this);
        this.prepareDataSet = this.prepareDataSet.bind(this);
    }

    prepareData = function (canvas) {
        let ctx = canvas.getContext("2d");
        let gradient = ctx.createLinearGradient(0, 0, 0, 400)
        gradient.addColorStop(0, 'rgba(0, 128, 245, 0.9)')
        gradient.addColorStop(0, 'rgba(0, 128, 245, 0.4)')
        gradient.addColorStop(0, '#0871ffba')
        gradient.addColorStop(1, '#ffffffed')

        var comments = value.reverse().map((value) => value.numOfComments);
        return (
            {       
                labels: value.reverse().map((value, index) => {return "Review " + (index + 1)}),
                datasets: [
                    {
                        label: 'Comments',
                        fill: true,
                        backgroundColor: gradient,
                        borderColor: 'rgba(0,128,245,0)',
                        data: comments
                    }
                ]
            });
    }
    prepareDataSet = function (data) {
        value = data.getReviewResponseStats;      
    }

    render() {
        return (
            <Query query={GET_REVIEW_RESPONSE_STATS} fetchPolicy="no-cache" variables={{ reviewRequestId: this.state.reviewRequestId}}>
                {({ loading, error, data}) => {
                    if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                    if (error) return <div className="text-center"><Error500 /></div>
                    if (data.getReviewResponseStats === null)
                        return <div className="text-center">
                            <img src="/images/statistic.jpg" width="90" alt="noData" /><br />
                                Not enough data to display the chart
                         </div>  
                   
                    this.prepareDataSet(data);
                    return (
                        <div style={{ height: '400px' }}>
                            <Line data={this.prepareData}
                                id="lineChart"
                                options={{
                                    maintainAspectRatio: false,
                                    width: "400",
                                    height: "400",
                                    scales: {
                                        ticks: {
                                            yAxes: [{
                                                beginAtZero: true
                                            }]
                                        }
                                    }
                                }} />
                        </div>
                );
                }
            }
        </Query>
        );
    }
}

export default CommentsAnalyzis;