import ClinetServices from '../../../core/service/clientUri';
import gql from 'graphql-tag';

var pageSize = 10;
var pageNumber = 0;
var filterBy = {};
var user = {};
let sortBy = 'modifiedOn';
let direction = 'DESC';
var user = JSON.parse(localStorage.getItem('users'));

const GET_REVIEW_LIST = gql`
query reviews($pageSize: Int, $pageNumber: Int, $sortBy:String, $direction:Direction, $filterBy: ReviewInput) {
    reviews (
        pageNumber: $pageNumber,
        pageSize: $pageSize,sortBy: $sortBy,direction: $direction, filterBy: $filterBy) {
        content {
          __typename
          ... on Review {
            id
            projectId
            projectName
            reviewRefId
            reviewUrl
            repoGroupName
            repoName
            manualReviewStatus
            sourceBranchName
            destinationBranchName
            pullRequestId
            commitId
            hasManualComment
            status
            createdOn
            modifiedOn
            isRepoDeleted
            requestedUser {
                id
                username
                name
            }
          }
        }
        totalPages
        totalElements
        last
        first
        numberOfElements
        size
        number
        empty
      }
}
`;

const GET_REVIEW_LIST_BY_APPROVEL = gql`
query reviewsForApproval($pageSize: Int, $pageNumber: Int, $sortBy: String!, $direction: Direction, $filterBy: ReviewInput) {
    reviewsForApproval (pageNumber: $pageNumber, sortBy: $sortBy, direction: $direction, pageSize: $pageSize, filterBy: $filterBy) {
        content {
          __typename
          ... on Review {
            id
            projectName
            reviewRefId
            reviewUrl
            repoGroupName
            repoName
            manualReviewStatus
            sourceBranchName
            destinationBranchName
            pullRequestId
            commitId
            status
            createdOn
            modifiedOn
            isRepoDeleted
            requestedUser {
                id
                username
                name
            }
          }
        }
        totalPages
        totalElements
        last
        first
        numberOfElements
        size
        number
        empty
      }
}
`;

const GET_MANUAL_REVIEW_LIST = gql`
query reviewsByManualReviewRequired($pageSize: Int, $pageNumber: Int, $sortBy: String!, $direction: Direction, $filterBy: ReviewInput) {
    reviewsByManualReviewRequired (pageNumber: $pageNumber,pageSize: $pageSize, sortBy: $sortBy, direction: $direction, filterBy: $filterBy) {
        content {
          __typename
          ... on Review {
            id
            projectName
            reviewRefId
            reviewUrl
            repoGroupName
            repoName
            manualReviewStatus
            sourceBranchName
            destinationBranchName
            pullRequestId
            commitId
            status
            createdOn
            modifiedOn
            isRepoDeleted
            requestedUser {
                id
                username
                name
            }
          }
        }
        totalPages
        totalElements
        last
        first
        numberOfElements
        size
        number
        empty
      }
}
`;

const GET_REQUESTED_USER = gql`
query reviewsByRequestedUser($id: String!, $username: String!, $name: String!, $emailId: String, $pageNumber: Int, $pageSize: Int) {
    reviewsByRequestedUser (id: $id, username: $username, name: $name, emailId: $emailId, pageNumber: $pageNumber, pageSize: $pageSize) {
        content {
          __typename
          ... on Review {
            id
            reviewRefId
            reviewUrl
            repoGroupName
            repoName
            manualReviewStatus
            sourceBranchName
            destinationBranchName
            pullRequestId
            commitId
            status
            createdOn
            modifiedOn
            isRepoDeleted
            requestedUser {
                id
                username
                name
            }
          }
        }
        totalPages
        totalElements
        last
        first
        numberOfElements
        size
        number
        empty
      }
}
`;

class reviewServices {
    constructor(props) {
        this.getFromClintList = this.getFromClintList.bind(this);
        this.getReviewsList = this.getReviewsList.bind(this);
        this.getReviewsListByUser = this.getReviewsListByUser.bind(this);
        this.getFromClintListApprove = this.getFromClintListApprove.bind(this);
        this.getApprovelReviewsList = this.getApprovelReviewsList.bind(this);
        this.getFromClintListByUser = this.getFromClintListByUser.bind(this);
        this.getFromClintManualList = this.getFromClintManualList.bind(this);
        this.getManualRequiredReviewsList = this.getManualRequiredReviewsList.bind(this);
    }

    async getFromClintList(client) {
        var response = await client.query({
            query: GET_REVIEW_LIST,
            variables: {pageSize: pageSize, pageNumber: pageNumber, filterBy: filterBy , sortBy: sortBy, direction: direction}
        });
        return await response.data.reviews;
    }

    async getFromClintListApprove(client) {
        var response = await client.query({
            query: GET_REVIEW_LIST_BY_APPROVEL,
            variables: { pageSize: pageSize, pageNumber: pageNumber, filterBy: filterBy, sortBy: sortBy, direction: direction }
        });
        return await response.data.reviewsForApproval;
    }

    async getFromClintListByUser(client) {
        var response = await client.query({
            query: GET_REQUESTED_USER,
            variables: { ...user, pageSize: pageSize, pageNumber: pageNumber }
        });
        return await response.data.reviewsByRequestedUser;
    }

    async getFromClintManualList(client) {
        var response = await client.query({
            query: GET_MANUAL_REVIEW_LIST,
            variables: {pageSize: pageSize, pageNumber: pageNumber, filterBy: filterBy, sortBy: sortBy, direction: direction }
        });
        return await response.data.reviewsByManualReviewRequired;
    }

    getReviewsList(query, filter) {
        this.setPaginationFilterAndSorting(query, filter);

        return this.getFromClintList(ClinetServices.getClienUri()).then(res => {
            return {
                data: res.content,
                page: res.number,
                totalCount: res.totalElements,
            }
        }, error => {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    }

    setPaginationFilterAndSorting(query, filter) {
        if (query) {
            pageSize = query.pageSize;
            pageNumber = query.page;
            filterBy = filter;

            if (query.orderBy) {
                sortBy = query.orderBy.field;
                direction = query.orderDirection.toUpperCase();
            }
        }
    }

    getReviewsListByUser(query) {
        if (query) {
            pageSize = query.pageSize;
            pageNumber = query.page;
            user = query.user;
        }

        return this.getFromClintListByUser(ClinetServices.getClienUri()).then(res => {
            return {
                data: res.content,
                page: res.number,
                totalCount: res.totalElements,
            }
        }, error => {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    }

    getApprovelReviewsList(query, filter) {
        this.setPaginationFilterAndSorting(query, filter);

        return this.getFromClintListApprove(ClinetServices.getClienUri()).then(res => {
            return {
                data: res.content,
                page: res.number,
                totalCount: res.totalElements,
            }
        }, error => {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    }

    getManualRequiredReviewsList(query, filter) {
        this.setPaginationFilterAndSorting(query, filter);

        return this.getFromClintManualList(ClinetServices.getClienUri()).then(res => {
            return {
                data: res.content,
                page: res.number,
                totalCount: res.totalElements,
            }
        }, error => {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    }

}

export default reviewServices;