import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import Error500 from '../../../core/error/error';
import './../../home/home.css';
import ReviewsService from '../../reviews/services/review-services';
import IconButton from '@material-ui/core/IconButton';
import PeopleIcon from '@material-ui/icons/People';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import './chart-style.css';
const APPLICATION_SUMMARY = gql`
query applicationSummary {
    applicationSummary {
        numOfProjects
        numOfReviewRequests
        numOfUsers
    }
}`;
var reviewsService = new ReviewsService();
class ApplicationSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviews: null
        }
        reviewsService.getReviewsList({ page: 0, pageSize: 5 }).then((res) => {
            this.setState({ reviews: res.data });
        });
        this.getIcon = this.getIcon.bind(this);
    }

    getIcon(url, params) {
        var iconToShow;
        if (params.status === 'Completed') {
            if (params.manualReviewStatus && params.manualReviewStatus !== 'Manual Review Completed') {
                iconToShow = <Link to={{ pathname: url, manualReviewStatus: params.manualReviewStatus }} ><i className="material-icons align-middle text-primary" title={params.status + ' (' + params.manualReviewStatus + ')'}>done_all</i></Link>
            } else {
                iconToShow = <Link to={{ pathname: url, manualReviewStatus: params.manualReviewStatus }} ><i className="material-icons align-middle text-success" title={params.status + (!!params.manualReviewStatus ? ' (' + params.manualReviewStatus + ')' : '')}>done_all</i></Link>
            }
        } else {
            iconToShow = <Link to={{ pathname: url, manualReviewStatus: params.manualReviewStatus }} ><i className="material-icons align-middle text-danger" title={params.status + (!!params.manualReviewStatus ? ' (' + params.manualReviewStatus + ')' : '')}>create</i></Link>
        }
        return iconToShow;
    }


    render() {
        return (
            <Query query={APPLICATION_SUMMARY}>
                {({ loading, error, data, refetch }) => {

                    if (error) return (
                        <div className="text-center">
                            <Error500 />
                        </div>
                    )

                    if (loading) return (
                        <div className="row p-2">
                            <div className="col-sm-12 chart-container p-4 bg-white my-0">
                                <div className="row">
                                    <div className="col-md-4 pr-0"><Skeleton variant="rect" height={"88px"} /></div>
                                    <div className="col-md-4 pr-0"><Skeleton variant="rect" height={"88px"} /></div>
                                    <div className="col-md-4"><Skeleton variant="rect" height={"88px"} /></div>
                                </div>
                            </div>
                        </div>
                    );
                    return (
                        <div className="row p-2">
                            <div className="col-sm-12 chart-container p-0">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row px-3">
                                            <div className="col-md-4 p-0">
                                                <div className="bg-white summary-container py-3 px-2">
                                                    <Link to="/reviews" style={{ textDecoration: 'none' }}>
                                                        <div className="row mt-2">
                                                            <div className="col-1 mr-4">
                                                                <IconButton className="icon-color-red">
                                                                    <RateReviewOutlinedIcon />
                                                                </IconButton>
                                                            </div>
                                                            <div className="col-8">
                                                                <h4 className="m-0">{data ? data.applicationSummary.numOfReviewRequests : '-'}</h4>
                                                                <div><b>Reviews</b></div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4 p-0">
                                                <div className="bg-white summary-container py-3 px-2">
                                                    <Link to="/projects" style={{ textDecoration: 'none' }}>
                                                        <div className="row mt-2">
                                                            <div className="col-1 mr-4">
                                                                <IconButton className="icon-color-blue">
                                                                    <CodeOutlinedIcon />
                                                                </IconButton>
                                                            </div>
                                                            <div className="col-8">
                                                                <h4 className="m-0">{data ? data.applicationSummary.numOfProjects : '-'}</h4>
                                                                <div><b>Repositories</b></div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4 p-0">
                                                <div className="bg-white summary-container users py-3 px-2">
                                                    <Link to="/users" style={{ textDecoration: 'none' }}>
                                                        <div className="row mt-2">
                                                            <div className="col-1 mr-4">
                                                                <IconButton className="icon-color-green">
                                                                    <PeopleIcon />
                                                                </IconButton>
                                                            </div>
                                                            <div className="col-8">
                                                                <h4 className="m-0">{data ? data.applicationSummary.numOfUsers : '-'}</h4>
                                                                <div><b>Active Users</b></div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Query>
        );
    }
}

export default ApplicationSummary;