import React, { Component } from 'react';
import MaterialTable from 'material-table';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';

class ListView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: [{
                title: "Project Name", field: "id"
            }, {
                title: "Lines of Code", field: "numOfLinesAdded"
            }, {
                title: "# Repos", field: "numOfRepositories"
            }, {
                title: "# Requests", field: "numOfReviews"
            },{
                title: "Code Quality", field: "qualityPercentage", render: function (params) {
                    return (<Tooltip title={params.qualityPercentage.toFixed(2) + '%'} placement="top"><div className={params.qualityPercentage > 50
                        ? params.qualityPercentage > 75
                            ? "heigh-quality"
                            : "medium-quality"
                        : "low-quality"
                    }><LinearProgress variant="determinate" value={params.qualityPercentage} />{params.qualityPercentage.toFixed(2)}%</div></Tooltip>);
                }
            }]
        }
    }

    render() {
        if (!this.props.data) {
            return null;
        }
        return (<MaterialTable
            title="Reviews"
            onRowClick={(event, rowData) => { this.props.handleDialog(rowData) }}
            columns={this.state.columnDefs}
            data={this.props.data}
            options={{
                filtering: false,
                toolbar: false,
                headerStyle: {
                    padding: '5px',
                    fontSize: '12px',
                    fontWeight: "bold",
                    color: 'black',
                    border: 'none',
                },
                pageSize: 7,
                actionsColumnIndex: -1,
                pageSizeOptions: [7, 10, 20, 30],
            }}
        />
        );
    }
}

export default ListView;
