import React, { Component } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import ProjectAddGroup from './add-project-group';
import ProjectEditGroup from './edit-project-group';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable from 'material-table';
import projectServices from './services/project-service';
import { UTCtoLocalTime } from '../../core/service/timezone';

var style = {
    border: 'none',
}
class ProjectGroupList extends Component {
    projectId;
    constructor(props) {
        super(props)
        this.projectServices = new projectServices();
        this.tableRef = React.createRef();
        this.state = {
            showModal: false,
            showEditModal: false,
            showViewModal: false,
            showRuleSetModal: false,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                isDesktop: window.innerWidth > 600,
            },
            columnDefs: [{
                title: "Project Group Name", field: "projectGroupName", filtering: true, filterCellStyle: style, headerStyle: style, filterPlaceholder: 'search', sort: "asc"
            }, {
                title: "Owner Name", field: "ownerName", filtering: true, filterCellStyle: style, headerStyle: style, filterPlaceholder: 'search'
            }, {
                title: "Owner Email Address", field: "ownerEmailAddress", filtering: true, filterCellStyle: style, headerStyle: style, filterPlaceholder: 'search'
            }, {
                title: "Created On", field: "createdOn", filtering: false, filterCellStyle: style, headerStyle: style, render: function (params) {
                    return UTCtoLocalTime(params.createdOn, 'YYYY-MMM-DD HH:mm A');
                }
            }]
        }
        this.addProjectDialog = this.addProjectDialog.bind(this);
        this.handleEditDialog = this.handleEditDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.getData = this.getData.bind(this);
    }

    onGridReady(params) {
        if ((window.innerWidth > 600)) {
            params.api.sizeColumnsToFit();
        }
    }

    getData(query) {
        const arrayToObject = (filter) =>
            Object.assign({}, ...filter.map(item => ({ [item.column.field]: item.column.tableData.filterValue })))
        const filter = arrayToObject(query.filters);
        return this.projectServices.getProjectGroupList(query, filter);
    }

    addProjectDialog() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    handleEditDialog(grid) {
        if (grid) {
            this.project = grid;
            this.setState({ showEditModal: true });
        } else if (!grid) {
            this.setState({ showEditModal: false });
        }

    }

    handleClose() {
        this.setState({ showViewModal: false });
    }

    render() {
        return (
            <div className="project-list post-list">
                    <div
                        className="ag-theme-material pt-1 pl-0 pr-0 pb-30"
                    >
                        <div className="row">
                            <div className="project-list-container">
                                <Button className="addbutton btn-link text-primary bg-white border-0 shadow-none" onClick={() => this.tableRef.current && this.tableRef.current.onQueryChange()}>
                                    <Tooltip title="Refresh" placement="top">
                                        <i className="material-icons text-primary">refresh</i>
                                    </Tooltip>
                                </Button>
                                <Button className="addbutton btn-link text-primary bg-white border-0" onClick={this.addProjectDialog}>
                                    <Tooltip title="Add Project" placement="top">
                                        <i className="material-icons align-middle">add_circle</i>
                                    </Tooltip>
                                </Button>
                            </div>
                        </div>
                        <MaterialTable
                            title="Reviews"
                            tableRef={this.tableRef}
                            columns={this.state.columnDefs}
                            data={query => this.getData(query)}
                            options={{
                                filtering: true,
                                toolbar: false,
                                headerStyle: {
                                    padding: '5px',
                                    fontSize: '12px',
                                    fontWeight: "bold",
                                    color: 'black',
                                    border: 'none',
                                },
                                pageSize: 7,
                                actionsColumnIndex: -1,
                                pageSizeOptions: [7, 10, 20, 30],
                            }}
                            actions={[
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit Project Group',
                                    onClick: (event, rowData) => { this.handleEditDialog(rowData) },
                                }
                            ]}
                            components={{
                                Action: props => {
                                    return (
                                        <button className={'btn btn-link p-0 ' + (props.action.icon !== 'edit' ? props.data.rules && props.data.rules.length ? "" : "text-danger" : "")}
                                            onClick={(event) => props.action.onClick(event, props.data)}>
                                            <Tooltip title={props.action.tooltip}>
                                                <i className="material-icons align-middle pr-4">{props.action.icon}</i>
                                            </Tooltip>
                                        </button>
                                    )
                                },
                            }} />
                        {this.state.showModal && <ProjectAddGroup showModal={this.state.showModal} onUpdate={this.addProjectDialog.bind(this)} refresh={() => this.tableRef.current && this.tableRef.current.onQueryChange()} history={this.props.history}></ProjectAddGroup>}
                        {this.state.showEditModal && <ProjectEditGroup projectId={this.project ? this.project.id : null} projectGroupName={this.project ? this.project.projectGroupName : null} showModal={this.state.showEditModal} onUpdate={this.handleEditDialog} refresh={() => this.tableRef.current && this.tableRef.current.onQueryChange()} history={this.props.history}></ProjectEditGroup>}
                    </div>
                </div>
            
        );
    }
}

export default ProjectGroupList;