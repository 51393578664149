import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { ApolloProvider } from 'react-apollo';

import AppHeader from '../core/header/header';
import AppFooter from './../core/footer/footer';
import clientService from './../core/service/clientUri';

import './../App.css';
import Auth from './../core/service/auth';
import preferenceService from './../core/service/preference';
import PageNotFound from './../core/error/404';
import Wiki from './wiki/';
import { router } from './service/routerService';

const client = clientService.getClienUri();
class App extends React.Component {
    render() {
        const user = preferenceService.getValue('users', 'local');
        if (this.props.location && this.props.location.pathname) {
            localStorage.setItem('currentPage', JSON.stringify(this.props.location.pathname));
        }
        return (
            <ApolloProvider client={client}>
                <div>
                    <div className="container-fluid content-height">
                        <div className="row">
                            <div className="col-lg-12 p-0">
                                <AppHeader />
                                <div className="content">
                                    <Switch>
                                        {Auth.hasRole(user, ['ROLE_SUPER_ADMIN'])
                                            ? router.map(data => {
                                                if (data.isSuperAdmin) {
                                                    return <Route path={data.path} refresh={data.refresh} component={data.component} />
                                                }
                                                return null;
                                            })
                                            : router.map(data => {
                                                if (data.access) {
                                                    return <Route path={data.path} refresh={data.refresh} component={data.component} />
                                                }
                                                return null;
                                            })
                                        }
                                        <Route path="/wiki" component={Wiki} />
                                        <Route component={PageNotFound} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AppFooter />
                </div>
            </ApolloProvider>
        );
    }
}

export default withRouter(App);
