import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import FolderIcon from '@material-ui/icons/Folder';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import projectService from './projectService';
import Tooltip from '@material-ui/core/Tooltip';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import projectServices from '../../project/services/project-service';


class ProjectValidateList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            projectList: [],
            projectId: [],
            checked: [],
            unchecked: [],
            check: false,
            splice: false,
            toggle: false,
            enabled: false,
            filterData: [],
            isValidateToken: null,
            isLoading: false,
        }
        this.projectList = new projectService();
        this.projectService = new projectServices();
        this.handleToggle = this.handleToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleInputToken = this.handleInputToken.bind(this);
        this.getProjectList = this.getProjectList.bind(this);
        this.handleEnable = this.handleEnable.bind(this);
        this.handleInitial = this.handleInitial.bind(this);
    }

    handleEnable() {
        this.setState({ enabled: true })
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.projectList.getProjectList({
            languageName: this.props.languageName && this.props.languageName,
            ruleId: this.props.ruleId && this.props.ruleId,
            projectGroupName: this.props.projectGroupName,
            repoAccessToken: this.props.repoAccessToken !== '' ? this.props.repoAccessToken : 'A',
        }).then((res) => {
            this.setState({ loading: false, projectList: res.data, filterData: res.data });
        })
    }

    handleChange(e) {
        let data = this.state.projectList.filter(item => {
            return item.projectName.toLowerCase().includes(e.target.value.toLowerCase());
        });

        this.setState({
            searchText: e.target.value,
            filterData: data
        });

    }

    handleInputToken(repoGroupName, repoName, repoUserToken, versionControl) {
        let tokenParams = {
            repoGroupName: repoGroupName,
            repoName: repoName,
            repoAccessToken: repoUserToken,
            versionControl: versionControl
        }
        if (tokenParams) {
            return this.projectService.getProjectRepoToken(tokenParams).then((response) => {
                if (response) {
                    this.setState({ isValidateToken: response.data })
                    return response;
                }
            });
        }
    }

    handleInitial(id) {
        if (!this.state.splice) {
            const currentIndex = this.state.checked.indexOf(id);
            const newProject = [...this.state.checked];
            if (currentIndex === -1) {
                newProject.push(id);
                this.setState({ check: true })
            } else {
                newProject.splice(currentIndex, 1);
            }
            this.setState({ checked: newProject });
            this.props.getList(newProject);
        }
    }

    handleToggle = value => () => {
        const currentIndex = this.state.checked.indexOf(value);
        const newProject = [...this.state.checked];
        this.setState({ check: true })
        if (currentIndex === -1) {
            newProject.push(value);
            this.setState({ check: true })
        } else {
            newProject.splice(currentIndex, 1);
            this.setState({ splice: true })
            this.setState({ check: false })
        }
        this.setState({ checked: newProject });
        this.props.getList(newProject);
    };


    getProjectList(data) {
        return {
            id: data.id,
            projectGroupId: data.projectGroupId,
            projectName: data.projectName,
            projectKey: data.projectKey,
            repoGroupName: data.repoGroupName,
            repoName: data.repoName,
            excludePattern: data.excludePattern,
            versionControl: data.versionControl,
            repoUserToken: this.props.repoAccessToken,
            branchExclutionsPattern: data.branchExclutionsPattern
        }
    }

    render() {
        return (
            <List disablePadding={true}>
                {(this.state.loading) ?
                    <div className="circular-loading"  ><CircularProgress size={40} placement={'top'} /></div>
                    : ''}
                {this.state.filterData.map((data, index) => {
                    return (
                        <ListItem disableGutters={true} key={index} dense button role={undefined}>
                            <ListItemIcon>
                                {data.repoUserToken ?
                                    <InputAdornment position="end">
                                        {data.isRepoAccessValid === true && <Tooltip title=" User Token is Valid for this repository" placement="top"><IconButton className="success" type="submit" aria-label="Token True">
                                            <CheckCircleOutlineIcon />
                                        </IconButton></Tooltip>}
                                        {data.isRepoAccessValid === false && <Tooltip title=" User Token is not Valid for this repository" placement="top"><IconButton color="secondary" type="submit" aria-label="Token False">
                                            <CancelOutlinedIcon />
                                        </IconButton></Tooltip>}
                                    </InputAdornment>
                                    :
                                    <FolderIcon />
                                }
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-wifi" primary={`${data.projectName} (${data.organizationName})`} secondary={data.repoGroupName + '/' + data.repoName} />
                            <ListItemSecondaryAction>
                                {this.state.checked.indexOf(data.id) == -1 && data.isRepoAccessValid ?
                                    this.handleInitial(data.id) : ''}
                                {data.isRepoAccessValid ?
                                    this.state.checked.indexOf(data.id) !== -1 ?
                                        <Tooltip title="Changes applicable for this repo" placement="top">
                                            <Switch
                                                edge="end"
                                                onChange={this.handleToggle(data.id)}
                                                checked={this.state.checked.indexOf(data.id) !== -1}
                                                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                            />
                                        </Tooltip>
                                        :
                                        <Tooltip title="Changes  not applicable for this repo" placement="top">
                                            <Switch
                                                edge="end"
                                                onChange={this.handleToggle(data.id)}
                                                checked={this.state.checked.indexOf(data.id) !== -1}
                                                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                            />
                                        </Tooltip>
                                    :
                                    <Tooltip title=" Changes not applicable for this repo" placement="top">
                                        <div>
                                            <Switch
                                                edge="end"
                                                disabled
                                                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                            />
                                        </div>
                                    </Tooltip>
                                }
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}

            </List>

        );
    }
}

export default ProjectValidateList;