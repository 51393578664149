import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Snackbars from '../../../../../core/error/Snackbars';
import { TextValidator } from 'react-material-ui-form-validator';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'react-quill/dist/quill.snow.css';
import './../ruleEdit.css';

const UPDATE_RULE = gql`
    mutation updateRuleById(
        $ruleId: Long!,
        $description: String,
        $message: String,
        $example: [[String]]) {
            updateRuleById(
                ruleId: $ruleId,
                description: $description,
                message: $message,
                example: $example)
                
    }
`;

class EditRuleConfiguration extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ruleName: '',
            message: '',
            description: '',
            example: '',
            submitted: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'select' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleChange(value) {
        this.setState({
            description: value
        });
    }

    handleSubmit() {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });
    }

    submit = () => {
        this.form.submit();
    }

    getLanguage(data) {
        var addLanguage = {
            html: 'html',
            jsp: 'html',
            javascript: 'javascript',
            typescript: 'typescript'
        }
        return addLanguage[data] || 'java';
    }

    validatorListener = (result) => {
        this.setState({ disabled: !result });
    }

    render() {
        return (
            <Mutation mutation={UPDATE_RULE} onCompleted={this.props.close}>
                {(updateRuleById, { loading, error }) => (
                    <ValidatorForm id="updateRuleById"
                        ref={(r) => { this.form = r; }}
                        onSubmit={e => {
                            this.setState({ submitted: true }, () => {
                                setTimeout(() => this.setState({ submitted: false }), 1000);
                            });
                            updateRuleById({ variables: { ruleId: this.props.ruleList.ruleId, message: this.state.message || this.props.ruleList.message, description: this.state.description || this.props.ruleList.description, example: (this.state.example) || (this.props.ruleList && this.props.ruleList.example && this.props.ruleList.example[0]) } });
                        }}
                        instantValidate
                    >
                        {error &&
                            <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={false} />
                        }
                        <div className="row">
                            <div className="col-md-6 mt-0 mb-4">
                                <h6 className="mb-2">Rule Name</h6>
                                <div>{this.props.ruleList.ruleName}</div>
                            </div>
                        </div>
                        <div className="row">
                            <h6 className="col-md-12 mb-3">Message</h6>
                            <div className="col-md-12 mb-3">
                                <TextValidator
                                    name="message"
                                    id="message"
                                    multiline={true}
                                    rows={1}
                                    cols={100}
                                    onChange={this.handleInputChange}
                                    value={this.state.message || this.props.ruleList.message}
                                    rowsMax={4}
                                    validators={['required']}
                                    errorMessages={['This field is Required']}
                                    validatorListener={this.validatorListener}
                                />
                            </div>
                            <h6 className="col-md-12 mb-3">Description</h6>
                            <div className="col-md-12 mb-3">
                                <ReactQuill
                                    value={this.state.description || this.props.ruleList.description}
                                    onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="row">
                            <h6 className="col-md-12 mb-3">Example</h6>
                            <div className="col-md-6 mb-3">
                                <TextValidator
                                    name="example"
                                    id="example"
                                    multiline={true}
                                    rows={4}
                                    cols={100}
                                    onChange={this.handleInputChange}
                                    value={(this.state.example) || (this.props.ruleList && this.props.ruleList.example && this.props.ruleList.example[0][0])}
                                    rowsMax={40}
                                    validators={['required']}
                                    errorMessages={['This field is Required']}
                                    validatorListener={this.validatorListener}
                                />
                            </div>
                            <div className="syntax-hight col-md-6 mb-3">
                                <SyntaxHighlighter language={this.getLanguage(this.props.ruleList.languageName)} style={vs}>
                                    {(this.state.example) || (this.props.ruleList.example && this.props.ruleList.example.length ? (this.props.ruleList.example[0]) : null)}
                                </SyntaxHighlighter>
                            </div>
                        </div>
                        <div className="text-right col-md-12 mb-3">
                            <Button className="mr-3" onClick={this.props.close} color="secondary">
                                Close
                            </Button>
                            <Button color="primary" type="submit" variant="contained" disabled={loading} onClick={this.submit}>
                                {loading ? <CircularProgress disableShrink size={24} /> : 'Update'}
                            </Button>
                        </div>
                    </ValidatorForm>
                )}
            </Mutation>
        )
    }
}

export default EditRuleConfiguration

