import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import ProjectList from './../components/projects/project-validate';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import './project.css';


const ADD_PROJECT_GROUP = gql`
        mutation updateProjectGroup(
        $id: Long,
        $projectGroupName: String!,
        $organizationName: String!,
        $repoAccessToken: String,
        $description: String,
        $ownerName: String,
        $ownerEmailAddress: String,
        $reviewers: [UserInput],
        $projectIds: [Long]) {
        updateProjectGroup(
        id: $id,
        projectGroupName: $projectGroupName,
        organizationName: $organizationName,
        repoAccessToken: $repoAccessToken,
        description: $description,
        ownerName: $ownerName,
        ownerEmailAddress: $ownerEmailAddress,
        reviewers: $reviewers,
        projectIds: $projectIds) {
        id
        }
        }
        `;

class EditToken extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            loading: false,
            loadingData: false,
            showDeleteModal: false,
            deleteModal: false,
            open: false,
            check: false,
            configuration: 'byProject',
            selectedProjectList: [],
            id: null,
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmitObject = this.handleSubmitObject.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getList = this.getList.bind(this);
        this.getProjectValues = this.getProjectValues.bind(this);
    }

    handleClose() {
        this.props.handleCancel();
        this.setState({
            selectedProjectList: [],
        })
    }

    handleChange(event) {
        this.setState({
            configuration: event.target.value
        })
    }

    handleSubmitObject() {
        const userId = this.props.userData.id;
        const organizationId = this.props.orgId;

        return this.props.isSUAdmin ? { userId } : { userId, organizationId };
    }

    getProjectValues() {
        return {
            id: this.props.projectId,
            projectGroupName: this.props.projectGroupName,
            organizationName: this.props.organizationName,
            repoAccessToken: this.props.repoAccessToken,
            description: this.props.description,
            ownerName: this.props.ownerName,
            ownerEmailAddress: this.props.ownerEmailAddress,
            reviewers: this.props.reviewers,
            projectIds: this.state.selectedProjectList
        }
    }

    getList(data) {
        this.setState({
            selectedProjectList: data
        });
        this.setState({ loadingData: true });
    }

    render() {
        return (
            <div>
                <Mutation mutation={ADD_PROJECT_GROUP} onCompleted={() => { this.handleClose() }}>
                    {(updateProjectGroup, { loading, error, data }) => {
                        return (
                            <Dialog
                                open={this.props.editModal}
                                aria-labelledby="simple-dialog-title">
                                <DialogTitle id="customized-dialog-title" onClose={this.props.handleCancel}>
                                    Personal Access Token Edit  Confirmation
                                </DialogTitle> <Divider />
                                <DialogContent className="" style={{ height: '250px' }}>
                                    <DialogContentText >
                                        <div>We have found the changes at personal access token. Please confirm your changes for the following repositories.<b>(Hint: <IconButton size='small' className="success"><CheckCircleOutlineIcon /></IconButton>
                                            current token is valid and accessible for this repository.
                                    <IconButton size='small' color="secondary" type="submit" aria-label="Token False">
                                                <CancelOutlinedIcon />
                                            </IconButton>
                                            current token is invalid and not accessible for this repository.)</b></div>
                                    </DialogContentText>
                                    <ProjectList getList={this.getList} projectId={this.props.projectId} projectGroupName={this.props.projectGroupName} repoAccessToken={this.props.repoAccessToken} />
                                </DialogContent>
                                <Divider />
                                <DialogActions>
                                    {this.state.loadingData ?
                                    <Button onClick={() => {
                                        updateProjectGroup({ variables: { ...this.getProjectValues() } });
                                        { this.props.submit() }
                                    }} variant="contained" color="primary" >
                                        Update
                                    </Button>
                                    : ''}
                                    <Button onClick={this.props.handleCancel} color="secondary" >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        )
                    }}
                </Mutation>
            </div>
        );
    }
}

export default EditToken;