import React, { Component } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Chart, Line } from 'react-chartjs-2';
import zoom from 'chartjs-plugin-zoom'
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import './../../home/home.css';
import { LocalToUTCTime, UTCtoLocalTime, LocalDate } from '../../../core/service/timezone';

const REVIEWS_BY_DATE_RANGE = gql`
query reviewsByDateRange($range: Range, $startDate: Long, $endDate: Long, $timeZoneOffset: Long) {
    reviewsByDate (range: $range, startDate: $startDate, endDate: $endDate, timeZoneOffset: $timeZoneOffset) {
        createdDate
        month: _id
        reviews
    }
}`;

class ReviewsByDateRangeChart extends Component {
    constructor(props) {
        super(props);
        this.getDateOptions = this.getDateOptions.bind(this);
        this.reviewByDate = this.reviewByDate.bind(this);
        this.state = {
            groupByDateFilter: null,
            dateLabel: 'Today',
            drag: true,
            todayReviews: null,
            timeZoneOffset: new Date().getTimezoneOffset()
        };

        this.prepareReviewsByDateData = function (canvas, data) {
            var ctx = canvas.getContext("2d");
            var gradient = ctx.createLinearGradient(0, 0, 0, 400)
            gradient.addColorStop(0, 'rgba(0, 128, 245, 0.9)')
            gradient.addColorStop(0, 'rgba(0, 128, 245, 0.4)')
            gradient.addColorStop(0, '#0871ffba')
            gradient.addColorStop(1, '#ffffffed')

            var datasets = [
                {
                    data: data.map((data) => { return data.reviews }),
                    backgroundColor: gradient,
                    borderColor: 'rgba(0,128,245,0)',
                    label: 'Reviews',
                    fill: true
                }
            ];

            return {
                responsive: true,
                maintainAspectRatio: false,
                labels: data.map((data) => {
                    return data.createdDate ? UTCtoLocalTime(data.createdDate, 'D MMM') : data.month.split(" ")[0].slice(0, 3) + '-' + data.month.split(" ")[1].slice(2, 4)
                }),
                datasets: datasets
            }
        };
        this.resetZoom = this.resetZoom.bind(this);
        this.chartDragOn = this.chartDragOn.bind(this);
        this.chartDragOff = this.chartDragOff.bind(this);
    }

    ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    getDateOptions(picker) {
        var seletedDate = '';
        if (picker.startDate.format('YYYY-MM-DD') === picker.endDate.format('YYYY-MM-DD')) {
            seletedDate = picker.startDate.format('YYYY-MM-DD');
        }
        else {
            seletedDate = picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD');
        }

        return {
            dateObj: picker,
            label: seletedDate
        }

    };

    reviewByDate(event, picker, refetch) {
        var data = this.getDateOptions(picker);
        if (picker.chosenLabel !== 'Today' && data && data.dateObj) {
            this.setState({
                dateLabel: data.label,
                groupByDateFilter: null,
                startDate: data.dateObj.startDate.format('YYYY-MM-DD'),
                endDate: data.dateObj.endDate.format('YYYY-MM-DD')
            }, () => {
                refetch();
            });
        } else {
            this.setState({
                dateLabel: 'Today',
                groupByDateFilter: null,
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            }, () => {
                refetch();
            });
        }
    }

    componentWillMount() {
        Chart.plugins.register(zoom)
    }

    resetZoom() {
        this.barChart.chartInstance.resetZoom();
    }

    chartDragOn() {
        this.setState({
            drag: false
        })
    }

    chartDragOff() {
        this.setState({
            drag: true
        })
    }

    render() {
        return (
            <Query query={REVIEWS_BY_DATE_RANGE} variables={{
                range: this.state.groupByDateFilter,
                startDate: this.state.startDate ? LocalToUTCTime(this.state.startDate, 'x') : LocalDate(),
                endDate: this.state.endDate ? LocalToUTCTime(this.state.endDate, 'x') : LocalDate(),
                timeZoneOffset: this.state.timeZoneOffset
            }}>
                {({ loading, error, data, refetch }) => {

                    if (this.state.todayReviews === null && data && data.reviewsByDate && data.reviewsByDate.length) {
                        this.setState({
                            todayReviews: data.reviewsByDate[data.reviewsByDate.length - 1].reviews
                        })
                    }

                    if (error) return (
                        <div className="text-center"></div>
                    )

                    if (loading) return (
                        <div className="row p-2">
                            <div className="col-sm-12 chart-container p-4 bg-white my-0">
                                <div className="row mb-4">
                                    <div className="col-lg-10">
                                        <Skeleton variant="text" width={"150px"} />
                                        <Skeleton variant="text" width={"100px"} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                    <div className="col-lg-4 mt-2 mb-2"><Skeleton variant="rect" /></div>
                                </div>
                                <Skeleton variant="rect" width={"100%"} height={"320px"} />
                            </div>
                        </div>
                    );
                    return (
                        <div className="row p-2">
                            <div className="col-sm-12 chart-container p-4 bg-white">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <h4>Reviews By Date Range</h4>
                                    </div>
                                    <div className="col-lg-3">
                                        {data.reviewsByDate && <Tooltip title={'Reset Zoom'} placement="top" id="placement">
                                            <IconButton className="text-right default" color="primary" aria-label="close" onClick={() => this.resetZoom()}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {data.reviewsByDate && this.state.drag && <Tooltip title={'Select the Chart to Magnify'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.chartDragOn()}>
                                                <BlurOnIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {data.reviewsByDate && !this.state.drag && <Tooltip title={'Scroll the Chart to Magnify'} placement="top" id="placement">
                                            <IconButton className="text-right" color="primary" aria-label="close" onClick={() => this.chartDragOff()}>
                                                <BlurOffIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="row">
                                            <div className="col-1 mr-4">
                                                <IconButton className="icon-color-blue" color="primary" style={{ cursor: 'default' }}>
                                                    <TrendingUpIcon />
                                                </IconButton>
                                            </div>
                                            <div className="col-6">
                                                <h4 className="m-0">{this.state.todayReviews}</h4>
                                                <div><b>Today Reviews</b></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-3">
                                        <Button className="text-right" name="month" size="medium" onClick={() => {
                                            this.setState({ groupByDateFilter: 'BY_MONTH', dateLabel: 'Date Range', startDate: null, endDate: null }, () => {
                                                refetch();
                                            })
                                        }}
                                            variant={this.state.groupByDateFilter === 'BY_MONTH' ? "contained" : "outlined"} color="primary">
                                            Month
                                        </Button>
                                        <Button className="text-right mr-3" size="medium" onClick={() => {
                                            this.setState({ groupByDateFilter: 'LAST_7_DAYS', dateLabel: 'Date Range', startDate: null, endDate: null }, () => {
                                                refetch()
                                            })
                                        }} variant={this.state.groupByDateFilter === 'LAST_7_DAYS' ? "contained" : "outlined"} color="primary">
                                            Week
                                        </Button>
                                    </div>
                                    <div className="col-lg-4 mt-3 pl-0 custom-select-style">
                                        <DateRangePicker containerStyles={{ display: "block" }}
                                            ranges={this.ranges}
                                            maxDate={moment().format('MM/DD/YYYY')}
                                            onApply={(event, picker) => this.reviewByDate(event, picker, refetch)}>
                                            <div className="custom-select dateRange">
                                                {this.state.dateLabel}
                                            </div>
                                        </DateRangePicker>
                                    </div>
                                </div>

                                <div className="row" style={{ height: '320px' }}>
                                    <div className="col-12 pt-4">
                                        {data.reviewsByDate.length ? <Line
                                            data={(canvas) => this.prepareReviewsByDateData(canvas, data ? data.reviewsByDate : [])}
                                            ref={(ref) => this.barChart = ref}
                                            options={{
                                                legend: false,
                                                maintainAspectRatio: false,
                                                pan: {
                                                    enabled: true,
                                                    mode: 'x',
                                                    speed: 10,
                                                    threshold: 10
                                                },
                                                zoom: {
                                                    enabled: true,
                                                    drag: this.state.drag,
                                                    mode: 'xy'
                                                }
                                            }} /> : <div className="text-center chart-align-top">
                                                <img src="/images/statistic.jpg" width="90" alt="noData" /><br />
                                                Not enough data to display the chart
                                  </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Query>
        );
    }
}

export default ReviewsByDateRangeChart;