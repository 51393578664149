import preferenceService from '../../service/preference';

export const resetFilterService = {
    resetFilters,
    resetForReviewGrid,
    resetForAppoverGrid,
    resetForManualReviewGrid,
    checkFilterPresentInReviewGrid,
    checkFilterPresentInApproverGrid,
    checkFilterPresentInManualGrid
};

function resetFilters() {
    preferenceService.removeValue('reviewGridValues');
    preferenceService.removeValue('approveGridValues');
    preferenceService.removeValue('manualGridValues');
    localStorage.setItem('gridQueryPage', 0);
    localStorage.setItem('gridQueryPageSize', 7);
    localStorage.setItem('manualGridQueryPage', 0);
    localStorage.setItem('manualGridQueryPageSize', 7);
    localStorage.setItem('approveGridQueryPage', 0);
    localStorage.setItem('approveGridQueryPageSize', 7);
}

function resetForReviewGrid() {
    preferenceService.removeValue('reviewGridValues');
    localStorage.setItem('gridQueryPage', 0);
    localStorage.setItem('gridQueryPageSize', 7);
}

function resetForAppoverGrid() {
    preferenceService.removeValue('approveGridValues');
    localStorage.setItem('approveGridQueryPage', 0);
    localStorage.setItem('approveGridQueryPageSize', 7);
}

function resetForManualReviewGrid() {
    preferenceService.removeValue('manualGridValues');
    localStorage.setItem('manualGridQueryPage', 0);
    localStorage.setItem('approveGridQueryPageSize', 7);
}

function checkFilterPresentInReviewGrid() {
    if (preferenceService.getValue('reviewGridValues') && (preferenceService.getValue('reviewGridValues').id !== "" || preferenceService.getValue('reviewGridValues').reviewUrl !== "" ||
        preferenceService.getValue('reviewGridValues').repoName !== "" || preferenceService.getValue('reviewGridValues').projectName !== "" ||
        preferenceService.getValue('reviewGridValues').status !== "" || preferenceService.getValue('reviewGridValues').name !== "" ||
        preferenceService.getValue('reviewGridValues').createdOn !== "" || preferenceService.getValue('reviewGridValues').modifiedOn !== "")) {
        return true;
    }
    else {
        return false;
    }
}

function checkFilterPresentInApproverGrid() {
    if (preferenceService.getValue('approveGridValues').idApprove !== "" || preferenceService.getValue('approveGridValues').reviewUrlApprove !== "" ||
        preferenceService.getValue('approveGridValues').repoNameApprove !== "" || preferenceService.getValue('approveGridValues').projectNameApprove !== "" ||
        preferenceService.getValue('approveGridValues').nameApprove !== "" ||
        (preferenceService.getValue('approveGridValues').createdOnApprove !== "" && preferenceService.getValue('approveGridValues').createdOnApprove !== "") ||
        (preferenceService.getValue('approveGridValues').modifiedOnApprove !== "" && preferenceService.getValue('approveGridValues').modifiedOnApprove !== "")) {
        return true;
    }
    else {
        return false;
    }
}

function checkFilterPresentInManualGrid() {
    if (preferenceService.getValue('manualGridValues').idManual !== "" || preferenceService.getValue('manualGridValues').reviewUrlManual !== "" ||
        preferenceService.getValue('manualGridValues').repoNameManual !== "" || preferenceService.getValue('manualGridValues').projectNameManual !== "" ||
        preferenceService.getValue('manualGridValues').nameManual !== "" ||
        (preferenceService.getValue('manualGridValues').createdOnManual !== "" && preferenceService.getValue('manualGridValues').createdOnManual !== "") ||
        (preferenceService.getValue('manualGridValues').modifiedOnManual !== "" && preferenceService.getValue('manualGridValues').modifiedOnManual !== "")) {
        return true;
    }
    else {
        return false;
    }
}