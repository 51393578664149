import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const GridView = (props) => {
    return (
        <Grid key={props.index} item xs={12} sm={6} md={3}>
            <Box boxShadow={3} onClick={() => props.handleDialog(props.value)}  className="cursor-pointer p-3 m-2">
                <div className="row">
                    <div className="col-12">
                        {props.value.id && <Tooltip placement="top" id="placement" title={props.value.id}><h4 className="float-left project-max-width heading-wrap text-overflow-ellipsis">{props.value.id}</h4></Tooltip>}
                        <div className="float-right repos-badge">{props.value.numOfRepositories === 1 ? props.value.numOfRepositories + ' Repo' : props.value.numOfRepositories + ' Repos'}</div>
                    </div>
                </div>
             
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-12 my-2">
                                <h6 className="text-overflow-ellipsis">Lines of Code: <Tooltip placement="top" id="placement" title={props.value.numOfLinesAdded}><span className="secondary">{props.value.numOfLinesAdded.toString().length >= 4 ? Math.trunc(props.value.numOfLinesAdded / 1000) + 'K' : props.value.numOfLinesAdded}</span></Tooltip></h6>
                            </div>
                            <div className="col-md-12">
                                <p className="p-0 m-0 text-bold">Code Quality ({props.value.qualityPercentage.toFixed(2)}%)</p>
                                <Slider
                                    value={+props.value.qualityPercentage.toFixed(2)}
                                    getAriaValueText={(value) => value}
                                    aria-labelledby="discrete-slider-always"
                                    valueLabelDisplay="auto"
                                    className={props.value.qualityPercentage > 50
                                        ? props.value.qualityPercentage > 75
                                            ? "heigh-quality"
                                            : "medium-quality"
                                        : "low-quality"
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row text-bold">
                            <div className="col-6 align-middle">
                                <GroupOutlinedIcon className="align-middle" />
                                <span className="align-middle"> {props.value.numOfUsers} {props.value.numOfUsers === 1 ? 'User' : 'Users'}</span>
                            </div>
                            <div className="col-6 align-middle text-right">
                                <RateReviewOutlinedIcon className="align-middle" />
                                <span className="align-middle"> {props.value.numOfReviews} {props.value.numOfReviews === 1 ? 'Review' : 'Reviews'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </Grid>
    )
}

export default GridView;