import React, { Component } from 'react';
import { Query } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import gql from 'graphql-tag';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Chip from '@material-ui/core/Chip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Mutation } from 'react-apollo';

import '../modals/modal.css';
import CommentErrorModal from './CommentError-modal';

const GET_RULE_BY_RULE_NAME = gql`
query ruleByRuleNameAndLanguageExtension($ruleName: String!, $languageExtension: String!) {
    rule: ruleByRuleNameAndLanguageExtension(ruleName: $ruleName, languageExtension: $languageExtension) {
    ruleId
	ruleName
	description
	languageName
	categoryName
	message
	priority
	since
	example
    properties {
        property {
            name
            value
        }
    }
	  }
    }
`;

const USER_COMMENT = gql`
    mutation approveReviewComment(
        $reviewId: String!,
        $commentId: String!,
        $userId: String!,
		$userName: String!,
		$fullName: String!,
		$emailId: String,
		$message: String) {
            approveReviewComment(
                reviewId: $reviewId,
                commentId: $commentId,
                userId: $userId,
                userName: $userName,
                fullName: $fullName,
                emailId: $emailId,
                message: $message
            ) {
                message
                type
                createdOn
                user {
                    id
                    username
                    name
                    emailId
                }
        }
    }
`;

var users = JSON.parse(localStorage.getItem('users'));

class CommentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSn: false,
        }
        this.handleClose = this.handleClose.bind(this);
        this.copyText = this.copyText.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
        this.getLanguageExtension = this.getLanguageExtension.bind(this);
    }

    handleClose() {
        this.props.onUpdate();
        this.props.onClose();
        this.props.onRefresh();
    }

    getLanguageExtension() {
        var path = this.props.commentData.location ? this.props.commentData.location.path.split('.') : '';
        var languageName = null
        if (path && path.length) {
            languageName = path[path.length - 1];
        }
        return languageName;
    }

    closeSnackbar() {
        this.setState({
            openSn: false
        })
    }

    copyText(event) {
        var copyText = event.target.innerHTML;
        var textField = document.createElement('textarea');
        textField.innerText = copyText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({ openSn: true })
    }

    render() {
        if (!this.props.commentData) return '';
        if (!this.props.commentData.checkName) {
            return <CommentErrorModal showModal={this.props.showModal} onUpdate={this.handleClose} commentData={this.props.commentData} />
        }
        return (
            <div>
                <Query fetchPolicy="no-cache" query={GET_RULE_BY_RULE_NAME} variables={{ ruleName: this.props.commentData.checkName, languageExtension: this.getLanguageExtension() }}>
                    {({ loading, error, data }) => {
                        if (error) return <CommentErrorModal showModal={this.props.showModal} onUpdate={this.handleClose} commentData={this.props.commentData} />
                        return (
                            <Dialog
                                open={this.props.showModal}
                                onClose={this.handleClose}
                                fullWidth={true}
                                maxWidth={"md"}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description">

                                <DialogTitle>{data.rule && <Tooltip title="Rule Name" placement="top" aria-label="Language Name">
                                    <span>{data.rule.ruleName}</span>
                                </Tooltip>}
                                    {this.props.commentData && this.props.commentData.status === 'IN_REVIEW' ?
                                        <Tooltip title="Status" placement="top" aria-label="Language Name">
                                            <Chip color="secondary" className="ml-3 text-right" label={'In Review'} />
                                        </Tooltip>
                                        : null}
                                    {data.rule ?
                                        <Tooltip title="Language Name" placement="top" aria-label="Language Name">
                                            <Chip className="ml-3 text-right" label={data.rule.languageName} />
                                        </Tooltip>
                                        : null}

                                    {data.rule ?
                                        <Tooltip title="Category Name" placement="top" aria-label="Category Name">
                                            <Chip className="text-right" label={data.rule.categoryName} />
                                        </Tooltip>
                                        : null}

                                </DialogTitle>
                                <Divider />
                                <DialogContent>
                                    {loading
                                        ? <div className="loading"><CircularProgress size={50} /></div>
                                        : <div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h6 className="mt-4">Description</h6>
                                                    <div>{data.rule && data.rule.description}</div>
                                                    <h6 className="mt-4">Message</h6>
                                                    <div>{this.props.commentData.description}</div>
                                                    <h6 className="mt-4">Location</h6>
                                                    <DialogContentText>
                                                        <span onClick={this.copyText}>{this.props.commentData.location.path}</span>
                                                    </DialogContentText>

                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-md-9">
                                                    <h6>Example</h6>
                                                    <code style={{ whiteSpace: 'pre-wrap' }}>
                                                        {data.rule && data.rule.example && data.rule.example.length && data.rule.example[0].length ? data.rule.example[0] : "Example is not available"}
                                                    </code>
                                                </div>
                                                <div className="md-offset-1">
                                                    <Divider orientation="vertical" />
                                                </div>
                                                <div className="col-md-2 pl-3">
                                                    <p><b>Start Line</b><br />
                                                        <label className="secondary">{this.props.commentData.location.lines.begin}</label>
                                                    </p>
                                                    <p className="mt-4"><b>End Line</b><br />
                                                        <label className="secondary">{this.props.commentData.location.lines.end}</label>
                                                    </p>
                                                    <p className="mt-4"><b>Severity</b><br />
                                                        <label className="secondary">{this.props.commentData.severity}</label>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>}
                                </DialogContent>
                                <Divider />
                                <DialogActions>
                                    {users && users.grantedAuthorities && users.grantedAuthorities.map(e => e.authority).includes('ROLE_APPROVER') &&
                                        <Mutation mutation={USER_COMMENT} onCompleted={this.handleClose}>
                                            {(approveReviewComment, { loading, error, data }) => {
                                                return (
                                                    <div>
                                                        {this.props.commentData.status === 'IN_REVIEW'
                                                            && <Button onClick={() => {

                                                                approveReviewComment({
                                                                    variables: {
                                                                        reviewId: this.props.reviewId,
                                                                        commentId: this.props.commentData.commentId || this.commentData.lineId,
                                                                        message: '',
                                                                        userId: users.id,
                                                                        fullName: users.name,
                                                                        userName: users.username,
                                                                        emailId: users.emailId,
                                                                    }
                                                                })
                                                            }} color="primary" autoFocus>
                                                                Approve
                                                        </Button>
                                                        }
                                                    </div>
                                                )
                                            }}
                                        </Mutation>
                                    }
                                    <Button onClick={this.handleClose} color="secondary" autoFocus>
                                        close
                                    </Button>
                                </DialogActions>

                            </Dialog>
                        );
                    }}
                </Query>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.openSn}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Copied!</span>}
                    action={[
                        <Button key="undo" color="secondary" size="small" onClick={this.closeSnackbar}>
                            Ok
                    </Button>,
                    ]}
                />

            </div>
        );
    }
}

export default CommentModal;