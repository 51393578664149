import React, { Component } from 'react';
import gql from 'graphql-tag';
import { DialogActions, DialogContent, DialogTitle, Dialog, Button, Select, Input, Toolbar } from '@material-ui/core';
import { Mutation } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Snackbars from '../../../../core/error/Snackbars';
import preferenceService from '../../../../core/service/preference';

const RESTORE_USER = gql`
mutation restoreUser($email: String!) {
    restoreUser( email: $email)
}`;

const user = preferenceService.getValue('users', 'local');
class RestoreUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: ''
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.toggleSnackbars = this.toggleSnackbars.bind(this);
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleDialog() {
        this.props.onUpdate();
        this.props.refresh();
    }

    toggleSnackbars() {
        this.setState({
            showSnackbar: false
        })
    }

    render() {
        return (
            <Dialog open={this.props.showModal} fullWidth={true} maxWidth={"sm"} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Restore User</DialogTitle>
                <div className="text-center">
                    <DialogActions>
                        <DialogContent dividers>
                            <div className="w-100">
                                <Mutation mutation={RESTORE_USER}>
                                    {(restoreUser, { loading, error, data }) => (
                                        <ValidatorForm id="restoreUser" name="form" onSubmit={e => {
                                            e.preventDefault();
                                            restoreUser({ variables: { ...this.state } });
                                        }}>
                                            {error && 
                                            <Snackbars status="error" message={error.message.split(":")[2]} showSnackbar={true} closeSnackBar={this.toggleSnackbars} />}
                                            {data &&
                                                <Snackbars status="success" message='Restore User Mail ID Successfully' showSnackbar={true} closeSnacker={true} />
                                            }
                                            <div className="col-md-7 mb-4">
                                                <TextValidator type="text"
                                                    name="email"
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                    label="Mail ID"
                                                    helperText={"Example: testmail@server.com"}
                                                    validators={['required']}
                                                    errorMessages={['This field is Required']} />
                                            </div>
                                            <div className="col-md-5">
                                                <Button color="primary" type="submit" variant="contained">
                                                    {loading ? <CircularProgress disableShrink size={24} /> : 'Restore'}
                                                </Button>
                                            </div>
                                        </ValidatorForm>
                                    )}
                                </Mutation>
                            </div>
                        </DialogContent>
                    </DialogActions>
                </div>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary" >
                        close
                    </Button>
                </DialogActions>
            </Dialog>

        );
    }
}

export default RestoreUser;