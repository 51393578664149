import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbars from '../../../../core/error/Snackbars';
import { avatarImage } from '../../../components/service/avatar-image';
import gql from 'graphql-tag';


const img = avatarImage.getImageLocation();
const ADD_COMMENT = gql`
    mutation addComment(
        $reviewId: String!,
        $comment: String!
        $path: String!,
        $line: Int!,
        $severity: CommentSeverity!,
        $userId: String!,
		$userName: String!,
		$fullName: String!,
        $emailId: String,
        ) {
            addComment(
                reviewId: $reviewId,
                comment: $comment
                path: $path,
                line: $line,
                severity: $severity
                userId: $userId,
                userName: $userName,
                fullName: $fullName,
                emailId: $emailId
            ) {
                commentId
                status
                type
                description
                severity
                manualComment
                location {
                    path
                    lines {
                        begin
                        end
                    }
                }
                createdOn
                user {
                    id
                    username
                    name
                    emailId
                }
        }
    }
`;

var addedData = [];
class AddComments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comment: ''
        }
        this.handelChange = this.handelChange.bind(this);
    }

    handelChange(e) {
        this.setState({
            comment: e.target.value
        })
    }

    render() {
        return (
            <Mutation mutation={ADD_COMMENT} onCompleted={() => {this.props.addComments(addedData)}}>
                {(addComment, { loading, error, data }) => {
                    if (data) {
                        addedData = data;
                    }
                    return (
                        <div className="comment-content p-3">
                            <div className="ml-5 pl-4" style={{ width: '100%' }}>
                                <div className="comments d-flex">
                                    <div className="avatar">
                                        <img src={img} className="align-self-start" alt="Avatar" width="30" />
                                    </div>
                                    <span className="inner-comment-content">
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            var users = JSON.parse(localStorage.getItem('users'));
                                            addComment({
                                                variables: {
                                                    reviewId: this.props.reviewId,
                                                    comment: this.state.comment,
                                                    path: this.props.path,
                                                    line: this.props.lineId,
                                                    severity: "normal",
                                                    userId: users.id,
                                                    fullName: users.name,
                                                    userName: users.username,
                                                    emailId: users.emailId,
                                                }
                                            })
                                        }}>
                                            {error && <Snackbars status="error" message={error} showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />}
                                            <h6 className="text-primary">{JSON.parse(localStorage.getItem('users')).name}</h6>
                                            <textarea className="add-comments-text border-0 w-100" placeholder="Justification" onChange={this.handelChange} value={this.state.comment} required></textarea>
                                            <hr className="p-0 m-0 pb-2"></hr>
                                            <p className="actions">
                                                {<Button type="submit">{loading ? <CircularProgress disableShrink size={24} /> : 'Add'}</Button>}
                                                <span className="secondary cursor-pointer pl-4" onClick={() => { this.props.onCancel() }}><i className="material-icons align-middle icon">close</i>Cancel</span>
                                            </p>
                                        </form>
                                    </span>
                                </div>
                            </div>
                        </div>)
                }}
            </Mutation>
        );
    }
}

export default AddComments;