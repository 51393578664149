import ClinetServices from '../../../../core/service/clientUri';
import gql from 'graphql-tag';

var pageSize = 10;
var pageNumber = 0;
var filterBy = {};
var sortBy = 'username'

const GET_USER_LIST_PAGE = gql`
query getUsers($pageSize: Int, $pageNumber: Int, $sortBy: String!, $direction: Direction, $filterBy: UsersInput) {
    getUsers(pageNumber: $pageNumber, pageSize: $pageSize, sortBy: $sortBy, direction: $direction, filterBy: $filterBy) {
        content {
          __typename
          ... on UsersDetails {
            id
            name
            username
            email
            organizationName
            orgUserMapId
            organizations {
                organizationId,
                OrganizationName,
                orgUserMapId,
                roles {
                    id
                    name
                }
            }
            }
        }
        totalPages
        totalElements
        last
        first
        numberOfElements
        size
        number
        empty		
    }
}
`;

class userManagementServices {

    constructor(props) {
        this.getFromClintList = this.getFromClintList.bind(this);
        this.getUserList = this.getUserList.bind(this);
    }

    async getFromClintList(client) {
        var response = await client.query({
            query: GET_USER_LIST_PAGE,
            variables: { pageSize: pageSize, pageNumber: pageNumber, sortBy: sortBy, filterBy: filterBy, direction: 'ASC' }
        });
        return await response.data.getUsers;
    }

    getUserList(query, filter) {
        if (query) {
            pageSize = query.pageSize;
            pageNumber = query.page;
            filterBy = filter;
        }

        return this.getFromClintList(ClinetServices.getClienUri()).then(res => {
            return {
                data: res.content,
                page: res.number,
                totalCount: res.totalElements,
            }
        }, error => {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            }
        });
    }
}

export default userManagementServices;