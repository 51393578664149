export default {
    "rules": {
        "adjacent-overload-signatures": true,
        "no-any": true,
        "no-empty-interface": true,
        "no-internal-module": true,
        "no-namespace": true,
        "no-non-null-assertion": true,
        "no-reference": true,
        "no-restricted-globals": true,
        "no-this-assignment": true,
        "no-var-requires": true,
        "only-arrow-functions": true,
        "prefer-for-of": true,
        "promise-function-async": true,
        "unified-signatures": true,
        "await-promise": true,
        "curly": true,
        "forin": true,
        "label-position": true,
        "no-arg": true,
        "no-bitwise": true,
        "no-conditional-assignment": true,
        "no-console": true,
        "no-construct": true,
        "no-debugger": true,
        "no-duplicate-super": true,
        "no-duplicate-variable": {
            "options": [
                "check-parameters"
            ]
        },
        "no-empty": true,
        "no-eval": true,
        "no-for-in-array": true,
        "no-inferred-empty-object-type": true,
        "no-invalid-template-strings": true,
        "no-misused-new": true,
        "no-object-literal-type-assertion": true,
        "no-shadowed-variable": true,
        "no-string-literal": true,
        "no-string-throw": true,
        "no-sparse-arrays": true,
        "no-unbound-method": true,
        "no-unsafe-any": true,
        "no-unsafe-finally": true,
        "no-unused-expression": true,
        "no-var-keyword": true,
        "no-void-expression": true,
        "prefer-conditional-expression": true,
        "radix": true,
        "restrict-plus-operands": true,
        "strict-boolean-expressions": [
            true,
            "allow-null-union",
            "allow-undefined-union",
            "allow-string",
            "allow-enum",
            "allow-number"
         ],
        "strict-type-predicates": true,
        "switch-default": true,
        "triple-equals": true,
        "use-default-type-parameter": true,
        "use-isnan": true,
        "cyclomatic-complexity": true,
        "eofline": true,
        "indent": {
            "options": [
                "spaces"
            ]
        },
        "linebreak-style": {
            "options": "LF"
        },
        "max-classes-per-file": {
            "options": 1
        },
        "max-file-line-count": {
            "options": 1000
        },
        "max-line-length": {
            "options": {
                "limit": 160
            }
        },
        "no-default-export": true,
        "no-duplicate-imports": true,
        "no-irregular-whitespace": true,
        "no-mergeable-namespace": true,
        "no-parameter-reassignment": true,
        "no-require-imports": true,
        "no-trailing-whitespace": true,
        "prefer-const": true,
        "trailing-comma": {
            "options": {
                "esSpecCompliant": true,
                "multiline": "always",
                "singleline": "never"
            }
        },
        "array-type": {
            "options": "generic"
        },
        "arrow-parens": true,
        "arrow-return-shorthand": {
            "options": "multiline"
        },
        "binary-expression-operand-order": true,
        "callable-types": true,
        "class-name": true,
        "comment-format": {
            "options": [
                "check-space",
                "check-uppercase"
            ]
        },
        "deprecation": true,
        "encoding": true,
        "import-spacing": true,
        "interface-over-type-literal": true,
        "match-default-export-name": true,
        "new-parens": true,
        "newline-before-return": true,
        "no-angle-bracket-type-assertion": true,
        "no-boolean-literal-compare": true,
        "no-consecutive-blank-lines": true,
        "no-reference-import": true,
        "no-unnecessary-callback-wrapper": true,
        "no-unnecessary-initializer": true,
        "no-unnecessary-qualifier": true,
        "no-unnecessary-type-assertion": true,
        "number-literal-format": true,
        "object-literal-key-quotes": {
            "options": "consistent-as-needed"
        },        
        "one-variable-per-declaration": true,
        "prefer-method-signature": true,
        "prefer-object-spread": true,
        "prefer-switch": true,
        "prefer-template": true,
        "member-access": [true, "check-accessor", "check-parameter-property"],
        "quotemark": {
            "options": [
                "single"
            ]
        },
        "return-undefined": true,
        "semicolon": {
            "options": [
                "always"
            ]
        },
        "typedef": {
            "options": [
                "call-signature",
                "arrow-call-signature",
                "parameter",
                "arrow-parameter",
                "property-declaration",
                "variable-declaration",
                "member-variable-declaration"
            ]
        },
        "unnecessary-bind": true,
        "unnecessary-else": true,
        "variable-name": {
            "options": [
                "ban-keywords",
                "check-format",
                "require-const-for-all-caps"
            ]
        }
    }
}