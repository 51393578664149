import React from 'react';
import { Redirect } from 'react-router-dom';

const Auth = {

    hasRole: (user, roles) => {
        
        if (user && user.grantedAuthorities) {
            return roles.some(role => {
                return user.grantedAuthorities.map(roles => roles.authority).includes(role);
            });
        }
        else {
            return false;
        }
    }
}

export default Auth;
