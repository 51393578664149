import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import CommentIcon from '@material-ui/icons/Comment';

import AddComments from './../../reviews/stashView/components/AddComments';
import ErrorType from '../../../core/error/simpleError';
import './diffView.css';
import ReviewComment from './../../reviews/stashView/components/ReviewComment';

var lastDestination = 0,
    tempMarker = [],
    marker = [];
class DiffView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            commentsList: this.props.comments,
            coll: marker,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.hideComment = this.hideComment.bind(this);
        this.addedComments = this.addedComments.bind(this);
        this.setValue = this.setValue.bind(this);
    }

    hideComment(cmt) {
        this.setState({
            [cmt.destination + '' + cmt.source]: false,
        });
    }

    setValue() {
        if (tempMarker.length !== marker.length) {
            marker = tempMarker;
            this.setState({
                coll: marker
            })
        }
        tempMarker = []
    }

    addedComments(data, cmt) {
        var manualCmt = this.props.comments;
        manualCmt[data.addComment.location.lines.begin] ? manualCmt[data.addComment.location.lines.begin].push(data.addComment) : manualCmt[data.addComment.location.lines.begin] = [data.addComment]

        this.setState({
            commentsList: manualCmt,
            [cmt.destination + '' + cmt.source]: false,
        });
    }

    handleClick(value) {
        var copyText = value;
        copyText = copyText.replace(/&amp;/g, '&');
        var textField = document.createElement('textarea');
        textField.innerText = copyText;
        document.getElementById('title-header').appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({ open: true });
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ open: false });
    }

    componentDidMount() {
        this.props.refresh();

    }

    render() {
        if (!this.props.data) return null;
        if (this.props.data.diff.diffs && !this.props.data.diff.diffs.length) {
            return (<div className="p-5">
                <ErrorType type="outDated" size={200}
                    head="No Diff Were Found"
                    content="They may Delete their branch or No changes Were done in our Latest Diff" />
            </div>);
        }
        return (
            <div>
                <div className="container-dl" id="title-header">
                    <div className="diff-overall-container row p-0 m-0">
                        <div className="p-2 pr-3 bg-light min-width-diff">
                            <Paper elevation={2} className="p-3 sticky-div w-100">
                                <h4 className="pb-2">File Location</h4>
                                <div className="row">
                                    <div className="col-md-10 col-7">
                                        <Breadcrumbs aria-label="breadcrumb">
                                            {this.props.data.diff.diffs[0] && this.props.data.diff.diffs[0].destination.components.map((data, index) => {
                                                return <Typography key={index} color="textPrimary">{data}</Typography>
                                            })}
                                        </Breadcrumbs>
                                    </div>
                                    <div className="col-md-2 col-5 text-right">
                                        <span className="pr-2">
                                            {this.props.data.diff.diffs[0] && this.props.data.diff.diffs[0].destination && this.props.data.diff.diffs[0].source
                                                ? <Chip size="small" label="Modified" color="secondary" />
                                                : <Chip size="small" label="Added" color="primary" />
                                            }
                                        </span>
                                        <Tooltip title="Copy Url" placement="top">
                                            <span className="ml-1 file-copy-icon" onClick={(e) => this.handleClick(this.props.data.diff.diffs[0].destination.toString)}><i className="material-icons align-middle">file_copy</i></span>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">

                                    </div>
                                </div>
                            </Paper>
                            <br />
                            {this.props.data.diff.status || !this.props.data.diff.diffs[0].hunks 
                                ? <div className="p-5">
                                    <ErrorType type="outDated" size={200}
                                        head={!this.props.data.diff.diffs[0].hunks ? "Unable to view the file" : "Outdated"}
                                        content={!this.props.data.diff.diffs[0].hunks ? "Downloadable file cannot be shown here. Please use the code repository to view the file" : "Please resubmit the request with the latest changes to see the diff"} />
                                </div>
                                :
                                <div className="w-100 overflow-auto">
                                    <div className="marker">
                                        {this.state.coll.map((data, index) => {
                                            var markerSize = 100 / this.state.coll.length;
                                        if (data.includes('i_')) {
                                                if (data.includes('true')) {
                                                    return (<Tooltip key={index} title="Manual Comment" placement="top">
                                                        <div key={index} onClick={() => {
                                                            this[data].scrollIntoView({
                                                                behavior: 'smooth',
                                                                block: "center", inline: "nearest"
                                                            });
                                                        }} className={"changed-line-path changed-line-manual bg-primary"} style={{
                                                            height: markerSize + "%"
                                                        }}></div>
                                                    </Tooltip>)
                                                }

                                                return (<Tooltip key={index} title="Comment" placement="top">
                                                    <div key={index} onClick={() => {
                                                        this[data].scrollIntoView({
                                                            behavior: 'smooth',
                                                            block: "center", inline: "nearest"
                                                        });
                                                    }} className={"changed-line-path changed-line bg-danger"} style={{
                                                        height: markerSize + "%"
                                                    }}></div>
                                                </Tooltip>)

                                            } else {
                                                return <div key={index} style={{
                                                    height: markerSize + "%"
                                                }}></div>
                                            }

                                        })}
                                    </div>
                                    <div className="diffView-container">
                                        {this.props.data.diff.diffs[0].hunks && this.props.data.diff.diffs[0].hunks.map((contaxt, index) => {
                                            if (!index) {
                                                lastDestination = 0;
                                            }
                                            return (
                                                <div key={index} className="m-auto bg-white mb-4 p-0 shadow-sm data">
                                                    {
                                                        Object.keys(this.props.comments).map((key, index) => {
                                                            return (contaxt.destinationLine > key && (key > lastDestination))
                                                                ?
                                                                <div key={index} className="row p-0 m-0">
                                                                    <div className="ml-4 mr-4 mt-3 mb-3 shadow comment-container bg-white rounded">
                                                                        {this.props.comments[key].map((cmt, index) => {
                                                                            tempMarker.push("i_" + cmt.commentId)
                                                                            return (<div key={index} ref={ref => this["i_" + cmt.commentId] = ref} id={"i_" + cmt.commentId}><ReviewComment key={index} data={cmt} reviewId={this.props.reviewId} state={this.props.state} /></div>)
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                : null;
                                                        })
                                                    }
                                                    {contaxt.segments.map((seg, index) => {
                                                        return (<div key={index} className={seg.type}>
                                                            {seg.lines.map((line, index) => {
                                                                tempMarker.push("s_" + index)
                                                                lastDestination = line.destination
                                                                return (<div key={index} className="row p-0 m-0 add-comment">
                                                                    <div className="col-md-12 d-flex">
                                                                        {!this.props.state && <div className="add-comment-icon">
                                                                            <Tooltip title="Add a comment on this line." placement="top">
                                                                                <CommentIcon onClick={() => { this.setState({ [line.destination + '' + line.source]: true }) }} color="primary" />
                                                                            </Tooltip>
                                                                        </div>}
                                                                        <span className="line line-src">{line.source || ''}</span>
                                                                        <span className="line line-dest"> {line.destination}</span>
                                                                        <div className="line">
                                                                            {seg.type === "ADDED" ? '+' : ''}
                                                                            {seg.type === "REMOVED" ? '-' : ''}
                                                                            {seg.type === "CONTEXT" ? ' ' : ''}
                                                                        </div>
                                                                        <span className="line-Changes pl-3" style={{ whiteSpace: 'pre-wrap' }}>
                                                                            {line.line}
                                                                        </span>
                                                                    </div>
                                                                    {this.state[line.destination + '' + line.source]
                                                                        ?
                                                                        <div className="ml-4 mr-4 mt-3 mb-3 shadow comment-container bg-white rounded">
                                                                            <div className="manual-comment">
                                                                                <AddComments path={this.props.data.diff.diffs[0] && this.props.data.diff.diffs[0].destination.toString} lineId={line.destination} reviewId={this.props.reviewId} state={this.props.state} data={this.props.data} onCancel={() => { this.hideComment(line) }} addComments={(e) => { this.addedComments(e, line) }} />
                                                                            </div>
                                                                        </div> : null
                                                                    }
                                                                    {this.state.commentsList[line.destination] && seg.type !== "REMOVED" ?
                                                                        <div className="ml-4 mr-4 mt-3 mb-3 shadow comment-container bg-white rounded">
                                                                            {this.state.commentsList[line.destination].map((cmt, index) => {
                                                                                tempMarker.push("i_" + cmt.commentId + cmt.manualComment)
                                                                                return (<div key={index} ref={ref => this["i_" + cmt.commentId + cmt.manualComment] = ref}
                                                                                    id={"i_" + cmt.commentId}>
                                                                                    <ReviewComment key={index} data={cmt} reviewId={this.props.reviewId} state={this.props.state} />
                                                                                </div>)
                                                                            })}
                                                                        </div>
                                                                        : null}
                                                                </div>)
                                                            })}
                                                        </div>)
                                                    })}

                                                    <div className="p-4 text-center text-danger bg-light">
                                                        * * * * * * * * *
                                                </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {this.setValue()}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.open}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.message ? this.state.message : "Copied!"}</span>}
                    action={[
                        <Button key="undo" color="secondary" size="small" onClick={this.handleClose}>
                            Ok
                        </Button>,
                    ]}
                />
            </div>
        );
    }
}

export default DiffView;