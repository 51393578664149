import React, { Component, useRef, useState } from 'react';
import { makeStyles, Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ReactToPrint from 'react-to-print';
import { ProjectReport } from './../components';
import { RepoReport } from './../components';
import '../../users/users.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Popover from '@material-ui/core/Popover';


const ref = React.createRef();
function PdfSelect(props) {

    const [repoName, setRepo] = React.useState("");

    const [option, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        if (option == false) {
            setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };
    const componentRef = useRef();
    const repoRef = useRef([]);
    const { innerHeight, innerWidth } = window;
    function setRepoName(row) {
        setRepo(row);
    }
    const { children, classes, onClose, filter, ...other } = props.data;
    return (
        <div onClick={handleClickOpen}>
            <IconButton className={"pdf-button"} color="primary">
                <Tooltip title="Export PDF" placement="top">
                    <PictureAsPdfIcon />
                </Tooltip>
            </IconButton>
            <div style={{ display: "none" }}>
                <Popover
                    anchorOrigin={{
                        vertical: innerWidth / 15,
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    ref={ref}
                    className="customize-inner-dialog-height -webkit-scrollbar"
                    fullScreen={window.innerWidth < 600}
                    scroll={'paper'}
                    open={option}>
                    <MuiDialogTitle disableTypography className={classes.root} {...other}>
                        <Typography variant="h5">Repositories ({props.value.numOfRepositories})</Typography>
                    </MuiDialogTitle>
                    <Divider />
                    <DialogContent className="project-group-edit-container">
                        <div>
                            <ReactToPrint
                                trigger={() => (
                                    <h6 style={{ cursor: "pointer", color: 'blue' }}>All Repo </h6>
                                )}
                                content={() => componentRef.current}
                            />
                            <div style={{ display: "none" }}>
                                <ProjectReport filter={props.filter} dateFilter={props.dateFilter} data={props.value} ref={componentRef} show={true} />
                            </div>
                        </div>
                        <Divider className="my-2 px-2" />
                        {props.value.repositories.map((row, index) => (
                            <div onClick={() => { setRepoName(row) }}>
                                <ReactToPrint
                                    trigger={() => (
                                        <h6 className="capitalize text-wrap" style={{ cursor: "pointer", color: 'blue' }}>{props.value.deleted ? <div className ="crossed-line">{row}</div> : row}
                                            <Divider className="my-2 px-2" /></h6>
                                    )}
                                    content={() => repoRef.current[index]}
                                />
                                <div style={{ display: "none" }}>
                                    {
                                        <RepoReport filter={props.filter} children={children} dateFilter={props.dateFilter} projectId={props.projectId} category={props.category} ref={(e) => (repoRef.current[index] = e)} show={true} value={row} />
                                    }
                                </div>
                            </div>
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </Popover>
            </div>
        </div>
    )
}

export default PdfSelect