import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { AgGridReact } from 'ag-grid-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import EditSchedulerManagement from './cronjob-message';
import EditCronJob from './edit-cronjob';
import '../admin.css';
import Error500 from '../../../core/error/error';
import { UTCtoLocalTime } from '../../../core/service/timezone';

var style = {
    border: 'none',
}
var user = JSON.parse(localStorage.getItem('users'));

const GET_All_CRONJOBS = gql`
query jobs {
    jobs {
        jobName
        duration
        lastRunTime
        startTime
        cronStatus
        endTime
        message{
            _id,
            error
        }
        parameter
        enabled
        expression
      }
}
`;

class CronJobManagement extends Component {
    jobData;
    constructor(props) {
        super(props)
        this.tableRef = React.createRef();
        this.state = {
            showEditModal: false,
            showUpdateModal: false,
            filter: '',
            defaultColDef: {
                sortable: true,
                resizable: true,
            },
            jobData: [],
            columnDefs: [{
                headerName: "Job Name", field: "jobName", suppressSizeToFit: false, cellRenderer: function (params) {
                    return params.data.jobName ? params.data.jobName : "";
                }
            },
            {
                headerName: "Schedule", field: "expression", suppressSizeToFit: false, cellRenderer: function (params) {
                    return params.data.expression ? params.data.expression : "";
                }

            }, {
                headerName: "Cron Status", field: "enabled", suppressSizeToFit: false, cellRenderer: function (params) {
                    return '<div class="custom-control custom-switch" title= "' + (params.data.enabled ? 'enabled' : 'disabled') + '"> <input type="checkbox" class="custom-control-input" id="customSwitch"' + (params.data.enabled ? 'checked' : '') + '><label class="custom-control-label" for="customSwitch1"></label></div>';
                }
            }, {
                headerName: "Last Run Time", field: "startTime", suppressSizeToFit: false, cellRenderer: function (params) {
                    var startTime = "<Tooltip title=" + (params.data.startTime ? UTCtoLocalTime(new Date(params.data.startTime.substr(0, 20).concat(params.data.startTime.substr(24))), 'YYYY-MMM-DD HH:mm A') : "") + "placement='top'><div>" +
                        (params.data.startTime ? UTCtoLocalTime(new Date(params.data.startTime.substr(0, 20).concat(params.data.startTime.substr(24))), 'YYYY-MMM-DD HH:mm A') : "") + "</div></Tooltip>";
                    return startTime;
                }
            }, {
                headerName: "Duration", field: "duration", suppressSizeToFit: false, cellRenderer: function (params) {


                    if (params.data.duration < 1000) {
                        return params.data.duration + " ms";
                    } else if (params.data.duration > 1000) {
                        params.data.duration = params.data.duration / 1000;
                        return params.data.duration + " secs";
                    } else {
                        params.data.duration = params.data.duration / 1000;
                        params.data.duration = params.data.duration / 60;
                        return params.data.duration + " minutes";
                    }

                }
            }, {
                headerName: "Status", field: "cronStatus", suppressSizeToFit: false, cellRenderer: function (params) {
                    return params.data.cronStatus ? params.data.cronStatus : "";
                }
            },
            {
                headerName: "Message", suppressSizeToFit: false, cellRenderer: function (params) {
                    if (!(params.data.message && params.data.message.length)) {
                        return '';
                    } else {
                        var JobConfigBtn = "<div><Tooltip title='Message' placement='top'><button class='btn btn-link " + (params.data.message && params.data.message.length ? 'text-danger' : '')
                            + " '><i class='material-icons'>info</i></button></Tooltip></div>";
                        return JobConfigBtn;
                    }
                }
            }, {
                headerName: "Action", suppressSizeToFit: false, cellRenderer: function (params) {
                    var LanguageConfigBtn = "<div><Tooltip title='Edit Language' placement='top'><button class='btn btn-link " + (params.data.languages && params.data.languages.length ? "" : "text-danger") + " '><i class='material-icons'>more_horiz</i></button></Tooltip></div>";
                    return LanguageConfigBtn;
                }
            }]
        }
        this.onGridReady = this.onGridReady.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.handleEditDialog = this.handleEditDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        params.api.sizeColumnsToFit();
    }

    onFilterTextBoxChanged(event) {
        this.setState({ filter: event.target.value })
        this.gridApi.setQuickFilter(event.target.value);
    }

    handleEditDialog(grid) {
        if (grid) {
            this.jobData = grid.data;
        }
        if (grid && grid.colDef.headerName === 'Message') {
            this.setState({ showEditModal: this.jobData.message && this.jobData.message.length !== 0 ? true : false });
        }
        if (grid && grid.colDef.headerName === 'Action') {
            this.setState({ showUpdateModal: true });
        }
        if (!grid) {
            this.setState({ showEditModal: false });
        }
    }

    handleClose() {
        this.setState({ showEditModal: false });
        this.setState({ showUpdateModal: false });
    }

    render() {

        return (
            <div className="col-md-12 user-management">
                <Query query={GET_All_CRONJOBS}>
                    {({ loading, error, data, refetch }) => {
                        if (loading) return <div className="loading"><CircularProgress size={50} /></div>
                        if (error) return <div className="text-center">
                            <Error500 />
                        </div>

                        return (
                            <div className="row">
                                <div className="col-md-12">
                                    <div
                                        className="ag-theme-material container-fluid pt-1 pl-0 pr-0 pb-0"
                                        style={{
                                            height: 'calc(100vh - 150px)', width: '100%'
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-md-3 mb-2">
                                                <TextField type="text"
                                                    label="Search"
                                                    id="filter-text-box"
                                                    value={this.state.filter}
                                                    onChange={this.onFilterTextBoxChanged}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className="offset-md-3 col-md-6 col-6 mt-4 refresh-icon">
                                                <Tooltip title="Refresh" placement="top">
                                                    <RefreshIcon onClick={() => refetch()} style={{ verticalalign: 'middle' }} color="primary" />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            rowData={data.jobs}
                                            pagination={true}
                                            animateRows={true}
                                            onGridReady={this.onGridReady}
                                            defaultColDef={this.state.defaultColDef}
                                            suppressRowClickSelection={true}
                                            onCellClicked={this.handleEditDialog}
                                            paginationAutoPageSize={true}
                                        >
                                        </AgGridReact>
                                        {this.state.showEditModal && <EditSchedulerManagement jobData={this.jobData} showModal={this.state.showEditModal} onClose={this.handleClose} onUpdate={this.handleEditDialog} refresh={() => refetch()} history={this.props.history}></EditSchedulerManagement>}
                                        {this.state.showUpdateModal && <EditCronJob jobData={this.jobData} showModal={this.state.showUpdateModal} onClose={this.handleClose} onUpdate={this.handleEditDialog} refresh={() => refetch()} history={this.props.history}></EditCronJob>}
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </Query>
            </div>
        );
    }
}

export default CronJobManagement;