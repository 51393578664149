import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './login.css';
import { loginService } from './services/login-service';
import Snackbars from './../error/Snackbars';

class Signup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            name: '',
            password: '',
            confirmPassword: '',
            username: '',
            error: '',
            formErrors: {
                email: '',
                name: '',
                password: '',
                confirmPassword: '',
                username: '',
                error: '',
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleSnackbars = this.toggleSnackbars.bind(this);
    }

    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
    }

    toggleSnackbars() {
        this.setState({
            showSnackbar: false
        })
    }

    validate(activeKey) {
        var form = document.getElementById("signup");
        form.reportValidity()
        if (form.checkValidity()) {
            this.setState({
                activeKey: activeKey,
            });
        }

    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true });
        const { email, name, password, username } = this.state;

        this.setState({ loading: true });
        this.setState({ errorPassword: false });
        loginService.signup(email, name, password, username)
            .then(
                user => {
                    const { from } = this.props.location.state || { from: { pathname: "/login" } };
                    this.props.history.push(from);
                },
                error => this.setState({ error, loading: false, showSnackbar: true })
            );
    }

    render() {
        const { loading, error } = this.state;
        return (
            <div className="login-bg">
                <div className="login-container">
                    <div className="offset-md-8 col-md-4 login-box p-0">
                        <ValidatorForm id="login" ref="form" name="form" onSubmit={this.onSubmit}>
                            <div className="offset-md-1 col-md-10 text-center p-5">
                                <img src="/images/nav-logo.png" alt="Logo" />
                            </div>
                            <div className="col-md-10 mb-4 offset-md-1">
                                {error && <Snackbars status="error" message={error} showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />}
                                <TextValidator type="text"
                                    name="name"
                                    inputProps={
                                        { maxLength: 50, minLength: 3 }
                                    }
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    label="Name"
                                    validators={['required']}
                                    errorMessages={['This field is Required']}
                                />
                            </div>
                            <div className="col-md-10 mb-4 offset-md-1">
                                <TextValidator type="text"
                                    name="username"
                                    inputProps={
                                        { maxLength: 50, minLength: 3 }
                                    }
                                    validators={['required']}
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                    label="Username"
                                    errorMessages={['This field is Required']}
                                />
                            </div>
                            <div className="col-md-10 mb-4 offset-md-1">
                            {error && <Snackbars status="error" message={error} showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />}
                                <TextValidator
                                    name="email"
                                    label="Email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    validators={['required', 'isEmail']}
                                    helperText="Note: sample@domain.com"
                                    inputProps={
                                        { maxLength: 100 }
                                    }
                                    errorMessages={['This field is Required', 'Email is not Valid']}
                                />
                            </div>
                            <div className="col-md-10 mb-4 offset-md-1">
                                <TextValidator type="password"
                                    name="password"
                                    inputProps={
                                        { maxLength: 20, minLength: 6 }
                                    }
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    label="Password"
                                    validators={['required']}
                                    errorMessages={['This field is Required']}
                                />
                            </div>
                            <div className="col-md-10 mb-5 offset-md-1">
                                <TextValidator type="password"
                                    name="confirmPassword"
                                    inputProps={
                                        { maxLength: 20, minLength: 6 }
                                    }
                                    value={this.state.confirmPassword}
                                    onChange={this.handleChange}
                                    label="Confirm Password"
                                    validators={['isPasswordMatch', 'required']}
                                    errorMessages={['Password Mismatch', 'This field is Required']}
                                />
                            </div>
                            <div className="col-md-10 offset-md-1 button-height">
                                <Button color="primary" type="submit" variant="contained" className="w-100" disabled={loading}>
                                    {loading ? <CircularProgress disableShrink size={24} /> : 'Sign Up'}
                                </Button>
                            </div>
                        </ValidatorForm>
                        <div className="offset-md-1 col-md-10 p-4 text-center">
                            <a href="/login">Back to Login</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Signup;