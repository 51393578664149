import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './login.css';
import Snackbars from './../error/Snackbars';

class Signup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            error: '',
            mailSent: false,
            status: '',
            formErrors: {
                email: '',
                error: '',
                mailSent: false,
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleSnackbars = this.toggleSnackbars.bind(this);
    }


    toggleSnackbars() {
        this.setState({
            showSnackbar: false
        })
    }

    validate(activeKey) {
        var form = document.getElementById("forget-password");
        form.reportValidity()
        if (form.checkValidity()) {
            this.setState({
                activeKey: activeKey,
            });
        }

    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.setState({
            error: '',
            mailSent: false,
            showSnackbar: false,
            status: "",
            error: '',
            loading: false
        })
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true,  mailSent: false});
        this.setState({ error: "", status: ""});
        const { email } = this.state;
        this.setState({ loading: true });
        fetch(`code-review-service/api/auth/forgot-password/${email}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            method: 'PATCH',
        }).then(async (response) => response.text().then(text => {
            if (response.ok) {
                this.setState({
                    mailSent: true,
                    showSnackbar: true,
                    status: "Mail Sent Successfully",
                    error: '',
                    loading: false,
                })
            }
            else {
                const data = text && JSON.parse(text);
                this.setState({
                    loading: false,
                    mailSent: false,
                    showSnackbar: true,
                    status: "",
                    error: (data && data.message) !== "No message available" ? (data && data.message) : response.statusText,
                })
            }
        }))
    }


    render() {
        const { loading, error } = this.state;
        return (
            <div className="login-bg">
                <div className="login-container">
                    <div className="offset-md-8 col-md-4 login-box p-0">
                        <ValidatorForm id="forgot-password" ref="form" name="form" onSubmit={this.onSubmit}>
                            <div className="offset-md-1 col-md-10 text-center p-5">
                                <img src="/images/nav-logo.png" alt="Logo" />
                            </div>
                            <div className="col-md-10 mb-4 offset-md-1">
                                {error && <Snackbars status="error" message={error} showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />}
                                {this.state.mailSent && <Snackbars status="success" message={this.state.status} showSnackbar={this.state.showSnackbar} closeSnackBar={this.toggleSnackbars} />}
                                <TextValidator
                                    name="email"
                                    label="Email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    validators={['required', 'isEmail']}
                                    helperText="Note: sample@domain.com"
                                    inputProps={
                                        { maxLength: 100 }
                                    }
                                    errorMessages={['This field is Required', 'Email is not Valid']}
                                />
                            </div>
                            <div className="col-md-10 offset-md-1 button-height">
                                <Button color="primary" type="submit" variant="contained" className="w-100" disabled={loading}>
                                    {loading ? <CircularProgress disableShrink size={24} /> : 'Send Mail'}
                                </Button>
                            </div>
                        </ValidatorForm>
                        <div className="offset-md-1 col-md-10 p-4 text-center">
                            <a href="/login">Back to Login</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Signup;