import React from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { loginService } from '../login/services/login-service';
import preferenceService from '../service/preference';

var jwt = require('jsonwebtoken');
const accessToken = preferenceService.getValue('user', 'local').accessToken;
var decoded = jwt.decode(accessToken, { complete: true });
var time = decoded && decoded.payload && decoded.payload.exp * 1000;
var sessionPopupClose = time - 1000 * 60

class SessionContinue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionModal: this.props.showModal
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleSessionContinue = this.handleSessionContinue.bind(this);
        this.handleSessionPopupClose = this.handleSessionPopupClose.bind(this);
        this.handleSessionPopupClose();
    }

    handleSessionPopupClose() {
        if (this.state.sessionModal) {
            this.interval = setInterval(() => {
                if (moment.now() > sessionPopupClose) {
                    loginService.logout(() => {
                        window.location.reload();
                    });
                    this.setState({
                        sessionModal: false
                    });
                }
            }, 10000);
        }
    }

    handleClose() {
        loginService.logout(() => {
            window.location.reload();
        });
        this.setState({
            sessionModal: false
        })
    }

    handleSessionContinue() {
        loginService.refreshToken(() => {
            window.location.reload();
        });

        this.setState({
            sessionModal: false
        });
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.sessionModal}
                    onClose={this.props.handleCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Continue Session?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to continue the session?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSessionContinue} color="primary">
                            Continue
                        </Button>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

export default SessionContinue;
