import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';

const filrStateColor = {
    MODIFY: '#1a73e8',
    ADD: '#3c8039',
    DELETE: '#e3742f'

};
const useTreeItemStyles = makeStyles(theme => ({
    root: {
        width: 'max-content',
        '&:focus > $content': {
            width: 'max-content',
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)',
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        '& $content': {
            paddingLeft: theme.spacing(1),
        },
    },
    expanded: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        paddingLeft: '5px',
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, manualComments, lintError, isExcluded, isUnSupported, viewed, type, labelInfo, color, comments, bgColor, ...other } = props;
    return (
        <TreeItem
            label={
                <div className={classes.labelRoot} style={{ color: filrStateColor[type] || '' }}>
                    {comments
                        ?
                        <Badge badgeContent={comments} color="secondary">
                            <LabelIcon color="inherit" />
                        </Badge>
                        : <LabelIcon color="inherit" />
                    }
                    <Typography variant="body2" className={classes.labelText}>
                        <span className="align-middle">{!viewed ? <b>{labelText}</b> : labelText}</span> {lintError ? <Tooltip title='Review Failed' placement='top'><i className="error-icon align-middle material-icons text-danger">info</i></Tooltip>: ''}
                        {isExcluded ? <Tooltip title='This file is excluded from review' placement='top'><i className="error-icon align-middle material-icons font-orange">announcement</i></Tooltip>: ''}
                        {isUnSupported ? <Tooltip title='Unsupported file for review' placement='top'><i className="error-icon align-middle material-icons font-orange">report_problem</i></Tooltip>: ''}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}


StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};

class TreeItems extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.updateFile = this.updateFile.bind(this);
        this.hasLinterError = this.hasLinterError.bind(this);
    }

    updateFile(data) {
        this.props.updateFile(data)
        this.setState({
            [data.name]: true
        })
    }

    hasLinterError(data) {
        let linterError = false;
        if (data) {
            data.map(innerData => {
                linterError = innerData.reviewFailed;
            })
        }
        return linterError;
    }

    render() {
        if (!this.props.data.length) {
            return null
        }
        return (
            <div>
                {this.props.data.map((data, index) => {
                    return (
                        <div>
                            {!data.children.length
                                ? <StyledTreeItem nodeId={data.name + Math.floor(Math.random() * 100) + 1}
                                    labelText={data.name}
                                    onClick={() => this.updateFile(data)}
                                    comments={this.hasLinterError(data.path.comments) ? "" : (data.path.comments && data.path.comments.length)}
                                    manualComments={data.path.numOfManualComments}
                                    type={data.path.type}
                                    viewed={this.state[data.name]}
                                    color={!data.children.length ? "#1a73e8" : null}
                                    bgColor={!data.children.length ? "#e8f0fe" : null}
                                    lintError={this.hasLinterError(data.path.comments)}
                                    isExcluded={data.path.isExcluded}
                                    isUnSupported={data.path.isUnSupported}
                                    labelIcon={data.children.length ? FolderIcon : InsertDriveFileIcon} />
                                : <StyledTreeItem nodeId={data.name}
                                    labelText={data.name}
                                    viewed={true}
                                    labelIcon={data.children.length ? FolderIcon : InsertDriveFileIcon} >
                                    <TreeItems data={data.children} updateFile={this.props.updateFile} />
                                </StyledTreeItem>
                            }
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default TreeItems;