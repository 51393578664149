import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import ProjectList from '../../components/projects/ProjectList';
import Snackbars from '../../../core/error/Snackbars'
const UPDATE_RULESET_PROJECT = gql`
mutation updateProjectRules($ruleConfigRequest: RuleConfigRequest) {
    updateProjectRules(ruleConfigRequest: $ruleConfigRequest)
}
`;

const UPDATE_RULE = gql`
mutation updateGlobalRules(
    $enabledByDefault: [Long],
    $disabledByDefault: [Long],
    $updateExistingProjects: Boolean = false,
    $projectIds: [Long]) 
    {
        updateGlobalRules(
            enabledByDefault: $enabledByDefault
            disabledByDefault: $disabledByDefault
            updateExistingProjects: $updateExistingProjects
            projectIds: $projectIds
        )
  }
`;

var languageName = '';
class RulesConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configuration: 'new',
            selectedProjectList: []
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getRulesConfiguration = this.getRulesConfiguration.bind(this);
        this.getList = this.getList.bind(this);
        this.getRulesByProject = this.getRulesByProject.bind(this);
    }

    handleClose(data) {
        this.props.onUpdate(data);
        this.setState({
            configuration: 'new',
            selectedProjectList: [],
        })
    }

    handleChange(event) {
        this.setState({
            configuration: event.target.value
        })
    }

    getList(data) {
        this.setState({
            selectedProjectList: data
        });
    }

    getRulesByProject(currentStateObject) {
        var rulesList = [];
        currentStateObject.forEach((cat, i) => {
            if (cat.rules) {
                cat.rules.forEach((rule, ind) => {
                    if (rule.enabled) {
                        languageName = rule.languageName
                        rulesList.push({
                            id: rule.ruleId,
                            ruleName: rule.ruleName,
                            ruleLanguage: rule.languageName,
                            ruleCategory: rule.categoryName,
                            rulePath: rule.path,
                            ruleOptions: rule.ruleOptions || null,
                            ruleSeverity: rule.ruleSeverity || null
                        })
                    }
                });
            }
        });

        var requestObject = {
            projectName: this.props.project.projectName,
            projectKey: this.props.project.projectKey,
            repoGroupName: this.props.project.repoGroupName,
            repoName: this.props.project.repoName,
            languageName: languageName,
            rules: rulesList
        }
        return {
            ruleConfigRequest: requestObject
        }
    }

    getRulesConfiguration(id) {
        if (id) {
            return this.getRulesByProject(this.props.ruleData)
        }
        return {
            enabledByDefault: this.props.data.enabled,
            disabledByDefault: this.props.data.disabled,
            updateExistingProjects: this.state.configuration === 'all',
            projectIds: this.state.configuration === 'byProject' ? this.state.selectedProjectList : [],
        }
    }

    render() {
        try {
            return (
                <div>
                    <Mutation mutation={this.props.projectId ? UPDATE_RULESET_PROJECT : UPDATE_RULE} onCompleted={() => { this.handleClose(true) }}>
                        {(updateGlobalRules, { loading, error, data }) => {
                            if (error && error.message && error.message.split(":")[2] && !JSON.stringify(error.message.split(":")[2]).includes("If you want to un-select all the rules, Please contact the administrator") && data && !data.updateProjectRules) {
                                return null
                            }
                            return (
                                <Dialog
                                    open={this.props.showModal}
                                    onClose={this.handleClose}
                                    fullWidth={true}
                                    maxWidth={!this.props.projectId ? "md" : "sm"}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description">
                                    <DialogTitle>
                                        Confirmation
                                </DialogTitle>
                                    <Divider />
                                    <DialogContent>
                                        {!this.props.projectId
                                            ? <div className="row">
                                                <div className="col-md-12">
                                                    <DialogContentText>
                                                    </DialogContentText>
                                                    <div className="pt-3 pl-3">
                                                        <RadioGroup className="pl-4" aria-label="Configuration" name="Configuration" value={this.state.configuration} onChange={this.handleChange}>
                                                            <FormControlLabel value="new" control={<Radio />} label="Apply the changes only to new project configurations" />
                                                            <FormControlLabel value="all" control={<Radio />} label="Apply the changes to all existing projects" />
                                                            <FormControlLabel value="byProject" control={<Radio />} label="Apply the changes only to selected projects" />
                                                        </RadioGroup>
                                                        {this.state.configuration === 'byProject'
                                                            ?
                                                            <div className="project pt-2">
                                                                <ProjectList getList={this.getList} languageName={this.props.ruleData && this.props.ruleData[0] && this.props.ruleData[0].rules && this.props.ruleData[0].rules[0] && this.props.ruleData[0].rules[0].languageName} />
                                                            </div>
                                                            : null
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="col-md-12">
                                                <p>Are you sure that you want to save the changes?</p>
                                            </div>
                                        }
                                    </DialogContent>
                                    <Divider />
                                    <DialogActions>
                                        <Button onClick={() => {
                                            updateGlobalRules({ variables: { ...this.getRulesConfiguration(this.props.projectId) } });
                                            this.setState({ showModal: false })
                                        }} variant="contained" color="primary" autoFocus>
                                            Save
                                    </Button>
                                        <Button onClick={() => { this.handleClose(false) }} color="secondary" autoFocus>
                                            Cancel
                                    </Button>
                                        {error && error.message && error.message.split(":")[2] && JSON.stringify(error.message.split(":")[2]).includes("If you want to un-select all the rules, Please contact the administrator") &&
                                            <Snackbars status="error" message={JSON.stringify(error.message.split(":")[2]).replaceAll("\"", "")} showSnackbar={true} closeSnacker={true} />}
                                    </DialogActions>
                                </Dialog>
                            )
                        }}
                    </Mutation>
                </div>
            );
        } catch {
            return ""
        }

    }
}

export default RulesConfiguration;