import React from 'react';

import Header from './../common/Header';
import ProjectTable from './ProjectTable';
import Quality from './../common/Quality';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { LocalToUTCTime } from '../../../../core/service/timezone';

const USERS_SUMMARY = gql`
    query userSummary($sortBy: String, $direction: Direction, $filter: String!, $startDate: Long, $endDate: Long, $category: String!) {
        users: userSummary(sortBy: $sortBy,  direction: $direction, filter: $filter, startDate: $startDate, endDate: $endDate, category: $category) {
            _id
            data {
                id
                developer {
                    id
                    username
                    name
                    emailId
                }
                numOfReviews
                repositories
                numOfLinesAdded
                numOfUnresolvedComments
                unresolvedReviewRequestCount
                unresolvedReviewRequest
                numOfReviewAttempts
                numOfRepositories
                qualityPercentage
                deleted
                languages {
                    language
                    percentage
                }
            }
        }
    }`;

class Chart extends React.Component {

    constructor(props) {
        super(props);
        this.getQualityValue = this.getQualityValue.bind(this);
        this.state = {
            category: 'PULL REQUEST'
        }
    }

    getQualityValue(data, users) {
        var numOfLinesAdded = 0;
        var qualityPercentage = 0;
        var numOfUsers = users.length;
        var numOfReviews = 0;

        data.forEach(elm => {
            qualityPercentage += +elm.qualityPercentage;
            numOfLinesAdded += +elm.numOfLinesAdded;
            numOfReviews += +elm.numOfReviews;
        })
        qualityPercentage = qualityPercentage / data.length;
        return {
            numOfUsers,
            qualityPercentage,
            numOfLinesAdded,
            numOfReviews,
            project: data
        }
    }

    render() {
        return (
            <Query fetchPolicy="no-cache" query={USERS_SUMMARY} variables={{ 
                sortBy: 'numOfLinesAdded',
                direction: 'DESC',
                filter: this.props.filter,
                startDate:  this.props.dateFilter.startDate ? LocalToUTCTime(this.props.dateFilter.startDate, 'x') : null,
                endDate: this.props.dateFilter.endDate ? LocalToUTCTime(this.props.dateFilter.endDate, 'x') : null, category: this.props.developerFilter
                }}>
                {({ loading, error, data }) => {
                    if (loading || error) {
                        return null;
                    }
                    const {users} = data;
                    return (
                        <div className="container p-5" style={{ margin: '0 0 0 -30px' }}>
                            <Header name={'Overall'} report={this.props.dateFilter.startDate 
                                ? this.props.dateFilter.startDate + " - " + this.props.dateFilter.endDate
                                : this.props.filter} isQuater={!!this.props.dateFilter.startDate} />
                            <Quality type={"Projects"} data={this.getQualityValue(this.props.data, users.data)} />
                            <div className="row m-0">
                                <ProjectTable data={this.props.data} users={users.data} />
                            </div>
                        </div>
                    )
                }}
            </Query>
        )
    }
}

export default Chart;