import React, { Component } from 'react';
import moment from 'moment';
import { Accordion, Card } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import CommentModal from './modals/comments-modal';
import Tooltip from '@material-ui/core/Tooltip';
import StashModal from './stashView/Stash-modal';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import gql from 'graphql-tag';
import '../reviews/modals/modal.css';
import './reviews.css';
import { Query } from 'react-apollo';
import Error404 from '../../core/error/404';
import CircularProgress from '@material-ui/core/CircularProgress';
import Auth from './../../core/service/auth';
let user = JSON.parse(localStorage.getItem('users'));



const GET_COMMENTS_BY_FILEPATH = gql`
query reviewResponsesById($id: String, $isPreviousResponse: Boolean, $previousResponseCount: Int, $responseId : Long,  $isApprovel: Boolean, $manualReviewStatus: String) {
    reviewResponsesById(id: $id,  isPreviousResponse: $isPreviousResponse, previousResponseCount: $previousResponseCount, responseId: $responseId, showOnlyInReviewComments: $isApprovel, manualReviewStatus: $manualReviewStatus) {
        reviewResponseId
        reviewRequestId
        analyzedFiles
        numOfAnalyzedFiles
        numOfLinesAdded
        requestedSource
        hasComments
        numOfComments
        numberOfAttempts
        nextResponseId       
        languagePercentage {
          language
          percentage
        }
        commentsByFilePath {
          filePath
          isExcluded
          isUnSupported
          manualComment
          repoManualComment
          numOfManualComments
          numOfRepoManualComments
          isFirstAttempt
          comments {
            type
            commentId
            manualComment
            repoManualComment
            user {
              id
              name
              username
            }
            createdOn
            checkName
            ruleOptions
            description
            categories
            location {
              path
              lines {
                begin
                end
              }
            }
            status
            reviewFailed
            severity
            actions {
              message
              user {
                  id
                  name
                  username
              }
              type
              createdOn
            }
            remediationPoints
          }
        }
        createdOn
      }
}
`;

class ReviewResponse extends Component {
    constructor(props) {
        super(props)
        this.state = {
            previousResponseFlag: false,
            previousResponseCount: 1,
            defaultColDef: {
                sortable: true,
                resizable: true,
            },
            openStashView: false,
            columnDefs: [{
                headerName: "File Name",
                field: "filePath",
                cellRenderer: "agGroupCellRenderer",
                hide: true,
                suppressSizeToFit: false,
            }, {
                headerName: "Check Name", field: "checkName", suppressSizeToFit: false, tooltipField: "checkName", cellRenderer: function (params) {
                    return params.data.checkName || 'Manual Comment';
                },
            }, {
                headerName: "Description", field: "description", tooltipField: "description", suppressSizeToFit: false
            }, {
                headerName: "Location", field: "location", suppressSizeToFit: false, cellRenderer: function (params) {
                    var text = params.data.location['path'] +
                        ' (Start: ' + params.data.location['lines']['begin'] + ' - End: ' + params.data.location['lines']['end'] + ')';
                    return "<span title=" + text + ">" + text + "</span>";
                }
            }, {
                headerName: "Line Numbers", field: "start", suppressSizeToFit: false, cellRenderer: function (params) {
                    return params.data.location['lines']['begin'] + ' - ' + params.data.location['lines']['end'];
                },
            }, {
                headerName: "Severity", field: "severity", suppressSizeToFit: false
            }, {
                headerName: "Type", field: "type", suppressSizeToFit: false
            }, {
                headerName: "Status", field: "status", suppressSizeToFit: false
            }],
            getNodeChildDetails: function getNodeChildDetails(rowItem) {
                let linterError = false;
                if (rowItem.comments) {
                    if (rowItem.comments.length === 1) {
                        rowItem.comments.map(data => {
                            linterError = data.reviewFailed;
                        }
                        );
                    }

                    if (linterError) {
                        return {
                            group: true,
                            expanded: false,
                            children: linterError ? [] : rowItem.comments,
                            key: rowItem.filePath + " " + "<Tooltip title='Review Failed' placement='top'><button class='btn btn-link "
                                + " '><i class='error-icon align-middle material-icons text-danger'>info</i></button></Tooltip>",
                        };
                    }
                    else {
                        return {
                            group: true,
                            expanded: false,
                            children: rowItem.comments,
                            key: rowItem.filePath,
                        };
                    }
                } else {
                    return null;
                }
            },
            commentOpen: false,
            commentData: null,
        }
        this.onGridReady = this.onGridReady.bind(this);
        this.handelDialog = this.handelDialog.bind(this);
        this.toggleStashView = this.toggleStashView.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.handlePreviousReview = this.handlePreviousReview.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handelDialog(grid) {
        if (grid && grid.data && grid.data.location && grid.data.checkName) {
            this.setState({
                commentData: grid.data,
                commentOpen: true
            });
        }
        if (grid && grid.data && !grid.data.location && grid.data.isFirstAttempt) {
            this.props.treeView(grid.data.filePath);
            return;
        }
        if (!grid) {
            this.setState({
                commentOpen: false
            })
        }
    }

    handleClose() {
        this.setState({
            commentData: null
        })
    }

    refreshPage() {
        this.props.refreshPage();
    }

    toggleStashView(grid) {
        if (grid) {
            grid.data.diffUrl = this.props.diffUrl;
            this.setState({
                stashComments: grid.data
            });
        }
        this.setState({
            openStashView: !this.state.openStashView
        })
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
        if ((window.innerWidth > 600)) {
            params.api.sizeColumnsToFit();
        }
    }

    handlePreviousReview() {
        this.setState({
            previousResponseFlag: true,
            previousResponseCount: this.props.responses.length + 1,
        })
    }

    render() {
        const numRows = this.props.responses && this.props.responses[0] ? this.props.responses[0].numberOfAttempts : 0;
        let nextResponseId = this.props.responses && this.props.responses[this.props.responses.length - 1] ? this.props.responses[this.props.responses.length - 1].nextResponseId : null;
        if (this.state.previousResponseFlag) {
            return (
                <Query query={GET_COMMENTS_BY_FILEPATH} fetchPolicy="no-cache" variables={{ id: this.props.reviewId, isPreviousResponse: this.state.previousResponseFlag, previousResponseCount: this.state.previousResponseCount, responseId: nextResponseId ? nextResponseId : null, isApprovel: this.props.isApprovel || false, manualReviewStatus: this.props.manualReviewStatus }}>
                    {({ loading, error, data, refetch }) => {
                        if (loading) return <div className="loading"><CircularProgress size={50} /></div>;
                        if (error) return <Error404 />
                        if (data && (this.props.responses.length !== 1 || this.state.previousResponseFlag)) {
                            this.props.responses.push(data.reviewResponsesById[data.reviewResponsesById.length - 1]);
                            this.setState({
                                previousResponseFlag: false,
                            })
                        }
                        return <Accordion defaultActiveKey={1} className="mt-3 mb-5">
                            {this.props.responses[0] !== undefined ? this.props.responses.map((response, index) => {
                                return (

                                    <Card className="border-0" key={index}>
                                        {!response.hasComments && <div className="col-md-12 text-center text-primary">
                                            <i className="material-icons">thumb_up</i>
                                            <div>
                                                <h4 className="text-dark">Code looks good. Enjoy the day!</h4>
                                            </div>
                                        </div>}
                                        <Card.Header className="bg-white border-0">
                                            <Accordion.Toggle as={Card.Header} variant="link" eventKey={index + 1}>
                                                <div className="w-100">
                                                    <div className="row pt-2">
                                                        <div className="col-sm-3 pb-3 review-header">
                                                            <b><i className="material-icons align-middle">expand_more</i><label>Review #</label>{numRows - index}</b>
                                                        </div>

                                                        <Tooltip title="Total Comments" placement="top">
                                                            <div className="col-sm-2 col-4 text-center text-mat-secondary">
                                                                <b><i className="material-icons align-middle">comment</i></b> {response.numOfComments}
                                                            </div>
                                                        </Tooltip>
                                                        <Tooltip title="Total Number of Lines Added" placement="top">
                                                            <div className="col-sm-2 col-4 text-center text-mat-secondary">
                                                                <b><i className="material-icons align-middle">code</i></b> {response.numOfLinesAdded}
                                                            </div>
                                                        </Tooltip>
                                                        <Tooltip title="Number of Files Analyzed" placement="top">
                                                            <div className="col-sm-2 col-4 text-center text-mat-secondary">
                                                                <b><i className="material-icons align-middle">assessment</i></b> {response.numOfAnalyzedFiles}
                                                            </div>
                                                        </Tooltip>
                                                        {Auth.hasRole(user, ['ROLE_ADMIN']) &&
                                                            <Tooltip title="Requested Source" placement="top">
                                                                <div className="col-sm-2 col-4 text-center text-mat-secondary">
                                                                    <b><i className="material-icons align-middle">call_received</i></b> {response.requestedSource ? response.requestedSource : "Web Console"}
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                        <div className="col-md-3 text-right date-align">
                                                            <span className="text-right"><i className="material-icons align-middle">date_range</i> {moment(response.createdOn).format('YYYY-MMM-DD hh:mm a')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        {response.hasComments
                                            ? <Accordion.Collapse eventKey={index + 1}>
                                                {(response.hasComments) ?
                                                    <div
                                                        className="ag-theme-material p-3 reviewsby-filegroup"
                                                        style={{
                                                            height: '350px', maxHeight: '400px', width: '100%'
                                                        }}
                                                    >
                                                        <AgGridReact
                                                            columnDefs={this.state.columnDefs}
                                                            rowData={response.commentsByFilePath}
                                                            pagination={false}
                                                            onRowClicked={this.handelDialog}
                                                            animateRows={true}
                                                            groupUseEntireRow={true}
                                                            groupRowInnerRenderer={(params) => {
                                                                if (params.data.isUnSupported) {
                                                                    return `${params.data.filePath}<Tooltip title='Unsupported file for review' placement='top'><button class='btn btn-link'><i class='error-icon align-middle material-icons font-orange'>report_problem</i></button></Tooltip>`;
                                                                }
                                                                if (params.data.isExcluded) {
                                                                    return `${params.data.filePath}<Tooltip title='This file is excluded from review' placement='top'><button class='btn btn-link'><i class='error-icon align-middle material-icons font-orange'>announcement</i></button></Tooltip>`;
                                                                }
                                                                if (params.data.manualComment && params.data.repoManualComment) {
                                                                    return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments Added">post_add </i><span class="badge badge-success">${params.data.numOfManualComments}</span></b>
                                                                            <span class='align-middle'></span><b><i class="material-icons text-primary align-middle" title="Manual Comments From Repository">reviews </i><span class="badge badge-success">${params.data.numOfRepoManualComments}</span></b>`;
                                                                } else {
                                                                    if (params.data.manualComment) {
                                                                        return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments Added">post_add </i><span class="badge badge-success">${params.data.numOfManualComments}</span></b>`;
                                                                    }
                                                                    if (params.data.repoManualComment) {
                                                                        return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments From Repository">reviews </i><span class="badge badge-success">${params.data.numOfRepoManualComments}</span></b>`;
                                                                    }
                                                                }

                                                                return params.value;
                                                            }}
                                                            getNodeChildDetails={this.state.getNodeChildDetails}
                                                            enableBrowserTooltips={true}
                                                            defaultColDef={this.state.defaultColDef}
                                                            onGridReady={this.onGridReady}>
                                                        </AgGridReact>
                                                    </div>
                                                    : ''}
                                            </Accordion.Collapse>
                                            : <div
                                                className="ag-theme-material p-3 reviewsby-filegroup"
                                                style={{
                                                    height: '350px', maxHeight: '400px', width: '100%'
                                                }}>
                                                <AgGridReact
                                                    columnDefs={this.state.columnDefs}
                                                    rowData={response.commentsByFilePath}
                                                    pagination={false}
                                                    onRowClicked={this.handelDialog}
                                                    animateRows={true}
                                                    groupUseEntireRow={true}
                                                    groupRowInnerRenderer={(params) => {
                                                        if (params.data.isUnSupported) {
                                                            return `${params.data.filePath}<Tooltip title='Unsupported file for review' placement='top'><button class='btn btn-link'><i class='error-icon align-middle material-icons font-orange'>report_problem</i></button></Tooltip>`;
                                                        }
                                                        if (params.data.isExcluded) {
                                                            return `${params.data.filePath}<Tooltip title='This file is excluded from review' placement='top'><button class='btn btn-link'><i class='error-icon align-middle material-icons font-orange'>announcement</i></button></Tooltip>`;
                                                        }
                                                        if (params.data.manualComment && params.data.repoManualComment) {
                                                            return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments Added">post_add </i><span class="badge badge-success">${params.data.numOfManualComments}</span></b>
                                                                    <span class='align-middle'></span><b><i class="material-icons text-primary align-middle" title="Manual Comments From Repository">reviews </i><span class="badge badge-success">${params.data.numOfRepoManualComments}</span></b>`;
                                                        } else {
                                                            if (params.data.manualComment) {
                                                                return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments Added">post_add </i><span class="badge badge-success">${params.data.numOfManualComments}</span></b>`;
                                                            }
                                                            if (params.data.repoManualComment) {
                                                                return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments From Repository">reviews </i><span class="badge badge-success">${params.data.numOfRepoManualComments}</span></b>`;
                                                            }
                                                        }
                                                        return params.value;
                                                    }}
                                                    getNodeChildDetails={this.state.getNodeChildDetails}
                                                    enableBrowserTooltips={true}
                                                    defaultColDef={this.state.defaultColDef}
                                                    onGridReady={this.onGridReady}>
                                                </AgGridReact>
                                            </div>
                                        }

                                    </Card>
                                )
                            }) : null}
                            <CommentModal reviewId={this.props.reviewId} showModal={this.state.commentOpen} onUpdate={this.handelDialog} onClose={this.handleClose} state={this.props.state} onRefresh={this.refreshPage} commentData={this.state.commentData} />
                            {numRows > 0 && numRows !== this.state.previousResponseCount && <Box textAlign='center' >
                                <Button variant="contained" color="primary" onClick={this.handlePreviousReview} >
                                    View Previous Reviews
                                </Button>
                            </Box>}
                        </Accordion>
                    }}
                </Query>


            )
        }

        else {
            return <Accordion defaultActiveKey={1} className="mt-3 mb-5">
                {this.props.responses[0] !== undefined ? this.props.responses.map((response, index) => {
                    return (

                        <Card className="border-0" key={index}>
                            {!response.hasComments && <div className="col-md-12 text-center text-primary">
                                <i className="material-icons">thumb_up</i>
                                <div>
                                    <h4 className="text-dark">Code looks good. Enjoy the day!</h4>
                                </div>
                            </div>}
                            <Card.Header className="bg-white border-0">
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey={index + 1}>
                                    <div className="w-100">
                                        <div className="row pt-2">
                                            <div className="col-sm-3 pb-3 review-header">
                                                <b><i className="material-icons align-middle">expand_more</i><label>Review #</label>{numRows - index > 0 ? numRows - index : 1}</b>
                                            </div>

                                            <Tooltip title="Total Comments" placement="top">
                                                <div className="col-sm-2 col-4 text-center text-mat-secondary">
                                                    <b><i className="material-icons align-middle">comment</i></b> {response.numOfComments}
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Total Number of Lines Added" placement="top">
                                                <div className="col-sm-2 col-4 text-center text-mat-secondary">
                                                    <b><i className="material-icons align-middle">code</i></b> {response.numOfLinesAdded}
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Number of Files Analyzed" placement="top">
                                                <div className="col-sm-2 col-4 text-center text-mat-secondary">
                                                    <b><i className="material-icons align-middle">assessment</i></b> {response.numOfAnalyzedFiles}
                                                </div>
                                            </Tooltip>
                                            {Auth.hasRole(user, ['ROLE_ADMIN']) &&
                                                <Tooltip title="Requested Source" placement="top">
                                                    <div className="col-sm-2 col-4 text-center text-mat-secondary">
                                                        <b><i className="material-icons align-middle">call_received</i></b> {response.requestedSource ? response.requestedSource : "Web Console"}
                                                    </div>
                                                </Tooltip>
                                            }
                                            <div className="col-md-2 text-right date-align">
                                                <span className="text-right"><i className="material-icons align-middle">date_range</i> {moment(response.createdOn).format('YYYY-MMM-DD hh:mm a')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>

                            {response.hasComments
                                ? <Accordion.Collapse eventKey={index + 1}>
                                    {(response.hasComments) ?
                                        <div
                                            className="ag-theme-material p-3 reviewsby-filegroup"
                                            style={{
                                                height: '350px', maxHeight: '400px', width: '100%'
                                            }}
                                        >
                                            <AgGridReact
                                                columnDefs={this.state.columnDefs}
                                                rowData={response.commentsByFilePath}
                                                pagination={false}
                                                onRowClicked={this.handelDialog}
                                                animateRows={true}
                                                groupUseEntireRow={true}
                                                groupRowInnerRenderer={(params) => {
                                                    if (params.data.isUnSupported) {
                                                        return `${params.data.filePath}<Tooltip title='Unsupported file for review' placement='top'><button class='btn btn-link'><i class='error-icon align-middle material-icons font-orange'>report_problem</i></button></Tooltip>`;
                                                    }
                                                    if (params.data.isExcluded) {
                                                        return `${params.data.filePath}<Tooltip title='This file is excluded from review' placement='top'><button class='btn btn-link'><i class='error-icon align-middle material-icons font-orange'>announcement</i></button></Tooltip>`;
                                                    }
                                                    if (params.data.manualComment && params.data.repoManualComment) {
                                                        return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments Added">post_add </i><span class="badge badge-success">${params.data.numOfManualComments}</span></b>
                                                                <span class='align-middle'></span><b><i class="material-icons text-primary align-middle" title="Manual Comments From Repository">reviews </i><span class="badge badge-success">${params.data.numOfRepoManualComments}</span></b>`;
                                                    } else {
                                                        if (params.data.manualComment) {
                                                            return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments Added">post_add </i><span class="badge badge-success">${params.data.numOfManualComments}</span></b>`;
                                                        }
                                                        if (params.data.repoManualComment) {
                                                            return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments From Repository">reviews </i><span class="badge badge-success">${params.data.numOfRepoManualComments}</span></b>`;
                                                        }
                                                    }
                                                    return params.value;
                                                }}
                                                getNodeChildDetails={this.state.getNodeChildDetails}
                                                enableBrowserTooltips={true}
                                                defaultColDef={this.state.defaultColDef}
                                                onGridReady={this.onGridReady}>
                                            </AgGridReact>
                                        </div>
                                        : ''}
                                </Accordion.Collapse>
                                : <div
                                    className="ag-theme-material p-3 reviewsby-filegroup"
                                    style={{
                                        height: '350px', maxHeight: '400px', width: '100%'
                                    }}>
                                    <AgGridReact
                                        columnDefs={this.state.columnDefs}
                                        rowData={response.commentsByFilePath}
                                        pagination={false}
                                        onRowClicked={this.handelDialog}
                                        animateRows={true}
                                        groupUseEntireRow={true}
                                        groupRowInnerRenderer={(params) => {
                                            if (params.data.isUnSupported) {
                                                return `${params.data.filePath}<Tooltip title='Unsupported file for review' placement='top'><button class='btn btn-link'><i class='error-icon align-middle material-icons font-orange'>report_problem</i></button></Tooltip>`;
                                            }
                                            if (params.data.isExcluded) {
                                                return `${params.data.filePath}<Tooltip title='This file is excluded from review' placement='top'><button class='btn btn-link'><i class='error-icon align-middle material-icons font-orange'>announcement</i></button></Tooltip>`;
                                            }
                                            if (params.data.manualComment && params.data.repoManualComment) {
                                                return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments Added">post_add </i><span class="badge badge-success">${params.data.numOfManualComments}</span></b>
                                                        <span class='align-middle'></span><b><i class="material-icons text-primary align-middle" title="Manual Comments From Repository">reviews </i><span class="badge badge-success">${params.data.numOfRepoManualComments}</span></b>`;
                                            } else {
                                                if (params.data.manualComment) {
                                                    return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments Added">post_add </i><span class="badge badge-success">${params.data.numOfManualComments}</span></b>`;
                                                }
                                                if (params.data.repoManualComment) {
                                                    return `<span class='align-middle'>${params.value}</span><b><i class="material-icons text-primary align-middle" title="Manual Comments From Repository">reviews </i><span class="badge badge-success">${params.data.numOfRepoManualComments}</span></b>`;
                                                }
                                            }
                                            return params.value;
                                        }}
                                        getNodeChildDetails={this.state.getNodeChildDetails}
                                        enableBrowserTooltips={true}
                                        defaultColDef={this.state.defaultColDef}
                                        onGridReady={this.onGridReady}>
                                    </AgGridReact>
                                </div>
                            }

                        </Card>
                    )
                }) : null}
                <CommentModal reviewId={this.props.reviewId} showModal={this.state.commentOpen} onUpdate={this.handelDialog} onClose={this.handleClose} state={this.props.state} onRefresh={this.refreshPage} commentData={this.state.commentData} />
                {numRows > 0 && numRows !== this.state.previousResponseCount && <Box textAlign='center' >
                    <Button variant="contained" color="primary" onClick={this.handlePreviousReview} >
                        View Previous Reviews
                    </Button>
                </Box>}
            </Accordion>
        }

    }
}

export default ReviewResponse;
