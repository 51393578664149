import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import MaterialTable from 'material-table';
import Tooltip from '@material-ui/core/Tooltip';

function ListView(props) {
    const columnDefs = [{
        title: "Developer Name", field: "developer.name"
    }, {
        title: "Lines of Code", field: "numOfLinesAdded"
    }, {
        title: "# Requests", field: "numOfReviews"
    }, {
        title: "# Reviews", field: "numOfReviewAttempts"
    }, {
        title: "# Requests(unresolved)", field: "unresolvedReviewRequestCount"
    }, {
        title: "# Comments(unresolved)", field: "numOfUnresolvedComments"
    }, {
        title: "Code Quality", field: "codeQuality", render: function (params) {
            return (<Tooltip title={params.qualityPercentage + '%'} placement="top"><div className={params.qualityPercentage > 50
                ? params.qualityPercentage > 75
                    ? "heigh-quality"
                    : "medium-quality"
                : "low-quality"
            }><LinearProgress variant="determinate" value={params.qualityPercentage} />{params.qualityPercentage}%</div></Tooltip>);
        }
    }];

    return (
        <MaterialTable
            title="Reviews"
            columns={columnDefs}
            data={props.users}
            options={{
                filtering: false,
                toolbar: false,
                headerStyle: {
                    padding: '5px',
                    fontSize: '12px',
                    fontWeight: "bold",
                    color: 'black',
                    border: 'none',
                },
                pageSize: 7,
                actionsColumnIndex: -1,
                pageSizeOptions: [7, 10, 20, 30],
            }}
        />
    )
}

export default ListView;