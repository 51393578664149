export default {
    "parserOptions": {
        "ecmaVersion": 5,
        "sourceType": "script",
        "ecmaFeatures": {}
    },
    "rules": {
        "accessor-pairs": 2,
        "array-bracket-newline": 2,
        "array-bracket-spacing": 2,
        "array-callback-return": 2,
        "array-element-newline": 2,
        "arrow-body-style": 2,
        "arrow-parens": 2,
        "arrow-spacing": 2,
        "block-scoped-var": 2,
        "block-spacing": 2,
        "brace-style": 2,
        "callback-return": 2,
        "camelcase": 2,
        "capitalized-comments": 2,
        "class-methods-use-this": 2,
        "comma-dangle": 2,
        "comma-spacing": 2,
        "comma-style": 2,
        "complexity": 2,
        "computed-property-spacing": 2,
        "consistent-return": 2,
        "consistent-this": 2,
        "constructor-super": 2,
        "curly": 2,
        "default-case": 2,
        "default-param-last": 2,
        "dot-location": 2,
        "dot-notation": 2,
        "eol-last": 1,
        "eqeqeq": 2,
        "for-direction": 2,
        "func-call-spacing": 2,
        "func-name-matching": 2,
        "func-names": 0,
        "func-style": 0,
        "function-call-argument-newline": 1,
        "function-paren-newline": 0,
        "generator-star-spacing": 2,
        "getter-return": 2,
        "global-require": 2,
        "guard-for-in": 2,
        "handle-callback-err": 2,
        "id-blacklist": 2,
        "id-length": 2,
        "id-match": 2,
        "implicit-arrow-linebreak": 2,
        "indent": ["error", "tab"],
        "init-declarations": 2,
        "jsx-quotes": 2,
        "key-spacing": 2,
        "keyword-spacing": 2,
        "line-comment-position": 1,
        "linebreak-style": 2,
        "lines-around-comment": 2,
        "lines-between-class-members": 2,
        "max-classes-per-file": 2,
        "max-depth": ["error", { "max": 4 }],
        "max-len": [0, { "code": 120, "tabWidth": 4 }],
        "max-lines": ["error", {"max": 300}],
        "max-lines-per-function": ["off", { "max": 50 }],
        "max-nested-callbacks": 2,
        "max-params": ["error", { "max": 5 }],
        "max-statements": ["error", { "max": 10 }],
        "max-statements-per-line": ["error", { "max": 1 }],
        "multiline-comment-style": 2,
        "multiline-ternary": 1,
        "new-cap": 2,
        "new-parens": 2,
        "newline-per-chained-call": 2,
        "no-alert": 2,
        "no-array-constructor": 2,
        "no-async-promise-executor": 2,
        "no-await-in-loop": 2,
        "no-bitwise": 2,
        "no-buffer-constructor": 2,
        "no-caller": 2,
        "no-case-declarations": 2,
        "no-class-assign": 2,
        "no-compare-neg-zero": 2,
        "no-cond-assign": 2,
        "no-confusing-arrow": 2,
        "no-console": 2,
        "no-const-assign": 2,
        "no-constant-condition": 2,
        "no-continue": 2,
        "no-control-regex": 2,
        "no-debugger": 2,
        "no-delete-var": 2,
        "no-div-regex": 2,
        "no-dupe-args": 2,
        "no-dupe-class-members": 2,
        "no-dupe-keys": 2,
        "no-duplicate-case": 2,
        "no-duplicate-imports": 2,
        "no-else-return": 2,
        "no-empty": 2,
        "no-empty-character-class": 2,
        "no-empty-function": 2,
        "no-empty-pattern": 2,
        "no-eq-null": 2,
        "no-eval": 2,
        "no-ex-assign": 2,
        "no-extend-native": 2,
        "no-extra-bind": 2,
        "no-extra-boolean-cast": 2,
        "no-extra-label": 2,
        "no-extra-parens": 2,
        "no-extra-semi": 2,
        "no-fallthrough": 2,
        "no-floating-decimal": 2,
        "no-func-assign": 2,
        "no-global-assign": 2,
        "no-implicit-coercion": 2,
        "no-implicit-globals": 2,
        "no-implied-eval": 2,
        "no-import-assign": 2,
        "no-inline-comments": 1,
        "no-inner-declarations": 2,
        "no-invalid-regexp": 2,
        "no-invalid-this": 2,
        "no-irregular-whitespace": 2,
        "no-iterator": 2,
        "no-label-var": 2,
        "no-labels": 2,
        "no-lone-blocks": 2,
        "no-lonely-if": 2,
        "no-loop-func": 2,
        "no-magic-numbers": 2,
        "no-misleading-character-class": 2,
        "no-mixed-operators": 2,
        "no-mixed-requires": 2,
        "no-mixed-spaces-and-tabs": 2,
        "no-multi-assign": 2,
        "no-multi-spaces": 2,
        "no-multi-str": 2,
        "no-multiple-empty-lines": 2,
        "no-negated-condition": 2,
        "no-nested-ternary": 2,
        "no-new": 2,
        "no-new-func": 2,
        "no-new-object": 2,
        "no-new-require": 2,
        "no-new-symbol": 2,
        "no-new-wrappers": 2,
        "no-obj-calls": 2,
        "no-octal": 2,
        "no-octal-escape": 2,
        "no-param-reassign": 2,
        "no-path-concat": 2,
        "no-plusplus": 2,
        "no-process-env": 2,
        "no-process-exit": 2,
        "no-proto": 2,
        "no-prototype-builtins": 2,
        "no-redeclare": 2,
        "no-regex-spaces": 2,
        "no-restricted-globals": 2,
        "no-restricted-imports": 2,
        "no-restricted-modules": 2,
        "no-restricted-properties": 2,
        "no-restricted-syntax": 2,
        "no-return-assign": 2,
        "no-return-await": 2,
        "no-script-url": 2,
        "no-self-assign": 2,
        "no-self-compare": 2,
        "no-sequences": 2,
        "no-shadow": 2,
        "no-shadow-restricted-names": 2,
        "no-sparse-arrays": 2,
        "no-sync": 2,
        "no-tabs": 0,
        "no-template-curly-in-string": 2,
        "no-ternary": 0,
        "no-this-before-super": 2,
        "no-throw-literal": 2,
        "no-trailing-spaces": 2,
        "no-undef": 0,
        "no-undef-init": 2,
        "no-undefined": 2,
        "no-underscore-dangle": 2,
        "no-unexpected-multiline": 2,
        "no-unmodified-loop-condition": 2,
        "no-unneeded-ternary": 2,
        "no-unreachable": 2,
        "no-unsafe-finally": 2,
        "no-unsafe-negation": 2,
        "no-unused-expressions": 2,
        "no-unused-labels": 2,
        "no-unused-vars": 2,
        "no-use-before-define": 2,
        "no-useless-call": 2,
        "no-useless-catch": 2,
        "no-useless-computed-key": 2,
        "no-useless-concat": 2,
        "no-useless-constructor": 2,
        "no-useless-escape": 2,
        "no-useless-rename": 2,
        "no-useless-return": 2,
        "no-var": 0,
        "no-void": 2,
        "no-warning-comments": 2,
        "no-whitespace-before-property": 2,
        "no-with": 2,
        "nonblock-statement-body-position": 2,
        "object-curly-newline": 2,
        "object-curly-spacing": 2,
        "object-property-newline": 2,
        "object-shorthand": 2,
        "one-var": 2,
        "one-var-declaration-per-line": 2,
        "operator-assignment": 2,
        "operator-linebreak": 2,
        "padded-blocks": 0,
        "padding-line-between-statements": 2,
        "prefer-arrow-callback": 0,
        "prefer-const": 2,
        "prefer-destructuring": 2,
        "prefer-named-capture-group": 2,
        "prefer-numeric-literals": 2,
        "prefer-object-spread": 2,
        "prefer-promise-reject-errors": 2,
        "prefer-reflect": 2,
        "prefer-regex-literals": 2,
        "prefer-rest-params": 2,
        "prefer-spread": 2,
        "prefer-template": 2,
        "quote-props": 0,
        "quotes": [2, "single"],
        "radix": 0,
        "require-atomic-updates": 2,
        "require-await": 0,
        "require-jsdoc": 0,
        "require-unicode-regexp": 2,
        "require-yield": 2,
        "rest-spread-spacing": 2,
        "semi": 2,
        "semi-spacing": 2,
        "semi-style": 2,
        "sort-imports": 1,
        "sort-keys": 1,
        "sort-vars": 1,
        "space-before-blocks": 2,
        "space-before-function-paren": 2,
        "space-in-parens": 2,
        "space-infix-ops": 2,
        "space-unary-ops": 2,
        "spaced-comment": 1,
        "strict": 2,
        "switch-colon-spacing": 2,
        "symbol-description": 2,
        "template-curly-spacing": 2,
        "template-tag-spacing": 2,
        "unicode-bom": 2,
        "use-isnan": 2,
        "valid-jsdoc": 2,
        "valid-typeof": 2,
        "vars-on-top": 2,
        "wrap-iife": 0,
        "wrap-regex": 2,
        "yield-star-spacing": 2,
        "yoda": 2
    },
    "env": {
        "es6": false
    }
}