import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import DefaultValue from './../../../components/ruleset-modal/component/DefaultValue'
import AltereValue from './../../../components/ruleset-modal/component/CurrentRuleConfigValue'

const GET_RULE_BY_RULE_NAME = gql`
query ruleByRuleNameAndLanguageExtension($ruleName: String!, $languageExtension: String!) {
    rule: ruleByRuleNameAndLanguageExtension(ruleName: $ruleName, languageExtension: $languageExtension) {
        ruleId
        ruleName
        description
        languageName
        categoryName
        message
        priority
        since
        example
        configurations {
            type
            properties
            enumValue
            items {
                type
                enumValue
            }
        }
        ruleOptions
        properties {
            property {
                name
                value
                type
                description
                delimiter
                min
                max
            }
        }
    }
}`;

const convertLinks = (input) => {
    var text = input || '';
    const linksFound = text.match(/(?:www|https?)[^\s]+/g);
    const aLink = [];

    if (linksFound != null) {

        for (let i = 0; i < linksFound.length; i++) {
            let replace = linksFound[i];
            if (!(linksFound[i].match(/(http(s?)):\/\//))) { replace = 'http://' + linksFound[i] }
            let linkText = replace.split('/')[2];
            if (linkText.substring(0, 3) === 'www') { linkText = linkText.replace('www.', '') }
            if (linkText.match(/youtu/)) {

                let youtubeID = replace.split('/').slice(-1)[0];
                aLink.push('<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' + youtubeID + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>')
            }
            else if (linkText.match(/vimeo/)) {
                let vimeoID = replace.split('/').slice(-1)[0];
                aLink.push('<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' + vimeoID + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>')
            }
            else {
                aLink.push(' <a class="secondary" href="' + replace.replace('>', '') + '" target="_blank">' + replace.replace('>', '') + '</a>');
            }
            text = text.split(linksFound[i]).map(item => { return aLink[i].includes('iframe') ? item.trim() : item }).join(aLink[i]);
        }
        return { __html: text };

    }
    else {
        return { __html: input };
    }
}


class MoreInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.getLanguageExtension = this.getLanguageExtension.bind(this);
        this.moreInfo = this.moreInfo.bind(this);
    }

    getLanguageExtension(cmt) {
        var path = cmt && cmt.location ? cmt.location.path.split('.') : '';
        var languageName = null
        if (path && path.length) {
            languageName = path[path.length - 1];
        }
        return languageName;
    }

    moreInfo(name) {
        this.setState({
            [name]: !this.state[name],
        });
    }

    getLanguage(data) {
        var addLanguage = {
            html: 'html',
            jsp: 'html',
            javascript: 'javascript',
            typescript: 'typescript'
        }
        return addLanguage[data] || 'java';
    }

    render() {
        if (!this.props.data) {
            return null;
        }
        return (
            <CardContent className="ml-3 pl-5 pr-1 mr-3">
                <Query query={GET_RULE_BY_RULE_NAME} variables={{ ruleName: this.props.data && this.props.data.checkName, languageExtension: this.getLanguageExtension(this.props.data) }}>
                    {({ loading, error, data }) => {
                        if (error) return null;
                        if (loading) return <LinearProgress color="primary" />;
                        return (
                            <div>
                                <div className="row">
                                    <div className="col-md-12 p-0 m-0 pb-2 pr-4">
                                        <Tooltip title="Severity" placement="top" aria-label="Severity">
                                            <Chip className="ml-3 align-middle float-left" color="secondary" label={this.props.data && this.props.data.severity} />
                                        </Tooltip>
                                        {data.rule ?
                                            <Tooltip title="Language Name" placement="top" aria-label="Language Name">
                                                <Chip className="ml-3 align-middle float-right" label={data.rule.languageName} />
                                            </Tooltip>
                                            : null}
                                        {data.rule ?
                                            <Tooltip title="Category Name" placement="top" aria-label="Category Name">
                                                <Chip className="float-right align-middle" label={data.rule.categoryName} />
                                            </Tooltip>
                                            : null}
                                    </div>
                                </div>
                                <div className="row mt-2 actions">
                                    <div className="col-md-5">
                                        <h6 className="mt-4">Rule Name</h6>
                                        <div>{this.props.data && this.props.data.checkName}</div>
                                        <h6 className="mt-4">Message</h6>
                                        <div>{this.props.data ? this.props.data.description : 'No Message Found'}</div>
                                        <h6 className="mt-4">Description</h6>
                                        {data.rule && data.rule.description ? <div dangerouslySetInnerHTML={data.rule && convertLinks(data.rule.description)}></div> : <div>No Description Found</div>}
                                        {data.rule && <span className="secondary pl-3 cursor-pointer float-right" onClick={() => { this.moreInfo(this.props.data.checkName) }}>
                                            {!this.state[this.props.data.checkName]
                                                ? <span><i className="material-icons p-0 align-middle icon">expand_more</i> Show Configuration</span>
                                                : <span><i className="material-icons p-0 align-middle icon">expand_less</i> Hide Configuration</span>
                                            }
                                        </span>}
                                        {<Collapse in={this.state[this.props.data.checkName]} timeout="auto" unmountOnExit>
                                            <DefaultValue isMoreInfo={true} data={data && data.rule ? data.rule.configurations : []} />
                                            <AltereValue isMoreInfo={true} data={
                                                this.props.data && this.props.data.ruleOptions
                                                    ? this.props.data
                                                    : (data && data.rule && data.rule.ruleOptions) ? data.rule : []
                                            } />
                                        </Collapse>}
                                            
                                    </div>
                                    <div className="md-offset-1">
                                            <Divider orientation="vertical" />
                                        </div>
                                        <div className="col-md-6 code-example-block hide-scroll-bar">
                                            <h6 className="bg-white position-sticky">Example</h6>
                                            <SyntaxHighlighter className="border-0" language={this.getLanguage(data.rule && data.rule.languageName)} style={vs}>
                                                {data.rule && data.rule.example && data.rule.example.length && data.rule.example[0].length ? data.rule.example[0] : "Example is not available"}
                                            </SyntaxHighlighter>
                                        </div>
                                    </div>
                                </div>)
                        }}
                </Query>
            </CardContent>
                );
            }
        }
        
export default MoreInfo;