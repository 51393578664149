const AuthorService = {
    getAuthor: function(data) {
        if (!data) {
            return [];
        }

        var user = [];
        if (data.author || data.committer) {
            user.push(data.author);
            user.push(data.committer);
        } else {
            if (data.commits) {
                data.commits.forEach((commit) => {
                    user.push(commit.committer);
                    user.push(commit.author);
                });
            }
        }
        return Array.from(new Set(user.map(a => a.emailAddress)))
                    .map(emailAddress => {
                        return user.find(a => a.emailAddress === emailAddress)
                    });
    },

};

export default AuthorService;