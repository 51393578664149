import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import gql from 'graphql-tag';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import '../../project/project.css';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});



const UPDATE_JOB = gql`
    mutation updateJob(
        $jobName: String!,
        $expression: String,
        ) {
            updateJob(
                jobName: $jobName,
                expression: $expression) 
    }
`;

const GET_BY_JOB = gql`
    query jobByJobName($jobName: String!) {
        jobByJobName(jobName: $jobName) {
            jobName
            duration
            lastRunTime
            startTime
            cronStatus
            endTime
            message{
                _id,
                error
            }
            parameter
            enabled
            expression
        }
    }
`;

class EditSchedulerManagement extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            jobName: '',
            duration: '',
            lastRunTime: '',
            startTime: '',
            cronStatus: '',
            endTime: '',
            message: '',
            parameter: '',
            enabled: '',
            expression: '',
            submitted: false,
        };
        this.handleDialog = this.handleDialog.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleDialog() {
        this.props.onUpdate();
        this.props.refresh();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'select' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit() {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });
    }

    submit = () => {
        this.form.submit();
    }

    validatorListener = (result) => {
        this.setState({ disabled: !result });
    }



    render() {

        return (
            <Query fetchPolicy="no-cache" query={GET_BY_JOB} variables={{ jobName: this.props.jobData && this.props.jobData.jobName }}>

                {({ loading, error, data }) => {
                    this.isError = error;
                    if (!this.state.jobName || this.state.jobName !== data.jobByJobName.jobName) {
                        if (data && data.jobByJobName) {
                            this.setState({
                                lastData: data.jobByJobName
                            });
                            for (var key in data.jobByJobName) {
                                this.setState({
                                    [key]: data.jobByJobName[key]
                                });
                            }
                        }
                    }
                    
                    return (
                        <Mutation mutation={UPDATE_JOB} onCompleted={() => this.handleDialog()}>
                            {(jobByJobName, { loading, error }) => (
                                <ValidatorForm id="updateJob"
                                    ref={(r) => { this.form = r; }}
                                    onSubmit={e => {
                                        if (!this.state.jobName) {
                                            delete this.state.jobName;
                                        }
                                        this.setState({ submitted: true }, () => {
                                            setTimeout(() => this.setState({ submitted: false }), 1000);
                                        });

                                        jobByJobName({ variables: { _id: this.state.message._id } });
                                    }}
                                    instantValidate
                                >
                                    <Dialog maxWidth={"md"} fullWidth={true}
                                        fullScreen={window.innerWidth < 600}
                                        open={this.props.showModal}>
                                        <DialogTitle id="customized-dialog-title"
                                            onClose={this.handleDialog}>{'Message'} </DialogTitle >
                                        {loading ? <LinearProgress color="primary" /> : <Divider />}
                                        <DialogContent className="project-group-edit-container">
                                            {loading && <div className="loading"><CircularProgress size={50} /></div>}
                                            <table className="table w-100">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>ERROR</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.props.jobData.message.map(messages => {
                                                        return (<tr>
                                                            <td>{messages._id.toUpperCase()}</td>
                                                            <td>{messages.error}</td>
                                                        </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                        </DialogContent>
                                        <Divider />
                                        <DialogActions>
                                            <Button onClick={this.handleDialog} color="secondary">
                                                Close
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </ValidatorForm>
                            )
                            }
                        </Mutation>
                    )

                }
                }
            </Query >
        )
    }
}

export default EditSchedulerManagement
