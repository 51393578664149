import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import gql from 'graphql-tag';

import Auth from '../../../../core/service/auth';
import ManualHistoryList from './ManualHistoryList';

const USER_COMMENT = gql`
    mutation addressedManualReview(
        $reviewId: String!,
        $userId: String!,
		$userName: String!,
		$fullName: String!,
		$emailId: String,
		$message: String) {
            addressedManualReview(
                reviewId: $reviewId,
                userId: $userId,
                userName: $userName,
                fullName: $fullName,
                emailId: $emailId,
                message: $message
            ) 
    }
`;

const COMPLETE_REVIEW_COMMENT = gql`
    mutation completeManualReview(
        $reviewId: String!,
        $userId: String!,
		$userName: String!,
		$fullName: String!,
		$emailId: String,
		$message: String) {
            completeManualReview(
                reviewId: $reviewId,
                userId: $userId,
                userName: $userName,
                fullName: $fullName,
                emailId: $emailId,
                message: $message
            ) 
    }
`;

const REJECT_REVIEW_COMMENT = gql`
    mutation rejectedManualReview(
        $reviewId: String!,
        $userId: String!,
		$userName: String!,
		$fullName: String!,
		$emailId: String,
		$message: String) {
            rejectManualReview(
                reviewId: $reviewId,
                userId: $userId,
                userName: $userName,
                fullName: $fullName,
                emailId: $emailId,
                message: $message
            ) 
    }
`;

const BULK_IGNORE_COMMENTS = gql`
    mutation bulkIgnoreReviewComments(
        $reviewId: String!,
        $userId: String!,
		$userName: String!,
		$fullName: String!,
		$emailId: String,
		$message: String) {
            bulkIgnoreReviewComments(
                reviewId: $reviewId,
                userId: $userId,
                userName: $userName,
                fullName: $fullName,
                emailId: $emailId,
                message: $message
            ) 
    }
`;

const BULK_APPROVE_COMMENTS = gql`
    mutation bulkApproveReviewComments(
        $reviewId: String!,
        $userId: String!,
		$userName: String!,
		$fullName: String!,
		$emailId: String,
		$message: String) {
            bulkApproveReviewComments(
                reviewId: $reviewId,
                userId: $userId,
                userName: $userName,
                fullName: $fullName,
                emailId: $emailId,
                message: $message
            ) 
    }
`;

const BULK_REJECT_COMMENTS = gql`
    mutation bulkRejectReviewComments(
        $reviewId: String!,
        $userId: String!,
		$userName: String!,
		$fullName: String!,
		$emailId: String,
		$message: String) {
            bulkRejectReviewComments(
                reviewId: $reviewId,
                userId: $userId,
                userName: $userName,
                fullName: $fullName,
                emailId: $emailId,
                message: $message
            ) 
    }
`;

var users = JSON.parse(localStorage.getItem('users'));

class ManualCommentsAddressed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: ''
        }
        this.handelChange = this.handelChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handelChange(e) {
        this.setState({
            message: e.target.value
        })
    }

    handleClose() {
        this.setState({
            message: ''
        })
        this.props.onUpdate();
        this.props.refresh();
    }

    render() {
        return (

            <Dialog
                open={this.props.showModal}
                onClose={this.handleClose}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle>{this.props.isReviewComments ? !this.props.isApprovel ? 'Ignore all the comments' : 'Approve All the Comments' : 'Comments'}</DialogTitle>
                <Divider />
                <DialogContent>
                    <div className="row p-3 comment-history-info-container">
                        <div style={{ width: '100%' }}>
                            <div className="comments-section comment-history">
                                <ManualHistoryList isReviewComments={this.props.isReviewComments}
                                    isApprovel={this.props.isApprovel}
                                    isManualReviewStatus={this.props.isManualReviewStatus}
                                    data={this.props.data.review.manualReviewActions} />
                            </div>
                            {(this.props.isReviewComments) && <div className="comments d-flex">
                                <span className="w-100">
                                    <div className="w-100 comment-cont-manual">
                                        <textarea className="add-comments-text border-0 w-100"
                                            placeholder="Justification"
                                            onChange={this.handelChange}
                                            required
                                            value={this.state.message}>
                                        </textarea>
                                    </div>
                                </span>
                            </div>}
                            {(!this.props.isReviewComments) &&
                                ((this.props.isManualReviewStatus === 'Manual Comments Added' && !this.props.isApprovel) || (this.props.isManualReviewStatus === 'Manual Comments Addressed' && this.props.isApprovel)) &&
                                <div className="comments d-flex">
                                    <span className="w-100">
                                        <div className="w-100 comment-cont-manual">
                                            <textarea className="add-comments-text border-0 w-100"
                                                placeholder="Justification"
                                                onChange={this.handelChange}
                                                value={this.state.message}>
                                            </textarea>
                                        </div>
                                    </span>
                                </div>}
                        </div>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" autoFocus>close</Button>
                    {!this.props.isApprovel && this.props.isReviewComments &&
                        <Mutation mutation={BULK_IGNORE_COMMENTS} onCompleted={() => { this.handleClose() }}>
                            {(bulkIgnoreReviewComments, { loading, error }) => {
                                return (
                                    <Tooltip title="Request to Ignore All" placement="top" >
                                        <Button onClick={() => {
                                            bulkIgnoreReviewComments({
                                                variables: {
                                                    reviewId: this.props.reviewId,
                                                    message: this.state.message || 'Please Ignore The Comments',
                                                    userId: users.id,
                                                    fullName: users.name,
                                                    userName: users.username,
                                                    emailId: users.emailId,
                                                }
                                            })
                                        }} variant="contained" disabled={loading} color="primary" autoFocus>
                                            Request
                                        </Button>
                                    </Tooltip>
                                )
                            }}
                        </Mutation>
                    }
                    {this.props.isApprovel && this.props.isReviewComments &&
                        <Mutation mutation={BULK_APPROVE_COMMENTS} onCompleted={() => { this.handleClose() }}>
                            {(bulkApproveReviewComments, { loading, error }) => {
                                return (
                                    <Button onClick={() => {
                                        bulkApproveReviewComments({
                                            variables: {
                                                reviewId: this.props.reviewId,
                                                message: this.state.message || 'Ignored The Comments',
                                                userId: users.id,
                                                fullName: users.name,
                                                userName: users.username,
                                                emailId: users.emailId,
                                            }
                                        })
                                    }} variant="contained" disabled={loading} color="primary" autoFocus>
                                        Approve All
                                </Button>
                                )
                            }}
                        </Mutation>
                    }
                    {this.props.isApprovel && this.props.isReviewComments &&
                        <Mutation mutation={BULK_REJECT_COMMENTS} onCompleted={() => { this.handleClose() }}>
                            {(bulkRejectReviewComments, { loading, error }) => {
                                return (
                                    <Button onClick={() => {
                                        bulkRejectReviewComments({
                                            variables: {
                                                reviewId: this.props.reviewId,
                                                message: this.state.message || 'Rejected all the comments',
                                                userId: users.id,
                                                fullName: users.name,
                                                userName: users.username,
                                                emailId: users.emailId,
                                            }
                                        })
                                    }} variant="contained" disabled={loading} color="secondary" autoFocus>
                                        Reject All
                                </Button>
                                )
                            }}
                        </Mutation>
                    }
                    {!this.props.isReviewComments && !this.props.isApprovel && this.props.isManualReviewStatus === 'Manual Comments Added' &&
                        <Mutation mutation={USER_COMMENT} onCompleted={() => { this.handleClose() }}>
                            {(addressedManualReview, { loading, error }) => {
                                return (
                                    <Button onClick={() => {
                                        addressedManualReview({
                                            variables: {
                                                reviewId: this.props.reviewId,
                                                message: this.state.message || 'Manual Comments Addressed',
                                                userId: users.id,
                                                fullName: users.name,
                                                userName: users.username,
                                                emailId: users.emailId,
                                            }
                                        })
                                    }} variant="contained" disabled={loading} color="primary" autoFocus>
                                        Request
                                </Button>
                                )
                            }}
                        </Mutation>
                    }
                    {!this.props.isReviewComments
                        && Auth.hasRole(users, ['ROLE_APPROVER'])
                        && this.props.isApprovel
                        && (this.props.isManualReviewStatus === 'Manual Comments Addressed' || this.props.isManualReviewStatus === 'Manual Review Required') &&
                        <Mutation mutation={COMPLETE_REVIEW_COMMENT} onCompleted={() => { this.handleClose() }}>
                            {(completeManualReview, { loading, error }) => {
                                return (
                                    <Button onClick={() => {
                                        completeManualReview({
                                            variables: {
                                                reviewId: this.props.reviewId,
                                                message: this.state.message || 'Manual Review Completed',
                                                userId: users.id,
                                                fullName: users.name,
                                                userName: users.username,
                                                emailId: users.emailId,
                                            }
                                        })
                                    }} variant="contained" color="primary" disabled={loading} autoFocus>
                                        Approve
                                </Button>
                                )
                            }}
                        </Mutation>
                    }
                    {!this.props.isReviewComments && Auth.hasRole(users, ['ROLE_APPROVER']) && this.props.isApprovel && this.props.isManualReviewStatus === 'Manual Comments Addressed' &&
                        <Mutation mutation={REJECT_REVIEW_COMMENT} onCompleted={() => { this.handleClose() }}>
                            {(rejectedManualReview, { loading, error }) => {
                                return (
                                    <Button onClick={() => {
                                        rejectedManualReview({
                                            variables: {
                                                reviewId: this.props.reviewId,
                                                message: this.state.message || 'Manual Comments Addressed',
                                                userId: users.id,
                                                fullName: users.name,
                                                userName: users.username,
                                                emailId: users.emailId,
                                            }
                                        })
                                    }} variant="contained" disabled={loading} color="secondary" autoFocus>
                                        Reject
                                </Button>
                                )
                            }}
                        </Mutation>
                    }
                </DialogActions>
            </Dialog>

        );
    }
}

export default ManualCommentsAddressed;