import React from 'react';
import Summary from './Summary';
import Release from './Release';
import Linter from './Linter';
import UserManual from './UserManual';
import QualityBot from './QualityBot'

function WikiPage(props) {
    if (props.content) {
        if (!props.isRelease) {
            return (
                <QualityBot content={props.content} />
            )
        }if (props.content.heading && props.isRelease) {
            return (
                <Release content={props.content} />
            )
        }if(props){
            return (
                <UserManual content={props.content} />
            )
        } else {
            return <Summary content={props.content} />
        }
    } else {
        return (
            <Linter />
        );
    }

}

export default WikiPage;